import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ImportHistoryListingColumns } from "../../../Db";
import ImportRowComponent from "../../import_row_component/ImportRowComponent";
import { pageSize } from "../../../Config";
import PaginationComponent from "../../pagination/PaginationComponent";
import LoadingAnimationComponent from "../../loading_component/LoadingAnimationComponent";
import {  getImportsListApi } from "../../../Api";
const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
`;
const EmptyText = styled(Typography)`
  margin: 0px;
  font: normal normal 500 14px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
  margin-top: 50px;
`;
const LeadImportsDataTable = ({ refresh, setRefresh, scope }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);

  const getImportsList = async ({ page_no, page_size }) => {
    let queryParams = { scope: scope };
    let response = await getImportsListApi({
      page_no: page_no,
      page_size: page_size,
      queryParams: queryParams,
    });

    if (Object.keys(response).length > 0) {
      setData(response.items);
      setCount(response.item_count);
    } else {
      setData([]);
      setCount(0);
    }
  };

  const init = async () => {
    await getImportsList({ page_no: 0, page_size: pageSize });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setLoading(true);
    await getImportsList({ page_no: 0, page_size: size });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setLoading(true);
    setSize(e.target.value);
    setPage(0);
    await getImportsList({
      page_no: 0,
      page_size: e.target.value,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getImportsList({ page_no: page - 1, page_size: size });

    setLoading(false);
  };

  return !loading ? (
    <Box sx={{ width: "100%", flexDirection: "column" }}>
      <TableWrapper>
        {data.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              display: "table",
              tableLayout: "fixed",
              minHeight: "calc(100vh - 218px)",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ backgroundColor: "#EFEFF4", height: "40px" }}>
                <TableRow>
                  {ImportHistoryListingColumns.map((e) => {
                    return (
                      <TableCell className="textContainer">
                        {e.headerName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((e, i) => {
                  return (
                    <ImportRowComponent
                      item={e}
                      columns={ImportHistoryListingColumns}
                      idx={i}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <TableContainer
              component={Paper}
              style={{ width: "100%", overflowX: "auto" }}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead
                  style={{ backgroundColor: "#EFEFF4", height: "40px" }}
                >
                  <TableRow>
                    {ImportHistoryListingColumns.map((e) => {
                      return (
                        <TableCell className="textContainer">
                          {e.headerName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                width: "100%",
                height: "calc(100vh - 305px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EmptyText>No rows</EmptyText>
            </Box>
          </>
        )}
        <Box sx={{ padding: "0px 20px" }}>
          <PaginationComponent
            page_no={page}
            row={data}
            page_size={size}
            size={size}
            count={count}
            handlePageSize={handlePageSize}
            handlePage={handlePagination}
          />
        </Box>
      </TableWrapper>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 218px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(LeadImportsDataTable);
