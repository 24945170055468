import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal } from "@material-ui/core";
import {
  Box,
  Button,
  Typography,
  Divider,
  AppBar,
  Toolbar,
} from "@mui/material";
import { consoleLogger, getLocalizedText } from "../../Functions";
import ImportFileScreen from "./screens/ImportFileScreen";
import rootStore from "../../stores/RootStore";
import { LoadingButton } from "@mui/lab";
import Papa from "papaparse";
import ColumnMappingScreen from "./screens/ColumnMappingScreen";
import { createCustomerApi, createImportApi, updateImportApi } from "../../Api";
import ResultScreen from "./screens/ResultScreen";
import MuiAlert from "@mui/material/Alert";
import CreateCustomerProgressScreen from "./screens/CreateCustomerProgressScreen";
import moment from "moment";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 600px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const ImportCustomersDialog = ({ open, handleClose, handleCloseResult }) => {
  const [showFileErrorMessage, setShowFileErrorMessage] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [fileData, setFileData] = useState([]);
  const customerColumns = [...rootStore.customerStore.columnsList].filter(
    (column) => column["is_visible"] === true
  );
  const [uploadedFileColumns, setUploadedFileColumns] = useState([]);
  const [file, setFile] = useState(null);
  const [fileAndCustomerColumnMapping, setFileAndCustomerColumnMapping] =
    useState({});

  const [isImporting, setIsImporting] = useState(false);
  const [isImportDone, setIsImportDone] = useState(false);
  const [createProgressMessage, setCreateProgressMessage] = useState("");
  const [results, setResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [fileName, setFileName] = useState("");

  const handlePrevButton = () => {
    setFormStep(formStep - 1);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setShowFileErrorMessage(false);
      setFileErrorMessage("");
      setIsLoadingFile(true);
      let startTime = performance.now();
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        worker: true,
        complete: function (results) {
          let elapsedTime = (performance.now() - startTime) / 1000;
          consoleLogger("Performance time for parsing csv:", elapsedTime, "s");
          let rowCount = Array.isArray(results.data) ? results.data.length : 0;
          consoleLogger("ROW Count:", rowCount);
          if (rowCount === 0) {
            setFileErrorMessage("Error: No data is present in uploaded file.");
            setShowFileErrorMessage(true);
          } else {
            if (rowCount - 1 > 1000) {
              // we can import upto 1000 customers
              setFileErrorMessage(
                "Error: The number of rows has exceeded the allowed limit."
              );
              setShowFileErrorMessage(true);
            } else if (rowCount === 1) {
              // there is only one entry which can be used for column headers, and no entries present to import
              setFileErrorMessage(
                "Import failed: Please ensure your import file contains customer data."
              );
              setShowFileErrorMessage(true);
            } else {
              let headers = results.data[0];
              consoleLogger("CSV Rows:", headers);
            }
          }
          const fileNameWithoutExt =
            file.name.substring(0, file.name.lastIndexOf(".")) || file.name; // file name without extension if present else returns file name directly
          setFileName(fileNameWithoutExt);
          setUploadedFileColumns(results.data[0]);
          setFileData(results.data);
          setFile(file);
          setIsLoadingFile(false);
        },
      });
    }
  };
  const getFileColumnHeaders = () => {
    let tempFileColumnHeaders = [];
    uploadedFileColumns.forEach((columnHeader) => {
      tempFileColumnHeaders.push({ label: columnHeader, key: columnHeader });
    });
    return tempFileColumnHeaders;
  };

  const generatePayloadData = ({ customerData }) => {
    let tempPayload = {};

    for (const key in fileAndCustomerColumnMapping) {
      let mappedData = fileAndCustomerColumnMapping[key];
      if (mappedData["checked"] === true) {
        let index = customerColumns.findIndex(
          (column) =>
            column["column_name"] ===
            mappedData["customer_column"]["column_name"]
        );

        if (index !== -1) {
          let columnMeta = customerColumns[index];
          if (customerData.hasOwnProperty(key)) {
            let value = customerData[key];
            if (value !== null && value !== "") {
              if (columnMeta["data_type"] === "integer") {
                value = parseInt(customerData[key]);
              } else if (columnMeta["data_type"] === "decimal") {
                value = parseFloat(customerData[key]);
              } else if (
                columnMeta["data_type"] === "date" ||
                columnMeta["data_type"] === "datetime"
              ) {
                let momentDateTime = moment(customerData[key]);
                if (momentDateTime.isValid()) {
                  value = momentDateTime.toISOString();
                } else {
                  value = null;
                }
              }
            }
            if (columnMeta["is_custom_param"]) {
              let keyName = columnMeta["column_name"].replace(
                "custom_params_",
                ""
              );
              if (!tempPayload.hasOwnProperty("custom_params")) {
                tempPayload["custom_params"] = {};
              }
              tempPayload["custom_params"][keyName] = value;
            } else {
              tempPayload[columnMeta["column_name"]] = value;
            }
          }
        }
      }
    }
    return tempPayload;
  };

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(fileAndCustomerColumnMapping).forEach((item) => {
      if (fileAndCustomerColumnMapping[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of customers are mapped or not
  const allCustomerRequiredColumnsFilled = () => {
    let requiredColumnList = customerColumns.filter(
      (column) => column.required
    );
    const checkedFileToCustomerMapping = Object.keys(
      fileAndCustomerColumnMapping
    )
      .filter((key) => fileAndCustomerColumnMapping[key].checked === true)
      .reduce((result, key) => {
        result[key] = fileAndCustomerColumnMapping[key];
        return result;
      }, {});

    let flag = requiredColumnList.every((item) => {
      return Object.values(checkedFileToCustomerMapping).some(
        (obj) => obj["customer_column"]["id"] === item.id
      );
    });

    return flag;
  };

  const createImport = async ({ totalRows }) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", fileName);
    formData.append("entity", "contact");
    formData.append("assignment_rule", "{}");
    formData.append("xdata", "{}");
    formData.append("import_type", "create_customers");
    formData.append("total_rows", totalRows);

    let response = await createImportApi({
      payload: formData,
    });
    return response;
  };

  const updateImport = async ({ importID, additionalDetails }) => {
    let formData = new FormData();
    formData.append("status", "completed");
    for (const key in additionalDetails) {
      formData.append(key, additionalDetails[key]);
    }
    let response = await updateImportApi({
      importID: importID,
      payload: formData,
    });
    return response;
  };

  const handleImport = async () => {
    let payloadDataList = [];
    let customerEntriesFromFile = fileData.slice(1);
    let totalRows = customerEntriesFromFile.length;
    let createImportResponse = await createImport({
      totalRows: totalRows,
    });
    if (createImportResponse.hasError()) {
      setErrorMessage(createImportResponse.errorMessage);
      setShowErrorMessage(true);
      return;
    }
    setIsImporting(true);
    setCreateProgressMessage(`Creating (1/${totalRows}).`); // setting the initial create message
    customerEntriesFromFile.forEach((entry) => {
      let customerData = {};
      // here each entry is a list of value strings (from imported file)
      entry.forEach((value, index) => {
        customerData[uploadedFileColumns[index]] = value;
      });

      //generating payload data with correct data types - to send it in create api in the form of form data
      let payloadData = generatePayloadData({ customerData: customerData });
      payloadDataList.push(payloadData);
    });
    const responseList = [];
    const errorResponseList = []; // to contain only error results
    let rowsCreated = 0;
    let rowsErrored = 0;
    let rowsProcessed = 0;
    let importID =
      createImportResponse.data !== null &&
      createImportResponse.data.hasOwnProperty("id")
        ? createImportResponse.data["id"]
        : "";
    for (let i = 0; i < payloadDataList.length; i++) {
      let payloadData = payloadDataList[i];
      let response = await createCustomerApi({
        payload: payloadData,
      });
      let customerName = payloadData.hasOwnProperty("first_name")
        ? payloadData["first_name"]
        : "";
      if (response.hasError()) {
        rowsErrored += 1;
        let result = {
          row_number: i + 1,
          name: customerName,
          status: "failure",
          message: "Error creating customer!",
        };
        responseList.push(result);
        errorResponseList.push(result);
      } else {
        rowsCreated += 1;
        let result = {
          row_number: i + 1,
          name: customerName,
          status: "success",
          message: "Customer created successfully!",
        };
        responseList.push(result);
      }
      rowsProcessed += 1;
      setCreateProgressMessage(
        `Creating (${i + 1}/${payloadDataList.length}).`
      );

      // Determine status
      let importStatus =
        rowsProcessed === totalRows ? "completed" : "processing";

      // Call updateImport after every 100 entries
      if (rowsProcessed % 100 === 0 || rowsProcessed === totalRows) {
        let additionalDetails = {
          total_rows: totalRows,
          rows_processed: rowsProcessed,
          rows_created: rowsCreated,
          rows_errored: rowsErrored,
          status: importStatus,
        };

        // once all entries create api calls are done - send error log and full log to server
        if (importStatus === "completed") {
          // Convert response lists to CSV
          const fullLogCSV = generateCSV(responseList, "full_log.csv");
          const errorLogCSV = generateCSV(errorResponseList, "error_log.csv");
          additionalDetails["error_log"] = errorLogCSV;
          additionalDetails["full_log"] = fullLogCSV;
        }
        await updateImport({
          importID,
          additionalDetails: additionalDetails,
        });
      }
    }
    setResults(responseList);
    setIsImportDone(true);
  };

  const generateCSV = (data, fileName) => {
    let startTime = performance.now(); // Start performance timer

    let csvContent = Papa.unparse(data);

    let elapsedTime = (performance.now() - startTime) / 1000; // End performance timer
    consoleLogger(
      `Performance time for generating ${fileName}:`,
      elapsedTime,
      "s"
    );

    return new File([csvContent], fileName, { type: "text/csv" });
  };

  const renderFormSection = () => {
    if (formStep === 0) {
      return (
        <ImportFileScreen
          handleFileUpload={(event) => {
            handleFileUpload(event);
          }}
          file={file}
          fileErrorMessage={fileErrorMessage}
          showFileErrorMessage={showFileErrorMessage}
          fileName={fileName}
          handleFileName={(fileName) => setFileName(fileName)}
        />
      );
    } else if (formStep === 1) {
      if (!isImporting) {
        return (
          <ColumnMappingScreen
            fileColumnHeaders={getFileColumnHeaders()}
            fileAndCustomerColumnMapping={fileAndCustomerColumnMapping}
            setFileAndCustomerColumnMapping={setFileAndCustomerColumnMapping}
            customerColumnsList={customerColumns.sort(
              (a, b) => b.required - a.required
            )}
          />
        );
      } else {
        if (!isImportDone) {
          return (
            <CreateCustomerProgressScreen
              loadingMessage={createProgressMessage}
              noteMessage={
                "Please do not refresh or leave this tab while your data is being imported."
              }
            />
          );
        } else {
          return <ResultScreen data={results} />;
        }
      }
    }
  };

  const RenderButtons = () => {
    if (isImportDone) {
      return (
        <Button
          onClick={handleCloseResult}
          type="button"
          variant="contained"
          color="inherit"
          style={{
            textTransform: "none",

            fontSize: "12px",
            fontWeight: "bold",
            width: "100px",
          }}
        >
          {getLocalizedText("close")}
        </Button>
      );
    } else {
      if (!isImporting) {
        if (formStep === 0) {
          return (
            <>
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("cancel")}
              </Button>
              {isLoadingFile ? (
                <LoadingButton
                  loading
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    width: "100px",
                  }}
                >
                  <span> {getLocalizedText("next")}</span>
                </LoadingButton>
              ) : (
                <Button
                  disabled={showFileErrorMessage === true || file === null}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100px",
                  }}
                >
                  {getLocalizedText("next")}
                </Button>
              )}
            </>
          );
        } else if (formStep === 1) {
          return (
            <>
              <Button
                onClick={handlePrevButton}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("prev")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",

                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("import")}
              </Button>
            </>
          );
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        disableEscapeKeyDown={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container
            component={"form"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (formStep < 1) {
                setFormStep(formStep + 1);
              } else {
                if (isAtleastOneColumnMapped()) {
                  if (allCustomerRequiredColumnsFilled()) {
                    handleImport();
                  } else {
                    setErrorMessage(
                      getLocalizedText(
                        "all_required_fields_for_customer_creation_are_not_mapped",
                        "project"
                      )
                    );
                    setShowErrorMessage(true);
                  }
                } else {
                  setErrorMessage(
                    getLocalizedText("please_map_atleast_one_column_to_proceed")
                  );
                  setShowErrorMessage(true);
                }
              }
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("import_customers")}</Header>

                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>

            <Divider style={{ margin: "16px", marginTop: "0px" }} />
            <Box
              style={{
                height: "100%",
                height: "100%",
                padding: "0px 16px",
              }}
            >
              {renderFormSection()}
            </Box>

            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: "100%",
                  position: "relative",
                }}
              >
                {showErrorMessage && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowErrorMessage(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <RenderButtons />
                    </Box>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default ImportCustomersDialog;
