import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../Functions";
import { CircularProgress, Typography } from "@mui/material";
import Select from "react-select";
import { getQuoteTemplateListApi } from "../../Api";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const CreateInvoiceModal = ({ open, setOpen, handlePostContinue }) => {
  const [quotationType, setQuotationType] = useState(null);

  const [quotationList, setQuotationList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleContinue = async (e) => {
    e.preventDefault();
    let index = quotationList.findIndex(
      (entry) => entry["id"] === quotationType["value"]
    );
    handlePostContinue(quotationList[index]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getQuotationList = async () => {
    let response = await getQuoteTemplateListApi();
    setQuotationList(response);
  };
  const init = async () => {
    await getQuotationList();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const getOptionsToRender = () => {
    let filteredList = quotationList.filter(
      (entry) => entry["invoice_template"] === null || entry["invoice_template"]
    );
    return filteredList.map((entry) => ({
      label: entry["name"],
      value: entry["id"],
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        {!loading ? (
          <Box component={"form"} onSubmit={handleContinue}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Create Invoice</Header>
                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Select Template</Label>
                      <Select
                        menuPosition="fixed"
                        options={getOptionsToRender()}
                        value={quotationType}
                        onChange={(selectedOption) => {
                          setQuotationType(selectedOption);
                        }}
                        closeMenuOnSelect={true}
                        styles={customStyles}
                        isClearable
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={quotationType === null}
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "420px",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ModelContainer>
    </Modal>
  );
};

export default CreateInvoiceModal;
