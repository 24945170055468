import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, styled, Typography } from "@mui/material";
const MenuText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
`;
const WidgetActionsComponent = ({
  canShowPageBreakOption,
  handleDelete,
  handleAddAbove,
  handleAddBelow,
  handleAddPageBreak,
}) => {
  const [anchorE1, setAnchorE1] = React.useState(null);
  const Open = Boolean(anchorE1);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };

  return (
    <>
      <IconButton
        style={{ padding: "0px" }}
        aria-label="more"
        id="long-button"
        aria-controls={Open ? "long-menu" : undefined}
        aria-expanded={Open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE1}
        open={Open}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleDelete();
            handleClose();
          }}
        >
          <MenuText>Remove Content</MenuText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            handleAddAbove();
            handleClose();
          }}
        >
          <MenuText>Add Content Above</MenuText>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();

            handleAddBelow();
            handleClose();
          }}
        >
          <MenuText>Add Content Below</MenuText>
        </MenuItem>
        {canShowPageBreakOption && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleAddPageBreak();
              handleClose();
            }}
          >
            <MenuText>Add Page Break</MenuText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default WidgetActionsComponent;
