import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { CircularProgress, Grid } from "@material-ui/core";
import { getProductDetailsApi } from "../../Api";
import { consoleLogger, IsoToLocalDate, IsoToLocalTime } from "../../Functions";
import rootStore from "../../stores/RootStore";
import PreviewPdfDialog from "../files_page_components/PreviewPdfDialog";
import PreviewImageDialog from "../files_page_components/PreviewImageDialog";
import InputFileViewComponent from "../add_product_sidepanel/components/InputFileViewComponent";
import MediaThumbnailCard from "../add_product_sidepanel/components/MediaThumbnailCard";
import PreviewVideoDialog from "../files_page_components/PreviewVideoDialog";
import { Circle } from "@mui/icons-material";

const ModelWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 24px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 8px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;
const fixedFields = ["name", "product_type", "sku", "desc"];

const ViewProductSidepanel = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const columnsList = [...rootStore.productStore.columnsList];

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [viewPdfDialog, setViewPdfDialog] = useState(false);
  const [viewVideoDialog, setViewVideoDialog] = useState(false);

  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [productMediaData, setProductMediaData] = useState({});
  const [productDetails, setProductDetails] = useState({});

  const getProductDetails = async () => {
    let response = await getProductDetailsApi({ productID: props.productID });
    return response;
  };
  const init = async () => {
    let productData = await getProductDetails();
    setProductDetails(productData);
    let dataToRender = generateDataToRender(productData);
    setDataList(dataToRender);
    let tempImageFiles = Object.keys(productData)
      .filter((key) => key.startsWith("image_"))
      .map((key) => productData[key])
      .filter((value) => value !== null);

    let tempDocFiles = Object.keys(productData)
      .filter((key) => key.startsWith("file_"))
      .map((key) => productData[key])
      .filter((value) => value !== null);

    for (const key in productData) {
      if (
        key === "product_image" ||
        key === "product_brochure" ||
        key === "product_video"
      ) {
        if (
          productData[key] !== null &&
          productData[key] !== "" &&
          productData[key].hasOwnProperty("url")
        ) {
          let tempObj = { type: key, data: productData[key] };
          setProductMediaData(tempObj);
        }
      }
    }
    setSelectedImages(tempImageFiles);
    setSelectedDocuments(tempDocFiles);
    setLoading(false);
  };

  const getColumnValue = (field, data_type, data) => {
    if (data.hasOwnProperty(field)) {
      let value = data[field];
      if (value !== null && value !== "") {
        if (data_type === "date") {
          value = IsoToLocalDate(value);
        } else if (data_type === "datetime") {
          value = IsoToLocalTime(value);
        }
      }
      return value !== null ? value : "";
    } else {
      return "";
    }
  };
  const generateDataToRender = (productData) => {
    let tempList = [];
    let filteredColumns = columnsList.filter((column) => column["is_visible"]);
    filteredColumns.forEach((column) => {
      let item = {
        display_name: column["display_name"],
        value: getColumnValue(
          column["column_name"],
          column["data_type"],
          productData
        ),
        column_name: column["column_name"],
      };
      tempList.push(item);
    });
    tempList.sort((a, b) => {
      let A = a.display_name.toLowerCase();
      let B = b.display_name.toLowerCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
    tempList = tempList.sort(
      (a, b) =>
        fixedFields.indexOf(a.column_name) - fixedFields.indexOf(b.column_name)
    );

    return tempList;
  };
  useEffect(() => {
    init();
  }, []);

  const handleImagePreview = (image) => {
    setSelectedFileUrl(image["url"]);
    setViewImageDialog(true);
  };
  const handleDocumentPreview = (doc) => {
    setSelectedFileUrl(doc["url"]);
    setViewPdfDialog(true);
  };

  const handleVideoPreview = (video) => {
    setSelectedFileUrl(video["url"]);
    setViewVideoDialog(true);
  };
  const RenderListOfStrings = ({ value }) => {
    let listOfObjects;

    if (value !== null && value !== "") {
      if (typeof value === "string") {
        try {
          listOfObjects = JSON.parse(value); // Parse if it's a JSON string
        } catch (error) {
          consoleLogger("Error parsing JSON string:", error);
          listOfObjects = {}; // Default to an empty object on error
        }
      } else if (typeof value === "object") {
        listOfObjects = value; // Assign directly if it's an object
      } else {
        consoleLogger("Invalid value type:", typeof value);
        listOfObjects = {};
      }
    } else {
      listOfObjects = {}; // Default to an empty object if `value` is null or empty
    }

    if (Object.keys(listOfObjects).length > 0) {
      return (
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableBody>
              {Object.keys(listOfObjects).map((key, idx) => (
                <TableRow key={idx}>
                  <TableCell sx={{ width: "50%" }}>{key}</TableCell>
                  <TableCell>{listOfObjects[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }

    return <Detail>-</Detail>;
  };

  const RenderBulletPoints = ({ value }) => {
    let list =
      value !== null && value !== "" && Array.isArray(value) ? value : [];
    if (list.length > 0) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {list.map((item) => {
            return (
              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Circle
                  style={{
                    width: "8px",
                    height: "8px",
                    marginTop: "6px",
                    color: "gray",
                  }}
                />
                <Detail>{item}</Detail>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <Detail>-</Detail>;
  };
  return !loading ? (
    <Box sx={{ width: 500 }}>
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Product Details</ModelHeader>
        </Toolbar>
      </AppBar>
      <ModelWrapper>
        <Grid container spacing={3}>
          {Object.keys(productMediaData).length > 0 && (
            <Grid item xs={12}>
              <MediaThumbnailCard
                mediaData={productMediaData}
                mediaUrl={productMediaData["data"]["url"]}
                handleRemove={() => {}}
                handleView={() => {
                  if (productMediaData["type"] === "product_image") {
                    handleImagePreview(productMediaData["data"]);
                  } else if (productMediaData["type"] === "product_brochure") {
                    handleDocumentPreview(productMediaData["data"]);
                  } else if (productMediaData["type"] === "product_video") {
                    handleVideoPreview(productMediaData["data"]);
                  }
                }}
                canEdit={false}
              />
            </Grid>
          )}
          {dataList.map((item) => {
            if (item["column_name"] === "tags") {
              return (
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{item.display_name}</Label>
                    <RenderBulletPoints value={item.value} />
                  </InputWrapper>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={6}>
                  <InputWrapper>
                    <Label>{item.display_name}</Label>
                    <Detail>{item.value !== "" ? item.value : "-"} </Detail>
                  </InputWrapper>
                </Grid>
              );
            }
          })}
          <Grid item xs={12}>
            <InputWrapper>
              <SectionHeader>Specifications</SectionHeader>
              <RenderListOfStrings
                value={
                  productDetails.hasOwnProperty("specifications")
                    ? productDetails["specifications"]
                    : null
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <SectionHeader>Additional Details</SectionHeader>
              <RenderListOfStrings
                value={
                  productDetails.hasOwnProperty("additional_details")
                    ? productDetails["additional_details"]
                    : null
                }
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <SectionHeader>Images</SectionHeader>
              {selectedImages.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedImages.map((image, idx) => {
                    return (
                      <InputFileViewComponent
                        canRemove={false}
                        fileURL={image["url"]}
                        handleRemove={() => {}}
                        handleView={() => handleImagePreview(image)}
                        scope={"image"}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <InputWrapper>
              <SectionHeader>Documents</SectionHeader>
              {selectedDocuments.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedDocuments.map((doc, idx) => {
                    return (
                      <InputFileViewComponent
                        canRemove={false}
                        fileURL={doc["url"]}
                        handleRemove={() => {}}
                        handleView={() => handleDocumentPreview(doc)}
                        scope={"pdf"}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Detail>-</Detail>
              )}
            </InputWrapper>
          </Grid>
        </Grid>
      </ModelWrapper>
      {viewPdfDialog && (
        <PreviewPdfDialog
          open={viewPdfDialog}
          setOpen={setViewPdfDialog}
          file={selectedFileUrl}
        />
      )}
      {viewImageDialog && (
        <PreviewImageDialog
          open={viewImageDialog}
          setOpen={setViewImageDialog}
          file={selectedFileUrl}
          isCreate={false}
        />
      )}
      {viewVideoDialog && (
        <PreviewVideoDialog
          fileUrl={selectedFileUrl}
          open={viewVideoDialog}
          setOpen={setViewVideoDialog}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 500,
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ViewProductSidepanel;
