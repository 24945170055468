import { FileUpload } from "@mui/icons-material";
import { Box, Card, CardActionArea, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomFilePickerDialog from "./CustomFilePickerDialog";
import ImageQuickViewComponent from "../quotation_builder_components/card_widgets/ImageQuickViewComponent";
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  text-align: center;
`;
const FilePickerCompoent = ({
  handleFileUrl,
  fileUrl,
  handleFileClick,
  handleFileRemove,
}) => {
  const [openFilePicker, setOpenFilePicker] = useState(false);
  return (
    <>
      <Card
        sx={{
          border: "1px solid #f9f9fc",
          bgcolor: "#f9f9fc",
          width: "120px",
        }}
      >
        {fileUrl !== "" && (
          <ImageQuickViewComponent
            fileURL={fileUrl}
            handleClick={handleFileClick}
            handleRemove={handleFileRemove}
          />
        )}
        <CardActionArea
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "6px",
            columnGap: "4px",
          }}
          onClick={() => {
            setOpenFilePicker(true);
          }}
        >
          <FileUpload
            style={{ width: "24px", height: "24px" }}
            color="primary"
          />
          <Label>Browse</Label>
        </CardActionArea>
      </Card>
      {openFilePicker && (
        <CustomFilePickerDialog
          open={openFilePicker}
          setOpen={setOpenFilePicker}
          handleHandleFilePick={(fileUrl) => {
            handleFileUrl(fileUrl);
            setOpenFilePicker(false);
          }}
        />
      )}
    </>
  );
};

export default FilePickerCompoent;
