import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { PDFViewer } from "@react-pdf/renderer";
import { Box, styled } from "@mui/material";
import QuoteWithContentLayout from "../pdf_layouts/QuoteWithContentLayout";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
const Appbar = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "40px",
  margin: "auto",
}));

const AppbarInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "100%",
  padding: `0px ${theme.spacing(2)}`,
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewCustomTemplateDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Container>
        <Appbar>
          <AppbarInnerContainer>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppbarInnerContainer>
        </Appbar>
        <PDFViewer
          showToolbar={false}
          style={{
            width: "100%",
            height: `calc(100vh - 47px)`,
          }}
        >
          <QuoteWithContentLayout data={data} />
        </PDFViewer>
      </Container>
    </Dialog>
  );
}
