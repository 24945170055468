import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import BasicDetailsCard from "./card_widgets/BasicDetailsCard";
import HeaderPropertyCard from "./card_widgets/HeaderPropertyCard";

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 112px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
  flex-direction: column;
  gap: 16px;
  display: flex;
`;
const BasicDetailsSection = ({
  basicDetails,
  setBasicDetails,
  companyDetails,
  handleCompanyDetails,
  setHeaderWidgetList,
  headerWidgetList,
}) => {
  return (
    <Container>
      <BasicDetailsCard
        basicDetails={basicDetails}
        setBasicDetails={setBasicDetails}
        setWidgetList={setHeaderWidgetList}
        widgetList={headerWidgetList}
      />
      <HeaderPropertyCard
        companyDetails={companyDetails}
        handleCompanyDetails={handleCompanyDetails}
      />
  
    </Container>
  );
};

export default BasicDetailsSection;
