import { Box, Grid, styled } from "@mui/material";
import React, { useCallback } from "react";
import MediaUploadCard from "./MediaUploadCard";
import MediaThumbnailCard from "./MediaThumbnailCard";
import { productMediaList } from "../../../Db";
const Container = styled(Box)`
  width: 100%;
  height: 100%;
`;
const MultiMediaUploadComponent = ({
  handleMediaUpload,
  mediaDetails,
  handleRemove,
  handleView,
  getFileUrl,
}) => {
  const RenderMediaCard = useCallback(() => {
    let isMediaUploaded = false;
    let mediaData = { type: "", icon: "", label: "" };
    const index = productMediaList.findIndex(
      (entry) =>
        mediaDetails[entry["type"]] !== null &&
        mediaDetails[entry["type"]] !== ""
    );
    if (index !== -1) {
      isMediaUploaded = true;
      mediaData = productMediaList[index];
    }

    if (isMediaUploaded) {
      return (
        <MediaThumbnailCard
          handleRemove={() => handleRemove(mediaData["type"])}
          handleView={() => handleView(mediaData["type"])}
          mediaData={mediaData}
          mediaUrl={
            mediaDetails[mediaData["type"]] !== null &&
            mediaDetails[mediaData["type"]] !== ""
              ? getFileUrl(mediaDetails[mediaData["type"]])
              : ""
          }
        />
      );
    } else {
      return (
        <Grid container>
          {productMediaList.map((media, idx) => {
            return (
              <Grid
                item
                xs={media["type"] === "product_image" ? 12 : 6}
                key={idx}
              >
                <MediaUploadCard
                  isDisabled={media["type"] === "product_video"}
                  handleMediaUpload={(file) => {
                    handleMediaUpload(file, media["type"]);
                  }}
                  mediaUrl={
                    mediaDetails[media["type"]] !== null &&
                    mediaDetails[media["type"]] !== ""
                      ? getFileUrl(mediaDetails[media["type"]])
                      : ""
                  }
                  mediaData={media}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }
  }, [mediaDetails]);

  return (
    <Container>
      <RenderMediaCard />
    </Container>
  );
};

export default MultiMediaUploadComponent;
