import React, { useState } from "react";
import { WhatsApp } from "@material-ui/icons";
import { Box, CardActionArea, Typography } from "@mui/material";
import { IconButton } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import rootStore from "../../../stores/RootStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { dataRenderer } from "../../../Functions";

const ItemWrapper = styled(CardActionArea)({
  display: "flex",
  padding: "20px",
});

const ItemLeft = styled(Box)({
  marginRight: "20px",
});

const ItemRight = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const Text2 = styled(Typography)({
  margin: "0px",
  fontWeight: 600,
  fontSize: "12px",
  fontFamily: "Poppins",
  color: "#2b2b2b",
  marginBottom: "2px",
});

const Subtitle = styled(Typography)({
  margin: "0px",
  fontWeight: 500,
  fontSize: "10px",
  fontFamily: "Poppins",
  color: "#2b2b2b",
});

const Text3 = styled(Typography)({
  margin: "0px",
  fontWeight: 500,
  fontSize: "10px",
  fontFamily: "Poppins",
  color: "#a3a3a3",
});

const Text = styled(Typography)({
  margin: "0px",
  fontWeight: 600,
  fontSize: "10px",
  fontFamily: "Open Sans",
  color: "#185dd2",
  cursor: "pointer",
  textTransform: "none",
});

const WabaNotificationComponent = (props) => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("uid");

  const projectNotifications =
    rootStore.wabaChatNotificationState.getNotificationsForProject(
      rootStore.authStore.currentProject.id
    );
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded((prevState) => !prevState);
  };

  const renderCardBody = () => {
    return projectNotifications.length <= 3 ? (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {projectNotifications
          .slice(0, expanded ? projectNotifications.length : 2) //only first two alerts are shown un collapsed view
          .map((message, index) => (
            <div key={index}>
              <Text
                sx={{ marginRight: "2px" }}
                onClick={() => {
                  //remove clicked notification
                  rootStore.wabaChatNotificationState.removeNotification(
                    message.lead_id,
                    rootStore.authStore.currentProject.id
                  );
                  rootStore.userStore.setWabaNotificationClicked();
                  navigate(`/users/${userID}/leads/${message.lead_id}`, {
                    state: {
                      lead_status: null,
                      scope: "waba_alert",
                    },
                  });
                  props.setAnchorEl(null);
                }}
              >
                {message.lead_name !== null && message.lead_name !== ""
                  ? dataRenderer(message.lead_name)
                  : dataRenderer(message.flid)}
              </Text>
            </div>
          ))}
      </Box>
    ) : (
      <Text3>You have {projectNotifications.length} new message(s)</Text3>
    );
  };

  return (
    <>
      <ItemWrapper
        style={{
          backgroundColor: "#f9f9fc",
        }}
        onClick={() => {
          if (projectNotifications.length > 3) {
            props.setShowWabaSidepanel(true);
          }
        }}
      >
        <ItemLeft>
          <WhatsApp
            style={{
              color: "#757575",
            }}
          />
        </ItemLeft>
        <ItemRight>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text2>WhatsApp</Text2>
            {projectNotifications.length <= 3 && (
              <>
                <Subtitle>{projectNotifications.length + " message(s)"}</Subtitle>
                {projectNotifications.length > 2 && (
                  <IconButton size={"small"} onClick={handleToggle}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                )}
              </>
            )}
          </Box>
          {renderCardBody()}
        </ItemRight>
      </ItemWrapper>
    </>
  );
};

export default observer(WabaNotificationComponent);
