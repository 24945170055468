import { Box, Grid, styled } from "@mui/material";
import React from "react";
import TextEditorCard from "./TextEditorCard";
const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
  border-radius: 4px;
`;

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 112px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
  flex-direction: column;
  gap: 16px;
  display: flex;
`;
const FooterDetailsCard = ({ widgetList, setWidgetList }) => {
  const renderWidgetCard = ({ widgetData }) => {
    if (widgetData["type"] === "text") {
      return (
        <TextEditorCard
          defaultFontSize="8px"
          editableData={widgetData}
          handleEdit={(htmlContent) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = htmlContent;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    }
  };
  return (
    <Container>
      <Wrapper>
        <Grid container spacing={2} justifyContent={"center"}>
          {widgetList.map((widget, idx) => {
            return (
              <Grid item xs={12} key={idx}>
                {renderWidgetCard({ widgetData: widget })}
              </Grid>
            );
          })}
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default FooterDetailsCard;
