import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
} from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { OutlinedInput, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { deactivateLeadDealApi } from "../../../Api";
import { getLocalizedText } from "../../../Functions";
import { myTheme } from "../../../themeUtils";
const dialogWidth = 420;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const WarningText = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const CloneDealDialog = (props) => {
  const [reason, setReason] = useState("");

  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = { deactivation_reason: reason };
    let response = await deactivateLeadDealApi({
      leadID: props.leadID,
      dealID: props.dealID,
      payload: payload,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.handleCloneDeal();
      handleClose();
    }
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>{getLocalizedText("clone_deal")}</Header>
                  <SubHeader>
                    {" "}
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <WarningText>
                      By cloning this deal, the current deal will be
                      deactivated. Do you wish to proceed?
                    </WarningText>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Reason*</Label>
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        value={reason}
                        type={"text"}
                        placeholder={"Enter Value"}
                        required
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      variant="contained"
                      color="default"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {}}
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                        ...myTheme.Button.btnBlue,
                      }}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default CloneDealDialog;
