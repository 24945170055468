import { OutlinedInput } from "@mui/material";
import React from "react";

const CustomDecimalField = ({
  fontSize = "12px",
  isRequired,
  value,
  fieldName,
  hintText,
  isDiabled,
  handleBlur,
  handleChange,
}) => {
  return (
    <OutlinedInput
      style={{ height: "38px" }}
      inputProps={{
        "aria-label": "weight",
        style: {
          fontSize: fontSize,
        },
      }}
      required={isRequired}
      value={value}
      name={fieldName}
      type={"number"}
      disabled={isDiabled}
      placeholder={hintText}
      onBlur={(event) => {
        handleBlur(event);
      }}
      onChange={(event) => {
        handleChange(event);
      }}
    />
  );
};

export default CustomDecimalField;
