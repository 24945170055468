import { Box, CircularProgress, styled, Typography } from "@mui/material";
import React from "react";
const Label = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #4d4e4f;
`;

const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const CreateCustomerProgressScreen = ({ loadingMessage, noteMessage }) => {
  return (
    <Wrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{ width: "30px", height: "30px" }}
          color="primary"
        />
        <Label>{loadingMessage}</Label>
      </Box>
      <Label style={{ fontSize: "12px" }}>
        <span style={{ color: "red" }}>*</span>
        {noteMessage}
      </Label>
    </Wrapper>
  );
};

export default CreateCustomerProgressScreen;
