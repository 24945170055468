import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextEditorCard from "./TextEditorCard";

const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
`;

const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 112px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const QuotationPropertyCard = ({
  editableData,
  handleQuotationProperties,
  scope,
  widgetList,
  setWidgetList,
}) => {
  const handleChange = (key, value) => {
    let tempObj = {
      expiry_date: editableData["expiry_date"],
      amount: editableData["amount"],
      quote_date: editableData["quote_date"],
    };
    tempObj[key] = value;

    handleQuotationProperties(tempObj);
  };

  const renderWidgets = () => {
    return widgetList.map((widgetData, idx) => {
      if (widgetData["type"] === "text") {
        return (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Quotation Title</Label>
              <TextEditorCard
                editableData={widgetData}
                handleEdit={(htmlContent) => {
                  let tempWidgetList = [...widgetList];
                  const index = tempWidgetList.findIndex(
                    (entry) => entry["id"] === widgetData["id"]
                  );
                  if (index !== -1) {
                    let widgetData = tempWidgetList[index];
                    widgetData["content"] = htmlContent;
                    tempWidgetList[index] = widgetData;
                    setWidgetList(tempWidgetList);
                  }
                }}
              />
            </InputWrapper>
          </Grid>
        );
      }
    });
  };

  return (
    <Container>
      <Wrapper>
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <InputWrapper sx={{ width: "200px" }}>
                {scope === "quote" ? (
                  <Label>Quote Date</Label>
                ) : (
                  <Label>Invoice Date</Label>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomizedDatePicker
                    onChange={(event) => {
                      handleChange("quote_date", event);
                    }}
                    value={editableData["quote_date"]}
                    inputFormat="YYYY-MM-DD"
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </InputWrapper>
            </Grid>
            <Grid item>
              <InputWrapper sx={{ width: "200px" }}>
                <Label>Expiry Date</Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CustomizedDatePicker
                    disabled={editableData["quote_date"] === null}
                    onChange={(event) => {
                      handleChange("expiry_date", event);
                    }}
                    minDate={editableData["quote_date"]}
                    value={editableData["expiry_date"]}
                    inputFormat="YYYY-MM-DD"
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "100%" }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </InputWrapper>
            </Grid>
            {renderWidgets()}
          </Grid>
        </Box>
      </Wrapper>
    </Container>
  );
};

export default QuotationPropertyCard;
