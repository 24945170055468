import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@material-ui/core";
import { AppBar, Button, Toolbar, Typography, Box } from "@mui/material";
import { Close } from "@material-ui/icons";
import { dataRenderer, getLocalizedText } from "../../../Functions";
import rootStore from "../../../stores/RootStore";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const Text = styled(Typography)({
  margin: "0px",
  marginBottom: "4px",
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Open Sans",
  color: "#2b2b2b",
  cursor: "pointer",
  textTransform: "none",
});

const ModelHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Open Sans",
}));

const NotificationContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#f9f9fc",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
  padding: "15px",
  position: "relative",
  overflow: "auto",
  gap: "8px",
}));

const WabaNotificationSidepanelComponent = (props) => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("uid");
  const projectNotifications =
    rootStore.wabaChatNotificationState.getNotificationsForProject(
      rootStore.authStore.currentProject.id
    );

  useEffect(() => {}, [
    rootStore.wabaChatNotificationState.notificationState.canShowBadge,
  ]);

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const handleNavigateToLead = (leadID) => {
    rootStore.wabaChatNotificationState.removeNotification(
      leadID,
      rootStore.authStore.currentProject.id
    );
    rootStore.userStore.setWabaNotificationClicked();
    navigate(`/users/${userID}/leads/${leadID}`, {
      state: {
        lead_status: null,
        scope: "waba_alert",
      },
    });
  };

  const handleRemove = (leadID) => {
    rootStore.wabaChatNotificationState.removeNotification(
      leadID,
      rootStore.authStore.currentProject.id
    );
  };

  return (
    <>
      <Box role="presentation" sx={{ overflow: "hidden" }}>
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>
              {getLocalizedText("whatsapp_notifications")}
            </ModelHeader>
            <IconButton
              onClick={handleDrawerClose}
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "20px",
                right: "30px",
                cursor: "pointer",
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <NotificationContainer>
        {projectNotifications.length === 0 ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "2px",
            }}
          >
            <Text>{getLocalizedText("no_notifications")}</Text>
          </Box>
        ) : (
          projectNotifications.map((message, index) => (
            <Box
              key={index}
              //onClick={() => }
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                height: "85px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  padding: "4px",
                }}
              >
                <Box
                  sx={{
                    height: "fit-content",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "2px",
                  }}
                >
                  <Text
                    sx={{ marginRight: "2px" }}
                    onClick={() => {
                      //remove clicked notification
                      rootStore.wabaChatNotificationState.removeNotification(
                        message.lead_id,
                        rootStore.authStore.currentProject.id
                      );
                      rootStore.userStore.setWabaNotificationClicked();
                      navigate(`/users/${userID}/leads/${message.lead_id}`, {
                        state: {
                          lead_status: null,
                          scope: "waba_alert",
                        },
                      });
                    }}
                  >
                    {message.lead_name !== null && message.lead_name !== ""
                      ? dataRenderer(message.lead_name)
                      : dataRenderer(message.flid)}{" "}
                  </Text>
                  <IconButton
                    onClick={(e) => {
                      handleRemove(message.lead_id);
                    }}
                    size={"small"}
                    sx={{
                      height: "14px",
                      width: "14px",
                      cursor: "pointer",
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>

                <Button
                  onClick={() => {
                    handleNavigateToLead(message.lead_id);
                  }}
                  variant="text"
                  color="primary"
                  sx={{ width: "25%" }}
                >
                  {getLocalizedText("go_to_lead")}
                </Button>
              </Box>
            </Box>
          ))
        )}
      </NotificationContainer>
    </>
  );
};

export default observer(WabaNotificationSidepanelComponent);
