import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, Drawer } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import AddDealSidePanel from "../add_deal_sidepanel/AddDealSidePanel";
import {
  getLeadActiveDealApi,
  getLeadDealListApi,
} from "../../Api";
import AddPredefinedDealSidePanel from "../add_deal_sidepanel/AddPredefinedDealSidepanel";
import DealDeatilsWithHistoryWidget from "./details_widgets/DealDetailsWithHistoryWidget";
import DeactivateDealDailog from "./details_widgets/DeactivateDealDialog";
import CloneDealDialog from "./details_widgets/CloneDealDialog";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 258px);`};
  margin-bottom: 20px;
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  ${`min-height: calc(100vh - 282px);`};
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
`;

const DealsTab = ({
  leadID,
  setOpenAddDealPanel,
  openAddDealPanel,
  openAddPredefinedDealPanel,
  setOpenAddPredefinedDealPanel,
  openDeactivateDealDialog,
  setOpenDeactivateDealDialog,
  openCloneDealDialog,
  setOpenCloneDealDialog,
  setHasDealList,
  setHasActiveDeal,
}) => {
  const [details, setDetails] = useState({});
  const [cloneDealDetails, setCloneDealDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [dealsHistoryList, setDealsHistoryList] = useState([]);
  const [scope, setScope] = useState("regular");

  const handleCloneDeal = () => {
    setCloneDealDetails(details);
    setRefresh(true);
    setScope("clone");
    setOpenAddDealPanel(true);
  };

  const fetchActiveDealAndDealList = async () => {
    let active_deal_response = await getLeadActiveDealApi({
      leadID: leadID,
    });
    let deal_list_response = await getLeadDealListApi({ leadID: leadID });
    setDetails(active_deal_response);
    setDealsHistoryList(deal_list_response);
    if (deal_list_response.length > 0) {
      setHasDealList(true);
    } else {
      setHasDealList(false);
    }
    if (Object.keys(active_deal_response).length > 0) {
      setHasActiveDeal(true);
    } else {
      setHasActiveDeal(false);
    }
  };

  const init = async () => {
    setLoading(true);
    await fetchActiveDealAndDealList();
    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await fetchActiveDealAndDealList();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const resetStateVariables = () => {
    setHasActiveDeal(false);
    setHasDealList(false);
    setOpenAddDealPanel(false);
    setOpenAddPredefinedDealPanel(false);
    setOpenDeactivateDealDialog(false);
  };

  useEffect(() => {
    init();
    return () => {
      resetStateVariables();
    };
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <DetailsWrapper>
              <DealDeatilsWithHistoryWidget
                details={details}
                dealsHistoryList={dealsHistoryList}
              />
            </DetailsWrapper>
          ) : (
            <DetailsWrapper
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <CircularProgress />
            </DetailsWrapper>
          )}
        </Wrapper>
      </Container>

      {openAddDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddDealPanel}
          onClose={() => {
            setOpenAddDealPanel(false);
            if (scope === "clone") {
              setScope("regular");
              setCloneDealDetails({});
            }
          }}
        >
          <AddDealSidePanel
            leadID={leadID}
            setRefresh={setRefresh}
            setOpen={setOpenAddDealPanel}
            open={openAddDealPanel}
            scope={scope}
            cloneDealDetails={cloneDealDetails}
            onCloneClose={() => {
              setScope("regular");
              setCloneDealDetails({});
            }}
          />
        </Drawer>
      )}

      {openAddPredefinedDealPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openAddPredefinedDealPanel}
          onClose={() => {
            setOpenAddPredefinedDealPanel(false);
          }}
        >
          <AddPredefinedDealSidePanel
            leadID={leadID}
            setRefresh={setRefresh}
            setOpen={setOpenAddPredefinedDealPanel}
            open={openAddPredefinedDealPanel}
          />
        </Drawer>
      )}
      {openDeactivateDealDialog && (
        <DeactivateDealDailog
          open={openDeactivateDealDialog}
          setOpen={setOpenDeactivateDealDialog}
          leadID={leadID}
          dealID={details["id"]}
          setRefresh={setRefresh}
        />
      )}
      {openCloneDealDialog && (
        <CloneDealDialog
          leadID={leadID}
          dealID={details["id"]}
          open={openCloneDealDialog}
          setOpen={setOpenCloneDealDialog}
          handleCloneDeal={handleCloneDeal}
        />
      )}
    </>
  );
};

export default observer(DealsTab);
