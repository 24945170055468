import { AppBar, Box, Button, Grid, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import MuiAlert from "@mui/material/Alert";
import ColumnMappingScreen from "./indiamart_mapping_screens/ColumnMappingScreen";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import DefaultParameterScreen from "./column_mapping_screens/DefaultParameterScreen";
import {
  createShikshaConfigApi,
  getAdminSystemUsersApi,
  updateShikshaConfigApi,
} from "../../../Api";
import ProjectAndNameFieldScreen from "./column_mapping_screens/ProjectAndNameFieldScreen";
import { LoadingButton } from "@mui/lab";

const dialogWidth = 600;
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 200px;
  width: 100%;
`;

const ViewShikshaIntegrationConfigDialog = ({
  open,
  setOpen,
  formData,
  integrationData,
  postSubmitCallback,
}) => {
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [formQuestionList, setFormQuestionsList] = useState([]);
  const [formAndProjectColumnMapping, setFormAndProjectColumnMapping] =
    useState({});
  const [selectedProjectID, setSelectedProjectID] = useState("");
  const [name, setName] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [selectedDefaultParameterMapping, setSelectedDefaultParameterMapping] =
    useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (formAndProjectColumnMapping[item]["checked"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of the selected project are mapped or not
  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checkedCurrToDestProjectList = Object.keys(
      formAndProjectColumnMapping
    )
      .filter((key) => formAndProjectColumnMapping[key].checked === true)
      .reduce((result, key) => {
        result[key] = formAndProjectColumnMapping[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checkedCurrToDestProjectList).some(
        (obj) => obj["project_lead"]["id"] === item.id
      );
    });

    return flag;
  };

  const handleConfirmation = async () => {
    setIsFormSubmitting(true);
    setShowConfirmationDialog(false);
    let payload = {
      destination_project_id: selectedProjectID,
      name: name,
    };
    let tempList = {};
    Object.keys(formAndProjectColumnMapping).forEach((key) => {
      if (formAndProjectColumnMapping[key]["checked"]) {
        tempList[key] = formAndProjectColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["mapping"] = tempList;
    payload["sync_type"] = "push";

    const systemUserData = await getAdminSystemUsersApi();
    payload["default_params"] = {
      ...selectedDefaultParameterMapping,
      lead_creator_id: systemUserData.hasOwnProperty("id")
        ? systemUserData["id"]
        : "",
    };

    let response = {};
    if (Object.keys(formData).length === 0) {
      response = await createShikshaConfigApi({ payload: payload });
    } else {
      response = await updateShikshaConfigApi({
        payload: payload,
        configID: formData["id"],
      });
    }

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setIsSubmitFail(false);
      postSubmitCallback(response.data);
    }
    setIsFormSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 2) {
      if (isAtleastOneColumnMapped()) {
        setIsSubmitFail(false);
        if (allProjectRequiredColumnsFilled()) {
          setShowConfirmationDialog(true);
        } else {
          setErrorMessage("All required fields are not mapped.");
          setIsSubmitFail(true);
        }
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else {
      setFormStep(formStep + 1);
      setIsSubmitFail(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else if (formStep < 2) {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              type="submit"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "fit-content",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              disabled={isFormSubmitting}
              variant="contained"
              color="default"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            {isFormSubmitting ? (
              <LoadingButton
                loading
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  width: "fit-content",
                }}
              >
                <span> {getLocalizedText("save")}</span>
              </LoadingButton>
            ) : (
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "fit-content",
                  textTransform: "none",
                }}
              >
                Save
              </Button>
            )}
          </Grid>
        </>
      );
    }
  };

  const init = async () => {
    if (Object.keys(formData).length > 0) {
      if (formData.hasOwnProperty("destination_project_id")) {
        setSelectedProjectID(formData.destination_project_id);
      }

      if (formData.hasOwnProperty("name") && formData["name"] !== null) {
        setName(formData["name"]);
      }
      let tempObj = {
        lead_owner_id: "",
        lead_status_id: "",
        lead_source_id: "",
        lead_stage_id: "",
        team_id: "",
      };

      if (
        formData.hasOwnProperty("default_params") &&
        formData["default_params"] !== null
      ) {
        tempObj["lead_owner_id"] =
          formData["default_params"].hasOwnProperty("lead_owner_id") &&
          formData["default_params"]["lead_owner_id"] !== null
            ? formData["default_params"]["lead_owner_id"]
            : "";

        tempObj["team_id"] =
          formData["default_params"].hasOwnProperty("team_id") &&
          formData["default_params"]["team_id"] !== null
            ? formData["default_params"]["team_id"]
            : "";

        tempObj["lead_status_id"] =
          formData["default_params"].hasOwnProperty("lead_status_id") &&
          formData["default_params"]["lead_status_id"] !== null
            ? formData["default_params"]["lead_status_id"]
            : "";

        tempObj["lead_source_id"] =
          formData["default_params"].hasOwnProperty("lead_source_id") &&
          formData["default_params"]["lead_source_id"] !== null
            ? formData["default_params"]["lead_source_id"]
            : "";
        tempObj["lead_stage_id"] =
          formData["default_params"].hasOwnProperty("lead_stage_id") &&
          formData["default_params"]["lead_stage_id"] !== null
            ? formData["default_params"]["lead_stage_id"]
            : "";
      }
      setSelectedDefaultParameterMapping(tempObj);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            {(() => {
              if (formStep === 0) {
                return (
                  <ProjectAndNameFieldScreen
                    nameFieldValue={name}
                    title={"Configure API Response"}
                    selectedProjectID={selectedProjectID}
                    setSelectedProjectID={setSelectedProjectID}
                    handleNameField={(value) => {
                      setName(value);
                    }}
                    handleProject={(value) => {
                      setSelectedProjectID(value);
                      setSelectedDefaultParameterMapping({
                        lead_owner_id: "",
                        lead_status_id: "",
                        lead_source_id: "",
                        lead_stage_id: "",
                        team_id: "",
                      });
                    }}
                  />
                );
              } else if (formStep === 1) {
                return (
                  <DefaultParameterScreen
                    formData={formData}
                    selectedDefaultParameterMapping={
                      selectedDefaultParameterMapping
                    }
                    setSelectedDefaultParameterMapping={
                      setSelectedDefaultParameterMapping
                    }
                    selectedProjectID={selectedProjectID}
                    loading={loading}
                    setLoading={setLoading}
                  />
                );
              } else {
                return (
                  <ColumnMappingScreen
                    projectID={selectedProjectID}
                    formData={formData}
                    projectColumnList={projectColumnList}
                    setProjectColumnList={setProjectColumnList}
                    formQuestionList={formQuestionList}
                    setFormQuestionsList={setFormQuestionsList}
                    formAndProjectColumnMapping={formAndProjectColumnMapping}
                    setFormAndProjectColumnMapping={
                      setFormAndProjectColumnMapping
                    }
                    type={integrationData.type}
                    loading={loading}
                    setLoading={setLoading}
                  />
                );
              }
            })()}

            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                  <RenderButton />
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        </ModelContainer>
      </Modal>
      {showConfirmationDialog && (
        <CustomConfirmationDialog
          handleConfirm={handleConfirmation}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
          message={"Are you sure you want to save the form changes?"}
        />
      )}
    </>
  );
};

export default ViewShikshaIntegrationConfigDialog;
