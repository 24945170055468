import { Box, Grid, styled } from "@mui/material";
import React from "react";
import TableComponentCard from "./card_widgets/TableComponentCard";
import ImageComponentCard from "./card_widgets/ImageComponentCard";
import TextEditorCard from "./card_widgets/TextEditorCard";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 64px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
`;
const RenderContentSection = ({ widgetList, setWidgetList }) => {
  const renderWidgetCard = ({ widgetData }) => {
    if (widgetData["type"] === "table") {
      return (
        <TableComponentCard
          editableData={widgetData}
          handleSaveTable={(tableData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = tableData;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "image") {
      return (
        <ImageComponentCard
          editableData={widgetData}
          handleEditImage={(imageData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = { image_url: imageData["image_url"] };
              widgetData["properties"] = {
                alignment: imageData["alignment"],
                size: imageData["size"],
                aspect_ratio: imageData["aspect_ratio"],
                orgWidth: imageData["orgWidth"],
                orgHeight: imageData["orgHeight"],
                modWidth: imageData["modWidth"],
                modHeight: imageData["modHeight"],
              };
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "text") {
      return (
        <TextEditorCard
          editableData={widgetData}
          handleEdit={(htmlContent) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = htmlContent;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    }
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"center"}>
        {widgetList.map((widget, idx) => {
          if (widget["type"] !== "page_break") {
            return (
              <Grid item xs={12} key={idx}>
                {renderWidgetCard({ widgetData: widget })}
              </Grid>
            );
          }
        })}
      </Grid>
    </Container>
  );
};

export default RenderContentSection;
