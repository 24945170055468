import { Box, IconButton, styled, Typography } from "@mui/material";
import React from "react";
import TableComponentCard from "./card_widgets/TableComponentCard";
import ImageComponentCard from "./card_widgets/ImageComponentCard";
import TextEditorCard from "./card_widgets/TextEditorCard";
import WidgetActionsComponent from "./WidgetActionsComponent";
import PageBreakCard from "./card_widgets/PageBreakCard";
import { Add } from "@material-ui/icons";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 64px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
`;
const AddContentBox = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 2px dashed #aeaeae;
  padding: 16px;
  border-radius: 4px;
`;
const Text = styled(Typography)`
  font: normal normal 600 16px Open Sans;
  margin: 0px;
  color: #4d4e4f;
`;
const RenderContentSection = ({
  widgetList,
  setWidgetList,
  handleAddAbove,
  handleAddBelow,
  handleAddPageBreak,
  handleAdd,
}) => {
  const renderWidgetCard = ({ widgetData }) => {
    if (widgetData["type"] === "table") {
      return (
        <TableComponentCard
          editableData={widgetData}
          handleSaveTable={(tableData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = tableData;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "image") {
      return (
        <ImageComponentCard
          editableData={widgetData}
          handleEditImage={(imageData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = { image_url: imageData["image_url"] };
              widgetData["properties"] = {
                alignment: imageData["alignment"],
                size: imageData["size"],
                aspect_ratio: imageData["aspect_ratio"],
                orgWidth: imageData["orgWidth"],
                orgHeight: imageData["orgHeight"],
                modWidth: imageData["modWidth"],
                modHeight: imageData["modHeight"],
              };
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "text") {
      return (
        <TextEditorCard
          editableData={widgetData}
          handleEdit={(htmlContent) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = htmlContent;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "page_break") {
      return <PageBreakCard />;
    }
  };

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {widgetList.map((widget, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                backgroundColor: "white",
                padding: "16px",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: "6px",
                }}
              >
                <WidgetActionsComponent
                  canShowPageBreakOption={widget["type"] !== "page_break"}
                  handleAddAbove={() => handleAddAbove(widget)}
                  handleAddBelow={() => handleAddBelow(widget)}
                  handleAddPageBreak={() => handleAddPageBreak(widget)}
                  handleDelete={() => {
                    let tempWidgetList = [...widgetList];
                    tempWidgetList = tempWidgetList.filter(
                      (entry) => entry["id"] !== widget["id"]
                    );
                    setWidgetList(tempWidgetList);
                  }}
                />
              </Box>
              {renderWidgetCard({ widgetData: widget })}
            </Box>
          );
        })}
        <AddContentBox>
          <IconButton color="primary" onClick={handleAdd}>
            <Add
              style={{
                width: "40px",
                height: "40px",
              }}
            />
          </IconButton>
          <Text>Add Content</Text>
        </AddContentBox>
      </div>
    </Container>
  );
};

export default RenderContentSection;
