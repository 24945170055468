import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createLeadInvoiceApi,
  editLeadInvoiceApi,
  getQuoteTemplateListApi,
  markLeadQuotationAsInvoicedApi,
} from "../../Api";
import InvoiceAndProuductInfo from "./screens/InvoiceAndProuductInfo";
import AddProductModal from "../add_quote_sidepanel/dialogs/AddProductModal";
import EditProductModal from "../add_quote_sidepanel/dialogs/EditProductModal";
import ViewProductModal from "../add_quote_sidepanel/dialogs/ViewProductModal";
import BillingInfo from "../add_quote_sidepanel/screens/BillingInfo";
import AdditionalInfo from "../add_quote_sidepanel/screens/AdditionalInfo";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  consoleLogger,
  embedVariableValuesInMessageString,
  getLocalizedText,
} from "../../Functions";
import { invoiceTaxOptions } from "../../Db";
import AddProductLineItemModal from "../add_quote_sidepanel/dialogs/AddProductLineItemModal";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const WarningAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="warning"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const AddInvoiceSidePanel = ({
  setOpen,
  activeDealDetails,
  leadID,
  leadData,
  setRefresh,
  existingInvoiceData,
  scope,
  setInvoiceScope,
  selectedQuoteTemplate,
}) => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    title: "",
    invoice_date: null,
    expiry_date: null,
  });
  const [invoiceTitle, setInvoiceTitle] = useState("Invoice");
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [quoteList, setQuoteList] = useState([]);
  const [selectedQuoteID, setSelectedQuoteID] = useState("");
  const [quotationTemplateConfig, setQuotationTemplateConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [amountDetails, setAmountDetails] = useState({
    sub_total: "",
    additional_discount: "",
    additional_tax: "",
    adjustments: "",
    grand_total: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    terms_and_conditions: "",
    additional_information: "",
  });
  const [tableAdditionalColumns, setTableAdditionalColumns] = useState([]);
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [showShippingAddress, setShowShippingAddress] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showAdditionalInformation, setShowAdditionalInformation] =
    useState(false);

  //tax reset handling on place_of_supply change
  const [showItemTaxWarning, setShowItemTaxWarning] = useState(false);
  const [itemTaxFlag, setItemTaxFlag] = useState(false);
  const [totalItemsReset, setTotalItemsReset] = useState(0);
  const [styleDetails, setStyleDetails] = useState({
    table_header: { background_color: "", text_color: "" },
    grand_total: { background_color: "", text_color: "" },
  });
  const [templateVersion, setTemplateVersion] = useState(null);
  const [productList, setProductList] = useState([]);
  const [openProductLineItemForm, setOpenProductLineItemForm] = useState(false);
  const [isEditProductLineItem, setIsEditProductLineItem] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setInvoiceScope("");
  };
  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const getCompanyDetails = () => {
    let index = quoteList.findIndex((quote) => quote["id"] === selectedQuoteID);
    return quoteList[index]["schema"]["company_details"];
  };

  const handleMarkAsInvoiced = async (quoteID) => {
    let response = await markLeadQuotationAsInvoicedApi({
      leadID: leadID,
      quoteID: quoteID,
    });
    return response;
  };

  const createInvoice = async (payload) => {
    payload["lead_id"] = leadID;
    if (scope === "to_invoice") {
      payload["quotation_id"] = existingInvoiceData["id"];
    }

    let response = await createLeadInvoiceApi({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      if (scope === "to_invoice") {
        const markAsInvoiceResponse = await handleMarkAsInvoiced(
          existingInvoiceData["id"]
        ); //marking as invoiced if converting from a quotation
        if (markAsInvoiceResponse.hasError()) {
          setIsSubmitSuccess(false);
          setErrorMessage(markAsInvoiceResponse.errorMessage);
          setIsSubmitFail(true);
        } else {
          setRefresh(true);
          setIsSubmitFail(false);
          setIsSubmitSuccess(true);
        }
      } else {
        setRefresh(true);
        setIsSubmitFail(false);
        setIsSubmitSuccess(true);
      }
    }
  };

  const editInvoice = async (payload) => {
    let response = await editLeadInvoiceApi({
      payload: payload,
      leadID: leadID,
      invoiceID: existingInvoiceData["id"],
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSubmit = async () => {
    let formData = {
      invoice_date: details.invoice_date,
      expiry_date: details.expiry_date,
      quotation_template_id: selectedQuoteID,
    };
    if (activeDealDetails.hasOwnProperty("id")) {
      formData["deal_id"] = activeDealDetails["id"];
    }
    let tempQuotationSchema = {
      invoice_title: details.title,
      styles: styleDetails,
      template_version: templateVersion,
    };
    tempQuotationSchema["additional_columns"] = tableAdditionalColumns;

    let itemList = [];

    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: getValidFloatValue(productItem["rate"]),
        amount: getValidFloatValue(productItem["amount"]),
        quantity: getValidIntegerValue(productItem["quantity"]),
        discount: getValidFloatValue(productItem["discount"]),
        tax: getValidFloatValue(productItem["tax"]),
        desc: productItem["desc"],
      };
      tempObj["name"] = productItem["item"]["name"];
      // while adding product line item - storing all product data under product_data key
      if (productItem.hasOwnProperty("product_data")) {
        tempObj["product_data"] = productItem["product_data"];
      }
      itemList.push(tempObj);
    });
    tempQuotationSchema["items"] = itemList;
    let tempAmountDetails = {};
    Object.keys(amountDetails).forEach((key) => {
      tempAmountDetails[key] = getValidFloatValue(amountDetails[key]);
    });
    tempQuotationSchema["amount_details"] = tempAmountDetails;
    tempQuotationSchema["billing_details"] = billingDetails;
    tempQuotationSchema["additional_details"] = additionalDetails;
    tempQuotationSchema["place_of_supply"] = placeOfSupply;
    if (scope === "edit") {
      //copy schema from existing invoice data
      tempQuotationSchema["version"] = 2;
      tempQuotationSchema["company_details"] = {};
      tempQuotationSchema["company_details"] =
        existingInvoiceData["schema"]["company_details"];
      tempQuotationSchema["show_gst"] = existingInvoiceData["schema"][
        "show_gst"
      ]
        ? existingInvoiceData["schema"]["show_gst"]
        : false;
      tempQuotationSchema["title"] = invoiceTitle;
      tempQuotationSchema["additional_details"]["show_additional_information"] =
        existingInvoiceData["schema"]["additional_details"][
          "show_additional_information"
        ];
      tempQuotationSchema["additional_details"]["show_terms_and_conditions"] =
        existingInvoiceData["schema"]["additional_details"][
          "show_terms_and_conditions"
        ];
      tempQuotationSchema["additional_details"]["show_quotation_number"] =
        existingInvoiceData["schema"]["additional_details"][
          "show_quotation_number"
        ];
      tempQuotationSchema["billing_details"]["show_shipping_address"] =
        existingInvoiceData["schema"]["billing_details"][
          "show_shipping_address"
        ];
    } else {
      //VERSION 2 SCHEMA copy required template schema to invoice schema
      tempQuotationSchema["version"] = 2;
      tempQuotationSchema["company_details"] = {};
      tempQuotationSchema["company_details"] =
        quotationTemplateConfig["schema"]["company_details"];

      tempQuotationSchema["show_gst"] = quotationTemplateConfig["schema"][
        "show_gst"
      ]
        ? quotationTemplateConfig["schema"]["show_gst"]
        : false;
      tempQuotationSchema["title"] = invoiceTitle;
      tempQuotationSchema["additional_details"]["show_additional_information"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_additional_information"
        ];
      tempQuotationSchema["additional_details"]["show_terms_and_conditions"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_terms_and_conditions"
        ];
      tempQuotationSchema["additional_details"]["show_quotation_number"] =
        quotationTemplateConfig["schema"]["additional_details"][
          "show_quotation_number"
        ];
      tempQuotationSchema["billing_details"]["show_shipping_address"] =
        quotationTemplateConfig["schema"]["billing_details"][
          "show_shipping_address"
        ];
    }

    formData["schema"] = tempQuotationSchema;
    formData["amount"] = parseFloat(tempAmountDetails["grand_total"]);
    if (scope === "edit" || scope === "revise") {
      //sending new balance if old balance is there
      if (existingInvoiceData["balance"] !== null) {
        const amount =
          existingInvoiceData["amount"] !== null
            ? existingInvoiceData["amount"]
            : 0;
        const paid = amount - existingInvoiceData["balance"];
        formData["balance"] = formData["amount"] - paid;
      }
      await editInvoice(formData);
    } else {
      await createInvoice(formData);
    }
  };

  const getQuotationList = async () => {
    let response = await getQuoteTemplateListApi();
    //listing only invoice_template or invoice_template = null templates (null for existing templates)
    const filteredResponse = response.filter(
      (template) =>
        template.invoice_template === null || template.invoice_template
    );
    setQuoteList(filteredResponse);
    if (scope === "edit" || scope === "revise" || scope === "to_invoice") {
      let index = response.findIndex(
        (quote) => quote["id"] === existingInvoiceData["quotation_template_id"]
      );
      if (index !== -1) {
        setQuotationTemplateConfig(response[index]);

        let quoteData = response[index]["schema"];
        setShowShippingAddress(
          quoteData["billing_details"]["show_shipping_address"]
        );
        setShowAdditionalInformation(
          quoteData["additional_details"]["show_additional_information"]
        );
        setShowTermsAndConditions(
          quoteData["additional_details"]["show_terms_and_conditions"]
        );

        if (scope === "to_invoice") {
          //if shema has invoice_title, check for it
          if (hasQuotationAndInvoiceTitles(response[index]["schema"])) {
            const invoiceTitle =
              response[index]["schema"].hasOwnProperty("invoice_title") &&
              response[index]["schema"]["invoice_title"] !== null &&
              response[index]["schema"]["invoice_title"] !== ""
                ? response[index]["schema"]["invoice_title"]
                : "Invoice";
            setInvoiceTitle(invoiceTitle);
            //else set it to default title
          } else {
            setInvoiceTitle("Invoice");
          }
        }
      }
    } else {
      let index = response.findIndex(
        (quote) => quote["id"] === existingInvoiceData["quotation_template_id"]
      );
      if (index !== -1) {
        const schema = response[index]["schema"];

        if (
          schema.hasOwnProperty("title") &&
          schema["title"] !== null &&
          schema["title"] !== ""
        ) {
          setInvoiceTitle(schema["title"]);
        }
      }
    }
  };

  const initBillingSectionPrefill = (selectedTemplateSchema) => {
    setBillingDetails({
      billing_name: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_zipcode: "",
      billing_gst: "",
      shipping_name: "",
      shipping_address: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_zipcode: "",
    });
    let tempBillingDetails = {};
    for (var key in selectedTemplateSchema["address_variables"]) {
      if (selectedTemplateSchema["address_variables"][key] != "") {
        tempBillingDetails[key] = "";
        const value = embedVariableValuesInMessageString({
          messageString: selectedTemplateSchema["address_variables"][key],
          leadData: leadData,
        });
        tempBillingDetails[key] = value;
      }
    }
    setBillingDetails(tempBillingDetails);
  };

  const checkAndSetupActiveDealItems = () => {
    let newList = [];
    if (
      activeDealDetails.hasOwnProperty("deal_items") &&
      activeDealDetails["deal_items"] !== null
    ) {
      activeDealDetails["deal_items"].forEach((product) => {
        let tempObj = { desc: "" };
        tempObj["discount"] = product["discount"];
        tempObj["rate"] = product["rate"];
        tempObj["quantity"] = product["quantity"];
        if (product.hasOwnProperty("product") && product["product"] !== null) {
          tempObj["type"] = "product";
          tempObj["product_data"] = {
            id: product["product"]["id"],
            name: product["product"]["name"],
            unit_price: product["product"]["unit_price"],
            unit_cost: product["product"]["unit_cost"],
            tax: product["product"]["tax"],
            max_discount: product["product"]["max_discount"],
            product_code: product["product"]["product_code"],
          };
        }
        //tax calc from product data
        tempObj["tax"] =
          product.hasOwnProperty("product") &&
          product["product"] !== null &&
          product["product"].hasOwnProperty("tax") &&
          product["product"]["tax"] !== null &&
          product["product"]["tax"] !== ""
            ? product["product"]["tax"]
            : null;
        tempObj["amount"] = product["amount"];
        const taxedAmount =
          tempObj["tax"] !== null && tempObj["tax"] > 0
            ? (tempObj["amount"] * tempObj["tax"]) / 100
            : 0;
        tempObj["amount"] = (tempObj["amount"] + taxedAmount).toFixed(2);

        tempObj["item"] = {
          id: uuidv4(),
        };
        if (product["item"] !== null) {
          tempObj["item"]["name"] = product["item"];
        } else {
          tempObj["item"]["name"] = product["product"]["name"];
        }
        tempObj["discount"] = product.hasOwnProperty("discount")
          ? product["discount"]
          : "";
        newList.push(tempObj);
      });
    }
    return newList;
  };

  const initCreateData = async () => {
    await getQuotationList();
    setQuotationTemplateConfig(selectedQuoteTemplate);
    setSelectedQuoteID(selectedQuoteTemplate["id"]);
    handleQuotationData(selectedQuoteTemplate);
  };

  const initEditableData = async () => {
    await getQuotationList();

    if (existingInvoiceData["schema"].hasOwnProperty("additional_columns")) {
      setTableAdditionalColumns(
        existingInvoiceData["schema"]["additional_columns"]
      );
    }

    if (
      existingInvoiceData["schema"].hasOwnProperty("title") &&
      existingInvoiceData["schema"]["title"] !== null &&
      existingInvoiceData["schema"]["title"] !== ""
    ) {
      setInvoiceTitle(existingInvoiceData["schema"]["title"]);
    }
    setInvoiceNumber(existingInvoiceData["invoice_number"]);
    setSelectedQuoteID(existingInvoiceData["quotation_template_id"]);
    setBillingDetails(existingInvoiceData["schema"]["billing_details"]);
    let updatedAmountDetails = updateTotalAmount({
      type: "init",
      amountDetails: existingInvoiceData["schema"]["amount_details"],
    });
    setAmountDetails(updatedAmountDetails);
    setAdditionalDetails(existingInvoiceData["schema"]["additional_details"]);

    // if template version is 2 get the styles from schema data
    let templateVersion =
      existingInvoiceData.hasOwnProperty("schema") &&
      existingInvoiceData["schema"] !== null &&
      existingInvoiceData["schema"].hasOwnProperty("template_version") &&
      existingInvoiceData["schema"]["template_version"] !== null &&
      existingInvoiceData["schema"]["template_version"] !== ""
        ? existingInvoiceData["schema"]["template_version"]
        : 1;
    setTemplateVersion(templateVersion);
    if (templateVersion > 1) {
      setStyleDetails(existingInvoiceData["schema"]["styles"]);
    }

    let tempObj = {
      title: existingInvoiceData["schema"]["invoice_title"],
      invoice_date:
        existingInvoiceData["invoice_date"] !== null &&
        existingInvoiceData["invoice_date"] !== ""
          ? existingInvoiceData["invoice_date"]
          : null,
      expiry_date:
        existingInvoiceData["expiry_date"] !== null &&
        existingInvoiceData["expiry_date"] !== ""
          ? existingInvoiceData["expiry_date"]
          : null,
    };
    setDetails(tempObj);
    setPlaceOfSupply(
      existingInvoiceData["schema"]["place_of_supply"] !== null &&
        existingInvoiceData["schema"]["place_of_supply"] !== ""
        ? existingInvoiceData["schema"]["place_of_supply"]
        : null
    );
    let tempList = [...existingInvoiceData["schema"]["items"]];
    tempList.forEach((item) => {
      item["item"] = { name: item["name"], id: uuidv4() };
      item["desc"] =
        item.hasOwnProperty("desc") && item["desc"] !== null
          ? item["desc"]
          : "";
      if (
        item.hasOwnProperty("product_data") &&
        item["product_data"] !== null
      ) {
        item["type"] = "product";
      }
    });
    setProductAddedList(tempList);
  };

  const initQuoteDataConvert = async () => {
    await getQuotationList();

    setSelectedQuoteID(existingInvoiceData["quotation_template_id"]);
    setBillingDetails(existingInvoiceData["quote_schema"]["billing_details"]);
    let updatedAmountDetails = updateTotalAmount({
      type: "init",
      amountDetails: existingInvoiceData["quote_schema"]["amount_details"],
    });
    setAmountDetails(updatedAmountDetails);
    setAdditionalDetails(
      existingInvoiceData["quote_schema"]["additional_details"]
    );
    if (
      existingInvoiceData["quote_schema"].hasOwnProperty("additional_columns")
    ) {
      setTableAdditionalColumns(
        existingInvoiceData["quote_schema"]["additional_columns"]
      );
    }
    // if template version is 2 get the styles from schema data
    let templateVersion =
      existingInvoiceData.hasOwnProperty("quote_schema") &&
      existingInvoiceData["quote_schema"] !== null &&
      existingInvoiceData["quote_schema"].hasOwnProperty("template_version") &&
      existingInvoiceData["quote_schema"]["template_version"] !== null &&
      existingInvoiceData["quote_schema"]["template_version"] !== ""
        ? existingInvoiceData["quote_schema"]["template_version"]
        : 1;
    setTemplateVersion(templateVersion);
    if (templateVersion > 1) {
      setStyleDetails(existingInvoiceData["quote_schema"]["styles"]);
    }

    let tempObj = {
      title: existingInvoiceData["quote_schema"]["quote_title"],
      invoice_date:
        existingInvoiceData["quote_date"] !== null &&
        existingInvoiceData["quote_date"] !== ""
          ? existingInvoiceData["quote_date"]
          : null,
      expiry_date:
        existingInvoiceData["expiry_date"] !== null &&
        existingInvoiceData["expiry_date"] !== ""
          ? existingInvoiceData["expiry_date"]
          : null,
    };
    setDetails(tempObj);
    setPlaceOfSupply(
      existingInvoiceData["quote_schema"]["place_of_supply"] !== null &&
        existingInvoiceData["quote_schema"]["place_of_supply"] !== ""
        ? existingInvoiceData["quote_schema"]["place_of_supply"]
        : null
    );
    let tempList = [...existingInvoiceData["quote_schema"]["items"]];
    tempList.forEach((item) => {
      item["discount"] =
        item.hasOwnProperty("discount") &&
        item["discount"] !== null &&
        item["discount"] !== undefined &&
        item["discount"] !== ""
          ? parseFloat(item["discount"])
          : 0;
      item["item"] = { name: item["name"], id: uuidv4() };

      item["tax"] =
        item.hasOwnProperty("tax") && item["tax"] !== null ? item["tax"] : "";
      item["desc"] =
        item.hasOwnProperty("desc") && item["desc"] !== null
          ? item["desc"]
          : "";
      if (
        item.hasOwnProperty("product_data") &&
        item["product_data"] !== null
      ) {
        item["type"] = "product";
      }
    });
    checkAndSetItemTaxFlag(tempList);
    setProductAddedList(tempList);
  };

  const checkAndIntegrateTemplateItems = ({
    templateData,
    productAddedList,
  }) => {
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      let tempList = [...productAddedList];
      let templateItemList = [];

      templateData.schema["items"].forEach((item) => {
        let tempObj = {};
        tempObj["tax"] = item["tax"];
        tempObj["discount"] = item["discount"];
        tempObj["rate"] = item["rate"];
        tempObj["amount"] = item["amount"];
        tempObj["quantity"] = item["quantity"];
        tempObj["item"] = {
          name: item["name"],
          id: uuidv4(),
        };
        tempObj["desc"] =
          item.hasOwnProperty("desc") && item["desc"] !== null
            ? item["desc"]
            : "";
        templateItemList.push(tempObj);
      });
      tempList = tempList.concat(templateItemList);
      setProductAddedList(tempList);
      updateCalculatedAmount(tempList);
    }
  };
  const setup = async () => {
    if (scope === "edit" || scope === "revise") {
      await initEditableData();
    } else if (scope === "to_invoice") {
      await initQuoteDataConvert();
    } else {
      await initCreateData();
    }

    setLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleDeleteProduct = (indexToDelete) => {
    const newList = productAddedList.filter(
      (_, index) => index !== indexToDelete
    );
    setProductAddedList(newList);
    updateCalculatedAmount(newList);
    checkAndSetItemTaxFlag(newList);
  };

  const checkAndSetItemTaxFlag = (itemList) => {
    if (
      itemList.every(
        (x) => x.tax !== "" && x.tax !== null && x.tax !== undefined
      )
    ) {
      setItemTaxFlag(false);
    } else {
      setItemTaxFlag(true);
    }
  };

  const hasQuotationAndInvoiceTitles = (schema) => {
    if (
      schema.hasOwnProperty("quotation_title") &&
      schema.hasOwnProperty("invoice_title")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateCalculatedAmount = (itemList) => {
    let tempObj = { ...amountDetails };
    let amount = 0;
    itemList.forEach((item) => {
      amount += parseFloat(item["amount"]);
    });
    tempObj["sub_total"] = amount.toFixed(2);

    let totalAmount = amount;
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;

    totalAmount = totalAmount + taxedAmount;
    totalAmount = totalAmount - discountedAmount;
    //calc difference to auto adjust
    let roundedDifference = parseFloat(
      (Math.round(totalAmount) - totalAmount).toFixed(2)
    );
    tempObj["adjustments"] = roundedDifference;

    tempObj["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);

    setAmountDetails(tempObj);
  };

  const updateTotalAmount = ({ type, amountDetails }) => {
    let totalAmount = parseFloat(amountDetails["sub_total"]);
    let taxedAmount =
      amountDetails["additional_tax"] !== null &&
      amountDetails["additional_tax"] !== ""
        ? parseFloat(amountDetails["additional_tax"])
        : 0;
    let discountedAmount =
      amountDetails["additional_discount"] !== null &&
      amountDetails["additional_discount"] !== ""
        ? parseFloat(amountDetails["additional_discount"])
        : 0;
    let adjustmentAmount =
      amountDetails["adjustments"] !== null &&
      amountDetails["adjustments"] !== ""
        ? parseFloat(amountDetails["adjustments"])
        : 0;

    totalAmount = totalAmount + taxedAmount;
    totalAmount = totalAmount - discountedAmount;

    let roundedDifference = 0;
    if (type === "adjustments" || type === "init") {
      //using entered adjustment amount if it was init or from text field
      totalAmount = totalAmount + adjustmentAmount;
    } else {
      // done only if not changing adjustments directly or not init
      //calc difference to auto adjust
      roundedDifference = parseFloat(
        (Math.round(totalAmount) - totalAmount).toFixed(2)
      );
      amountDetails["adjustments"] = roundedDifference;
    }

    amountDetails["grand_total"] = parseFloat(
      totalAmount + roundedDifference
    ).toFixed(2);
    return amountDetails;
  };

  const canShowFormStepWithTermsAndAdditionalInfo = () => {
    return (
      showTermsAndConditions === true || showAdditionalInformation === true
    );
  };

  const RenderButton = () => {
    if (activeStep === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("cancel")}
          </Button>
          <Button
            disabled={selectedQuoteID === ""}
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("next")}
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("prev")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {canShowFormStepWithTermsAndAdditionalInfo()
              ? getLocalizedText("next")
              : getLocalizedText("submit")}
          </Button>
        </Box>
      );
    } else if (activeStep === 2) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("prev")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            {getLocalizedText("submit")}
          </Button>
        </Box>
      );
    }
  };

  const getHeaderText = () => {
    if (scope === "edit") {
      return getLocalizedText("edit_invoice");
    } else if (scope === "revise") {
      return getLocalizedText("revise_invoice");
    } else {
      return getLocalizedText("create_invoice");
    }
  };
  const getSuccessMessageText = () => {
    if (scope === "edit") {
      return getLocalizedText("invoice_edited_successfully");
    } else if (scope === "revise") {
      return getLocalizedText("invoice_revised_successfully");
    } else {
      return getLocalizedText("invoice_created_successfully");
    }
  };

  const removeTemplateItemsFromList = ({ templateData }) => {
    let newList = [];
    if (
      templateData.schema.hasOwnProperty("items") &&
      templateData.schema["items"] !== null &&
      templateData.schema["items"].length > 0
    ) {
      productAddedList.forEach((item) => {
        let index = templateData.schema["items"].findIndex(
          (obj) => obj["name"] === item["item"]["name"]
        );
        if (index === -1) {
          newList.push(item);
        }
      });
    }
    return newList;
  };

  const isProductAlreadyAdded = (id) => {
    let index = productAddedList.findIndex(
      (product) =>
        product.hasOwnProperty("product_data") &&
        product["product_data"]["id"] === id
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };

  const handleCloneItem = (item) => {
    const newItem = JSON.parse(JSON.stringify(item));
    newItem.item.id = uuidv4();

    const newProductsList = [...productAddedList, newItem];

    setProductAddedList(newProductsList);
    updateCalculatedAmount(newProductsList);
    checkAndSetItemTaxFlag(newProductsList);
  };

  const handleQuotationData = (quoteTemplateData) => {
    let schemaData = quoteTemplateData["schema"];

    // if template version is 2 get the styles from schema data
    let templateVersion =
      schemaData.hasOwnProperty("template_version") &&
      schemaData["template_version"] !== null &&
      schemaData["template_version"] !== ""
        ? schemaData["template_version"]
        : 1;
    setTemplateVersion(templateVersion);
    if (templateVersion > 1) {
      setStyleDetails(schemaData["styles"]);
    } else {
      setStyleDetails({
        table_header: {
          background_color: "",
          text_color: "",
        },
        grand_total: {
          background_color: "",
          text_color: "",
        },
      });
    }

    let tempItemList = checkAndSetupActiveDealItems();
    setProductAddedList(tempItemList);
    updateCalculatedAmount(tempItemList);
    checkAndIntegrateTemplateItems({
      templateData: quoteTemplateData,
      productAddedList: tempItemList,
    });

    //prefill invoice title from template

    //if shema has invoice_title, check for it
    if (hasQuotationAndInvoiceTitles(schemaData)) {
      const invoiceTitle =
        schemaData.hasOwnProperty("invoice_title") &&
        schemaData["invoice_title"] !== null &&
        schemaData["invoice_title"] !== ""
          ? schemaData["invoice_title"]
          : "Invoice";
      setInvoiceTitle(invoiceTitle);
      //else set it to default title
    } else {
      setInvoiceTitle("Invoice");
    }

    //check if all items have tax values and set flag
    checkAndSetItemTaxFlag(tempItemList);

    //prefill subject
    const subject =
      schemaData["subject"] !== null && schemaData["subject"] !== undefined
        ? schemaData["subject"]
        : "";
    setDetails({
      ...details,
      title: subject,
      invoice_date:
        details["invoice_date"] !== null && details["invoice_date"] !== ""
          ? details["invoice_date"]
          : moment().startOf("day"),
    });

    //set prefill if available in selected template
    if (quoteTemplateData["schema"].hasOwnProperty("address_variables")) {
      initBillingSectionPrefill(schemaData);
      //else reset
    } else {
      setBillingDetails({
        billing_name: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_country: "",
        billing_zipcode: "",
        billing_gst: "",
        shipping_name: "",
        shipping_address: "",
        shipping_city: "",
        shipping_state: "",
        shipping_country: "",
        shipping_zipcode: "",
      });
    }
    setShowShippingAddress(
      schemaData["billing_details"]["show_shipping_address"]
    );
    setShowAdditionalInformation(
      schemaData["additional_details"]["show_additional_information"]
    );
    setShowTermsAndConditions(
      schemaData["additional_details"]["show_terms_and_conditions"]
    );

    let tempAdditionalDetails = {
      terms_and_conditions: "",
      additional_information: "",
    };
    if (schemaData["additional_details"]["show_terms_and_conditions"]) {
      tempAdditionalDetails["terms_and_conditions"] =
        schemaData["additional_details"]["terms_and_conditions"];
    }
    if (schemaData["additional_details"]["show_additional_information"]) {
      tempAdditionalDetails["additional_information"] =
        schemaData["additional_details"]["additional_information"];
    }
    setAdditionalDetails(tempAdditionalDetails);
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getHeaderText()}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              const maxStepIndex = canShowFormStepWithTermsAndAdditionalInfo()
                ? 2
                : 1;
              if (activeStep < maxStepIndex) {
                if (itemTaxFlag) {
                  setShowItemTaxWarning(true);
                } else {
                  handleNext();
                }
              } else {
                handleSubmit();
              }
            }}
          >
            <InputContainer>
              {(() => {
                if (activeStep === 0) {
                  return (
                    <InvoiceAndProuductInfo
                      handleDelete={(productItem) => {
                        let tempProductAddedList = [...productAddedList];
                        tempProductAddedList = tempProductAddedList.filter(
                          (entry) =>
                            entry["item"]["id"] !== productItem["item"]["id"]
                        );
                        setProductAddedList(tempProductAddedList);
                        updateCalculatedAmount(tempProductAddedList);
                        checkAndSetItemTaxFlag(tempProductAddedList);
                      }}
                      handleEdit={(item) => {
                        setSelectedProductData(item);
                        if (
                          item.hasOwnProperty("type") &&
                          item["type"] === "product"
                        ) {
                          setIsEditProductLineItem(true);
                          setOpenProductLineItemForm(true);
                        } else {
                          setOpenEditProductModal(true);
                        }
                      }}
                      handleView={(item) => {
                        setSelectedProductData(item);
                        setOpenViewProductModal(true);
                      }}
                      handleAddCustomItem={() => {
                        setOpenAddProductModal(true);
                      }}
                      handleAddProduct={() => {
                        setOpenProductLineItemForm(true);
                      }}
                      handleClone={(item) => {
                        handleCloneItem(item);
                      }}
                      tableAdditionalColumns={tableAdditionalColumns}
                      setTableAdditionalColumns={setTableAdditionalColumns}
                      details={details}
                      invoiceTitle={invoiceTitle}
                      setInvoiceTitle={setInvoiceTitle}
                      isFormDisabled={selectedQuoteID === ""}
                      productAddedList={productAddedList}
                      placeOfSupply={placeOfSupply}
                      handleAmountDetails={(event) => {
                        let tempObj = {
                          ...amountDetails,
                          [event.target.name]: event.target.value,
                        };
                        let updatedAmountDetails = updateTotalAmount({
                          type: event.target.name,
                          amountDetails: tempObj,
                        });

                        setAmountDetails(updatedAmountDetails);
                      }}
                      handleChange={(name, value) => {
                        setDetails({ ...details, [name]: value });
                      }}
                      handlePlaceOfSupply={(value) => {
                        setPlaceOfSupply(value);
                        //reset tax value for all items and set flag
                        if (productAddedList.length !== 0) {
                          let newProductList = [...productAddedList];
                          newProductList = newProductList.map((item) => {
                            let discount =
                              item.hasOwnProperty("discount") &&
                              item["discount"] !== null &&
                              item["discount"] !== undefined &&
                              item["discount"] !== ""
                                ? parseFloat(item["discount"])
                                : 0;
                            let rate =
                              item.hasOwnProperty("rate") &&
                              item["rate"] !== null &&
                              item["rate"] !== undefined &&
                              item["rate"] !== ""
                                ? parseFloat(item["rate"])
                                : 0;
                            let quantity =
                              item.hasOwnProperty("quantity") &&
                              item["quantity"] !== null &&
                              item["quantity"] !== undefined &&
                              item["quantity"] !== ""
                                ? parseInt(item["quantity"])
                                : 0;
                            let amount = rate * quantity;

                            let discountedAmount = (amount * discount) / 100;
                            amount = (amount - discountedAmount).toFixed(2);

                            if (
                              item["type"] === "product" &&
                              item.hasOwnProperty("product_data") &&
                              item["product_data"] !== null
                            ) {
                              item["tax"] = item["product_data"]["tax"];
                              const taxedAmount =
                                item["tax"] !== null && item["tax"] > 0
                                  ? (amount * item["tax"]) / 100
                                  : 0;
                              item["amount"] = (
                                parseFloat(amount) + taxedAmount
                              ).toFixed(2);
                            } else {
                              item["tax"] = "";
                              item["amount"] = amount;
                            }
                            return item;
                          });
                          setProductAddedList(newProductList);
                          const hasEmptyTax = newProductList.some(
                            (item) => item["tax"] === ""
                          );
                          if (hasEmptyTax) {
                            setItemTaxFlag(true);
                          }
                        }
                      }}
                      amountDetails={amountDetails}
                      invoiceNumber={invoiceNumber}
                    />
                  );
                } else if (activeStep === 1) {
                  return (
                    <BillingInfo
                      billingDetails={billingDetails}
                      handleChange={(name, value) => {
                        setBillingDetails({ ...billingDetails, [name]: value });
                      }}
                      showShippingAddress={showShippingAddress}
                    />
                  );
                } else if (activeStep === 2) {
                  return (
                    <AdditionalInfo
                      additionalInformation={additionalDetails}
                      handleChange={(name, value) => {
                        setAdditionalDetails({
                          ...additionalDetails,
                          [name]: value,
                        });
                      }}
                      showAdditionalInformation={showAdditionalInformation}
                      showTermsAndConditions={showTermsAndConditions}
                    />
                  );
                }
              })()}
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                {showItemTaxWarning && (
                  <WarningAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowItemTaxWarning(false);
                    }}
                    severity="warning"
                    sx={{ width: "100%" }}
                  >
                    {getLocalizedText(
                      "please_update_tax_for_all_items_before_proceeding"
                    )}
                  </WarningAlert>
                )}
                <ButtonWrapper>
                  <RenderButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>{getSuccessMessageText()}</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        )}
      </Box>
      {openAddProductModal && (
        <AddProductModal
          open={openAddProductModal}
          setOpen={setOpenAddProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          updateCalculatedAmount={(productList) =>
            updateCalculatedAmount(productList)
          }
          taxOptions={invoiceTaxOptions}
        />
      )}
      {openEditProductModal && (
        <EditProductModal
          open={openEditProductModal}
          setOpen={setOpenEditProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          editableProduct={selectedProductData}
          setEditableProduct={setSelectedProductData}
          updateCalculatedAmount={(productList) =>
            updateCalculatedAmount(productList)
          }
          setTotalItemsReset={setTotalItemsReset}
          totalItemsReset={totalItemsReset}
          itemTaxFlag={itemTaxFlag}
          setItemTaxFlag={setItemTaxFlag}
          setShowItemTaxWarning={setShowItemTaxWarning}
          taxOptions={invoiceTaxOptions}
        />
      )}
      {openViewProductModal && (
        <ViewProductModal
          open={openViewProductModal}
          setOpen={setOpenViewProductModal}
          data={selectedProductData}
          setData={setSelectedProductData}
        />
      )}

      {openProductLineItemForm && (
        <AddProductLineItemModal
          editableProduct={selectedProductData}
          handleClose={() => {
            setIsEditProductLineItem(false);
            setOpenProductLineItemForm(false);
          }}
          isEdit={isEditProductLineItem}
          isProductAlreadyAdded={(id) => isProductAlreadyAdded(id)}
          open={openProductLineItemForm}
          postAddCallback={(details) => {
            let tempProductAddedList = [...productAddedList];
            tempProductAddedList.push(details);
            setProductAddedList(tempProductAddedList);
            updateCalculatedAmount(tempProductAddedList);
            setIsEditProductLineItem(false);
            setOpenProductLineItemForm(false);
          }}
          postEditCallback={(details) => {
            let tempProductAddedList = [...productAddedList];
            let index = productAddedList.findIndex(
              (productItem) =>
                productItem["item"]["id"] === details["item"]["id"]
            );
            if (index !== -1) {
              tempProductAddedList[index] = details;
            }
            setProductAddedList(tempProductAddedList);
            updateCalculatedAmount(tempProductAddedList);

            const hasAllItemWithTax = tempProductAddedList.every(
              (entry) => entry.tax !== ""
            );
            if (hasAllItemWithTax) {
              setItemTaxFlag(false);
              setShowItemTaxWarning(false);
            }
            setIsEditProductLineItem(false);
            setOpenProductLineItemForm(false);
          }}
          productList={productList}
          setProductList={setProductList}
          taxOptions={invoiceTaxOptions}
        />
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AddInvoiceSidePanel;
