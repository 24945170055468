import React from "react";
import { StyleSheet, View, Text, Font } from "@react-pdf/renderer";
import { dataRenderer } from "../../../../../Functions";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {},
  row: {
    flexDirection: "row",
    display: "flex",
    borderTop: "1px solid #D3D3D3",
    borderBottom: "1px solid #D3D3D3",
    paddingLeft: "16px",
  },
  columnWrapper: {
    flexDirection: "column",
    display: "flex",
  },
  rowWrapper: {
    flexDirection: "row",
    display: "flex",
  },
  headerCell: {
    textAlign: "left",
    padding: "4px 8px",
  },
  firstCell: {
    textAlign: "center",
    width: "2%",
    padding: "4px 0px",
  },
  dataText: {
    fontSize: 8,
    fontWeight: 400,
    color: "black",
    fontFamily: "Poppins",
    textAlign: "right",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
});
const AreaBasedTableRow = ({
  data,
  index,
  showTaxCol,
  showSizeCols,
  checkIfCanShowProductColumn,
  getColumnMeta,
}) => {
  const getProductColumnValue = (columnName) => {
    let productCode =
      data["product_data"].hasOwnProperty(columnName) &&
      data["product_data"][columnName] !== null &&
      data["product_data"][columnName] !== ""
        ? data["product_data"][columnName]
        : "-";
    return productCode;
  };

  const getValidValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      return value;
    }
    return "";
  };
  const getAmount = (percentage) => {
    let tempPercentage = getValidValue(percentage);

    let actualAmount =
      data.width &&
      data.height &&
      data.width !== 0 &&
      data.height !== 0 &&
      data.width !== "" &&
      data.height !== ""
        ? data["rate"] * data["total_unit"]
        : data["rate"] * data["quantity"];
    let tempAmount = getValidValue(actualAmount);
    if (tempPercentage !== "" && tempAmount !== "") {
      return ((tempAmount * tempPercentage) / 100).toFixed(2);
    }
    return "-";
  };

  const checkIfRowHasSize = (row) => {
    return (
      row.width &&
      row.height &&
      row.width !== 0 &&
      row.height !== 0 &&
      row.width !== "" &&
      row.height !== ""
    );
  };

  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.row}>
        <View style={styles.firstCell}>
          <Text style={{ ...styles.dataText, textAlign: "left" }}>
            {index + 1}
          </Text>
        </View>
        <View style={styles.verticalDivider} />
        <View style={{ ...styles.headerCell, width: "30%" }}>
          <Text
            style={{
              ...styles.dataText,
              textAlign: "left",
              fontStyle: "bold",
              fontSize: 9,
            }}
          >
            {data.hasOwnProperty("product_data") &&
            data["product_data"] !== null
              ? dataRenderer(data["product_data"]["name"])
              : dataRenderer(data["name"])}
          </Text>
          {data.hasOwnProperty("desc") &&
            data["desc"] !== null &&
            data["desc"] !== "" && (
              <Text
                style={{
                  ...styles.dataText,
                  textAlign: "left",
                }}
              >
                {data["desc"]}
              </Text>
            )}
          {Array.isArray(data["additional_details"]) &&
            data["additional_details"].map((detail, index) => (
              <Text
                key={index}
                style={{
                  ...styles.dataText,
                  textAlign: "left",
                }}
              >
                • {dataRenderer(detail.field)}: {dataRenderer(detail.value)}
              </Text>
            ))}
          {checkIfCanShowProductColumn("product_code") &&
            data.hasOwnProperty("product_data") &&
            data["product_data"] !== null && (
              <Text
                style={{
                  ...styles.dataText,
                  fontSize: 7,
                  textAlign: "left",
                  marginTop: 4,
                  color: "#1976d2",
                }}
              >
                {`${
                  getColumnMeta("product_code")["display_name"]
                } : ${getProductColumnValue(
                  getColumnMeta("product_code")["column_name"]
                )}`}
              </Text>
            )}
        </View>
        <View style={styles.verticalDivider} />
        {showSizeCols && (
          <>
            <View
              style={{
                ...styles.headerCell,
                width: "14%",
              }}
            >
              <Text style={styles.dataText}>
                {checkIfRowHasSize(data)
                  ? data["width"] + " x " + data["height"]
                  : "-"}
              </Text>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}
        <View
          style={{
            ...styles.headerCell,
            width: "10%",
          }}
        >
          <Text style={styles.dataText}>{dataRenderer(data["unit"])}</Text>
        </View>
        <View style={styles.verticalDivider} />
        {showSizeCols && (
          <>
            <View
              style={{
                ...styles.headerCell,
                width: "10%",
              }}
            >
              <Text style={styles.dataText}>
                {dataRenderer(data["in_unit"])}
              </Text>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}
        <View
          style={{
            ...styles.headerCell,
            width: showSizeCols ? "10%" : "15%",
          }}
        >
          <Text style={styles.dataText}>{dataRenderer(data["quantity"])}</Text>
        </View>
        <View style={styles.verticalDivider} />

        {showSizeCols && (
          <>
            <View
              style={{
                ...styles.headerCell,
                width: "15%",
              }}
            >
              <Text style={styles.dataText}>
                {dataRenderer(data["total_unit"])}
              </Text>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}
        <View
          style={{
            ...styles.headerCell,
            width: showSizeCols ? "10%" : "15%",
          }}
        >
          <Text style={styles.dataText}>{dataRenderer(data["rate"])}</Text>
        </View>
        <View style={styles.verticalDivider} />
        {showTaxCol && (
          <>
            <View
              style={{
                ...styles.headerCell,
                width: "25%",
                padding: "0px",
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text
                style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
              >
                {parseFloat(data["tax"]) >= 0 ? dataRenderer(data["tax"]) : "-"}
              </Text>
              <View style={styles.verticalDivider} />
              <Text
                style={{ ...styles.dataText, width: "50%", padding: "4px 8px" }}
              >
                {parseFloat(data["tax"]) >= 0
                  ? getAmount(parseFloat(data["tax"]))
                  : "-"}
              </Text>
            </View>
            <View style={styles.verticalDivider} />
          </>
        )}
        <View
          style={{
            ...styles.headerCell,
            width: showSizeCols ? "12%" : "17%",
          }}
        >
          <Text style={{ ...styles.dataText, width: "100%" }}>
            {dataRenderer(data["amount"])}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default AreaBasedTableRow;
