import { Box, Grid, styled } from "@mui/material";
import React from "react";
import EditorCard from "./card_widgets/TextEditorCard";
import ImagePickerCard from "./card_widgets/ImageComponentCard";
import TableGeneratorCard from "./card_widgets/TableComponentCard";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 112px);`};
  overflow-y: auto;
  padding: 16px;
  background-color: #f9f9fc;
`;
const RenderFooterComponents = ({ widgetList, setWidgetList }) => {
  const renderWidgetCard = ({ widgetData }) => {
    if (widgetData["type"] === "table") {
      return (
        <TableGeneratorCard
          editableData={widgetData}
          handleSaveTable={(tableData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = tableData;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else if (widgetData["type"] === "image") {
      return (
        <ImagePickerCard
          editableData={widgetData}
          handleEditImage={(imageData) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = { image_url: imageData["image_url"] };
              widgetData["properties"] = {
                alignment: imageData["alignment"],
                size: imageData["size"],
              };
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    } else {
      return (
        <EditorCard
          defaultFontSize="8px"
          editableData={widgetData}
          handleEdit={(htmlContent) => {
            let tempWidgetList = [...widgetList];
            const index = tempWidgetList.findIndex(
              (entry) => entry["id"] === widgetData["id"]
            );
            if (index !== -1) {
              let widgetData = tempWidgetList[index];
              widgetData["content"] = htmlContent;
              tempWidgetList[index] = widgetData;
              setWidgetList(tempWidgetList);
            }
          }}
        />
      );
    }
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent={"center"}>
        {widgetList.map((widget, idx) => {
          return (
            <Grid item xs={12} key={idx}>
              {renderWidgetCard({ widgetData: widget })}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default RenderFooterComponents;
