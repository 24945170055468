import { Box, Button, Modal, styled } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
const ModalContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ColorPickerDialog = (props) => {
  const [color, setColor] = useState("#ffffff");

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleApply = () => {
    props.handleColor(color);
  };
  useEffect(() => {
    if (props.color !== "") {
      setColor(props.color);
    }
  }, []);
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      disableScrollLock={true}
    >
      <ModalContainer>
        <SketchPicker
          color={color}
          onChange={handleColorChange}
          disableAlpha={false}
        />
        <ButtonWrapper>
          <Button
            onClick={props.handleClose}
            type="button"
            variant="contained"
           color="inherit"
            style={{
              textTransform: "none",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            type="button"
            variant="contained"
            id="call-log-btn"
            color="primary"
            style={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Apply
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default ColorPickerDialog;
