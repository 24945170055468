import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getFolderFileListApi, getFoldersListApi } from "../../Api";
import FolderScreen from "./screens/FolderScreen";
import FileScreen from "./screens/FileScreen";
import { ChevronRight } from "@mui/icons-material";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  padding-top: 0px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const BreadCrumbText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const CustomFilePickerDialog = ({ open, setOpen, handleHandleFilePick }) => {
  const [currentView, setCurrentView] = useState("folder");
  const [fileList, setFileList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFolderData, setSelectedFolderData] = useState({});

  const getFolderList = async () => {
    let response = await getFoldersListApi({
      page: 0,
      page_size: 100,
    });
    if (Object.keys(response).length > 0) {
      setFolderList(response.items);
    } else {
      setFolderList([]);
    }
  };

  const getFilesList = async ({ folderID }) => {
    let response = await getFolderFileListApi({
      folderID: folderID,
      page: 0,
      page_size: 100,
    });
    if (Object.keys(response).length > 0) {
      let filteredList = response.items.filter(
        (file) =>
          file.hasOwnProperty("file_type") &&
          file["file_type"] !== null &&
          file["file_type"].startsWith("image")
      );
      setFileList(filteredList);
    } else {
      setFileList([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const init = async () => {
    await getFolderList();
    setLoading(false);
  };
  useEffect(() => {
    init();
    setLoading(false);
  }, []);

  const RenderView = () => {
    if (currentView === "folder") {
      return (
        <FolderScreen
          folderList={folderList}
          handleFolderClick={async (folderData) => {
            setSelectedFolderData(folderData);
            await getFilesList({ folderID: folderData["id"] });
            setCurrentView("file");
          }}
        />
      );
    } else if (currentView === "file") {
      return (
        <FileScreen
          fileList={fileList}
          handleFileClick={(fileData) => {
            //do something
            handleHandleFilePick(fileData["file"]);
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <ModelContainer>
        {!loading ? (
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Select Image</Header>
                  <SubHeader>Select from below options</SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <TopWrapper>
                  {currentView === "file" ? (
                    <BreadCrumbText
                      style={{ color: "#185DD2", cursor: "pointer" }}
                      onClick={() => {
                        setCurrentView("folder");
                        setSelectedFolderData({});
                        setFileList([]);
                      }}
                    >
                      Folders
                    </BreadCrumbText>
                  ) : (
                    <BreadCrumbText>Folders</BreadCrumbText>
                  )}
                  <ChevronRight style={{ width: "16px", height: "16px" }} />
                  {Object.keys(selectedFolderData).length > 0 && (
                    <BreadCrumbText>
                      {selectedFolderData["name"]}
                    </BreadCrumbText>
                  )}
                </TopWrapper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RenderView />
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        ) : (
          <Box
            sx={{
              width: "600px",
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ModelContainer>
    </Modal>
  );
};

export default CustomFilePickerDialog;
