import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import { CurrencyRupeeRounded, Percent } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #ffffff",
  },
}));
const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  rowGap: "20px",
  overflowX: "hidden",
  overflowY: "auto",
  maxHeight: "calc(100vh - 300px)",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  maxHeight: "95vh",
  width: "550px",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "8px",
  padding: "16px",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
  margin: 0,
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  margin: 0,
  marginTop: "2px",
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 14px Open Sans",
  color: "#a9a9a9",
}));

const AddAreaBasedItemModal = (props) => {
  const [selectedProductData, setSelectedProductData] = useState({
    rate: "",
    quantity: "",
    discount: "",
    desc: "",
    total_unit: "",
    in_unit: "",
    height: "",
    width: "",
  });
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [customProductName, setCustomProductName] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddAdditionalDetail = () => {
    const initialFields = { field: "", value: "" };

    const newList = [
      ...additionalDetailsList,
      { id: uuidv4(), ...initialFields },
    ];

    setAdditionalDetailsList(newList);
  };
  const handleDeleteAdditionalDetail = (id) => {
    setAdditionalDetailsList((prev) =>
      prev.filter((detail) => detail.id !== id)
    );
  };
  const handleAdditionalDetailChange = (id, field, value) => {
    setAdditionalDetailsList((prev) =>
      prev.map((detail) =>
        detail.id === id ? { ...detail, [field]: value } : detail
      )
    );
  };

  const getBaseAmount = ({ rate, totalUnit, quantity }) => {
    if (
      selectedProductData.width &&
      selectedProductData.height &&
      selectedProductData.width !== 0 &&
      selectedProductData.height !== 0 &&
      selectedProductData.width !== "" &&
      selectedProductData.height !== ""
    ) {
      return rate * totalUnit;
    }
    return rate * quantity;
  };

  const handleAddProduct = () => {
    let newObj = {
      desc: selectedProductData["desc"],
    };
    let rate =
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);

    let discount =
      selectedProductData["discount"] !== ""
        ? parseInt(selectedProductData["discount"])
        : 0;
    newObj["height"] = selectedProductData["height"];
    newObj["width"] = selectedProductData["width"];
    newObj["in_unit"] =
      selectedProductData["in_unit"] === "" ||
      selectedProductData["in_unit"] === null
        ? null
        : parseFloat(selectedProductData["in_unit"]).toFixed(2);
    newObj["unit"] = selectedProductData["unit"];
    newObj["total_unit"] =
      selectedProductData["total_unit"] === "" ||
      selectedProductData["total_unit"] === null
        ? null
        : parseFloat(selectedProductData["total_unit"]).toFixed(2);
    let amount = getBaseAmount({
      rate: rate,
      totalUnit: newObj["total_unit"],
      quantity: quantity,
    });

    // rate * newObj["total_unit"];
    newObj["discount"] = discount;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["amount"] = amount;

    let discountedAmount = (newObj["amount"] * discount) / 100;
    newObj["amount"] = (newObj["amount"] - discountedAmount).toFixed(2);
    newObj["additional_details"] = additionalDetailsList;
    newObj["item"] = {
      name: customProductName,
      id: uuidv4(),
    };

    let newList = [...props.productAddedList, newObj];
    props.setProductAddedList(newList);
    props.setOpen(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                handleAddProduct();
              }}
            >
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "0px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>Add a Item</Header>
                    <SubHeader>Fill in the below details</SubHeader>
                  </HeaderWrapper>
                </Toolbar>
                <Divider style={{ margin: "10px 0px" }} />
              </AppBar>
              <InputContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SectionHeader>
                      {getLocalizedText("item_details")}
                    </SectionHeader>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Name*</Label>
                      <OutlinedInput
                        required
                        type="text"
                        placeholder="Name"
                        onChange={(e) => {
                          setCustomProductName(e.target.value);
                        }}
                        name="name"
                        value={customProductName}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Description</Label>
                      <OutlinedInput
                        multiline
                        rows={3}
                        type="text"
                        placeholder="Description"
                        onChange={handleChange}
                        name="desc"
                        value={selectedProductData["desc"]}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  {/* custom key value pairs */}
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>
                            {getLocalizedText("additional_details")}
                          </SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            maxHeight: "15vh",
                            overflowY: "auto",
                          }}
                        >
                          {additionalDetailsList.map((row) => (
                            <Box
                              key={row.id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginBottom: "6px",
                              }}
                            >
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  maxLength: 2000,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Name"
                                value={row.field}
                                onChange={(e) => {
                                  handleAdditionalDetailChange(
                                    row.id,
                                    "field",
                                    e.target.value
                                  );
                                }}
                                size="small"
                                sx={{ flex: 1 }}
                              />
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  maxLength: 25,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Value"
                                value={row.value}
                                onChange={(e) => {
                                  handleAdditionalDetailChange(
                                    row.id,
                                    "value",
                                    e.target.value
                                  );
                                }}
                                size="small"
                                sx={{ flex: 1 }}
                              />

                              <IconButton
                                onClick={() =>
                                  handleDeleteAdditionalDetail(row.id)
                                }
                                color="error"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}

                          <Button
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              handleAddAdditionalDetail();
                            }}
                          >
                            <AddIcon /> Add
                          </Button>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  {/* Area details */}
                  <Grid item xs={12}>
                    <SectionHeader>Area Details</SectionHeader>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Width</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Width"
                        onChange={(e) => {
                          let inUnit = null;

                          if (
                            selectedProductData["height"] !== null &&
                            selectedProductData["height"] !== ""
                          ) {
                            inUnit =
                              e.target.value === ""
                                ? null
                                : selectedProductData["height"] *
                                  e.target.value;
                          }
                          let newObj = {
                            ...selectedProductData,
                            width: e.target.value,
                            in_unit: inUnit,
                            total_unit:
                              inUnit !== null &&
                              selectedProductData["quantity"] !== null &&
                              selectedProductData["quantity"] !== ""
                                ? inUnit * selectedProductData["quantity"]
                                : null,
                          };
                          setSelectedProductData(newObj);
                        }}
                        name="width"
                        value={selectedProductData["width"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0.1,
                          step: "0.1",
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Height</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Height"
                        name="height"
                        onChange={(e) => {
                          let inUnit = null;

                          if (
                            selectedProductData["width"] !== null &&
                            selectedProductData["width"] !== ""
                          ) {
                            inUnit =
                              e.target.value === ""
                                ? null
                                : selectedProductData["width"] * e.target.value;
                          }

                          let newObj = {
                            ...selectedProductData,
                            height: e.target.value,
                            in_unit: inUnit,
                            total_unit:
                              inUnit !== null &&
                              selectedProductData["quantity"] !== null &&
                              selectedProductData["quantity"] !== ""
                                ? inUnit * selectedProductData["quantity"]
                                : null,
                          };
                          setSelectedProductData(newObj);
                        }}
                        value={selectedProductData["height"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0.1,
                          step: "0.1",
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Unit</Label>
                      <OutlinedInput
                        placeholder="Unit"
                        name="unit"
                        onChange={handleChange}
                        value={selectedProductData["unit"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>In Units</Label>
                      <OutlinedInput
                        readOnly
                        type="number"
                        placeholder="In Units"
                        name="in_unit"
                        value={selectedProductData["in_unit"] || ""}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Quantity*</Label>

                      <OutlinedInput
                        required
                        type="number"
                        placeholder="Quantity"
                        onChange={(e) => {
                          let totalUnit = null;
                          if (
                            selectedProductData["in_unit"] !== null &&
                            selectedProductData["in_unit"] !== ""
                          ) {
                            totalUnit =
                              e.target.value === ""
                                ? null
                                : selectedProductData["in_unit"] *
                                  e.target.value;
                          }
                          let newObj = {
                            ...selectedProductData,
                            quantity: e.target.value,
                            total_unit: totalUnit,
                          };
                          setSelectedProductData(newObj);
                        }}
                        name="quantity"
                        value={selectedProductData["quantity"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Total Units</Label>
                      <OutlinedInput
                        readOnly
                        type="number"
                        placeholder="Total Units"
                        name="total_unit"
                        value={selectedProductData["total_unit"] || ""}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Rate*</Label>
                      <OutlinedInput
                        required
                        type="number"
                        placeholder="Rate"
                        name="rate"
                        onChange={handleChange}
                        value={selectedProductData["rate"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <CurrencyRupeeRounded
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0,
                          step: "0.01",
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: "100%",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "0px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "flex-end",
                          gap: "16px",
                          marginTop: "20px",
                        }}
                      >
                        {" "}
                        <Button
                          onClick={handleClose}
                          type="button"
                          variant="contained"
                          color="inherit"
                          style={{
                            textTransform: "none",

                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            textTransform: "none",

                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddAreaBasedItemModal;
