import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import ImageQuickViewWithoutActionComponent from "../../quotation_builder_components/card_widgets/ImageQuickViewWithoutActionComponent";
const Container = styled(Box)`
  width: 100%;
  height: 300px;
  overflow-y: auto;
`;
const FileScreen = ({ fileList, handleFileClick }) => {
  return fileList.length > 0 ? (
    <Container>
      <Grid container spacing={2}>
        {fileList.map((fileData, index) => {
          return (
            <Grid item key={index}>
              <ImageQuickViewWithoutActionComponent
                fileURL={fileData["file"]}
                handleFileClick={() => handleFileClick(fileData)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  ) : (
    <Container
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Typography style={{ fontSize: "12px" }}>No file found.</Typography>
    </Container>
  );
};

export default FileScreen;
