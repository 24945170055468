import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer, Tooltip } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import {
  invoicesPageColumnHeaders,
  quoteInvoiceListingFilters,
} from "../../Db";
import { pageSize } from "../../Config";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { styled } from "@mui/material/styles";
import { Typography, IconButton, Chip } from "@mui/material";
import { Preview } from "@mui/icons-material";
import ViewInvoiceSidepanel from "../../components/invoice_sidepanel/ViewInvoiceSidepanel";
import { useNavigate } from "react-router";
import { dataRenderer, getLocalizedText,IsoToLocalDate, isCustomQuotationTemplate } from "../../Functions";
import { getInvoicesListApi } from "../../Api";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import moment from "moment";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const ProgressWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${`min-height: calc(100vh - 236px);`};
  background-color: white;
  padding: 20px;
`;
const TextHighlight = styled(Typography)`
  margin: 0px;
  font: normal normal 600 14px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const ChipsContainer = styled(Box)`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  column-gap: 8px;
`;

const InvoicesPage = () => {
  const [row, setRow] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const [page, setPage] = useState(0);
  const [initCount, setInitCount] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [openViewInvoicePanel, setOpenViewInvoicePanel] = useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({});
  let userID = localStorage.getItem("uid");
  const navigate = useNavigate();

  const getInvoicesList = async ({ page, page_size }) => {
    setLoadingTable(true);
    setRow([]);
    let response = await getInvoicesListApi({
      page: page,
      page_size: page_size,
    });
    if (Object.keys(response).length > 0) {
      setCount(response.item_count);
      setInitCount(response.item_count);
      setRow(response.items);
      filter(response.items, response.item_count);
    } else {
      setCount(0);
      setInitCount(0);
      setRow([]);
      setFilteredRow([]);
    }
    setLoadingTable(false);
  };

  const init = async () => {
    setLoading(true);
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Quotations")
    ) {
      await getInvoicesList({ page: page, page_size: size });
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handlePageSize = async (e) => {
    setPage(0);
    setSize(e.target.value);
    await getInvoicesList({ page: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getInvoicesList({ page: page - 1, page_size: size });
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const LeadIdColumn = [
    {
      field: "lead_name",
      headerName: getLocalizedText("lead_name", "project").toUpperCase(),
      width: 250,
      cellRenderer: (params) => {
        return params.data.lead?.name ? (
          <Tooltip title={params.data.lead.name}>
            <>{dataRenderer(params.data.lead.name)}</>
          </Tooltip>
        ) : (
          "-"
        );
      },
    },
    {
      field: "associated_lead",
      headerName: getLocalizedText("associated_lead", "project").toUpperCase(),

      width: 200,

      cellRenderer: (params) => {
        let value =
          params.data.hasOwnProperty("lead") && params.data["lead"] !== null
            ? dataRenderer(params.data.lead.flid)
            : "-";
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", height: "100%" }}
            onClick={(e) => {
              e.preventDefault();
              if (
                params.data.hasOwnProperty("lead") &&
                params.data["lead"] !== null
              ) {
                navigate(
                  `/users/${userID}/leads/${params.data["lead"]["id"]}`,
                  {
                    state: {
                      lead_status: null,
                      scope: "quotes_page",
                    },
                  }
                );
              }
            }}
          >
            <Tooltip title={value}>
              <TextHighlight>{value}</TextHighlight>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "actions",
      headerName: "ACTIONS",
      resizable: false,
      minWidth: 130,
      flex: 1,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}
          >
            <Tooltip title={"Preview"}>
              <IconButton
                style={{ padding: "0px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  window.open(
                    `/quote/preview?lead_id=${params.data.lead.id}&invoice_id=${params.data["id"]}&is_invoice=yes`,
                    "_blank"
                  );
                }}
              >
                <Preview
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const filter = (row, initCount) => {
    let data = [...row];

    if (rootStore.invoicesPageState.invoicesChipIndex === 0) {
      // this month
      const startOfThisMonth = moment().startOf("month");
      data = data.filter((element) => {
        const elementDate = moment(IsoToLocalDate(element["invoice_date"]));
        const endOfThisMonth = moment().endOf("month");
        return elementDate.isBetween(
          startOfThisMonth,
          endOfThisMonth,
          null,
          "[]"
        );
      });
    } else if (rootStore.invoicesPageState.invoicesChipIndex === 1) {
      // last month
      const startOfLastMonth = moment().subtract(1, "months").startOf("month");
      const endOfLastMonth = moment().subtract(1, "months").endOf("month");
      data = data.filter((element) => {
        const elementDate = moment(IsoToLocalDate(element["invoice_date"]));
        return elementDate.isBetween(
          startOfLastMonth,
          endOfLastMonth,
          null,
          "[]"
        );
      });
    }

    setFilteredRow(data);
    setCount(
      rootStore.invoicesPageState.invoicesChipIndex === 2
        ? initCount
        : data.length
    ); //set count to response_count for "All" filter. else set it to data length
    if (rootStore.invoicesPageState.invoicesChipIndex !== 2) {
      setPage(0);
    }
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Quotations"
  ) ? (
    <>
      <ParentContainer>
        <Container>
          <Row>
            <TopWrapper>
              <Header>Invoices</Header>
              <ChevronRight style={{ width: "16px", height: "16px" }} />
            </TopWrapper>
          </Row>
          <ChipsContainer>
            {quoteInvoiceListingFilters.map((data) => {
              return (
                <Chip
                  clickable
                  color={
                    data.key === rootStore.invoicesPageState.invoicesChipIndex
                      ? "primary"
                      : "default"
                  }
                  label={data.label}
                  onClick={() => {
                    rootStore.invoicesPageState.updateInvoicesChipIndex(
                      data.key
                    );
                    filter(row, initCount);
                  }}
                />
              );
            })}
          </ChipsContainer>
          {!loadingTable ? (
            <TableWrapper>
              {!loading ? (
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={filteredRow}
                    defaultColDef={defaultColDef}
                    columnDefs={invoicesPageColumnHeaders.concat(
                      LeadIdColumn,
                      actionColumn
                    )}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      if (
                        isCustomQuotationTemplate(row.data["schema"]) === false
                      ) {
                        setSelectedInvoiceData(row.data);
                        setOpenViewInvoicePanel(true);
                      }
                    }}
                  />
                </div>
              ) : (
                <ProgressWrapper>
                  <LoadingAnimationComponent size={"small"} />
                </ProgressWrapper>
              )}
            </TableWrapper>
          ) : (
            <TableWrapper
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingAnimationComponent size={"small"} />
            </TableWrapper>
          )}

          <PaginationWrapper>
            <PaginationComponent
              page_no={page}
              row={filteredRow}
              page_size={size}
              size={size}
              count={count}
              handlePageSize={handlePageSize}
              handlePage={handlePagination}
            />
          </PaginationWrapper>
        </Container>
      </ParentContainer>
      <Footer />
      {openViewInvoicePanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewInvoicePanel}
          onClose={() => {
            setSelectedInvoiceData({});
            setOpenViewInvoicePanel(false);
          }}
        >
          <ViewInvoiceSidepanel
            scope={"invoices"}
            details={selectedInvoiceData}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(InvoicesPage);
