import { Box, Grid, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Transfer } from "antd";
import "antd/dist/antd.css";
import "../../styles/multiselect.css";
import { AppBar, Divider, Toolbar, Button } from "@mui/material";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getLocalizedText } from "../../Functions";
import rootStore from "../../stores/RootStore";
import MuiAlert from "@mui/material/Alert";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "& > .label": {
    display: "inline-block",
    maxWidth: "calc(100% - 20px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "&.drop-over-downward": {
    borderBottom: "2px dashed #1890ff",
  },
  "&.drop-over-upward": {
    borderTop: "2px dashed #1890ff",
  },
}));

const SelectProductColumnsDialog = ({ open, handleClose, handlePostApply }) => {
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const columnsList = [...rootStore.productStore.columnsList];

  const init = () => {
    let tempDataList = [];
    columnsList.forEach((column) => {
      let tempData = {
        key: column["column_name"],
        title: column["display_name"],
      };
      tempDataList.push(tempData);
    });
    setData(tempDataList);

    let tempTargetKeys = [];
    rootStore.productStore.selectColumnsList.forEach((column) => {
      let index = tempDataList.findIndex(
        (entry) => entry["key"] === column["column_name"]
      );
      if (index !== -1) {
        tempTargetKeys.push(column["column_name"]);
      }
    });
    setTargetKeys(tempTargetKeys);
  };
  useEffect(() => {
    init();
  }, []);
  const handleKeys = (keys) => {
    setErrorMessage("");
    setShowAlert(false);
    setTargetKeys(Array.from(new Set(keys)));
  };
  const handleApply = () => {
    if (targetKeys.length > 0) {
      let updatedSelectColumnsList = [];
      targetKeys.forEach((key) => {
        let index = columnsList.findIndex(
          (column) => column["column_name"] === key
        );
        if (index !== -1) {
          updatedSelectColumnsList.push(columnsList[index]);
        }
      });
      handlePostApply(updatedSelectColumnsList);
    } else {
      setErrorMessage(
        getLocalizedText("please_have_atleast_one_column_in_use")
      );
      setShowAlert(true);
    }
  };

  //order select column related functions

  // change order
  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    handleKeys(clonedList);
  };

  //end//

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <HeaderWrapper>
              <Header> {getLocalizedText("select_columns_to_display")}</Header>

              <SubHeader>
                {getLocalizedText(
                  "choose_and_move_column_to_display_in_the_table"
                )}
              </SubHeader>
            </HeaderWrapper>
          </Toolbar>
        </AppBar>

        <Divider style={{ margin: "16px", marginTop: "0px" }} />
        <Box
          style={{
            height: "100%",
            width: "100%",
            padding: "0px 16px",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <Transfer
              dataSource={data}
              listStyle={{
                width: "fit-content",
                height: 300,
              }}
              titles={[
                getLocalizedText("columns_available"),
                getLocalizedText("columns_in_use"),
              ]}
              targetKeys={targetKeys}
              onChange={handleKeys}
              render={(it) => (
                <DraggableItem
                  index={targetKeys.findIndex((key) => key === it.key)}
                  label={it.title}
                  moveRow={moveRow}
                />
              )}
            />
          </DndProvider>
        </Box>

        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          sx={{
            top: "auto",
            bottom: 0,
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
              width: "100%",
              position: "relative",
            }}
          >
            {showAlert && (
              <ErrorAlert
                onClose={(e) => {
                  e.stopPropagation();
                  setShowAlert(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </ErrorAlert>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-end",
                    gap: "16px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="inherit"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleApply();
                    }}
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",

                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                    }}
                  >
                    {getLocalizedText("apply")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ModelContainer>
    </Modal>
  );
};

const type = "DraggableItem";

const DraggableItem = ({ index, label, moveRow }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      className={`${isOver ? dropClassName : ""}`}
    >
      <span className="label">{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default SelectProductColumnsDialog;
