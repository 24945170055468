import { Box, Card, CardActionArea, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import packageJson from "../../../../package.json";
import React from "react";
import { Cancel } from "@material-ui/icons";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black with 40% opacity */
  z-index: 1;
`;
const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PdfThumbnail = styled(Box)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Video = styled("video")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const MediaThumbnailCard = ({
  mediaData,
  mediaUrl,
  handleRemove,
  handleView,
  canEdit = true,
}) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        borderRadius: 0,
      }}
      onClick={() => {
        handleView();
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            "& .rpv-core__viewer.rpv-core__viewer--light": {
              position: "absolute",
            },
            height: "170px",
          }}
        >
          <Overlay />
          {canEdit === true && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
              color="error"
              style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                padding: "2px",
                zIndex: 2, // Ensure the icon is above the overlay
              }}
            >
              <Cancel />
            </IconButton>
          )}

          {(() => {
            if (mediaData["type"] === "product_image") {
              return <Image src={mediaUrl} style={{ zIndex: 0 }} />;
            } else if (mediaData["type"] === "product_video") {
              return <Video src={mediaUrl} style={{ zIndex: 0 }} />;
            } else if (mediaData["type"] === "product_brochure") {
              return (
                <PdfThumbnail>
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
                  >
                    <Viewer
                      fileUrl={mediaUrl}
                      plugins={[thumbnailPluginInstance]}
                    />
                    <Thumbnails />
                  </Worker>
                </PdfThumbnail>
              );
            }
          })()}
        </Container>
      </CardActionArea>
    </Card>
  );
};

export default MediaThumbnailCard;
