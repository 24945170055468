import React, { useCallback } from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  Font,
  Image,
  Text,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import TableLayout from "./TableLayout";
import { convertPxToPts } from "../../../Functions";
import FooterLayout from "./FooterLayout";
import HeaderLayout from "./HeaderLayout";
import HeaderLayoutWithoutCompanyDetails from "./HeaderLayoutWithoutCompanyDetails";
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 400,
      fontStyle: "normal", // Regular
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: 500,
      fontStyle: "normal", // Medium
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: 700,
      fontStyle: "normal", // Bold
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
      fontWeight: 300,
      fontStyle: "italic", // Italic
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  border: {
    position: "absolute",
    top: 8,
    left: 8,
    right: 8,
    bottom: 8,
    border: "1px solid black", // border style
  },
  borderContainer: {
    border: "1px solid black",
    padding: 10,
    marginBottom: 20, // Add space for the bottom border
    flexGrow: 1, // Allow content to grow and push to the bottom if needed
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "16px",
  },
  titleText: {
    fontSize: "22px",
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  imageStyle: {
    width: "60px",
    height: "auto",
    objectFit: "contain",
  },
  textSm: {
    fontSize: 6,
    fontWeight: "400",
    color: "black",
    fontFamily: "Poppins",
  },
});
const htmlStyleSheet = {
  // clear margins for all <p> tags
  strong: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  em: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 300,
  },
  p: {
    fontFamily: "Poppins",
    margin: 0,
    fontSize: 9,
  },
  ul: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
  ol: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
};

const footerHtmlStyleSheet = {
  // clear margins for all <p> tags
  strong: {
    fontSize: 6,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  em: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 300,
  },
  p: {
    fontFamily: "Poppins",
    margin: 0,
    fontSize: 6,
  },
  ul: {
    fontFamily: "Poppins",
    fontSize: 6,
  },
  ol: {
    fontFamily: "Poppins",
    fontSize: 6,
  },
};
const QuoteWithContentLayout = ({ data }) => {
  const canShowBorder = () => {
    return data["styles"]["border"] !== "no";
  };

  const getPaddingValueForPage = () => {
    if (canShowBorder()) {
      return data["styles"]["padding"] + 16;
    } else {
      return data["styles"]["padding"];
    }
  };

  const PageBorderLayout = useCallback(() => {
    if (canShowBorder()) {
      return (
        <>
          {/* Left Border */}

          <View
            fixed
            style={{
              position: "absolute",
              top: `${data["styles"]["padding"]}px`,
              left: `${data["styles"]["padding"]}px`,
              bottom: `${data["styles"]["padding"]}px`,
              width: 1,
              backgroundColor: "#D3D3D3",
            }}
          />

          {/* Right Border */}

          <View
            fixed
            style={{
              position: "absolute",
              top: `${data["styles"]["padding"]}px`,
              right: `${data["styles"]["padding"]}px`,
              bottom: `${data["styles"]["padding"]}px`,
              width: 1,
              backgroundColor: "#D3D3D3",
            }}
          />

          {/* Top Boder */}

          <View
            fixed
            style={{
              position: "absolute",
              top: `${data["styles"]["padding"]}px`,
              left: `${data["styles"]["padding"]}px`,
              right: `${data["styles"]["padding"]}px`,
              height: 1,
              backgroundColor: "#D3D3D3",
            }}
          />

          {/* Bottom Border */}
          <View
            fixed
            style={{
              position: "absolute",
              bottom: `${data["styles"]["padding"]}px`,
              right: `${data["styles"]["padding"]}px`,
              left: `${data["styles"]["padding"]}px`,
              height: 1,
              backgroundColor: "#D3D3D3",
            }}
          />
        </>
      );
    }
    return null;
  }, [data]);

  const renderPageNumber = React.useCallback(({ pageNumber }) => {
    return <Text style={styles.textSm}>{pageNumber}</Text>;
  }, []);

  const getImageHeightInPoints = useCallback(
    (component, dpi = 96) => {
      const { properties } = component;
      // Get modWidth and modHeight
      const modWidth = properties?.modWidth || 0;
      const modHeight = properties?.modHeight || 0;

      // Ensure valid dimensions
      if (!modWidth || !modHeight) {
        console.error("Invalid image dimensions");
        return { height: "auto", width: "auto" };
      }

      // Calculate the rendered width based on the size percentage
      const sizePercentage = properties?.size || 100; // Default to 100% if size is not provided
      const renderedWidth = (modWidth * parseInt(sizePercentage)) / 100;
      const aspectRatio = modWidth / modHeight;
      const renderedHeight = renderedWidth / aspectRatio;

      // Convert pixels to points
      const widthInPoints = (renderedWidth / dpi) * 72;
      const heightInPoints = (renderedHeight / dpi) * 72;

      return { height: heightInPoints, width: widthInPoints };
    },
    [data]
  );

  const canShowCompanyDetailsInHeader = () => {
    let companyDetails =
      data.hasOwnProperty("company_details") && data["company_details"] !== null
        ? data["company_details"]
        : {};
    if (
      companyDetails.hasOwnProperty("show_in_header") &&
      companyDetails["show_in_header"] !== null
    ) {
      return companyDetails["show_in_header"];
    }
    return false;
  };

  console.log("footer content", JSON.stringify(data.footer.components));

  return (
    <Document>
      <Page
        size="A4"
        style={{
          ...styles.page,
          padding: `${getPaddingValueForPage()}px`,
          paddingBottom: `${getPaddingValueForPage() - 8}px`,
        }}
        wrap={true}
      >
        <PageBorderLayout />

        <View fixed>
          {canShowCompanyDetailsInHeader() ? (
            <HeaderLayout
              companyDetails={
                data.hasOwnProperty("company_details") &&
                data["company_details"] !== null
                  ? data["company_details"]
                  : {}
              }
              headerComponents={data.header.components}
            />
          ) : (
            <HeaderLayoutWithoutCompanyDetails
              companyLogo={
                data.hasOwnProperty("company_details") &&
                data["company_details"] !== null &&
                data["company_details"].hasOwnProperty("company_logo") &&
                data["company_details"]["company_logo"] !== null
                  ? data["company_details"]["company_logo"]
                  : ""
              }
              headerComponents={data.header.components}
            />
          )}

          <View
            style={{
              marginTop: "8px",
              marginBottom: "8px",
              backgroundColor: "#D3D3D3",
              width: "100%",
              height: "1px",
            }}
          />
        </View>
        {data["components"].map((component, cidx) => {
          if (
            component["type"] === "image" &&
            component["content"]["image_url"] !== null &&
            component["content"]["image_url"] !== ""
          ) {
            let tempStyle = getImageHeightInPoints(component);

            if (component["properties"]["alignment"] === "center") {
              tempStyle["marginLeft"] = "auto";
              tempStyle["marginRight"] = "auto";
            } else if (component["properties"]["alignment"] === "left") {
              tempStyle["marginRight"] = "auto";
            } else if (component["properties"]["alignment"] === "right") {
              tempStyle["marginLeft"] = "auto";
            }

            return (
              <View wrap={false}>
                <Image
                  style={tempStyle}
                  src={component["content"]["image_url"]}
                />
              </View>
            );
          } else if (component["type"] === "table") {
            return <TableLayout tableData={component["content"]} />;
          } else if (component["type"] === "page_break") {
            return <View break={true} key={cidx} />;
          } else if (component["type"] === "text") {
            return (
              <View>
                <Html stylesheet={htmlStyleSheet}>
                  {convertPxToPts(component["content"])}
                </Html>
              </View>
            );
          }
        })}

        <View
          fixed
          style={{
            marginTop: "auto",
          }}
        >
          <View
            style={{
              marginTop: "8px",
              backgroundColor: "#D3D3D3",
              width: "100%",
              height: "1px",
              marginBottom: "8px",
            }}
          />
          <View
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {data.footer.components.map((component, idx) => {
                if (
                  component["type"] === "text" &&
                  component["content"] !== ""
                ) {
                  return (
                    <View key={idx}>
                      <Html stylesheet={footerHtmlStyleSheet}>
                        {convertPxToPts(component["content"])}
                      </Html>
                    </View>
                  );
                }
              })}
            </View>

            {/* show page number with footer text only when company details are not shown in footer */}
            {canShowCompanyDetailsInHeader() === true && (
              <View render={renderPageNumber} />
            )}
          </View>

          {/* if company details shown in footer , show page number with company details */}
          {canShowCompanyDetailsInHeader() === false && (
            <View
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <FooterLayout
                data={
                  data.hasOwnProperty("company_details") &&
                  data["company_details"] !== null
                    ? data["company_details"]
                    : {}
                }
              />
              <View render={renderPageNumber} />
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};
export default QuoteWithContentLayout;
