import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { getLocalizedText } from "../../../Functions";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const ItemActionComponent = ({
  handleView,
  handleEdit,
  handleDelete,
  handleClone,
  isFormDisabled,
}) => {
  const [anchorE1, setAnchorE1] = React.useState(null);
  const Open = Boolean(anchorE1);
  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE1(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={Open ? "long-menu" : undefined}
        aria-expanded={Open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        style={{ display: "flex", flexDirection: "column" }}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE1}
        open={Open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "fit-content",
          },
        }}
      >
        <MenuItem
          disabled={isFormDisabled}
          onClick={() => {
            handleClose();

            if (!isFormDisabled) {
              handleView();
            }
          }}
        >
          <Visibility
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            color="primary"
          />
          {getLocalizedText("view_item")}
        </MenuItem>
        <MenuItem
          disabled={isFormDisabled}
          onClick={() => {
            handleClose();

            if (!isFormDisabled) {
              handleEdit();
            }
          }}
        >
          <Edit
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            color="primary"
          />
          {getLocalizedText("edit_item")}
        </MenuItem>
        <MenuItem
          disabled={isFormDisabled}
          onClick={() => {
            handleClose();

            if (!isFormDisabled) {
              handleDelete();
            }
          }}
        >
          <Delete
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            color="error"
          />
          {getLocalizedText("delete_item")}
        </MenuItem>
        <MenuItem
          disabled={isFormDisabled}
          onClick={() => {
            handleClose();

            handleClone();
          }}
        >
          <ContentCopyIcon
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              marginRight: "8px",
            }}
            color="primary"
          />
          {getLocalizedText("clone_item")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ItemActionComponent;
