import React from "react";
import {
  Box,
  IconButton,
  Menu,
  OutlinedInput,
  styled,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { Close, Search } from "@material-ui/icons";
import { MultiSelect } from "react-multi-select-component";
import { quotationAdditionalColumns } from "../../../Db";
import { consoleLogger } from "../../../Functions";

const Container = styled(Box)`
  display: flex;
  width: 180px;
  height: fit-content;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;
const CheckboxLabel = styled(Typography)`
  margin: 0px;
  font: normal normal 500 12px Open Sans;
`;
const AdditionalColumnsDialog = ({
  anchor,
  handleClose,
  setTableAdditionalColumns,
  tableAdditionalColumns,
}) => {
  const open = Boolean(anchor);

  return (
    <Menu
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id="long-menu"
      disableScrollLock
      anchorEl={anchor}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        style: {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px -2px 8px rgba(0,0,0,0.32))",
            mb: 1.5,
            borderRadius: "8px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: "100%",
              right: 14,
              transform: "translateX(-50%)",
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderBottom: "5px solid white",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {quotationAdditionalColumns.map((column) => {
            const isChecked = tableAdditionalColumns.some(
              (item) => item.column_name === column.column_name
            );

            return (
              <FormControlLabel
                sx={{ width: "100%" }}
                key={column.column_name}
                control={
                  <Checkbox
                  
                    checked={isChecked}
                    color="primary"
                  />
                }
                label={
                  <CheckboxLabel sx={{ width: "100%", flexGrow: 1 }}>
                    {column.display_name}
                  </CheckboxLabel>
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  setTableAdditionalColumns((prev) =>
                    checked
                      ? [...prev, column]
                      : prev.filter(
                          (item) => item.column_name !== column.column_name
                        )
                  );
                }}
              />
            );
          })}
        </Box>
      </Container>
    </Menu>
  );
};

export default AdditionalColumnsDialog;
