import React from "react";

const TableComponent = ({ tableData, handleCellClick }) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
      }}
    >
      <tbody>
        {tableData.map((row, rowIndex) => (
          <tr key={`row-${rowIndex}`}>
            {row.map((cell, cellIndex) => {
              const isBorderNone = cell.style.border === "none";

              const isFirstRow = rowIndex === 0;
              const isFirstCellInRow = cellIndex === 0;

              // Determine adjacent cells
              const topCell =
                rowIndex > 0 ? tableData[rowIndex - 1][cellIndex] : null;
              const leftCell = cellIndex > 0 ? row[cellIndex - 1] : null;
              const bottomCell =
                rowIndex < tableData.length - 1
                  ? tableData[rowIndex + 1][cellIndex]
                  : null;
              const rightCell =
                cellIndex < row.length - 1 ? row[cellIndex + 1] : null;

              // Set borders dynamically based on adjacent cells
              const borderTop =
                isBorderNone || (topCell && topCell.style.border === "none")
                  ? "0px"
                  : isFirstRow
                  ? "1px solid black"
                  : "none";
              const borderLeft =
                isBorderNone || (leftCell && leftCell.style.border === "none")
                  ? "0px"
                  : isFirstCellInRow
                  ? "1px solid black"
                  : "none";
              const borderBottom =
                isBorderNone ||
                (bottomCell && bottomCell.style.border === "none")
                  ? "0px"
                  : "1px solid black";
              const borderRight =
                isBorderNone || (rightCell && rightCell.style.border === "none")
                  ? "0px"
                  : "1px solid black";
              return (
                <td
                  key={cell.id}
                  style={{
                    ...cell.style,
                    padding: "0px",
                    width: `${cell.style.width}%`,
                    backgroundColor: cell.style.backgroundColor,
                    boxSizing: "border-box",
                    borderTop,
                    borderLeft,
                    borderBottom,
                    borderRight,
                    paddingLeft:
                      cell.content !== "" ? cell.style.padding : "0px",
                    paddingTop: "8px",
                  }}
                  onClick={() => handleCellClick(cell)}
                >
                  <div
                    id="qb-table-component"
                    style={{ fontSize: "12px" }}
                    dangerouslySetInnerHTML={{
                      __html: cell.content !== "" ? cell.content : "&nbsp;",
                    }}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableComponent;
