import { Card, CardActionArea, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import React, { useRef } from "react";
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #a9a9a9;
  text-align: center;
`;

const MediaUploadCard = ({
  mediaData,
  handleMediaUpload,
  mediaUrl,
  isDisabled = false,
}) => {
  const inputRef = useRef(null);
  console.log("media url::::", JSON.stringify(mediaUrl));

  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        borderRadius: 0,
      }}
      onClick={() => {
        if (isDisabled === false) {
          inputRef.current.click();
        }
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          justifyContent: "center",
          alignItems: "center",
        }}
        disabled={isDisabled}
      >
        {mediaData["icon"]}
        <Label style={{ fontSize: mediaData["font_size"] }}>
          {mediaData["label"]}
        </Label>
        <input
          style={{ display: "none" }}
          type="file"
          ref={inputRef}
          accept={mediaData["allowed_file"]}
          onClick={() => {
            inputRef.current.value = "";
          }}
          onChange={(event) => handleMediaUpload(event)}
        />
      </CardActionArea>
    </Card>
  );
};

export default MediaUploadCard;
