import { Box, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { observer } from "mobx-react-lite";
import { getImportsListApi } from "../../../Api";
import PaginationComponent from "../../pagination/PaginationComponent";
import LoadingAnimationComponent from "../../loading_component/LoadingAnimationComponent";
import { customerImportsColumnHeaders } from "../../../Db";
import { pageSize } from "../../../Config";
import { Drawer } from "@material-ui/core";
import ImportDetailsSidePanel from "../../import_details_sidepanel/ImportDetailsSidePanel";
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 232px);`};
  background-color: white;
`;
const CustomerImportsDataTable = ({ refresh, setRefresh, scope }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(true);
  const [openViewImportInfoPanel, setOpenViewImportInfoPanel] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState({});

  const getImportsList = async ({ page_no, page_size }) => {
    let queryParams = { scope: scope };
    let response = await getImportsListApi({
      page_no: page_no,
      page_size: page_size,
      queryParams: queryParams,
    });

    if (Object.keys(response).length > 0) {
      setData(response.items);
      setCount(response.item_count);
    } else {
      setData([]);
      setCount(0);
    }
  };

  const init = async () => {
    await getImportsList({ page_no: 0, page_size: pageSize });
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const refetch = async () => {
    setLoading(true);
    await getImportsList({ page_no: 0, page_size: size });
    setLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);

  const handlePageSize = async (e) => {
    setLoading(true);
    setSize(e.target.value);
    setPage(0);
    await getImportsList({
      page_no: 0,
      page_size: e.target.value,
    });
    setLoading(false);
  };
  const handlePagination = async (e, page) => {
    setLoading(true);
    setPage(page - 1);
    await getImportsList({ page_no: page - 1, page_size: size });

    setLoading(false);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };
  return !loading ? (
    <Box sx={{ width: "100%", flexDirection: "column" }}>
      <TableWrapper>
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            domLayout="autoHeight"
            gridOptions={gridOptions}
            rowData={data}
            defaultColDef={defaultColDef}
            columnDefs={customerImportsColumnHeaders}
            animateRows={true}
            suppressCellFocus
            pagination={false}
            paginationPageSize={size}
            onRowClicked={(row) => {
              if (row.event.defaultPrevented) {
                return null;
              }
              setSelectedRowData(row.data);
              setOpenViewImportInfoPanel(true);
            }}
            suppressColumnVirtualisation={true}
          />
        </div>
        <Box sx={{ padding: "0px 20px" }}>
          <PaginationComponent
            page_no={page}
            row={data}
            page_size={size}
            size={size}
            count={count}
            handlePageSize={handlePageSize}
            handlePage={handlePagination}
          />
        </Box>
      </TableWrapper>
      {openViewImportInfoPanel && (
        <Drawer
          anchor={"right"}
          open={openViewImportInfoPanel}
          onClose={() => {
            setOpenViewImportInfoPanel(false);
          }}
        >
          <ImportDetailsSidePanel
            id={
              selectedRowData.hasOwnProperty("id") ? selectedRowData["id"] : ""
            }
          />
        </Drawer>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 218px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default observer(CustomerImportsDataTable);
