import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { consoleLogger, dataRenderer } from "../../../../Functions";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
export default function TableComponent({ tableData = [] }) {
  const shouldShowTaxColumn = () => {
    //don't show if all tax values are less negative
    return !tableData.every(
      (x) =>
        x.tax !== "" &&
        x.tax !== null &&
        x.tax !== undefined &&
        parseFloat(x.tax) < 0
    );
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 576px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item</TableHeaderCell>
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            {shouldShowTaxColumn() && (
              <TableHeaderCell align="right">Tax (%)</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Discount (%)</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  {row["name"]}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                {shouldShowTaxColumn() && (
                  <TableDataCell align="right">
                    {parseFloat(row["tax"]) >= 0
                      ? dataRenderer(row["tax"])
                      : "-"}
                  </TableDataCell>
                )}
                <TableDataCell align="right">{row["discount"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 634px)" }}
                colSpan={6}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
