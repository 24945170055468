import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import rootStore from "../stores/RootStore";
import { consoleLogger } from "../Functions";
import moment from "moment";

class WabaChatNotificationState {
  notificationsForProject = {};
  leadChatStatusState = {}; // for current open lead status change
  notificationState = {
    canShowBadge: false,
  };
  constructor() {
    makeObservable(this, {
      notificationState: observable,
      leadChatStatusState: observable,
      notificationsForProject: observable,
      getNotificationsForProject: action,
      onInboundMessage: action,
      onInboundStatusChange: action,
    });
    makePersistable(this, {
      name: WabaChatNotificationState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["notificationsForProject"],
    });
  }

  onInboundStatusChange = async (data) => {
    this.leadChatStatusState = data;
    consoleLogger("updated leadChatStatusState with:: ", data);
  };

  onInboundMessage = async (data) => {
    consoleLogger("notification data:: ", data);
    //store data project wise
    const pid = data?.project_id || "";
    if (pid !== "") {
      const notificationItem = {
        ...data,
        timestamp: moment().toISOString(),
      };

      if (this.notificationsForProject.hasOwnProperty(pid)) {
        //cloning original array
        const updatedNotifications = [...this.notificationsForProject[pid]];

        //checking if lead_id is already there
        const index = updatedNotifications.findIndex(
          (item) => item.lead_id === data.lead_id
        );

        if (index !== -1) {
          //updating only the timestamp if it is there (update needs to happen for live reload to happen in chat sidepanel)
          updatedNotifications[index] = notificationItem;
        } else {
          //pushing the item if lead_id was not there
          updatedNotifications.push(notificationItem);
        }

        this.notificationsForProject = {
          ...this.notificationsForProject,
          [pid]: updatedNotifications,
        };
      } else {
        //adding the first item to array if the pid is not there
        this.notificationsForProject = {
          ...this.notificationsForProject,
          [pid]: [notificationItem],
        };
      }
      this.setShowNotificationBadgeFlag(rootStore.authStore.currentProject.id);
      consoleLogger("notifs for pr:: ", this.notificationsForProject);
    }
  };

  setShowNotificationBadgeFlag = (pid) => {
    //set notification icon show badge flag
    if (
      this.notificationsForProject.hasOwnProperty(pid) &&
      this.notificationsForProject[pid].length !== 0
    ) {
      this.notificationState["canShowBadge"] = true;
    } else {
      this.notificationState["canShowBadge"] = false;
    }
  };

  getNotificationsForProject = (projectID) => {
    consoleLogger("get for pr:: ", projectID);

    if (this.notificationsForProject.hasOwnProperty(projectID)) {
      consoleLogger("ls:: ", this.notificationsForProject[projectID]);
      return this.notificationsForProject[projectID];
    }
    return [];
  };

  removeNotification = (leadId, projectID) => {
    if (this.notificationsForProject.hasOwnProperty(projectID)) {
      this.notificationsForProject[projectID] = this.notificationsForProject[
        projectID
      ].filter((item) => item.lead_id !== leadId);
    }
    this.setShowNotificationBadgeFlag(projectID);
  };

  reset = async () => {
    this.notificationsForProject = {};
    this.leadChatStatusState = {};
    this.notificationState = {
      canShowBadge: false,
    };
  };
}

export default WabaChatNotificationState;
