import React from "react";
import { StyleSheet, View, Text, Font, Image } from "@react-pdf/renderer";
import email_icon from "../../../assets/campaign_icons/mail.png";
import web_icon from "../../../assets/campaign_icons/website.png";
import { convertPxToPts } from "../../../Functions";
import Html from "react-pdf-html";
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 400,
      fontStyle: "normal", // Regular
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: 500,
      fontStyle: "normal", // Medium
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: 700,
      fontStyle: "normal", // Bold
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
      fontWeight: 300,
      fontStyle: "italic", // Italic
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    marginBottom: "0px",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    flexDirection: "column",
    display: "flex",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  headerCell: {
    textAlign: "left",
    flex: 1,
    padding: "4px 8px",
  },
  textLg: {
    fontSize: 10,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  textSm: {
    fontSize: 8,
    fontWeight: "400",
    color: "black",
    fontFamily: "Poppins",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
  imageStyle: {
    maxWidth: "30%",
    height: "60px",
    objectFit: "contain",
  },
  icon: {
    width: 10, // Adjust the size of the icon
    height: 10,
    marginRight: 5, // Space between icon and text
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
  },
});
const htmlStyleSheet = {
  // clear margins for all <p> tags
  strong: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  em: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 300,
  },
  p: {
    fontFamily: "Poppins",
    margin: 0,
    fontSize: 9,
  },
  ul: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
  ol: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
};
const HeaderLayout = ({ companyDetails, headerComponents }) => {
  return (
    <View style={styles.container}>
      <View style={styles.column}>
        {headerComponents.map((component, idx) => {
          if (component["type"] === "text") {
            return (
              <View key={idx}>
                <Html stylesheet={htmlStyleSheet}>
                  {convertPxToPts(component["content"])}
                </Html>
              </View>
            );
          }
        })}
      </View>
      <View style={styles.row}>
        {companyDetails["company_logo"] !== "" && (
          <Image
            src={companyDetails["company_logo"]}
            alt="company logo"
            style={styles.imageStyle}
          />
        )}

        <View style={styles.column}>
          <Text style={styles.textLg}>{companyDetails["company_name"]}</Text>
          <Text style={styles.textSm}>{companyDetails["company_address"]}</Text>
          <View style={styles.section}>
            <Image style={styles.icon} src={email_icon} />
            <Text style={styles.textSm}>{companyDetails["company_email"]}</Text>
          </View>
          <View style={styles.section}>
            <Image style={styles.icon} src={web_icon} />
            <Text style={styles.textSm}>
              {companyDetails["company_website"]}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default HeaderLayout;
