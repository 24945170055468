import { consoleLogger } from "../Functions";
import rootStore from "../stores/RootStore";

class NotifcationService {
  sendMessageIn = (im) => {
    consoleLogger(`Notification Service ::: send message in ::: ${im}`);
    this._handleInboundMessage(im);
  };

  _handleInboundMessage = (value) => {
    consoleLogger(
      `Notification Service ::: handle Inbound Message ::: ${value}`
    );
    let response = JSON.parse(value);
    consoleLogger(
      `Notification Service ::: handle Inbound Message  - ${response["messageID"]}`
    );
    consoleLogger(
      `Notification Service ::: handle Inbound Message response - ${response}`
    );
    if (response.hasOwnProperty("message")) {
      let message = response["message"];
      if (message.hasOwnProperty("action")) {
        switch (message["action"].toLowerCase()) {
          case "progressive_call":
            rootStore.phoneCallState.onProgressiveCallStart(message);
            break;
          case "call_summary":
            rootStore.phoneCallState.onProgressiveCallEnd(message);
            break;
          case "waba_inbound_message":
            rootStore.wabaChatNotificationState.onInboundMessage(message);
            break;
          case "waba_status_update":
            rootStore.wabaChatNotificationState.onInboundStatusChange(message);
            break;
          default:
            break;
        }
      }
    }
  };
}

const notificationServiceInstance = new NotifcationService();
export default notificationServiceInstance;
