import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Edit, Visibility } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Box, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  consoleLogger,
  dataRenderer,
  getLocalizedText,
} from "../../../Functions";

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "8px 12px",
  whiteSpace: "nowrap",
}));

const TableDataCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "8px 12px",
  whiteSpace: "nowrap",
  position: "relative",
}));

const FixedTableHeaderCell = styled(TableHeaderCell)(({ theme }) => ({
  position: "sticky",
  right: 0,
  zIndex: 100,
}));

const TableItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "600",
  padding: 0,
}));
const TableSubText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  padding: "2px 0px",
}));

export default function TableComponentAreaBased({
  tableData,
  setSelectedProductData,
  setOpenEditProductModal,
  setOpenViewProductModal,
  handleCloneItem,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(0);
  const Open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedProductData({});
  };

  const canShowSizeRelatedCols = () => {
    return tableData.some(
      (item) =>
        item.width &&
        item.height &&
        item.width !== 0 &&
        item.height !== 0 &&
        item.width !== "" &&
        item.height !== ""
    );
  };

  const checkIfRowHasSize = (row) => {
    return (
      row.width &&
      row.height &&
      row.width !== 0 &&
      row.height !== 0 &&
      row.width !== "" &&
      row.height !== ""
    );
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 494px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item & Description</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">Size (W x H)</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Unit</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">In Units</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            {canShowSizeRelatedCols() && (
              <TableHeaderCell align="right">Total Units</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
            <TableHeaderCell align="right">{""}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  <TableItemTitle>{row["item"]["name"]}</TableItemTitle>
                  {row["desc"]}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {Array.isArray(row["additional_details"]) &&
                      row["additional_details"].map((detail, index) => (
                        <TableSubText
                          key={index}
                          style={{
                            textAlign: "left",
                            border: 0,
                          }}
                        >
                          • {dataRenderer(detail.field)}:{" "}
                          {dataRenderer(detail.value)}
                        </TableSubText>
                      ))}
                  </Box>
                </TableDataCell>
                {canShowSizeRelatedCols() ? (
                  <>
                    <TableDataCell align="right">
                      {checkIfRowHasSize(row)
                        ? row["width"] + " x " + row["height"]
                        : "-"}
                    </TableDataCell>
                    <TableDataCell align="right">{dataRenderer(row["unit"])}</TableDataCell>

                    <TableDataCell align="right">
                      {dataRenderer(row["in_unit"])}
                    </TableDataCell>
                  </>
                ) : (
                  <TableDataCell align="right">{dataRenderer(row["unit"])}</TableDataCell>
                )}
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                {canShowSizeRelatedCols() && (
                  <TableDataCell align="right">
                    {dataRenderer(row["total_unit"])}
                  </TableDataCell>
                )}
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <TableDataCell align="right">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={Open ? "long-menu" : undefined}
                    aria-expanded={Open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, i)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    style={{ display: "flex", flexDirection: "column" }}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={Open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "fit-content",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();

                        setSelectedProductData(tableData[selectedRowIndex]);
                        setOpenViewProductModal(true);
                      }}
                    >
                      <Visibility
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("view_item")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();

                        setSelectedProductData(tableData[selectedRowIndex]);
                        setOpenEditProductModal(true);
                      }}
                    >
                      <Edit
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("edit_item")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();

                        handleCloneItem(tableData[selectedRowIndex]);
                      }}
                    >
                      <ContentCopyIcon
                        style={{
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                          marginRight: "8px",
                        }}
                        color="primary"
                      />
                      {getLocalizedText("clone_item")}
                    </MenuItem>
                  </Menu>
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 552px)" }}
                colSpan={10}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
