import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import FolderCard from "../cards/FolderCard";
const Container = styled(Box)`
  width: 100%;
  height: 300px;
  overflow-y: auto;
`;
const FolderScreen = ({ folderList, handleFolderClick }) => {
  return folderList.length > 0 ? (
    <Container>
      <Grid container spacing={2}>
        {folderList.map((folderData, index) => {
          return (
            <Grid item xs={4} key={index}>
              <FolderCard
                folderData={folderData}
                handleFolderClick={() => handleFolderClick(folderData)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  ) : (
    <Container
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Typography style={{fontSize:"12px"}}>No folder found.</Typography>
    </Container>
  );
};

export default FolderScreen;
