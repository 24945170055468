import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import ColorPickerDialog from "./ColorPickerDialog";
import JoditEditor from "jodit-react";
const dialogWidth = 500;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const CheckboxLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const MIN_WIDTH_PERCENT = 5;
const CellEditDialog = ({
  open,
  setOpen,
  handleCellEdit,
  editableData,
  adjacentCellData,
}) => {
  const editor = useRef(null);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [content, setContent] = useState("");
  const [details, setDetails] = useState({
    border_visible: true,
    width: "",
  });

  const geMaxCellWidthLimit = () => {
    if (
      editableData.hasOwnProperty("style") &&
      editableData["style"].hasOwnProperty("width") &&
      adjacentCellData.hasOwnProperty("style") &&
      adjacentCellData["style"].hasOwnProperty("width")
    ) {
      let cellWidth = parseFloat(editableData["style"]["width"]);
      let adjacentCellWidth = parseFloat(adjacentCellData["style"]["width"]);
      let totalWidth = cellWidth + adjacentCellWidth;
      return totalWidth - MIN_WIDTH_PERCENT; // 5 is min width percent
    }
    return editableData["style"]["width"];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempCellData = {
      background_color: color,
      border: details["border_visible"] ? "1px solid black" : "none",
      width: parseFloat(details["width"]),
      content: content,
    };
    handleCellEdit(tempCellData);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (Object.keys(editableData).length > 0) {
      if (editableData.hasOwnProperty("content")) {
        setContent(editableData["content"]);
      }
      if (editableData.hasOwnProperty("style")) {
        setColor(editableData["style"]["backgroundColor"]);
        let tempDetails = {
          border_visible:
            editableData["style"].hasOwnProperty("border") &&
            editableData["style"]["border"] !== "none"
              ? true
              : false,
          width: editableData["style"].hasOwnProperty("width")
            ? parseFloat(parseFloat(editableData["style"]["width"]).toFixed(2))
            : "",
        };

        setDetails(tempDetails);
      }
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
      >
        <ModelContainer>
          <Box component={"form"} onSubmit={handleSubmit}>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>Edit Cell</Header>
                  <SubHeader>Fill in the below details</SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Width*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: MIN_WIDTH_PERCENT,
                          max: geMaxCellWidthLimit(),
                          step:"0.01",
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        name="width"
                        onChange={(e) => {
                          setDetails({ ...details, width: e.target.value });
                        }}
                        value={details.width}
                        type={"number"}
                        placeholder={"Enter Value"}
                        required
                        disabled={Object.keys(adjacentCellData).length === 0}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Background Color*</Label>
                      <Box
                        style={{
                          backgroundColor: color,
                          cursor: "pointer",
                          width: "30px",
                          height: "30px",
                          border: "1px solid gray",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          setOpenColorPicker(true);
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={details["border_visible"]}
                          color="primary"
                          size="small"
                        />
                      }
                      label={<CheckboxLabel>Border Visible</CheckboxLabel>}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          border_visible: e.target.checked,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        "& .jodit-status-bar": {
                          display: "none",
                        },
                      }}
                    >
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={{
                          // autofocus: true,
                          style: { font: "12px Arial" },
                          readonly: false,
                          placeholder: "Start typing",
                          toolbarInline: true,
                          toolbarAdaptive: false,
                          buttons: [
                            "bold",
                            "strikethrough",
                            "underline",
                            "italic",
                            "|",
                            "ul",
                            "ol",
                            "|",
                            "fontsize",
                            "brush",
                            "align",
                            "|",
                          ],
                        }}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: dialogWidth,
                  position: "relative",
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        textTransform: "none",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
          {openColorPicker && (
            <ColorPickerDialog
              color={color}
              handleClose={() => {
                setOpenColorPicker(false);
              }}
              open={openColorPicker}
              handleColor={(color) => {
                setColor(color);
                setOpenColorPicker(false);
              }}
            />
          )}
        </ModelContainer>
      </Modal>
    </>
  );
};

export default CellEditDialog;
