import { Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  Chip,
  OutlinedInput,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const Detail = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SpecificationsScreen = ({
  specificationList,
  handleSpecificationDetails,
  handleDelete,
}) => {
  const [details, setDetails] = useState({ label: "", value: "" });
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={5}>
            <InputWrapper>
              <Label>Specification Name</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.label}
                type="text"
                placeholder=""
                name="label"
                onChange={handleChange}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={5}>
            <InputWrapper>
              <Label>Specification Value</Label>

              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={details.value}
                type="text"
                placeholder=""
                name="value"
                onChange={handleChange}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                textTransform: "none",
                fontWeight: "bold",
                width: "100%",
                fontSize: "12px",
              }}
              disabled={details["label"] === "" || details["value"] === ""}
              color="primary"
              variant="contained"
              onClick={() => {
                handleSpecificationDetails(details);
                setDetails({ label: "", value: "" });
              }}
            >
              {" "}
              ADD
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          {specificationList.length > 0 &&
            specificationList.map((entry, idx) => {
              return (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <Detail>{entry["label"]}</Detail>
                  </Grid>
                  <Grid item xs={5}>
                    <Detail>{entry["value"]}</Detail>
                  </Grid>
                  <Grid xs={2} item>
                    <Button
                      style={{
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                        fontSize: "12px",
                      }}
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        handleDelete(entry["label"]);
                      }}
                    >
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpecificationsScreen;
