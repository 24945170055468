import { TableRows, ViewColumn } from "@mui/icons-material";
import { Box, IconButton, styled, Tooltip } from "@mui/material";
import React, { useState } from "react";
import TableComponent from "./table_components/TableComponent";
import CellEditDialog from "../dialogs/CellEditDialog";
import InsertRowColumnDialog from "../dialogs/InsertRowColumnDialog";
import DeleteRowColumnDialog from "../dialogs/DeleteRowColumnDialog";
const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
  /* padding-bottom: 0px; */
`;
const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 16px;
`;
const TableComponentCard = ({ editableData, handleSaveTable }) => {
  const [openCellEditor, setOpenCellEditor] = useState(false);
  const [selectedCellData, setSelectedCellData] = useState({});
  const [adjacentColumnCellData, setAdjacentColumnCellData] = useState({});
  const [insertType, setInsertType] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [openInsertIndexDialog, setOpenInsertIndexDialog] = useState(false);
  const [openDeleteIndexDialog, setOpenDeleteIndexDialog] = useState(false);

  const getAdjacentColumnCellData = (selectedCell) => {
    for (
      let rowIndex = 0;
      rowIndex < editableData["content"].length;
      rowIndex++
    ) {
      const row = editableData["content"][rowIndex];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const cell = row[colIndex];

        // Check if this is the selected cell
        if (cell["id"] === selectedCell["id"]) {
          if (colIndex === row.length - 1) {
            if (colIndex !== 0) {
              return row[colIndex - 1];
            }
          } else {
            return row[colIndex + 1];
          }
        }
      }
    }
    return {};
  };

  const handleCellEdit = (data) => {
    const cellID = selectedCellData["id"];
    let tempTableData = [];

    // Calculate width change for the edited cell
    const newWidth = data["width"];
    console.log("new width:::", newWidth);

    // Dynamically calculate the initial width of each column
    const numColumns = editableData["content"][0]
      ? editableData["content"][0].length
      : 0; // Get the number of columns (first row is assumed to have all columns)
    const initialWidth = parseFloat(100 / numColumns); // Divide 100% by number of columns to get the default width per column

    // Iterate through the table data to find the selected cell
    let columnToUpdate = -1;
    let currentWidth = initialWidth; // Default initial width for cells
    for (
      let rowIndex = 0;
      rowIndex < editableData["content"].length;
      rowIndex++
    ) {
      const row = editableData["content"][rowIndex];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const cell = row[colIndex];

        // Check if this is the selected cell
        if (cell["id"] === cellID) {
          columnToUpdate = colIndex; // Get the column index of the selected cell
          currentWidth = parseFloat(cell.style.width);
          break;
        }
      }
      if (columnToUpdate !== -1) break; // Exit the loop once the cell is found
    }
    // Update the table data
    for (
      let rowIndex = 0;
      rowIndex < editableData["content"].length;
      rowIndex++
    ) {
      const row = editableData["content"][rowIndex];
      const newRow = [];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const cell = row[colIndex];

        if (cell["id"] === cellID) {
          // Update the selected cell's content and width
          let newCellData = {
            ...cell,
            content: data["content"],
            style: {
              ...cell["style"],
              width: newWidth,
              border: data["border"],
              backgroundColor: data["background_color"],
            }, // Set new width for the selected cell
          };
          newRow.push(newCellData);
        } else if (colIndex === columnToUpdate) {
          // Update the width of all cells in the selected column
          let newCellData = {
            ...cell,
            style: { ...cell["style"], width: newWidth }, // Set new width for the selected column cells
          };
          newRow.push(newCellData);
        } else {
          // Adjust the adjacent column's width based on the selected column
          let adjacentColumnToUpdate = -1;
          if (columnToUpdate === numColumns - 1) {
            adjacentColumnToUpdate = columnToUpdate - 1;
          } else {
            adjacentColumnToUpdate = columnToUpdate + 1;
          }

          if (colIndex === adjacentColumnToUpdate) {
            let adjacentColWidth = parseFloat(cell["style"]["width"]);
            let adjacentColNewWidth = 0;
            if (newWidth > currentWidth) {
              let widthDiff = newWidth - currentWidth;
              adjacentColNewWidth = adjacentColWidth - widthDiff;
            } else {
              let widthDiff = currentWidth - newWidth;
              adjacentColNewWidth = adjacentColWidth + widthDiff;
            }
            let newCellData = {
              ...cell,
              style: { ...cell["style"], width: adjacentColNewWidth },
              // Adjust the previous column's width
            };
            newRow.push(newCellData);
          } else {
            newRow.push(cell);
          }
        }
      }

      // Add the modified row to the new table data
      tempTableData.push(newRow);
    }

    // Set the updated table data
    handleSaveTable(tempTableData);

    setOpenCellEditor(false);
  };
  const getMaxIndexToInsert = () => {
    if (insertType === "column") {
      if (editableData["content"].length > 0) {
        let firstRowData = editableData["content"][0]; //get first row data;
        return firstRowData.length + 1;
      }
    } else {
      return editableData["content"].length + 1;
    }
    return 1;
  };

  const getMaxIndexToDelete = () => {
    if (deleteType === "column") {
      if (editableData["content"].length > 0) {
        let firstRowData = editableData["content"][0]; //get first row data;
        return firstRowData.length;
      }
    } else {
      return editableData["content"].length;
    }
    return 1;
  };

  const isDeleteRowDisabled = () => {
    return editableData["content"].length < 2;
  };
  const isDeleteColumnisabled = () => {
    return editableData["content"][0].length < 2;
  };

  const insertColumn = (tableData, colIndex, minWidth = 5) => {
    const currentColumns = tableData[0].length;
    const totalColumns = currentColumns + 1; // Account for the new column
    const newWidth = parseFloat(100 / totalColumns); // New proportional width
    const reductionPerColumn = parseFloat(newWidth / currentColumns); // Distribute reduction among existing columns

    const updatedTable = tableData.map((row, rowIndex) => {
      let unallocatedReduction = 0; // Track unallocated width due to min width

      // New column cell
      let newCell = {
        id: `cell-${rowIndex}-${colIndex}`,
        style: {
          width: newWidth,
          border: "1px solid black",
          cursor: "pointer",
          padding: "2px",
          backgroundColor: "#ffffff",
        },
        content: "",
      };

      // Adjust existing columns
      const adjustedRow = row.map((cell, index) => {
        const currentWidth = parseFloat(cell.style.width) || 0;
        const newCellWidth =
          currentWidth - (currentWidth > minWidth ? reductionPerColumn : 0);

        if (currentWidth <= minWidth) {
          unallocatedReduction += reductionPerColumn; // Track unallocated reduction
          return {
            ...cell,
            id: `cell-${rowIndex}-${index}`,
            style: { ...cell.style, width: currentWidth },
          }; // Keep at min width
        }
        return {
          ...cell,
          id: `cell-${rowIndex}-${index >= colIndex ? index + 1 : index}`,
          style: { ...cell.style, width: newCellWidth },
        }; // Adjust width
      });

      // Finalize adjustment for the new column
      newCell.style.width -= unallocatedReduction;

      return [
        ...adjustedRow.slice(0, colIndex),
        newCell,
        ...adjustedRow.slice(colIndex),
      ];
    });

    return updatedTable;
  };

  const handleInsertAtIndex = (index) => {
    if (insertType === "row") {
      const newRow = editableData["content"][0].map((cell, colIndex) => ({
        ...cell,
        id: `cell-${index}-${colIndex}`,
        content: "",
        style: {
          ...cell["style"],
          border: "1px solid black",
          cursor: "pointer",
          padding: "2px",
          backgroundColor: "#ffffff",
        },
      }));

      const updatedTableData = [
        ...editableData["content"].slice(0, index),
        newRow,
        ...editableData["content"].slice(index).map((row, i) =>
          row.map((cell, colIndex) => ({
            ...cell,
            id: `cell-${index + i + 1}-${colIndex}`,
          }))
        ),
      ];
      handleSaveTable(updatedTableData);
    } else if (insertType === "column") {
      let updatedTable = insertColumn(editableData["content"], index);

      handleSaveTable(updatedTable);
    }
    setOpenInsertIndexDialog(false);
  };

  const deleteColumn = (tableData, colIndex) => {
    const currentColumns = tableData[0].length;

    const totalColumns = currentColumns - 1; // Account for the deleted column
    const deletedColumnWidth =
      parseFloat(tableData[0][colIndex]?.style?.width) || 0;
    const increasePerColumn = parseFloat(deletedColumnWidth / totalColumns); // Distribute deleted column's width

    const updatedTable = tableData.map((row, rowIndex) => {
      // Filter out the column to be deleted
      const filteredRow = row.filter((_, index) => index !== colIndex);

      // Adjust remaining columns' widths
      const adjustedRow = filteredRow.map((cell, newColIndex) => {
        const currentWidth = parseFloat(cell.style.width) || 0;
        const newCellWidth = currentWidth + increasePerColumn;

        return {
          ...cell,
          id: `cell-${rowIndex}-${newColIndex}`, // Update IDs to reflect new column index
          style: { ...cell.style, width: newCellWidth },
        };
      });

      return adjustedRow;
    });

    return updatedTable;
  };

  const deleteRow = (tableData, rowIndex) => {
    // Step 1: Remove the row at the specified index
    const updatedTableData = [
      ...tableData.slice(0, rowIndex), // Rows before the deleted row
      ...tableData.slice(rowIndex + 1), // Rows after the deleted row
    ];

    // Step 2: Re-index remaining rows to maintain consistency
    const reindexedTableData = updatedTableData.map((row, newRowIdx) =>
      row.map((cell, colIndex) => ({
        ...cell,
        id: `cell-${newRowIdx}-${colIndex}`, // Update IDs
      }))
    );

    return reindexedTableData;
  };

  const handleDeleteAtIndex = (index) => {
    if (deleteType === "row") {
      let updatedTableData = deleteRow([...editableData["content"]], index);
      handleSaveTable(updatedTableData);
    } else if (deleteType === "column") {
      let updatedTableData = deleteColumn([...editableData["content"]], index);
      handleSaveTable(updatedTableData);
    }
    setOpenDeleteIndexDialog(false);
  };

  return (
    <Wrapper>
      <ButtonWrapper>
        <Tooltip title="Insert Column">
          <IconButton
            style={{ padding: "0px" }}
            color="primary"
            onClick={() => {
              setInsertType("column");
              setOpenInsertIndexDialog(true);
            }}
          >
            <ViewColumn />
          </IconButton>
        </Tooltip>
        <Tooltip title="Insert Row">
          <IconButton
            style={{ padding: "0px" }}
            color="primary"
            onClick={() => {
              setInsertType("row");
              setOpenInsertIndexDialog(true);
            }}
          >
            <TableRows />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Column">
          <IconButton
            disabled={isDeleteColumnisabled()}
            style={{ padding: "0px" }}
            color="error"
            onClick={() => {
              setDeleteType("column");
              setOpenDeleteIndexDialog(true);
            }}
          >
            <ViewColumn />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Row">
          <IconButton
            disabled={isDeleteRowDisabled()}
            style={{ padding: "0px" }}
            color="error"
            onClick={() => {
              setDeleteType("row");
              setOpenDeleteIndexDialog(true);
            }}
          >
            <TableRows />
          </IconButton>
        </Tooltip>
      </ButtonWrapper>
      {editableData["content"].length > 0 && (
        <TableComponent
          tableData={editableData["content"]}
          handleCellClick={(cellData) => {
            let adjacentColumnCellData = getAdjacentColumnCellData(cellData);

            setAdjacentColumnCellData(adjacentColumnCellData);
            setSelectedCellData(cellData);
            setOpenCellEditor(true);
          }}
        />
      )}

      {openCellEditor && (
        <CellEditDialog
          handleCellEdit={(data) => {
            handleCellEdit(data);
          }}
          open={openCellEditor}
          setOpen={setOpenCellEditor}
          editableData={selectedCellData}
          adjacentCellData={adjacentColumnCellData}
        />
      )}
      {openInsertIndexDialog && (
        <InsertRowColumnDialog
          handleInsert={(index) => {
            handleInsertAtIndex(index);
          }}
          open={openInsertIndexDialog}
          setOpen={setOpenInsertIndexDialog}
          insertType={insertType}
          maxIndex={getMaxIndexToInsert()}
        />
      )}

      {openDeleteIndexDialog && (
        <DeleteRowColumnDialog
          handleDelete={(index) => {
            handleDeleteAtIndex(index);
          }}
          open={openDeleteIndexDialog}
          setOpen={setOpenDeleteIndexDialog}
          deleteType={deleteType}
          maxIndex={getMaxIndexToDelete()}
        />
      )}
    </Wrapper>
  );
};

export default TableComponentCard;
