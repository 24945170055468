import { AppBar, Box, Divider, Toolbar, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  getAdminMembersApi,
  getAdminSourcesApi,
  getAdminStagesApi,
  getAdminStatusesApi,
} from "../../../../Api";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
  overflow-y: auto;
  row-gap: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const DefaultParameterScreen = ({
  selectedDefaultParameterMapping,
  setSelectedDefaultParameterMapping,
  selectedProjectID,
  formData,
  loading,
  setLoading,
}) => {
  const [memberList, setMemberList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const handleChange = (event) => {
    setSelectedDefaultParameterMapping({
      ...selectedDefaultParameterMapping,
      [event.target.name]: event.target.value,
    });
  };

  const getMemberList = async () => {
    try {
      let response = await getAdminMembersApi(selectedProjectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const getSourceList = async () => {
    try {
      let response = await getAdminSourcesApi(selectedProjectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getStagesList = async () => {
    try {
      let response = await getAdminStagesApi(selectedProjectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getStatusList = async () => {
    try {
      let response = await getAdminStatusesApi(selectedProjectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const init = async () => {
    setLoading(true);
    let tempObj = {
      ...selectedDefaultParameterMapping,
    };
    let memberList = await getMemberList();
    let sourceList = await getSourceList();
    let stageList = await getStagesList();
    let statusList = await getStatusList();
    if (formData["is_connected"] === false) {
      let defaultSourceIndex = sourceList.findIndex(
        (source) => source["default"] === true
      );
      if (defaultSourceIndex !== -1) {
        tempObj["lead_source_id"] = sourceList[defaultSourceIndex]["id"];
      }
      let defaultStageIndex = stageList.findIndex(
        (stage) => stage["default"] === true
      );
      if (defaultStageIndex !== -1) {
        tempObj["lead_stage_id"] = stageList[defaultStageIndex]["id"];
      }

      let defaultStatusIndex = statusList.findIndex(
        (status) => status["default"] === true
      );
      if (defaultStatusIndex !== -1) {
        tempObj["lead_status_id"] = statusList[defaultStatusIndex]["id"];
      }
    }
    setSelectedDefaultParameterMapping(tempObj);

    setMemberList(memberList);
    setSourceList(sourceList);
    setStageList(stageList);
    setStatusList(statusList);

    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <>
      <Box>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <HeaderWrapper>
              <Header>Select Default Parameters</Header>
              <SubHeader>
                Fill in the Default Parameters to be set while creating the lead
              </SubHeader>
            </HeaderWrapper>
          </Toolbar>
        </AppBar>
        <Divider style={{ margin: "0px 16px" }} />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Owner*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={(event) => {
                      let tempObj = { ...selectedDefaultParameterMapping };
                      tempObj["lead_owner_id"] = event.target.value;
                      let index = memberList.findIndex(
                        (member) => member["user_id"] === event.target.value
                      );
                      if (index !== -1) {
                        tempObj["team_id"] = memberList[index]["team"]["id"];
                      } else {
                        tempObj["team_id"] = "";
                      }
                      setSelectedDefaultParameterMapping(tempObj);
                    }}
                    name="lead_owner_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_owner_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {memberList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.user_id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["name"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Source*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_source_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_source_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {sourceList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["source"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Stage*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_stage_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_stage_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {stageList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["stage"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWrapper>
                <Label>Select Lead Status*</Label>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    required
                    onChange={handleChange}
                    name="lead_status_id"
                    disableUnderline
                    variant="outlined"
                    value={selectedDefaultParameterMapping["lead_status_id"]}
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      borderRadius: "0px",
                    }}
                  >
                    {statusList.map((item, idx) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={idx}
                          style={{ fontSize: "12px" }}
                        >
                          {item["status"]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputWrapper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        width: "600px",
        height: "284px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default DefaultParameterScreen;
