import { Folder } from "@mui/icons-material";
import { Card, CardActionArea, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import React from "react";
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  text-align: center;
`;
const FolderCard = ({ folderData, handleFolderClick }) => {
  return (
    <Card
      sx={{
        border: "1px solid #f9f9fc",
        bgcolor: "#f9f9fc",
      }}
      onClick={handleFolderClick}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          rowGap: "4px",
        }}
      >
        <Folder style={{ width: "40px", height: "40px" }} color="primary"/>
        <Label>{folderData.name}</Label>
      </CardActionArea>
    </Card>
  );
};

export default FolderCard;
