import React from "react";
import { StyleSheet, View, Font, Image } from "@react-pdf/renderer";
import { convertPxToPts } from "../../../Functions";
import Html from "react-pdf-html";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 400,
      fontStyle: "normal", // Regular
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: 500,
      fontStyle: "normal", // Medium
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: 700,
      fontStyle: "normal", // Bold
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
      fontWeight: 300,
      fontStyle: "italic", // Italic
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
  },
  column: {
    flexDirection: "column",
    display: "flex",
    width: "70%",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    gap: "8px",
  },
  headerCell: {
    textAlign: "left",
    flex: 1,
    padding: "4px 8px",
  },
  textLg: {
    fontSize: 10,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  textSm: {
    fontSize: 8,
    fontWeight: "400",
    color: "black",
    fontFamily: "Poppins",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
  imageStyle: {
    width: "60px",
    height: "auto",
    objectFit: "contain",
  },
  icon: {
    width: 10, // Adjust the size of the icon
    height: 10,
    marginRight: 5, // Space between icon and text
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
  },
});
const htmlStyleSheet = {
  // clear margins for all <p> tags
  strong: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  em: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 300,
  },
  p: {
    fontFamily: "Poppins",
    margin: 0,
    fontSize: 9,
  },
  ul: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
  ol: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
};
const HeaderLayoutWithoutCompanyDetails = ({
  companyLogo,
  headerComponents,
}) => {
  return (
    <View style={styles.container}>
      {companyLogo !== "" && (
        <Image src={companyLogo} alt="company logo" style={styles.imageStyle} />
      )}
      <View style={styles.column}>
        {headerComponents.map((component, idx) => {
          if (component["type"] === "text") {
            return (
              <View key={idx} style={{ width: "100%" }}>
                <Html stylesheet={htmlStyleSheet}>
                  {convertPxToPts(component["content"])}
                </Html>
              </View>
            );
          }
        })}
      </View>
    </View>
  );
};

export default HeaderLayoutWithoutCompanyDetails;
