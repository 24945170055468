import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Edit, Visibility, Delete } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Typography, Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  consoleLogger,
  dataRenderer,
  getLocalizedText,
} from "../../../Functions";
import ItemActionComponent from "./ItemActionComponent";

const TableHeaderCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
`;
const TableDataCell = styled(TableCell)`
  font-size: 12px;
  padding: 8px 12px;
  white-space: nowrap;
  position: relative;
`;

const TableItemTitle = styled(TableCell)`
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  line-break: 1;
`;
const DescText = styled(Typography)`
  font-size: 12px;
`;
const ColumnText = styled(Typography)`
  font-size: 12px;
  color: #3687d7;
`;

export default function TableComponent({
  tableData = [],
  isFormDisabled,
  handleView,
  handleEdit,
  handleDelete,
  handleClone,
  tableAdditionalColumns,
}) {
  const shouldShowTaxColumn = () => {
    //don't show if all tax values are less negative
    return !tableData.every(
      (x) =>
        x.tax !== "" &&
        x.tax !== null &&
        x.tax !== undefined &&
        parseFloat(x.tax) < 0
    );
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        height: "calc(100vh - 494px)",
        overflowY: "auto",
        zIndex: "0",
        width: "100%",
      }}
      sx={{
        "&::-webkit-scrollbar": {
          width: "6px", // Customize scrollbar width
          height: "6px", // Customize scrollbar height for horizontal scroll
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888", // Color of the scrollbar thumb
          borderRadius: "10px", // Roundness of the scrollbar thumb
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555", // Color of the scrollbar thumb on hover
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1", // Color of the scrollbar track
        },
      }}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Item</TableHeaderCell>
            <TableHeaderCell align="right">Rate</TableHeaderCell>
            <TableHeaderCell align="right">Qty</TableHeaderCell>
            {shouldShowTaxColumn() && (
              <TableHeaderCell align="right">Tax (%)</TableHeaderCell>
            )}
            <TableHeaderCell align="right">Discount (%)</TableHeaderCell>
            <TableHeaderCell align="right">Amount</TableHeaderCell>
            <TableHeaderCell align="right">{""}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableDataCell component="th" scope="row">
                  <TableItemTitle sx={{ border: 0, borderBottom: 0 }}>
                    {" "}
                    {row["item"]["name"]}
                  </TableItemTitle>
                  <DescText>{row["desc"]}</DescText>
                  {row.hasOwnProperty("product_data") &&
                    tableAdditionalColumns.length > 0 && (
                      <>
                        {tableAdditionalColumns.map((column) => (
                          <Box key={column.column_name}>
                            <ColumnText>
                              {dataRenderer(column.display_name)}
                              {": "}
                              {dataRenderer(
                                row["product_data"][column.column_name]
                              )}{" "}
                            </ColumnText>
                          </Box>
                        ))}
                      </>
                    )}
                </TableDataCell>
                <TableDataCell align="right">{row["rate"]}</TableDataCell>
                <TableDataCell align="right">{row["quantity"]}</TableDataCell>
                {shouldShowTaxColumn() && (
                  <TableDataCell align="right">
                    {parseFloat(row["tax"]) >= 0
                      ? dataRenderer(row["tax"])
                      : "-"}
                  </TableDataCell>
                )}
                <TableDataCell align="right">{row["discount"]}</TableDataCell>
                <TableDataCell align="right">{row["amount"]}</TableDataCell>
                <TableDataCell align="right">
                  <ItemActionComponent
                    handleClone={() => handleClone(row)}
                    handleDelete={() => handleDelete(row)}
                    handleEdit={() => handleEdit(row)}
                    handleView={() => handleView(row)}
                    isFormDisabled={isFormDisabled}
                  />
                </TableDataCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableDataCell
                style={{ textAlign: "center", height: "calc(100vh - 552px)" }}
                colSpan={7}
              >
                no items.
              </TableDataCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
