//here we will store all the required data rendering on our page and will use whenever wherever it is required
import "./styles/multiselect.css";
import { Tooltip } from "@material-ui/core";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import template_img1 from "./assets/lp_template1.png";
import template_img2 from "./assets/lp_template2.png";
import template_img3 from "./assets/lp_template3.png";
import template_img4 from "./assets/lp_template4.png";
import template_img5 from "./assets/lp_template5.png";
import template_img6 from "./assets/lp_template6.png";

import {
  CameraAlt,
  FiberManualRecord,
  MoreHoriz,
  PictureAsPdf,
  YouTube,
} from "@material-ui/icons";
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  capitalizeWord,
  dataRenderer,
  dateHandler,
  dateTimeHandler,
  formatMinutes,
  getImportTypeDisplayName,
  getLocalizedText,
  getLocalTimeFromIsoString,
  getLocalTimeStringFromIsoDateString,
  isCustomQuotationTemplate,
  IsoToLocalDate,
  IsoToLocalTime,
  tabsDateHandler,
} from "./Functions";
import { Box, Chip, Typography } from "@mui/material";
import { Rectangle, Circle } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #363636;
`;
const TextHighlight = styled.span`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #3f51b5;
  cursor: pointer;
`;
const ColDirection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
`;
const Detail = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #b7b7b7;
`;

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}
const ColumnHeader = (value) => {
  return (
    <Container>
      <Text>{value}</Text>
    </Container>
  );
};
const RenderList = (value) => {
  if (value.length > 0) {
    return (
      <ColDirection>
        {value.map((e) => {
          <Text>{e}</Text>;
        })}
      </ColDirection>
    );
  }
};
const content = [
  {
    title: "Interactive Learning",
    body: "Learn and engage with content immersively through guided social interaction.",
  },
  {
    title: "Self Learning",
    body: "Learn daily via content designed and personalized for every student’s learning journey using AI",
  },
  {
    title: "Exploratory Learning",
    body: "Learn incrementally in a real-world scenario with open-ended learning objectives like lab simulations",
  },
  {
    title: "Social collaborative learning",
    body: "Learn with your friends together, solve problems, and complement team strengths",
  },
];

const Auth = [
  {
    login: {
      header: "User Login!",
      subheader: "please enter the below details to login",
      label: "Phone Number",
      code: "+91",
      disclaimer: " By signing up for Leadpixie, you agree to our",
      t1: "Terms Of Use",
      t2: "Privacy Policy",
    },
    update: {
      header: "Update Number",
      subheader: "Please enter the mobile number to update.",
      label: "Phone Number",
      code: "+91",
    },
    otp: {
      phone_header: "Please verify your phone number",
      email_header: "Please verify your email address",
      subheader: getLocalizedText("enter_4_digit_code_sent_to"),
      disclaimer: getLocalizedText("resend_otp_in"),
    },
  },
];

const ruleList = [
  {
    name: "Assignment as in import file",
    data: {},
    desc: "Lead owners will be assigned based on the the lead_owner column in the import file.",
    slug: "as_import_file",
  },
  {
    name: "Selected Project Members - Equally",
    data: {},
    desc: "",
    slug: "project_member_equally",
  },
];
const createImportRuleList = [
  {
    name: "Create Leads: Only Unique Phone Numbers",
    slug: "option_contact_exists_no_lead",
    desc: [
      "Create a new contact if no contact exists with the given phone number.",
      "Update contact details if the contact already exists with the given phone number.",
      "Create a lead if no lead exists for the contact.",
      "Throw an error if a lead already exists for the contact.",
    ],
  },
  {
    name: "Create Leads: Allow Duplicate Phone Numbers",
    slug: "option_allow_duplicates",
    desc: [
      "Create a new contact if no contact exists with the given phone number.",
      "Update contact details if the contact already exists with the given phone number.",
      "Create a lead if no lead exists for the contact.",
      "If a contact exists and lead(s) exists, add a new lead.",
    ],
  },
  {
    name: "Create Leads: Duplicate Phones with Unique Column",
    slug: "option_allow_duplicates_unique_column",
    desc: [
      "Create a new contact if no contact exists with the given phone number.",
      "Update contact details if the contact already exists with the given phone number.",
      "Create a lead if no lead exists for the contact.",
      "If a contact and lead(s) already exist, add a new lead only if the selected unique column value is not a duplicate.",
    ],
  },
];
const updateImportRuleList = [
  {
    name: "Update By Phone Number",
    slug: "option_update_phone",
    desc: "Update all leads that match the phone number",
  },
  {
    name: "Update By Friendly ID",
    slug: "option_update_flid",
    desc: "Update a lead that matches a specific friendly ID",
  },
  // {
  //   name: "option_update_id",
  //   slug: "option_update_id",
  //   desc: "if contact exists and more than one lead exists, update lead with a specific column value",
  // },
];

const callsColumns = [
  {
    field: "lead_status",
    headerName: "STATUS",
    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.lead_status}>
            <span
              className="csutable-cell-trucate"
              style={{
                color:
                  params.data.lead_status === "Not Interested"
                    ? "red"
                    : "green",
              }}
            >
              {params.data.lead_status}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "notes",

    headerName: "CALL NOTES",
    minWidth: 300,
    maxWidth: 500,
    flex: 1,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.notes} placement="bottom-start">
            <span className="csutable-cell-trucate">
              {params.data.notes !== "" && params.data.notes !== null
                ? params.data.notes
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "next_follow_up_on",
    headerName: "FOLLOW-UP ON",
    width: 200,

    cellRenderer: (params) => {
      let date;
      if (params.data.next_followup_on !== null) {
        date = IsoToLocalTime(params.data.next_followup_on);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.data.next_followup_on !== null ? date : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",

    headerName: "CALLER",
    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.caller.name}>
            <span className="csutable-cell-trucate">
              {params.data.caller.name}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "call_duration",

    headerName: "CALL DURATION (S)",
    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.call_duration !== null && params.data.call_duration !== ""
          ? `${params.data.call_duration}`
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED AT",
    width: 150,

    cellRenderer: (params) => {
      let date;
      if (params.data.created_at !== null) {
        date = IsoToLocalTime(params.data.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.data.created_at !== null ? date : params.data.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//****************************CheckIns Column******************************** */

const CheckInColumns = [
  {
    field: "place",
    sortable: false,

    headerName: ColumnHeader("Place"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.place}>
            <span className="csutable-cell-trucate">{params.row.place}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_in",
    sortable: false,

    headerName: ColumnHeader("Checked In"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_in}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_in)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_out",
    sortable: false,

    headerName: ColumnHeader("Checked Out"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_out}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_out)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const whatsappPageColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 100,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "template_title",
    headerName: "TEMPLATE TITLE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.template_title}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.template_title)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "message",
    headerName: "MESSAGE",
    autoHeight: true,
    width: 750,
    cellRenderer: (params) => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "auto",
              padding: "2px",
            }}
          >
            <Tooltip title={params.data.message}>
              <span
                className="csutable-cell-trucate"
                style={{ whiteSpace: "normal" }}
              >
                <Typography
                  fontSize={14}
                  style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                >
                  {dataRenderer(params.data.message)}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
];

const adminWhatsappPageColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 100,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "template_title",
    headerName: "TEMPLATE TITLE",

    width: 225,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.template_title}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.template_title) +
                " " +
                "(" +
                dataRenderer(params.data.language) +
                ")"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "message",
    headerName: "MESSAGE",
    autoHeight: (params) => {
      const message =
        params.data.components.find((x) => x.type.toLowerCase() === "body")[
          "text"
        ] || "";
      return message.length > 150;
    },
    width: 750,
    cellRenderer: (params) => {
      let message = "";
      if (params.data.components.length > 0) {
        message = params.data.components.find(
          (x) => x.type.toLowerCase() === "body"
        )["text"];
      }

      const truncatedMessage =
        message && message.length > 150
          ? message.slice(0, 150) + "..."
          : message;

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "2px",
            justifyContent: "center",
          }}
        >
          <Tooltip title={truncatedMessage} disableInteractive>
            <span
              className="csutable-cell-trucate"
              style={{
                whiteSpace: "normal",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                fontSize={14}
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {dataRenderer(truncatedMessage)}
              </Typography>
            </span>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",

    width: 160,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.status}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.status)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width: 160,
    cellRenderer: (params) => {
      if (
        params.data.category !== undefined &&
        params.data.category !== null &&
        params.data.category !== ""
      ) {
        return (
          <Tooltip title={params.data.category}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.category)}
            </span>
          </Tooltip>
        );
      } else {
        return "-";
      }
    },
  },
];

const visitsTabColumnHeaders = [
  {
    field: "place",

    headerName: "PLACE",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.place}>
            <span className="csutable-cell-trucate">{params.row.place}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_in",

    headerName: "CHECKED IN",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_in}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_in)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "checked_out",

    headerName: "CHECKED OUT",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.checked_out}>
            <span className="csutable-cell-trucate">
              {IsoToLocalTime(params.row.checked_out)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const leadActivityColumns = [
  {
    field: "activity_performed_at",
    headerName: "TIME OF ACTIVITY",
    minWidth: 200,
    cellRenderer: (params) => {
      let date =
        params.data.activity_performed_at !== null
          ? IsoToLocalTime(params.data.activity_performed_at)
          : "-";

      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={date}>
              <span className="csutable-cell-trucate">{date}</span>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
  {
    field: "activity_desc",
    headerName: "DESCRIPTION",
    minWidth: 250,
    autoHeight: true,
    flex: 1,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.activity_desc);
      return (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
            {(params.data.activity_type === "Assign" ||
              params.data.activity_type === "Transfer") &&
              params.data["additional_comments"] !== null && ( //only show additional comments for assign type
                <Box
                  className="csutable-cell-trucate"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {params.data["additional_comments"].map((item) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Circle
                          style={{
                            width: "8px",
                            height: "8px",
                            color: "#b7b7b7",
                          }}
                        />
                        <Detail>{item}</Detail>
                      </Box>
                    );
                  })}
                </Box>
              )}
          </Box>
        </>
      );
    },
  },
  {
    field: "activity_type",
    headerName: "ACTIVITY TYPE",
    minWidth: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.activity_type);

      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </Box>
        </>
      );
    },
  },

  {
    field: "user",
    headerName: "PERFORMED BY",
    minWidth: 200,
    cellRenderer: (params) => {
      let value =
        params.data.user !== null
          ? dataRenderer(params.data.user["name"])
          : "-";
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
];

const businessContactColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Name"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let full_name = `${params.row.salutation} ${params.row.first_name} ${params.row.last_name}`;
      return (
        <>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Tooltip title={full_name}>
              <span
                style={{
                  color: params.row.decision_maker ? "#ffffff" : "#353535",
                }}
                className="csutable-cell-trucate"
              >
                {full_name}
              </span>
            </Tooltip>
          </Box>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span
              style={{
                color: params.row.decision_maker ? "#f5f5f5" : "#353535",
              }}
              className="csutable-cell-trucate"
            >
              {params.row.title}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const notesContactColumns = [
  {
    field: "note",
    sortable: false,

    headerName: ColumnHeader("Note"),
    width: 400,
    renderCell: (params) => {
      return (
        <>
          <Tooltip
            title={<Typography fontSize={14}> {params.row.note}</Typography>}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {truncate(params.row.note, 70)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    sortable: false,
    headerName: ColumnHeader("Created By"),
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by.name}>
            <span className="csutable-cell-trucate">
              {params.row.created_by.name}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created At"),
    width: 220,
    sortable: false,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = IsoToLocalTime(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//*************user file tab column headers*********************************** */

const leadFileColumns = [
  {
    field: "file_type",
    headerName: ColumnHeader("File Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value = params.row.hasOwnProperty("file_type")
        ? dataRenderer(params.row.file_type)
        : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "uploaded_by",
    headerName: ColumnHeader("Uploaded By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value = params.row.hasOwnProperty("uploaded_by")
        ? dataRenderer(params.row.uploaded_by.name)
        : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Uploaded On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let value =
        params.row.hasOwnProperty("created_at") &&
        params.row.created_at !== null
          ? IsoToLocalTime(params.row.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      if (
        params.row.hasOwnProperty("status") &&
        params.row["status"] !== null
      ) {
        return <Chip label={params.row["status"]} color="primary" />;
      } else {
        return "-";
      }
    },
  },
];

//********import history column headers******************************* */

const ImportHistoryColumns = [
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_processed",
    headerName: ColumnHeader("Rows Processed"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_processed}>
            <span className="csutable-cell-trucate">
              {params.row.rows_processed}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_created",
    headerName: ColumnHeader("Rows Created"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_created}>
            <span className="csutable-cell-trucate">
              {params.row.rows_created}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_updated",
    headerName: ColumnHeader("Rows Updated"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_updated}>
            <span className="csutable-cell-trucate">
              {params.row.rows_updated}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_errored",
    headerName: ColumnHeader("Rows Errored"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_errored}>
            <span className="csutable-cell-trucate">
              {params.row.rows_errored}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "error_log",
    headerName: ColumnHeader("Error Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.error_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.error_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "full_log",
    headerName: ColumnHeader("Full Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.full_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.full_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "original_file",
    headerName: ColumnHeader("Original File"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.original_file}>
            <a
              className="csutable-cell-trucate"
              href={params.row.original_file}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
];

//********import history listing column headers******************************* */

const ImportHistoryListingColumns = [
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,
  },
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,
  },
  {
    field: "actions",
    headerName: ColumnHeader("Actions"),
  },
];

//********Raw Leads import history table column headers******************************* */

const RawLeadsImportHistoryTableColumns = [
  {
    field: "import_type",
    headerName: ColumnHeader("Job Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Creator"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//********Raw Leads import history column headers******************************* */

const RawLeadsImportHistoryColumns = [
  {
    field: "import_type",
    headerName: ColumnHeader("Import Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.import_type}>
            <span className="csutable-cell-trucate">
              {params.row.import_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: ColumnHeader("Imported By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.imported_by}>
            <span className="csutable-cell-trucate">
              {params.row.imported_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_time !== null) {
        date = dateTimeHandler(params.row.start_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_time !== null ? date : params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_time !== null) {
        date = dateTimeHandler(params.row.end_time);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_time !== null ? date : params.row.end_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_processed",
    headerName: ColumnHeader("Rows Processed"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_processed}>
            <span className="csutable-cell-trucate">
              {params.row.rows_processed}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_created",
    headerName: ColumnHeader("Rows Created"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_created}>
            <span className="csutable-cell-trucate">
              {params.row.rows_created}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_updated",
    headerName: ColumnHeader("Rows Updated"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_updated}>
            <span className="csutable-cell-trucate">
              {params.row.rows_updated}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rows_errored",
    headerName: ColumnHeader("Rows Errored"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.rows_errored}>
            <span className="csutable-cell-trucate">
              {params.row.rows_errored}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "error_log",
    headerName: ColumnHeader("Error Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.error_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.error_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "full_log",
    headerName: ColumnHeader("Full Log"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.full_log}>
            <a
              className="csutable-cell-trucate"
              href={params.row.full_log}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "original_file",
    headerName: ColumnHeader("Original File"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.original_file}>
            <a
              className="csutable-cell-trucate"
              href={params.row.original_file}
              target="_blank"
              rel="noreferrer"
            >
              <MoreHoriz />
            </a>
          </Tooltip>
        </>
      );
    },
  },
];

//**********************************target page *******************************************/

const TargetPageColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Name"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "measure",
    headerName: ColumnHeader("Measure"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.measure}>
            <span className="csutable-cell-trucate">{params.row.measure}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "start_date",
    headerName: ColumnHeader("Start Date"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.start_date !== null) {
        date = dateTimeHandler(params.row.start_date);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date !== null ? date : params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_date",
    headerName: ColumnHeader("End Date"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.end_date !== null) {
        date = dateTimeHandler(params.row.end_date);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.end_date !== null ? date : params.row.end_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "actual",
    headerName: ColumnHeader("Actual"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.actual}>
            <span className="csutable-cell-trucate">{params.row.actual}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "target",
    headerName: ColumnHeader("Target"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.target}>
            <span className="csutable-cell-trucate">{params.row.target}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "percentage_achieved",
    headerName: ColumnHeader("% Target Achieved"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.percentage_achieved}>
            <span className="csutable-cell-trucate">
              {params.row.percentage_achieved}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "creator",
    headerName: ColumnHeader("Creator"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.creator}>
            <span className="csutable-cell-trucate">{params.row.creator}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = dateTimeHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//**********************************target Details page *******************************************/

const TargetDetailsColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Member Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "team",
    headerName: ColumnHeader("Team"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.team}>
            <span className="csutable-cell-trucate">{params.row.team}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "target",
    headerName: ColumnHeader("Target"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.target}>
            <span className="csutable-cell-trucate">{params.row.target}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "actual",
    headerName: ColumnHeader("Actual"),
    sortable: false,

    width: 170,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.actual}>
            <span className="csutable-cell-trucate">{params.row.actual}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "percentage",
    headerName: ColumnHeader("% Target Achieved"),
    sortable: false,

    width: 400,
    renderCell: (params) => {
      return (
        <>
          <ProgressBar
            // completedClassName="barCompleted-progress-bar"
            labelClassName="label-progress-bar"
            width="400px"
            height="20px"
            bgColor="#59E3A3"
            borderRadius="0px"
            animateOnRender={true}
            completed={params.row.percentage}
          />
        </>
      );
    },
  },
];

//***************member setting columns*********************************************** */

const MemberSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Member Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: ColumnHeader("Email"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.email}>
            <span className="csutable-cell-trucate">{params.row.email}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "phone",
    headerName: ColumnHeader("Phone"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.phone}>
            <span className="csutable-cell-trucate">{params.row.phone}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "role",
    headerName: ColumnHeader("Role"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.role}>
            <span className="csutable-cell-trucate">{params.row.role}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "team_name",
    headerName: ColumnHeader("Team"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.team["name"]}>
            <span className="csutable-cell-trucate">
              {params.row.team["name"]}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "calling_mode",
    headerName: ColumnHeader("Calling Mode"),
    sortable: false,

    width: 140,
    renderCell: (params) => {
      let callingMode = "-";
      if (params.row.hasOwnProperty("calling_mode")) {
        if (
          params.row.calling_mode !== null ||
          params.row.calling_mode !== ""
        ) {
          callingMode = params.row.calling_mode;
        }
      }
      return (
        <>
          <Tooltip title={callingMode}>
            <span className="csutable-cell-trucate">{callingMode}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "unmask_columns",
    headerName: ColumnHeader("Unmask Columns"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.unmask_columns}>
            <span className="csutable-cell-trucate">
              {params.row.unmask_columns ? "True" : "False"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************new user setting columns******************** */

const NewUSerSettingColumns = [
  // {
  //   field: "sl no",
  //   headerName: "Sl No.",

  //   width: 230,
  //   cellRenderer: (params) => {
  //     return params.node.rowIndex + 1;
  //   },
  // },
  {
    field: "flid",
    headerName: "ID",
    width: 75,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.flid}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.flid)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",

    width: 230,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">{params.data.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.phone}>
            <span className="csutable-cell-trucate">{params.data.phone}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",

    width: 230,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.email}>
            <span className="csutable-cell-trucate">{params.data.email}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",

    width: 100,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.role}>
            <span className="csutable-cell-trucate">{params.data.role}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "last_action_at",
    headerName: "Last Action At",

    width: 200,
    cellRenderer: (params) => {
      let date = "-";
      if (params.data.last_action_at !== null) {
        date = IsoToLocalTime(params.data.last_action_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Created On",

    width: 125,
    cellRenderer: (params) => {
      let date;
      if (params.data.created_at !== null) {
        date = IsoToLocalDate(params.data.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.data.created_at !== null ? date : params.data.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const UserLoginHistoryColumns = [
  {
    field: "sl no",
    headerName: "Sl No.",
    width: 100,
    cellRenderer: (params) => {
      return params.node.rowIndex + 1;
    },
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 200,
    flex: 1,
    cellRenderer: (params) => {
      let date = "-";
      if (params.data.created_at !== null) {
        date = IsoToLocalTime(params.data.created_at);
      }
      return date;
    },
  },

  {
    field: "action",
    headerName: "Action",
    minWidth: 200,
    flex: 1,
    cellRenderer: (params) => {
      return dataRenderer(params.data.action);
    },
  },
  {
    field: "device_os",
    headerName: "Platform",
    minWidth: 200,
    flex: 1,
    cellRenderer: (params) => {
      if (params.data.device_os !== null) {
        return capitalizeWord(params.data.device_os);
      } else {
        return "-";
      }
    },
  },
  {
    field: "app_version",
    headerName: "Version",
    minWidth: 200,
    flex: 1,
    cellRenderer: (params) => {
      return dataRenderer(params.data.app_version);
    },
  },
];

//***************project setting columns******************* */

const ProjectSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Project Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = IsoToLocalTime(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//***************team setting columns******************* */

const TeamSettingColumns = [
  {
    field: "name",
    headerName: ColumnHeader("Team Name"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.name}>
            <span className="csutable-cell-trucate">{params.row.name}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//**************************admin statuses column headers *********************************/
const AdminStatusColumns = [
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "sub_status",
    headerName: ColumnHeader("Sub-Status"),
    sortable: false,

    width: 300,
    renderCell: (params) => {
      return params.row.sub_status.length > 0 ? (
        <ColDirection>
          {params.row.sub_status.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "flex-start",
                  marginBottom: "4px",
                }}
              >
                <FiberManualRecord
                  style={{
                    width: "12px",
                    height: "12px",
                    marginTop: "3px",
                    color: "gray",
                  }}
                />
                <span>{e}</span>
              </div>
            );
          })}
        </ColDirection>
      ) : (
        ""
      );
    },
  },
  {
    field: "group",
    headerName: ColumnHeader("Group"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      let value = dataRenderer(params.row.group);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "is_visible_to_add",
    headerName: ColumnHeader("Visible"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      if (params.row.is_visible_to_add === true) {
        return (
          <Chip
            style={{
              fontWeight: "bold",
              marginTop: "4px",
              marginBottom: "4px",
            }}
            color="success"
            label={"Yes"}
          />
        );
      } else {
        return (
          <Chip
            style={{
              fontWeight: "bold",
              marginTop: "4px",
              marginBottom: "4px",
            }}
            color="warning"
            label={"No"}
          />
        );
      }
    },
  },
  {
    field: "notes_mandatory",
    headerName: ColumnHeader("Call Notes"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      if (params.row.notes_mandatory === true) {
        return (
          <Chip
            style={{
              fontWeight: "bold",
              marginTop: "4px",
              marginBottom: "4px",
            }}
            color="success"
            label={"Yes"}
          />
        );
      } else {
        return (
          <Chip
            style={{
              fontWeight: "bold",
              marginTop: "4px",
              marginBottom: "4px",
            }}
            color="warning"
            label={"No"}
          />
        );
      }
    },
  },
  {
    field: "delete_protected",
    headerName: ColumnHeader("Delete Protection"),
    sortable: false,

    width: 125,
    renderCell: (params) => {
      if (params.row.delete_protected === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
  {
    field: "color",
    headerName: ColumnHeader("Color"),
    sortable: true,

    width: 130,
    renderCell: (params) => {
      if (params.row.color !== null) {
        return (
          <Rectangle
            style={{
              color: params.row.color,
              width: "30px",
              height: "30px",
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
];

//***************admin status_stage column headers********************** */

const AdminStatusToStageColumns = [
  {
    field: "lead_stage",
    headerName: "STAGE",
    sortable: false,

    width: 230,
    cellRenderer: (params) => {
      let value =
        params.data.lead_stage !== null
          ? dataRenderer(params.data.lead_stage.stage)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "ON STATUS",
    sortable: false,

    width: 230,
    cellRenderer: (params) => {
      let value =
        params.data.lead_status !== null
          ? dataRenderer(params.data.lead_status.status)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************admin source column headers********************** */
const AdminSourceColumns = [
  {
    field: "source",
    headerName: ColumnHeader("Source"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.source}>
            <span className="csutable-cell-trucate">{params.row.source}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************admin stage column headers********************** */
const AdminStageColumns = [
  {
    field: "stage",
    headerName: "STAGE",
    sortable: false,
    rowDrag: true,
    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.stage}>
            <span className="csutable-cell-trucate">{params.data.stage}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "rank",
    headerName: "RANK",
    sortable: true,

    width: 100,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.rank}>
            <span className="csutable-cell-trucate">{params.data.rank}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "delete_protected",
    headerName: "DELETE PROTECTED",
    sortable: false,
    width: 175,
    cellRenderer: (params) => {
      if (params.data.delete_protected === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
  {
    field: "color",
    headerName: "COLOR",
    sortable: true,

    width: 100,
    cellRenderer: (params) => {
      if (params.data.color !== null) {
        return (
          <Rectangle
            style={{
              color: params.data.color,
              width: "30px",
              height: "30px",
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
];

const AdminStageHiddenColumns = [
  {
    field: "stage",
    headerName: "STAGE",
    sortable: false,
    rowDrag: false,
    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.stage}>
            <span className="csutable-cell-trucate">{params.data.stage}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "delete_protected",
    headerName: "DELETE PROTECTED",
    sortable: false,
    width: 175,
    cellRenderer: (params) => {
      if (params.data.delete_protected === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
  {
    field: "color",
    headerName: "COLOR",
    sortable: true,

    width: 100,
    cellRenderer: (params) => {
      if (params.data.color !== null) {
        return (
          <Rectangle
            style={{
              color: params.data.color,
              width: "30px",
              height: "30px",
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
];

//*****************************admin ColumnTab herader************************* */

const AdminViewColumns = [
  {
    field: "name",
    headerName: "NAME",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">{params.data.name}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "group",
    headerName: "GROUP",
    sortable: false,
    width: 400,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.group}>
            <span className="csutable-cell-trucate">
              {params.data.group !== null && params.data.group !== ""
                ? params.data.group
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "data_type",
    headerName: "DATATYPE",
    sortable: false,
    width: 125,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.data_type}>
            <span className="csutable-cell-trucate">
              {params.data.data_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "filterable",
    headerName: "FILTERABLE",
    sortable: false,
    width: 125,
    cellRenderer: (params) => {
      if (params.data.filterable === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
  {
    field: "required",
    headerName: "REQUIRED",
    sortable: false,
    width: 125,
    cellRenderer: (params) => {
      if (params.data.required === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
];

const AdminColumnsGroup = [
  {
    field: "sequence",
    headerName: "Sequence",
    width: 130,
    rowDrag: (params) => {
      let disabledNames = [
        "Primary Information",
        "Secondary Information",
        "Followup Details",
      ];
      if (disabledNames.includes(params.data.group_name)) {
        return false;
      }
      return true;
    },
    cellRenderer: (params) => {
      return dataRenderer(params.data.group_order);
    },
  },
  {
    field: "group_name",
    headerName: "GROUP NAME",
    cellRenderer: (params) => {
      return dataRenderer(params.data.group_name);
    },
  },
];
//************************************Reports Listing Column Headers ******************************************* */

const ReportListColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_date",
    headerName: ColumnHeader("Start Date"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_date",
    headerName: ColumnHeader("End Date"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_date}>
            <span className="csutable-cell-trucate">
              {params.row.start_date}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Segments Listing Column Headers ******************************************* */

const SegmentListColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.project}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "creator",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.creator.name}>
            <span className="csutable-cell-trucate">{params.creator.name}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_on}>
            <span className="csutable-cell-trucate">
              {params.row.created_on}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Campaign Listing Column Headers ******************************************* */

const CampaignListColumnsHeaders = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "segment",
    headerName: ColumnHeader("Segment"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.segment}>
            <span className="csutable-cell-trucate">{params.row.segment}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "campaign_type",
    headerName: ColumnHeader("Campaign Type"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.campaign_type}>
            <span className="csutable-cell-trucate">
              {params.row.campaign_type}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
  },

  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,
  },
];

//************************************Jobs Listing Column Headers ******************************************* */

const JobListColumns = [
  {
    field: "action",
    headerName: ColumnHeader("Action"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip action={params.row.action}>
            <span className="csutable-cell-trucate">{params.row.action}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip status={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "project",
    headerName: ColumnHeader("Project"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip project={params.row.project}>
            <span className="csutable-cell-trucate">{params.row.project}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: ColumnHeader("Description"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.desc}>
            <span className="csutable-cell-trucate">{params.row.desc}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "job_type",
    headerName: ColumnHeader("Job Type"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.job_type}>
            <span className="csutable-cell-trucate">{params.row.job_type}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_time}>
            <span className="csutable-cell-trucate">
              {params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_time}>
            <span className="csutable-cell-trucate">{params.row.end_time}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************************Raw Lead Export Job Listing Column Headers ******************************************* */

const RawLeadExportJobColumns = [
  {
    field: "action",
    headerName: ColumnHeader("Actions"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip action={params.row.action}>
            <span className="csutable-cell-trucate">{params.row.action}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip status={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      let date;
      if (params.row.created_at !== null) {
        date = tabsDateHandler(params.row.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.row.created_at !== null ? date : params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: ColumnHeader("Start Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.start_time}>
            <span className="csutable-cell-trucate">
              {params.row.start_time}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: ColumnHeader("End Time"),
    sortable: false,

    width: 230,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.end_time}>
            <span className="csutable-cell-trucate">{params.row.end_time}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************support section column headers********************** */
const SupportSectionColumns = [
  {
    field: "title",
    headerName: ColumnHeader("Title"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.title}>
            <span className="csutable-cell-trucate">{params.row.title}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">{params.row.status}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "priority",
    headerName: ColumnHeader("Priority"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.priority}>
            <span className="csutable-cell-trucate">{params.row.priority}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,

    width: 130,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_by}>
            <span className="csutable-cell-trucate">
              {params.row.created_by}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created On"),
    sortable: false,

    width: 150,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.created_at}>
            <span className="csutable-cell-trucate">
              {params.row.created_at}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//*******************billing section column headers********************** */

const billingSectionColumns = [
  {
    field: "invoice_number",
    headerName: "INVOICE NUMBER",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.invoice_number}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.invoice_number)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice_amount",
    headerName: "INVOICE AMOUNT",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.invoice_amount}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.invoice_amount)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "balance_due",
    headerName: "BALANCE DUE",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.balance_due}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.balance_due)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "currency",
    headerName: "CURRENCY",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.currency}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.currency)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "issue_date",
    headerName: "ISSUE DATE",

    width: 150,
    cellRenderer: (params) => {
      let date =
        params.data.issue_date !== null && params.data.issue_date !== ""
          ? IsoToLocalDate(params.data.issue_date)
          : "-";
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "due_date",
    headerName: "DUE DATE",

    width: 150,
    cellRenderer: (params) => {
      let date =
        params.data.due_date !== null && params.data.due_date !== ""
          ? IsoToLocalDate(params.data.due_date)
          : "-";
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "billing_period",
    headerName: "BILLING PERIOD",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.billing_period}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.billing_period)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",

    width: 150,
    cellRenderer: (params) => {
      if (params.data.status.toLowerCase() === "void") {
        return <Chip color="default" label={params.data.status} />;
      } else if (params.data.status.toLowerCase() === "due") {
        return <Chip color="warning" label={params.data.status} />;
      } else if (params.data.status.toLowerCase() === "partial-payment") {
        return <Chip color="warning" label={params.data.status} />;
      } else if (params.data.status.toLowerCase() === "paid") {
        return <Chip color="success" label={params.data.status} />;
      }
    },
  },
];

//************************************Raw Leads Column Headers ******************************************* */

const RawLeadsColumnHeaders = [
  {
    field: "phone",
    headerName: "Phone",
    headerCheckboxSelection: true,
    checkboxSelection: true,

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.phone}>
            <span className="csutable-cell-trucate">
              {params.data.phone !== null ? params.data.phone : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {params.data.name !== null ? params.data.name : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "city",
    headerName: "City",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.city}>
            <span className="csutable-cell-trucate">
              {params.data.city !== null ? params.data.city : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.email}>
            <span className="csutable-cell-trucate">
              {params.data.email !== null ? params.data.email : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "source",
    headerName: "Source",

    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.source}>
            <span className="csutable-cell-trucate">
              {params.data.source !== null && params.data.source !== ""
                ? params.data.source
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: "Created By",

    width: 130,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null ? params.data.created_by.name : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_at",
    headerName: "Created On",
    cellRenderer: (params) => {
      let date;
      if (params.value.created_at !== null) {
        date = IsoToLocalTime(params.value.created_at);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {date !== null ? date : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************auto dialer campaign column headers****************** */
const AutoDialerCampaignColumnHeaders = [
  {
    field: "agent_name",
    headerName: ColumnHeader("Agent Name"),
    sortable: false,
    flex: 1,
  },
];
//***********************************END******************************* */

//************************Ozonetel dialer agent list specific column headers****************** */
const OzonetelDialerAgentListColumnHeaders = [
  {
    field: "agent_name",
    headerName: ColumnHeader("Agent Name"),
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.agent_name}>
          <span className="csutable-cell-trucate">{params.row.agent_name}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "name",
    headerName: ColumnHeader("User Name"),
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.user.name}>
          <span className="csutable-cell-trucate">{params.row.user.name}</span>
        </Tooltip>
      );
    },
  },
];
//***********************************END******************************* */

//*********************Raw Lead Move to Project response data column headers*********************** */

const MoveRawLeadsResponseColumnHeaders = [
  {
    field: "phone",
    headerName: ColumnHeader("Phone"),
    sortable: false,

    width: 150,

    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.phone}>
            <span className="csutable-cell-trucate">
              {params.row.phone !== null ? params.row.phone : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: ColumnHeader("Status"),
    sortable: false,

    width: 150,

    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.status}>
            <span className="csutable-cell-trucate">
              {params.row.status !== null ? params.row.status : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "message",
    headerName: ColumnHeader("Message"),
    sortable: false,

    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <Tooltip title={params.row.message}>
            <span className="csutable-cell-trucate">
              {params.row.message !== null ? params.row.message : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

//********************IP config column headers********************* */
const IpConfigColumnHeaders = [
  {
    field: "ip_address",
    headerName: ColumnHeader("IPV4 Address"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return dataRenderer(params.row.ip_address);
    },
  },
  {
    field: "created_at",
    headerName: ColumnHeader("Created At"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let date = null;
      if (params.row.created_at !== null && params.row.created_at !== "") {
        date = IsoToLocalTime(params.row.created_at);
      }
      return date !== null ? date : "-";
    },
  },
  {
    field: "created_by",
    headerName: ColumnHeader("Created By"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      if (params.row.created_by !== null) {
        return dataRenderer(params.row.created_by.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "deactivated_on",
    headerName: ColumnHeader("Deactivated On"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      let date = null;
      if (
        params.row.deactivated_on !== null &&
        params.row.deactivated_on !== ""
      ) {
        date = IsoToLocalTime(params.row.deactivated_on);
      }
      return date !== null ? date : "-";
    },
  },
  {
    field: "deactivated_by",
    headerName: ColumnHeader("Deactivated By"),
    sortable: false,
    width: 200,
    renderCell: (params) => {
      if (params.row.deactivated_by !== null) {
        return dataRenderer(params.row.deactivated_by.name);
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//********************Web Forms column headers********************* */
const WebFormsColumnHeaders = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      return dataRenderer(params.data.name);
    },
  },
  {
    field: "created_by",
    headerName: "Created By",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      if (params.data.created_by !== null) {
        return dataRenderer(params.data.created_by.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "created_at",
    headerName: "Created At",
    sortable: false,
    width: 200,
    cellRenderer: (params) => {
      let date = null;
      if (params.data.created_at !== null && params.data.created_at !== "") {
        date = IsoToLocalTime(params.data.created_at);
      }
      return date !== null ? date : "-";
    },
  },
];
//*****************************END******************************** */

//********************Project Administration Smart Views column headers********************* */
const ProjectAdminSmartViewColumnHeaders = [
  {
    field: "sequence",
    headerName: "Sequence",
    rowDrag: true,
    width: 130,
    cellRenderer: (params) => {
      return dataRenderer(params.data.sequence);
    },
  },

  {
    field: "name",
    headerName: "Tab Name",
    cellRenderer: (params) => {
      if (params.data.tab !== null) {
        return dataRenderer(params.data.tab.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "view_groups",
    headerName: "View Groups",
    autoHeight: true,
    cellRenderer: (params) => {
      let list = params.data.tab.viewgroups;
      if (list.length > 0) {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingY: "8px" }}
          >
            {list.map((e) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "4px",
                    alignItems: "flex-start",
                  }}
                >
                  <FiberManualRecord
                    style={{
                      width: "12px",
                      height: "12px",
                      color: "gray",
                      marginTop: "4px",
                    }}
                  />

                  <Typography style={{ wordBreak: "break-word" }}>
                    {e}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      } else {
        return "-";
      }
    },
  },
  {
    field: "show_closed_leads",
    headerName: getLocalizedText("show_closed_leads", "project"),

    width: 200,
    cellRenderer: (params) => {
      if (params.data.tab.show_closed_leads === true) {
        return (
          <Chip style={{ fontWeight: "bold" }} color="success" label={"Yes"} />
        );
      } else {
        return (
          <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
        );
      }
    },
  },
];
const ProjectAdminUnassignedTabViewColumnHeaders = [
  {
    field: "name",
    headerName: "Tab Name",
    cellRenderer: (params) => {
      if (params.data.tab !== null) {
        return dataRenderer(params.data.name);
      } else {
        return "-";
      }
    },
  },
  {
    field: "view_groups",
    headerName: "View Groups",
    autoHeight: true,
    cellRenderer: (params) => {
      let list = params.data.viewgroups;
      if (list.length > 0) {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingY: "8px" }}
          >
            {list.map((e) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "4px",
                    alignItems: "flex-start",
                  }}
                >
                  <FiberManualRecord
                    style={{
                      width: "12px",
                      height: "12px",
                      color: "gray",
                      marginTop: "4px",
                    }}
                  />

                  <Typography style={{ wordBreak: "break-word" }}>
                    {e}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//********************Project Administration View Groups column headers********************* */
const ProjectAdminViewGroupsColumnHeaders = [
  {
    field: "name",
    headerName: "GROUP NAME",
    cellRenderer: (params) => {
      if (params.data.tab !== null) {
        return dataRenderer(params.data.name);
      } else {
        return "-";
      }
    },
  },
];
//*****************************END******************************** */

//**********************project administration quality column headers */
const ProjectAdminQualityColumnHeaders = [
  {
    field: "column",
    headerName: "COLUMN",
    width: 200,
    cellRenderer: (params) => {
      if (params.data.column_meta !== null) {
        return dataRenderer(params.data.column_meta["name"]);
      } else {
        return "-";
      }
    },
  },
  {
    field: "operator",
    headerName: "OPERATOR",
    width: 150,
    cellRenderer: (params) => {
      if (params.data.operator !== null) {
        return operatorLabelMap[params.data.operator];
      } else {
        return "-";
      }
    },
  },
  {
    field: "score",
    headerName: "SCORE",
    width: 100,
    cellRenderer: (params) => {
      return dataRenderer(params.data.score);
    },
  },
];
//******************************END************************ */

//************************deals tab product column headers******************** */

const dealsTabProductsColumnHeaders = [
  {
    field: "rate",
    headerName: "RATE",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.rate);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "quantity",
    headerName: "QUANTITY",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.quantity);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "discount",
    headerName: "DISCOUNT(%)",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.discount);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    minWidth: 130,
    resizable: false,
    flex: 1,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.amount);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************Contacts page column headers******************** */

const ContactLeadsPageColumnHeaders = [
  {
    field: "flid",
    headerName: "ID",

    width: 100,

    cellRenderer: (params) => {
      let value = dataRenderer(params.data.flid);
      return (
        <>
          <Tooltip title={value}>
            <TextHighlight>{value}</TextHighlight>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal",
    headerName: "DEAL NAME",

    width: 150,

    cellRenderer: (params) => {
      let value = params.data.deal !== null ? params.data.deal["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "LEAD STATUS",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_owner",
    headerName: "LEAD OWNER",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead_owner !== null ? params.data.lead_owner["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "last_contacted_on",
    headerName: "LAST CONTACTED ON",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.last_contacted_on !== null &&
        params.data.last_contacted_on !== ""
          ? IsoToLocalTime(params.data.last_contacted_on)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const CustomerLeadsColumnHeaders = [
  {
    field: "deal",
    headerName: "DEAL NAME",

    width: 150,

    cellRenderer: (params) => {
      let value = params.data.deal !== null ? params.data.deal["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_status",
    headerName: "LEAD STATUS",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_owner",
    headerName: "LEAD OWNER",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead_owner !== null ? params.data.lead_owner["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "last_contacted_on",
    headerName: "LAST CONTACTED ON",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.last_contacted_on !== null &&
        params.data.last_contacted_on !== ""
          ? IsoToLocalTime(params.data.last_contacted_on)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const CustomerHistoryColumnHeaders = [
  {
    field: "flid",
    headerName: getLocalizedText("lead_id", "project").toUpperCase(),
    resizable: false,
    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead.flid);
      return (
        <>
          <Tooltip title={value}>
            <TextHighlight>{value}</TextHighlight>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "deal",
    headerName: "DEAL NAME",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead.deal !== null ? params.data.lead.deal["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "lead_owner",
    headerName: "LEAD OWNER",

    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead.lead_owner !== null
          ? params.data.lead.lead_owner["name"]
          : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED AT",

    width: 200,

    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null && params.data.created_at !== ""
          ? IsoToLocalTime(params.data.created_at)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const customerActivityColumns = [
  {
    field: "activity_performed_at",
    headerName: "TIME OF ACTIVITY",
    minWidth: 200,
    cellRenderer: (params) => {
      let date =
        params.data.activity_performed_at !== null
          ? IsoToLocalTime(params.data.activity_performed_at)
          : "-";

      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "activity_desc",
    headerName: "DESCRIPTION",
    minWidth: 250,
    flex: 1,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.activity_desc);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "activity_type",
    headerName: "ACTIVITY TYPE",
    minWidth: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.activity_type);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "user",
    headerName: "PERFORMED BY",
    minWidth: 200,
    cellRenderer: (params) => {
      let value =
        params.data.user !== null
          ? dataRenderer(params.data.user["name"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//************************Leads Deals Tab column headers******************** */

const dealsColumnHeaders = [
  {
    field: "name",
    headerName: "Deal Name",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "Deal Value",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_currency",
    headerName: "Deal Currency",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_currency}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_currency)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "expected_close_date",
    headerName: "Expected Close Date",

    width: 150,
    cellRenderer: (params) => {
      let date =
        params.data.expected_close_date !== null &&
        params.data.expected_close_date !== ""
          ? IsoToLocalDate(params.data.expected_close_date)
          : "-";
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">{date}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "priority",
    headerName: "Deal Priority",

    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return <Chip color="default" label={params.data.priority} />;
      } else if (params.data.priority.toLowerCase() === "medium") {
        return <Chip color="warning" label={params.data.priority} />;
      } else if (params.data.priority.toLowerCase() === "high") {
        return <Chip color="success" label={params.data.priority} />;
      }
    },
  },
];

//**************************************Deals page column headers******************* */
const dealsPageColumnHeaders = [
  {
    field: "name",
    headerName: "DEAL NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "FINAL DEAL VALUE",

    width: 180,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//**************************************Quotes page column headers******************* */

const quotesPageColumnHeaders = [
  {
    field: "quotation_sent_date",
    headerName: "DATE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip
            title={
              params.data.quote_date !== null
                ? IsoToLocalDate(params.data.quote_date)
                : "-"
            }
          >
            <span className="csutable-cell-trucate">
              {params.data.quote_date !== null
                ? IsoToLocalDate(params.data.quote_date)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "quote_number",
    headerName: "QUOTE NUMBER",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.quote_number}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.quote_number)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "quotation_status",
    headerName: "STATUS",
    resizable: false,
    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    width: 100,
    cellRenderer: (params) => {
      let value = "-";
      if (isCustomQuotationTemplate(params.data["quote_schema"])) {
        value = dataRenderer(params.data["amount"]);
      } else {
        if (
          params.data.hasOwnProperty("quote_schema") &&
          params.data["quote_schema"] !== null &&
          params.data["quote_schema"].hasOwnProperty("amount_details") &&
          params.data["quote_schema"]["amount_details"] !== null
        ) {
          value = dataRenderer(
            params.data["quote_schema"]["amount_details"]["grand_total"]
          );
        }
      }

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "sent_by",
    headerName: "SENT BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//**************************************Invoices page column headers******************* */
const invoicesPageColumnHeaders = [
  {
    field: "invoice_date",
    headerName: "DATE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip
            title={
              params.data.invoice_date !== null
                ? IsoToLocalDate(params.data.invoice_date)
                : "-"
            }
          >
            <span className="csutable-cell-trucate">
              {params.data.invoice_date !== null
                ? IsoToLocalDate(params.data.invoice_date)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice_number",
    headerName: "INVOICE NUMBER",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.invoice_number}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.invoice_number)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice_status",
    headerName: "STATUS",
    resizable: false,
    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "due_date",
    headerName: "DUE DATE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip
            title={
              params.data.expiry_date !== null
                ? IsoToLocalDate(params.data.expiry_date)
                : "-"
            }
          >
            <span className="csutable-cell-trucate">
              {params.data.expiry_date !== null
                ? IsoToLocalDate(params.data.expiry_date)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    width: 100,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.amount}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.amount)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "balance",
    headerName: "BALANCE",

    width: 100,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.balance}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.balance)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "sent_by",
    headerName: "SENT BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//*******************************Leads deals history column header******************** */
const leadDealHistoryColumnHeaders = [
  {
    field: "name",
    headerName: "DEAL NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "FINAL DEAL VALUE",

    width: 180,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "deal_owner",
    headerName: "DEAL OWNER",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.deal_owner !== null
          ? dataRenderer(params.data.deal_owner.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "priority",
    headerName: "DEAL PRIORITY",
    width: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="default"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "medium") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="warning"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "high") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="success"
            label={params.data.priority}
          />
        );
      }
    },
  },

  {
    field: "active",
    headerName: "ACTIVE",
    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.active !== null && params.data.active !== "") {
        if (params.data.active === true) {
          return (
            <Chip
              style={{ fontWeight: "bold" }}
              color="success"
              label={"Yes"}
            />
          );
        } else {
          return (
            <Chip style={{ fontWeight: "bold" }} color="warning" label={"No"} />
          );
        }
      } else {
        return "-";
      }
    },
  },
];
//***********************************END********************************************* */

//***********************************Lead quote list column headers**************** */
const leadQuoteListColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 70,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "quote_date",
    headerName: "DATE",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.quote_date !== null
          ? IsoToLocalDate(params.data.quote_date)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "quote_number",
    headerName: "QUOTE NUMBER",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.quote_number}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.quote_number)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",

    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "expiry_date",
    headerName: "DUE DATE",
    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.expiry_date !== null
          ? IsoToLocalDate(params.data.expiry_date)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.amount);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  // {
  //   field: "created_at",
  //   headerName: "CREATED AT",

  //   width: 200,
  //   cellRenderer: (params) => {
  //     let value =
  //       params.data.hasOwnProperty("created_at") &&
  //       params.data.created_at !== null
  //         ? IsoToLocalTime(params.data.created_at)
  //         : "-";

  //     return (
  //       <>
  //         <Tooltip title={value}>
  //           <span className="csutable-cell-trucate">{value}</span>
  //         </Tooltip>
  //       </>
  //     );
  //   },
  // },
  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//***********************************END********************************************* */

//***********************************Lead invoice list column headers**************** */
const leadInvoiceListColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 70,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "invoice_date",
    headerName: "DATE",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.invoice_date !== null
          ? IsoToLocalDate(params.data.invoice_date)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "invoice_number",
    headerName: "INVOICE NUMBER",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.invoice_number}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.invoice_number)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",

    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "expiry_date",
    headerName: "DUE DATE",
    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.expiry_date !== null
          ? IsoToLocalDate(params.data.expiry_date)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount",
    headerName: "AMOUNT",

    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.amount);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "balance",
    headerName: "BALANCE",

    width: 100,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.balance);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  // {
  //   field: "created_at",
  //   headerName: "CREATED AT",

  //   width: 200,
  //   cellRenderer: (params) => {
  //     let value =
  //       params.data.hasOwnProperty("created_at") &&
  //       params.data.created_at !== null
  //         ? IsoToLocalTime(params.data.created_at)
  //         : "-";

  //     return (
  //       <>
  //         <Tooltip title={value}>
  //           <span className="csutable-cell-trucate">{value}</span>
  //         </Tooltip>
  //       </>
  //     );
  //   },
  // },
  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

const invoicePaymentColumns = [
  {
    field: "payment_date",
    headerName: "PAYMENT DATE",
    width: 200,
    cellRenderer: (params) => {
      let date = null;
      if (params.data.payment_date !== null) {
        date = IsoToLocalTime(params.data.payment_date);
      }
      return (
        <>
          <Tooltip title={date}>
            <span className="csutable-cell-trucate">
              {params.data.payment_date !== null ? date : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "amount_received",
    headerName: "AMOUNT",
    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.amount_received);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "payment_mode",
    headerName: "MODE",
    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.payment_mode);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "reference_number",
    headerName: "REFERENCE NUMBER",
    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.reference_number);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "notes",
    headerName: "NOTES",
    width: 300,
    flex: 1,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.notes);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//**********************************END********************************************* */

//**************************************Files page column headers******************* */
const filesPageColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 70,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "name",
    headerName: "NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span
              className="csutable-cell-trucate"
              style={{ cursor: "pointer" }}
            >
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const FolderFilesColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.title);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: "DESCRIPTION",

    width: 300,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.desc);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.created_by.name);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED ON",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "document_type",
    headerName: "DOCUMENT TYPE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.document_type);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//***************************************END************************************** */

//**************************************Predefined Deals page column headers******************* */
const predefinedDealsPageColumnHeaders = [
  {
    field: "name",
    headerName: "DEAL NAME",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value",
    headerName: "FINAL DEAL VALUE",

    width: 180,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "deal_value_currency",
    headerName: "DEAL CURRENCY",

    width: 150,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.deal_value_currency}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.deal_value_currency)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "creator",
    headerName: "CREATOR",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.creator !== null
          ? dataRenderer(params.data.creator.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "priority",
    headerName: "DEAL PRIORITY",

    flex: 1,
    minWidth: 150,
    resizable: false,
    cellRenderer: (params) => {
      if (params.data.priority.toLowerCase() === "low") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="default"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "medium") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="warning"
            label={params.data.priority}
          />
        );
      } else if (params.data.priority.toLowerCase() === "high") {
        return (
          <Chip
            style={{ fontWeight: "bold" }}
            color="success"
            label={params.data.priority}
          />
        );
      }
    },
  },
];
//***************************************END************************************** */

//**************************************Admin Quotations page column headers******************* */

const getQuotationTitle = (schema) => {
  if (
    schema.quotation_title !== null &&
    schema.quotation_title !== "" &&
    schema.quotation_title !== undefined
  ) {
    return schema.quotation_title;
  } else if (
    schema.title !== null &&
    schema.title !== "" &&
    schema.title !== undefined
  ) {
    return schema.title;
  } else {
    return "-";
  }
};

const adminQuationsPageColumnHeaders = [
  {
    field: "sl_no",
    headerName: "S.NO",
    width: 70,

    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "name",
    headerName: "TEMPLATE NAME",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "QUOTATION TITLE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {params.data.schema ? getQuotationTitle(params.data.schema) : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "name",
    headerName: "INVOICE TITLE",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {params.data.schema !== null
                ? dataRenderer(params.data.schema.invoice_title)
                : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED AT",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//*******************************Files Tab Column headers used in contacts,customer details page */
const filesTabColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.title);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "desc",
    headerName: "DESCRIPTION",

    width: 300,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.desc);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "uploaded_by",
    headerName: "UPLOADED BY",

    width: 200,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.uploaded_by.name);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "UPLOADED ON",

    width: 150,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "file_type",
    headerName: "TYPE",

    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.file_type);

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//*********************************END******************************************* */

//**************************************Admin Project Workday template column headers******************* */
const adminProjectWorkdayColumnHeaders = [
  {
    field: "name",
    headerName: "TEMPLATE NAME",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "created_by",
    headerName: "CREATED BY",

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_by !== null
          ? dataRenderer(params.data.created_by.name)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED AT",
    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data.created_at !== null
          ? IsoToLocalTime(params.data.created_at)
          : "-";

      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//**************************************branch locations page column headers******************* */
const branchLocationsColumnHeaders = [
  {
    field: "location_name",
    headerName: "NAME",

    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.location_name}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.location_name)}
            </span>
          </Tooltip>
        </>
      );
    },
  },

  {
    field: "location_desc",
    headerName: "DESCRIPTION",
    flex: 1,
    width: 200,
    cellRenderer: (params) => {
      return (
        <>
          <Tooltip title={params.data.location_desc}>
            <span className="csutable-cell-trucate">
              {dataRenderer(params.data.location_desc)}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];
//***************************************END************************************** */

//********************************Attendance settings page column headers******************* */
const AttendanceSettingsPageColumns = [
  {
    field: "user",
    headerName: "USER",
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellStyle: (params) => {
      if (params.data.attendance_settings === null) {
        return {
          color: "#BCBEBE",
        };
      }
      return null;
    },
    width: 200,
    cellRenderer: (params) => {
      return params.data.user !== null
        ? dataRenderer(params.data["name"])
        : "-";
    },
  },
  {
    field: "attendance_in_location",
    headerName: "ATTENDANCE IN LOCATION",
    sortable: false,

    width: 250,
    cellRenderer: (params) => {
      if (params.data.attendance_settings !== null) {
        if (
          params.data.attendance_settings.attendance_in_location !== null &&
          params.data.attendance_settings.attendance_in_location !== ""
        ) {
          return params.data.attendance_settings.attendance_in_location[
            "location_name"
          ];
        } else {
          return "Anywhere";
        }
      } else {
        return "-";
      }
    },
  },
  {
    field: "attendance_out_location",
    headerName: "ATTENDANCE OUT LOCATION",
    sortable: false,

    width: 250,
    cellRenderer: (params) => {
      if (params.data.attendance_settings !== null) {
        if (
          params.data.attendance_settings.attendance_out_location !== null &&
          params.data.attendance_settings.attendance_out_location !== ""
        ) {
          return params.data.attendance_settings.attendance_out_location[
            "location_name"
          ];
        } else {
          return "Anywhere";
        }
      } else {
        return "-";
      }
    },
  },
  {
    field: "attendance_from_device",
    headerName: "ATTENDANCE FROM DEVICE",
    sortable: true,

    width: 250,
    cellRenderer: (params) => {
      return params.data.attendance_settings !== null
        ? dataRenderer(params.data.attendance_settings.attendance_from_device)
        : "-";
    },
  },
  {
    field: "mandatory_attendance_in",
    headerName: "MANDATORY ATTENDANCE IN",
    sortable: false,
    width: 250,
    cellRenderer: (params) => {
      if (params.data.attendance_settings === null) {
        return "-";
      } else {
        if (params.data.attendance_settings.mandatory_attendance_in === null) {
          return "-";
        } else {
          if (
            params.data.attendance_settings.mandatory_attendance_in === true
          ) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      }
    },
  },
  {
    field: "capture_face",
    headerName: "CAPTURE FACE",
    sortable: false,
    width: 250,
    cellRenderer: (params) => {
      if (params.data.attendance_settings === null) {
        return "-";
      } else {
        if (params.data.attendance_settings.capture_face === null) {
          return "-";
        } else {
          if (params.data.attendance_settings.capture_face === true) {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="success"
                label={"Yes"}
              />
            );
          } else {
            return (
              <Chip
                style={{ fontWeight: "bold" }}
                color="warning"
                label={"No"}
              />
            );
          }
        }
      }
    },
  },
];
//*********************************END************************************************** */

//********************************Attendance settings page column headers******************* */
const AttendanceHistoryPageColumns = [
  {
    field: "user",
    headerName: "USER",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return dataRenderer(params.data["name"]);
    },
  },
  {
    field: "phone",
    headerName: "PHONE",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return dataRenderer(params.data["phone"]);
    },
  },
  {
    field: "attendance_in_time",
    headerName: "ATTENDANCE IN TIME",
    sortable: false,

    width: 250,
    cellRenderer: (params) => {
      let value =
        params.data.attendance_data !== null &&
        params.data.attendance_data["attendance_in_time"] !== null
          ? getLocalTimeStringFromIsoDateString(
              params.data.attendance_data["attendance_in_time"]
            )
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "attendance_out_time",
    headerName: "ATTENDANCE OUT TIME",
    width: 250,
    sortable: false,

    cellRenderer: (params) => {
      let value =
        params.data.attendance_data !== null &&
        params.data.attendance_data["attendance_out_time"] !== null
          ? getLocalTimeStringFromIsoDateString(
              params.data.attendance_data["attendance_out_time"]
            )
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",
    sortable: false,
    width: 150,
    cellRenderer: (params) => {
      if (params.data.attendance_data === null) {
        return (
          <Chip
            style={{ fontWeight: "bold", width: "100px" }}
            color="warning"
            label={"Not Marked"}
          />
        );
      } else {
        return (
          <Chip
            style={{ fontWeight: "bold", width: "100px" }}
            color="success"
            label={"Marked"}
          />
        );
      }
    },
  },
];
//*********************************END************************************************** */

//************************view daywise worktime data column headers******************** */

const daywiseWorkTimeColumnHeaders = [
  {
    field: "day",
    headerName: "DAY",

    width: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.day);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: "START TIME",

    width: 130,
    cellRenderer: (params) => {
      let value =
        params.data["start_time"] !== null
          ? getLocalTimeStringFromIsoDateString(params.data["start_time"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: "END TIME",

    width: 130,
    cellRenderer: (params) => {
      let value =
        params.data["end_time"] !== null
          ? getLocalTimeStringFromIsoDateString(params.data["end_time"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];

//********************************Tasks page column headers******************* */
const tasksPageColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return (
        <span
          style={{
            textDecoration:
              params.data["task_status"] !== "Open" ? "line-through" : "none",
          }}
        >
          {dataRenderer(params.data["title"])}
        </span>
      );
    },
  },
  {
    field: "task_type",
    headerName: "TASK TYPE",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return params.data["task_type"] !== null
        ? dataRenderer(params.data["task_type"]["task_name"])
        : "-";
    },
  },
  {
    field: "start_time",
    headerName: "START TIME",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data["start_time"] !== null
          ? IsoToLocalTime(params.data["start_time"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: "END TIME",
    width: 200,
    sortable: false,

    cellRenderer: (params) => {
      let value =
        params.data["end_time"] !== null
          ? IsoToLocalTime(params.data["end_time"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//*********************************END************************************************** */

//********************************Lead tasks tab column headers******************* */
const leadTaskTabColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",
  },
  {
    field: "task_type",
    headerName: "TASK TYPE",
  },
  {
    field: "start_time",
    headerName: "START TIME",
  },
  {
    field: "end_time",
    headerName: "END TIME",
  },
  {
    field: "task_status",
    headerName: "STATUS",
  },
  {
    field: "priority",
    headerName: "PRIORITY",
  },
];
//*********************************END************************************************** */

//********************************Tasks type page column headers******************* */
const taskTypePageColumnHeaders = [
  {
    field: "task_name",
    headerName: "TASK NAME",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return dataRenderer(params.data["task_name"]);
    },
  },
  {
    field: "default_duration",
    headerName: "DEFAULT DURATION",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data["default_duration"] !== null &&
        params.data["default_duration"] !== ""
          ? formatMinutes(params.data["default_duration"])
          : "-";
      return value;
    },
  },
  {
    field: "desc",
    headerName: "DESCRIPTION",
    sortable: false,
    flex: 1,
    width: 250,
    cellRenderer: (params) => {
      return dataRenderer(params.data["desc"]);
    },
  },
];
//*********************************END************************************************** */

//********************************Lead Others Interaction Tab column headers******************* */
const leadOtherInteractionTabColumnHeaders = [
  {
    field: "title",
    headerName: "TITLE",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return <span>{dataRenderer(params.data["title"])}</span>;
    },
  },
  {
    field: "channel",
    headerName: "CHANNEL",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      return <span>{dataRenderer(params.data["channel"])}</span>;
    },
  },
  {
    field: "interacted_on",
    headerName: "INTERACTED ON",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data["interacted_on"] !== null
          ? IsoToLocalTime(params.data["interacted_on"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "CREATED AT",
    sortable: false,

    width: 200,
    cellRenderer: (params) => {
      let value =
        params.data["created_at"] !== null
          ? IsoToLocalTime(params.data["created_at"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "created_by",
    headerName: "CREATED BY",
    sortable: false,

    width: 200,
    flex: 1,
    cellRenderer: (params) => {
      let value =
        params.data["created_by"] !== null
          ? dataRenderer(params.data["created_by"]["name"])
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//*********************************END************************************************** */
//--------------------------------Customer imports column headers--------------------------
const customerImportsColumnHeaders = [
  {
    field: "import_type",
    headerName: "IMPORT TYPE",

    width: 130,
    cellRenderer: (params) => {
      let value =
        params.data.hasOwnProperty("import_type") &&
        params.data["import_type"] !== null
          ? getImportTypeDisplayName(params.data.import_type)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "imported_by",
    headerName: "IMPORTED BY",

    minWidth: 150,
    cellRenderer: (params) => {
      let value =
        params.data.hasOwnProperty("imported_by") &&
        params.data["imported_by"] !== null
          ? dataRenderer(params.data.imported_by)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "status",
    headerName: "STATUS",

    minWidth: 130,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.status);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "start_time",
    headerName: "START TIME",

    minWidth: 130,
    cellRenderer: (params) => {
      let value =
        params.data.hasOwnProperty("start_time") &&
        params.data["start_time"] !== null &&
        params.data["start_time"] !== ""
          ? dateTimeHandler(params.data.start_time)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "end_time",
    headerName: "END TIME",
    minWidth: 130,
    flex: 1,
    resizable: false,
    cellRenderer: (params) => {
      let value =
        params.data.hasOwnProperty("end_time") &&
        params.data["end_time"] !== null &&
        params.data["end_time"] !== ""
          ? dateTimeHandler(params.data.end_time)
          : "-";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
];
//-----------------------------------END----------------------------------------------

//********************Table widget test column headers******************* */
const TableWidgetTestColumnHeader = [
  {
    field: "column1",
    headerName: "Column1",
    sortable: false,
  },
  {
    field: "column2",
    headerName: "Column2",
    sortable: false,
  },
  {
    field: "column3",
    headerName: "Column3",
    sortable: false,
  },
  {
    field: "column4",
    headerName: "Column4",
    sortable: false,
  },
  {
    field: "column5",
    headerName: "Column5",
    sortable: false,
  },
  {
    field: "column6",
    headerName: "Column6",
    sortable: false,
  },
  {
    field: "column7",
    headerName: "Column7",
    sortable: false,
  },
];
//*****************************END************************************ */

//********************************landing page text contents***********************************

const LandingPageCardData = [
  {
    color: "#E5F8F4",
    img: img1,
    title: "Marketing",
    desc: "Effortlessly generate leads from all channels with LeadPixie",
  },
  {
    color: "#FFFAE5",
    img: img2,
    title: "Sales",
    desc: "Efficiently prioritize, distribute and manage leads for maximum results",
  },
  {
    color: "#FFFAE5",
    img: img3,
    title: "Startups",
    desc: "Easy to use, affordable and flexible, LeadPixie grows with your startup",
  },
  {
    color: "#E5F8F4",
    img: img4,
    title: "Revenue",
    desc: "Streamline your lead management process and watch your revenue soar",
  },
];

const SubscriptionCardData = [
  {
    tag: "Lite",
    desc: "Automate and optimize your sales cycle",
    price: "499",
    annual_tag: "/user/month, billed annually",
    features: {
      title: "Everything comes in Lite",
      keys: [
        "Lead Tracking",
        "Lead Scoring",
        "Custom Fields",
        "Predefine Reports",
        "Advanced Filters",
        "Lead Activities",
      ],
    },
  },
  {
    tag: "Pro",
    desc: "Manage multiple sales teams, avoid repetitive work and spend more time selling",
    price: "799",
    annual_tag: "/user/month, billed annually",
    tag_line: "All prices are exclusive of applicable taxes",
    features: {
      title: "Everything in Lite, plus",
      keys: [
        "Role Based Access",
        "Custom Tabs",
        "Campaigns",
        "Lead Segmentation",
        "Lead Distribution",
        "Custom Reports",
        "Mobile APP",
      ],
    },
  },
  {
    tag: "Ultimate",
    desc: "Advanced customization and in-depth Analytics to accelerate growth",
    // price: "4,999",
    annual_tag: "/user/month, billed annually",
    month_tag: "Contact us for a quote",
    features: {
      title: "Everything in Lite, plus",
      keys: [
        "Workflows",
        "Dynamic Forms",
        "AI Powered Insights",
        "Account Management",
        "Digital Marketing",
      ],
    },
  },
];

const LandingPageCarouselData = [
  {
    desc: "“The best part of LeadPixie is the ease and speed of customization for any business process. It is one of the lightest and easiest lead management software out there, and I would highly recommend it for teams of any size!”",
    name: "Vikram H",
    post: "Business Head, FB",
  },
  {
    desc: "“Our team was struggling with manual lead management processes and losing valuable leads in the process. With LeadPixie, we now have all of our leads in one centralized location and can prioritize them based on their potential value to our business. We've already seen an increase in closed deals and revenue thanks to this platform.”",
    name: "Harish Rao",
    post: "SFS",
  },
  {
    desc: "“We were hesitant to switch to a new lead management platform, but LeadPixie has exceeded our expectations. The integration with our existing tools has made the transition seamless and the custom workflows have allowed us to streamline our processes like never before. Our sales team is now able to close more deals and focus on what they do best: selling.”",
    name: "Praveen",
    post: "-",
  },
];
const FooterLinks = [
  {
    label: getLocalizedText("privacy_policy"),
    link: "https://d2dsj7nm5j2dtv.cloudfront.net/lp-pp.html",
  },
  {
    label: getLocalizedText("terms_conditions"),
    link: "https://d2dsj7nm5j2dtv.cloudfront.net/lp-tos.html",
  },
];

const daysList = [
  { name: "Monday", value: "monday" },
  { name: "Tuesday", value: "tuesday" },
  { name: "Wednesday", value: "wednesday" },
  { name: "Thursday", value: "thursday" },
  { name: "Friday", value: "friday" },
  { name: "Saturday", value: "saturday" },
  { name: "Sunday", value: "sunday" },
];

const monthsList = [
  { name: "January", value: "january" },
  { name: "February", value: "february" },
  { name: "March", value: "march" },
  { name: "April", value: "april" },
  { name: "May", value: "may" },
  { name: "June", value: "june" },
  { name: "July", value: "july" },
  { name: "August", value: "august" },
  { name: "September", value: "september" },
  { name: "October", value: "october" },
  { name: "November", value: "november" },
  { name: "December", value: "december" },
];

const productTypeList = ["Inventory", "Non-Inventory", "Service"];
const dealPriorityList = ["High", "Medium", "Low"];
const currencylist = [
  { name: "Afghan Afghani", code: "AFA" },
  { name: "Albanian Lek", code: "ALL" },
  { name: "Algerian Dinar", code: "DZD" },
  { name: "Angolan Kwanza", code: "AOA" },
  { name: "Argentine Peso", code: "ARS" },
  { name: "Armenian Dram", code: "AMD" },
  { name: "Aruban Florin", code: "AWG" },
  { name: "Australian Dollar", code: "AUD" },
  { name: "Azerbaijani Manat", code: "AZN" },
  { name: "Bahamian Dollar", code: "BSD" },
  { name: "Bahraini Dinar", code: "BHD" },
  { name: "Bangladeshi Taka", code: "BDT" },
  { name: "Barbadian Dollar", code: "BBD" },
  { name: "Belarusian Ruble", code: "BYR" },
  { name: "Belgian Franc", code: "BEF" },
  { name: "Belize Dollar", code: "BZD" },
  { name: "Bermudan Dollar", code: "BMD" },
  { name: "Bhutanese Ngultrum", code: "BTN" },
  { name: "Bitcoin", code: "BTC" },
  { name: "Bolivian Boliviano", code: "BOB" },
  { name: "Bosnia-Herzegovina Convertible Mark", code: "BAM" },
  { name: "Botswanan Pula", code: "BWP" },
  { name: "Brazilian Real", code: "BRL" },
  { name: "British Pound Sterling", code: "GBP" },
  { name: "Brunei Dollar", code: "BND" },
  { name: "Bulgarian Lev", code: "BGN" },
  { name: "Burundian Franc", code: "BIF" },
  { name: "Cambodian Riel", code: "KHR" },
  { name: "Canadian Dollar", code: "CAD" },
  { name: "Cape Verdean Escudo", code: "CVE" },
  { name: "Cayman Islands Dollar", code: "KYD" },
  { name: "CFA Franc BCEAO", code: "XOF" },
  { name: "CFA Franc BEAC", code: "XAF" },
  { name: "CFP Franc", code: "XPF" },
  { name: "Chilean Peso", code: "CLP" },
  { name: "Chilean Unit of Account", code: "CLF" },
  { name: "Chinese Yuan", code: "CNY" },
  { name: "Colombian Peso", code: "COP" },
  { name: "Comorian Franc", code: "KMF" },
  { name: "Congolese Franc", code: "CDF" },
  { name: "Costa Rican Colón", code: "CRC" },
  { name: "Croatian Kuna", code: "HRK" },
  { name: "Cuban Convertible Peso", code: "CUC" },
  { name: "Czech Republic Koruna", code: "CZK" },
  { name: "Danish Krone", code: "DKK" },
  { name: "Djiboutian Franc", code: "DJF" },
  { name: "Dominican Peso", code: "DOP" },
  { name: "East Caribbean Dollar", code: "XCD" },
  { name: "Egyptian Pound", code: "EGP" },
  { name: "Eritrean Nakfa", code: "ERN" },
  { name: "Estonian Kroon", code: "EEK" },
  { name: "Ethiopian Birr", code: "ETB" },
  { name: "Euro", code: "EUR" },
  { name: "Falkland Islands Pound", code: "FKP" },
  { name: "Fijian Dollar", code: "FJD" },
  { name: "Gambian Dalasi", code: "GMD" },
  { name: "Georgian Lari", code: "GEL" },
  { name: "German Mark", code: "DEM" },
  { name: "Ghanaian Cedi", code: "GHS" },
  { name: "Gibraltar Pound", code: "GIP" },
  { name: "Greek Drachma", code: "GRD" },
  { name: "Guatemalan Quetzal", code: "GTQ" },
  { name: "Guinean Franc", code: "GNF" },
  { name: "Guyanaese Dollar", code: "GYD" },
  { name: "Haitian Gourde", code: "HTG" },
  { name: "Honduran Lempira", code: "HNL" },
  { name: "Hong Kong Dollar", code: "HKD" },
  { name: "Hungarian Forint", code: "HUF" },
  { name: "Icelandic Króna", code: "ISK" },
  { name: "Indian Rupee", code: "INR" },
  { name: "Indonesian Rupiah", code: "IDR" },
  { name: "Iranian Rial", code: "IRR" },
  { name: "Iraqi Dinar", code: "IQD" },
  { name: "Israeli New Sheqel", code: "ILS" },
  { name: "Italian Lira", code: "ITL" },
  { name: "Jamaican Dollar", code: "JMD" },
  { name: "Japanese Yen", code: "JPY" },
  { name: "Jordanian Dinar", code: "JOD" },
  { name: "Kazakhstani Tenge", code: "KZT" },
  { name: "Kenyan Shilling", code: "KES" },
  { name: "Kuwaiti Dinar", code: "KWD" },
  { name: "Kyrgystani Som", code: "KGS" },
  { name: "Laotian Kip", code: "LAK" },
  { name: "Latvian Lats", code: "LVL" },
  { name: "Lebanese Pound", code: "LBP" },
  { name: "Lesotho Loti", code: "LSL" },
  { name: "Liberian Dollar", code: "LRD" },
  { name: "Libyan Dinar", code: "LYD" },
  { name: "Litecoin", code: "LTC" },
  { name: "Lithuanian Litas", code: "LTL" },
  { name: "Macanese Pataca", code: "MOP" },
  { name: "Macedonian Denar", code: "MKD" },
  { name: "Malagasy Ariary", code: "MGA" },
  { name: "Malawian Kwacha", code: "MWK" },
  { name: "Malaysian Ringgit", code: "MYR" },
  { name: "Maldivian Rufiyaa", code: "MVR" },
  { name: "Mauritanian Ouguiya", code: "MRO" },
  { name: "Mauritian Rupee", code: "MUR" },
  { name: "Mexican Peso", code: "MXN" },
  { name: "Moldovan Leu", code: "MDL" },
  { name: "Mongolian Tugrik", code: "MNT" },
  { name: "Moroccan Dirham", code: "MAD" },
  { name: "Mozambican Metical", code: "MZM" },
  { name: "Myanmar Kyat", code: "MMK" },
  { name: "Namibian Dollar", code: "NAD" },
  { name: "Nepalese Rupee", code: "NPR" },
  { name: "Netherlands Antillean Guilder", code: "ANG" },
  { name: "New Taiwan Dollar", code: "TWD" },
  { name: "New Zealand Dollar", code: "NZD" },
  { name: "Nicaraguan Córdoba", code: "NIO" },
  { name: "Nigerian Naira", code: "NGN" },
  { name: "North Korean Won", code: "KPW" },
  { name: "Norwegian Krone", code: "NOK" },
  { name: "Omani Rial", code: "OMR" },
  { name: "Pakistani Rupee", code: "PKR" },
  { name: "Panamanian Balboa", code: "PAB" },
  { name: "Papua New Guinean Kina", code: "PGK" },
  { name: "Paraguayan Guarani", code: "PYG" },
  { name: "Peruvian Nuevo Sol", code: "PEN" },
  { name: "Philippine Peso", code: "PHP" },
  { name: "Polish Zloty", code: "PLN" },
  { name: "Qatari Rial", code: "QAR" },
  { name: "Romanian Leu", code: "RON" },
  { name: "Russian Ruble", code: "RUB" },
  { name: "Rwandan Franc", code: "RWF" },
  { name: "Salvadoran Colón", code: "SVC" },
  { name: "Samoan Tala", code: "WST" },
  { name: "São Tomé and Príncipe Dobra", code: "STD" },
  { name: "Saudi Riyal", code: "SAR" },
  { name: "Serbian Dinar", code: "RSD" },
  { name: "Seychellois Rupee", code: "SCR" },
  { name: "Sierra Leonean Leone", code: "SLL" },
  { name: "Singapore Dollar", code: "SGD" },
  { name: "Slovak Koruna", code: "SKK" },
  { name: "Solomon Islands Dollar", code: "SBD" },
  { name: "Somali Shilling", code: "SOS" },
  { name: "South African Rand", code: "ZAR" },
  { name: "South Korean Won", code: "KRW" },
  { name: "South Sudanese Pound", code: "SSP" },
  { name: "Special Drawing Rights", code: "XDR" },
  { name: "Sri Lankan Rupee", code: "LKR" },
  { name: "St. Helena Pound", code: "SHP" },
  { name: "Sudanese Pound", code: "SDG" },
  { name: "Surinamese Dollar", code: "SRD" },
  { name: "Swazi Lilangeni", code: "SZL" },
  { name: "Swedish Krona", code: "SEK" },
  { name: "Swiss Franc", code: "CHF" },
  { name: "Syrian Pound", code: "SYP" },
  { name: "Tajikistani Somoni", code: "TJS" },
  { name: "Tanzanian Shilling", code: "TZS" },
  { name: "Thai Baht", code: "THB" },
  { name: "Tongan Pa'anga", code: "TOP" },
  { name: "Trinidad & Tobago Dollar", code: "TTD" },
  { name: "Tunisian Dinar", code: "TND" },
  { name: "Turkish Lira", code: "TRY" },
  { name: "Turkmenistani Manat", code: "TMT" },
  { name: "Ugandan Shilling", code: "UGX" },
  { name: "Ukrainian Hryvnia", code: "UAH" },
  { name: "United Arab Emirates Dirham", code: "AED" },
  { name: "Uruguayan Peso", code: "UYU" },
  { name: "US Dollar", code: "USD" },
  { name: "Uzbekistan Som", code: "UZS" },
  { name: "Vanuatu Vatu", code: "VUV" },
  { name: "Venezuelan BolÃvar", code: "VEF" },
  { name: "Vietnamese Dong", code: "VND" },
  { name: "Yemeni Rial", code: "YER" },
  { name: "Zambian Kwacha", code: "ZMK" },
  { name: "Zimbabwean dollar", code: "ZWL" },
];

const fieldBlockList = [
  {
    label: "Short Text",
    structure: {
      id: "id1",
      name: "short-text",
      attributes: {
        minCharacters: "",
        maxCharacters: "",
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Long Text",
    structure: {
      id: "id2",
      name: "long-text",
      attributes: {
        minCharacters: "",
        maxCharacters: "",
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Number",
    structure: {
      id: "id3",
      name: "number",
      attributes: {
        setMax: false,
        max: 0,
        setMin: false,
        min: 0,
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",
        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
  {
    label: "Email",
    structure: {
      id: "id4",
      name: "email",
      attributes: {
        required: false,
        nextBtnLabel: false,
        classnames: "",
        // placeholder: "",

        defaultValue: "",

        label: "",
        customHTML: "",
        layout: "stack",
        attachmentFocalPoint: {
          x: 0.5,
          y: 0.5,
        },
        attachmentFancyBorderRadius: false,
        attachmentBorderRadius: "0px",
        attachmentMaxWidth: "none",
      },
    },
  },
];

const externalFormTypeList = ["Embedded", "Page"];
const filterOperatorMapList = {
  string: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
    { label: "Contains", value: "contains" },
    { label: "Not Contains", value: "not contains" },
    { label: "Starts With", value: "starts with" },
  ],
  integer: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
  ],
  integer_range: [
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
  ],
  date: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
  ],
  date_range: [
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
  ],
  list: [
    { label: "In", value: "in" },
    { label: "Not In", value: "not in" },
  ],
  parent: [{ label: "In", value: "in" }],
  child: [{ label: "In", value: "in" }],
};

const reportFilterOperatorMapList = {
  string: [
    { label: "Equals", value: "equals" },
    { label: "Contains", value: "contains" },
    { label: "Starts With", value: "starts with" },
  ],
  integer: [{ label: "Equals", value: "equals" }],
  integer_range: [{ label: "Between", value: "between" }],
  date_or_datetime: [{ label: "Between", value: "between" }],
  list: [{ label: "In", value: "in" }],
};

const qualityOperatorMapList = {
  string: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
    { label: "Contains", value: "contains" },
    { label: "Not Contains", value: "not contains" },
    { label: "Starts With", value: "starts with" },
    { label: "In", value: "in" },
    { label: "Not In", value: "not in" },
    { label: "Exists", value: "exists" },
  ],
  integer: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
    { label: "Exists", value: "exists" },
  ],

  date: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
    { label: "Exists", value: "exists" },
  ],
};

const contactOperatorMapList = {
  string: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
    { label: "Contains", value: "contains" },
    { label: "Not Contains", value: "not contains" },
    { label: "Starts With", value: "starts with" },
  ],
  integer: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
  ],
  integer_range: [
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
  ],
  date: [
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not equals" },
  ],
  date_range: [
    { label: "Between", value: "between" },
    { label: "Not Between", value: "not between" },
  ],
  list: [
    { label: "In", value: "in" },
    { label: "Not In", value: "not in" },
  ],
  parent: [{ label: "In", value: "in" }],
  child: [{ label: "In", value: "in" }],
};

const operatorLabelMap = {
  equals: "Equals",
  "not equals": "Not Equals",
  contains: "Contains",
  "not contains": "Not Contains",
  "starts with": "Starts With",
  between: "Between",
  "not between": "Not Between",
  in: "In",
  "not in": "Not In",
  exists: "Exists",
};

const templatesConfig = {
  book_demo: {
    content_meta: {
      title: "Unlock Your Sales Potential: Book a Demo Today!",
      sub_title:
        "Elevate Your Strategy with LeadPixie's Tailored Solutions. Book Your Personalized Demo Now and Transform Your Business Success.",
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
      pp_link: "",
      form_title: "Book a Demo",
    },
    form_meta: [
      {
        id: "493d59fd-abaa-43b4-b1f2-6d242e5d8468",
        label: "Name",
        field_type: "text",
        required: true,
        inputs: [],
      },
      {
        id: "e96b8657-86e6-436e-ace5-a659505fd104",
        label: "Phone",
        field_type: "phone",
        required: true,
        inputs: [],
      },
      {
        id: "964ac876-3e94-44a7-8f92-52d7df600823",
        label: "Email",
        field_type: "email",
        required: false,
        inputs: [],
      },
      {
        id: "1d2e46fa-b35e-4a83-8682-de9d0e6e517a",
        label: "Company",
        field_type: "text",
        required: true,
        inputs: [],
      },
      {
        id: "5d5ce767-bf8a-4df1-b0e4-c756df5b268e",
        label: "Company Size",
        field_type: "dropdown",
        required: true,
        inputs: [
          "1-5",
          "6-10",
          "11-25",
          "26-50",
          "51-100",
          "101-250",
          "251-499",
          "500+",
        ],
      },
    ],
  },
  get_quote: {
    content_meta: {
      title: "Get Your Customized Quote Today!",
      sub_title:
        "Experience personalized solutions designed to elevate your business strategy. Our experts are ready to craft tailored quotes to suit your specific requirements.",
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
      pp_link: "",

      form_title: "Want to get a quote for our service",
    },
    form_meta: [
      {
        id: "37c69905-3130-4bb0-9e47-b9ffb8eb6e0f",
        label: "Choose service",
        field_type: "dropdown",
        required: true,
        inputs: ["Service 1", "Service 2", "Service 3"],
      },
      {
        id: "3d771ca4-5683-4619-a978-d4c8f1cd73bc",
        label: "Name",
        field_type: "text",
        required: true,
        inputs: [],
      },
      {
        id: "b22f4dce-cf34-43ed-9b2d-41604ebcb3e0",
        label: "Phone",
        field_type: "phone",
        required: true,
        inputs: [],
      },
      {
        id: "bd2c69e4-e8fa-4d61-aeb1-9feded5b9256",
        label: "Email",
        field_type: "email",
        required: false,
        inputs: [],
      },
    ],
  },
  submit_information: {
    content_meta: {
      title: "Provide us with your information",
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
      pp_link: "",
    },
    form_meta: [
      {
        id: "af7a74c6-7f7c-46dd-b295-cbcc446d6357",
        label: "Name",
        field_type: "text",
        required: true,
        inputs: [],
      },
      {
        id: "e117823d-4b61-4b61-8272-b4e85840fb68",
        label: "Email",
        field_type: "email",
        required: false,
        inputs: [],
      },
      {
        id: "70568c6f-20fc-49e9-8285-dbfd9add0a4f",
        label: "Phone",
        field_type: "phone",
        required: true,
        inputs: [],
      },
    ],
  },
  event_registration: {
    content_meta: {
      title: "TechConnect 2024: The Future of Technology",
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
      pp_link: "",
      key_points: [
        {
          title: "Date and Time",
          sub_title: "",
        },
        {
          title: "Why Attend",
          sub_title:
            "Keynote speeches by industry pioneers on emerging technologies",
        },
        {
          title: "Registration Benefits",
          sub_title: "",
        },
        {
          title: "How to Register?",
          sub_title: "Fill out the Form to Register",
        },
      ],
      form_title: "Event Registration",
    },
    form_meta: [
      {
        id: "154afa74-6f8b-49a5-8ff9-d970eb75e2fd",
        label: "Name",
        field_type: "text",
        required: true,
        inputs: [],
      },
      {
        id: "74332bd6-8fbd-4ea2-8bce-c2d1a2170f56",
        label: "Email",
        field_type: "email",
        required: false,
        inputs: [],
      },
      {
        id: "9efd8ff6-9eca-46c3-abee-9f0842e57eec",
        label: "Phone",
        field_type: "phone",
        required: true,
        inputs: [],
      },
      {
        id: "76654ddd-e62c-4792-827f-b5bdec915075",
        label: "Topic",
        field_type: "dropdown",
        required: true,
        inputs: ["Topic 1", "Topic 2", "Topic 3"],
      },
      {
        id: "c8be2662-d19d-4a16-bab7-9cd8ef37cd19",
        label: "Preferred Slot",
        field_type: "dropdown",
        required: true,
        inputs: ["10AM to 11AM", "11AM to !2PM", "4PM to 5PM", "5PM to 6PM"],
      },
    ],
  },
  admission_form: {
    content_meta: {
      title: "Limitless Learning With New Infrastructure Experiences",
      sub_title:
        "FlexShaala is expanding to a 5 acre campus with new sports amenities. Experience a bigger, better campus",
      pp_link: "",
      form_title: "Admission Enquiry Form",
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
    },
    form_meta: [
      {
        id: "9618f9fc-adc6-4125-9f70-090ec0910b63",
        label: "Student Name",
        inputs: [],
        required: true,
        field_type: "text",
      },
      {
        id: "d8d0b28b-d11e-4131-b304-445c1505a712",
        label: "Parent Name",
        inputs: [],
        required: true,
        field_type: "text",
      },
      {
        id: "a27f0488-5803-4c58-8a15-7527ffe5903b",
        label: "Mobile",
        inputs: [],
        required: true,
        field_type: "phone",
      },
      {
        id: "931e92a3-543d-4250-b1de-c641f0f695c1",
        label: "Email Id",
        inputs: [],
        required: true,
        field_type: "email",
      },

      {
        id: "f28b0ba5-b6db-4bef-83c3-5ee9e512eb14",
        label: "Select Branch",
        inputs: ["Branch 1", "Branch 2", "Branch 3"],
        required: true,
        field_type: "dropdown",
      },
      {
        id: "d4496cf9-e60e-4387-a1dd-93dc4adeea9c",
        label: "Select Class",
        inputs: [
          "Nursery",
          "Jr. Kg",
          "Sr. Kg",
          "Grade I",
          "Grade II",
          "Grade III",
          "Grade IV",
          "Grade V",
          "Grade VI",
          "Grade VII",
          "Grade VIII",
          "Grade IX",
          "Grade X",
          "Grade XI",
          "Grade XII",
        ],
        required: true,
        field_type: "dropdown",
      },
    ],
  },
  test_ride_page: {
    content_meta: {
      pp_link: "",
      disclaimer:
        "I hereby authorize demo to send me notifications, updates, and other relevant communications via email, SMS, phone calls, or other electronic means.",
      form_title: `Take it for a spin, Book a test ride!`,
      footer_text: "Copyright© 2023 Test1 | Test2. All Rights Reserved | ",
      thankyou_message:
        "Thank you for sharing your details! Our representative will contact you shortly",
    },
    form_meta: [
      {
        id: "3b4a7bb8-eeed-4a14-a234-4ced070537ef",
        label: "City",
        inputs: [],
        required: true,
        field_type: "text",
      },
      {
        id: "459ac2b8-c118-4a41-8f8a-37606748a032",
        label: "Name",
        inputs: [],
        required: true,
        field_type: "text",
      },
      {
        id: "df4e1d85-d1f6-432f-a41e-4b52fb5784d7",
        label: "Email Address",
        inputs: [],
        required: true,
        field_type: "email",
      },
      {
        id: "e2194047-864b-4ef7-82d5-e3741e754b9e",
        label: "Pincode",
        inputs: [],
        required: true,
        field_type: "integer",
      },
      {
        id: "bdcb7c62-7235-47a3-8f5d-dc3ed98a222c",
        label: "Location",
        inputs: [],
        required: true,
        field_type: "text",
      },
      {
        id: "896a2350-5c67-4136-9054-5a617e145710",
        label: "Mobile",
        inputs: [],
        required: true,
        field_type: "phone",
      },
    ],
  },
};

const formFieldTypeList = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Integer",
    value: "integer",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Dropdown",
    value: "dropdown",
  },
];

const templateList = [
  { label: "Book a Demo", value: "book_demo", img: template_img1 },
  { label: "Get Quote", value: "get_quote", img: template_img2 },
  {
    label: "Contact Us",
    value: "submit_information",
    img: template_img3,
  },
  {
    label: "Event Registration",
    value: "event_registration",
    img: template_img4,
  },
  {
    label: "Admission Form",
    value: "admission_form",
    img: template_img5,
  },
  {
    label: "Test Ride Page",
    value: "test_ride_page",
    img: template_img6,
  },
];

const templateBgColors = {
  book_demo:
    "linear-gradient(90deg, rgba(252,52,83,1) 0%, rgba(252,95,78,1) 21%, rgba(252,191,69,1) 100%);",
  get_quote:
    "linear-gradient(90deg, rgba(252,52,83,1) 0%, rgba(78,252,241,1) 0%, rgba(182,69,252,1) 80%);",
  submit_information:
    "linear-gradient(90deg, rgba(252,52,83,1) 0%, rgba(78,248,252,1) 0%, rgba(252,71,69,1) 80%);",
  event_registration:
    " linear-gradient(90deg, rgba(252,52,83,1) 0%, rgba(252,78,234,1) 0%, rgba(252,191,69,1) 80%);",
};

const optionFieldsList = [
  "dropdown",
  "checkbox",
  "checkbox_with_other",
  "radio",
  "radio_with_other",
];

const systemFieldList = [
  "name",
  "email",
  "city",
  "state",
  "country",
  "phone",
  "language",
  "job_title",
  "company_name",
];

const leadPresetColorsWithLabels = [
  { color: "nil", label: "-" },
  { color: "#808080", label: "Not Sure" },
  { color: "#FFA500", label: "Confused" },
  {
    color: "#FFFF00",
    label: "Undecided",
  },
  { color: "#FFD700", label: "Needs More Convincing" },
  { color: "#0000FF", label: "Cold" },
  { color: "#90EE90", label: "Likely to Convert" },
  { color: "#008000", label: "Definitely Will Convert" },
  { color: "#FF0000", label: "Will Not Convert" },
];

const leadContextMenuItems = [
  {
    label: getLocalizedText("set_lead_color", "project"),
    value: "set_lead_color",
  },
  {
    label: "Add Call Log",
    value: "add_call_log",
  },
  {
    label: "Add Remarks",
    value: "add_remarks",
  },
];

const submissionScopeList = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 3 Days", value: "last 3 days" },
  { label: "All", value: "all" },
];
const nextFollowupRelativeDates = [
  "Today",
  "Tomorrow",
  "In 3 Days",
  "In 5 Days",
  "Next Week",
  "Next Month",
  "Custom",
];

const sortableFieldsListForLeads = [
  "city",
  "state",
  "lead_source",
  "lead_status",
  "lead_stage",
  "lead_created_at",
  "next_follow_up_on",
  "lead_assigned_on",
  "lead_owner",
  "last_contacted_on",
];
const integrationsConfigList = [
  {
    type: "facebook",
    title: "Facebook Lead Ads",
    desc: "Receive new leads from  Instagram and Facebook lead ads in your LeadPixie account",
    status: "",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "indiamart",
    title: "IndiaMart",
    desc: "Receive new leads from IndiaMart in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url:
      "https://help.indiamart.com/knowledge-base/lms-crm-integration-v2/",
  },
  {
    type: "aajjo",
    title: "Aajjo",
    desc: "Receive new leads from Aajjo in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "webhooks",
    title: "Webhooks",
    desc: "Receive new leads from Webhooks in your LeadPixie account",
    status: "connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "whatsapp",
    title: "WhatsApp",
    desc: "Receive new leads from WhatsApp in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "housing",
    title: "Housing",
    desc: "Receive new leads from Housing in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "99acres",
    title: "99 Acres",
    desc: "Receive new leads from 99 Acres in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "justdial",
    title: "JustDial",
    desc: "Receive new leads from JustDial in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "shiksha",
    title: "Shiksha",
    desc: "Receive new leads from Shiksha in your LeadPixie account",
    status: "not connected",
    is_enabled: true,
    help_link_url: "",
  },
  {
    type: "google_forms",
    title: "Google Forms",
    desc: "Receive new leads from Google Forms in your LeadPixie account",
    status: "not connected",
    is_enabled: false,
    help_link_url: "",
  },
];

const indiamartIntegrationConfigTypes = ["pull", "push"];

//used in Indiamart integration last dialog - webhook
const indiamartWebhookDescs = [
  "Copy the Webhook URL below.",
  "Go to your IndiaMART portal and paste it into the Webhook field under API settings.",
];

const indiamartIntegrationFields = [
  {
    key: "SENDER_NAME",
    label: "Sender Name",
  },
  {
    key: "SENDER_MOBILE",
    label: "Sender Mobile",
  },
  {
    key: "SENDER_EMAIL",
    label: "Sender Email",
  },
  {
    key: "SENDER_COMPANY",
    label: "Sender Company",
  },
  {
    key: "SENDER_ADDRESS",
    label: "Sender Address",
  },
  {
    key: "SENDER_CITY",
    label: "Sender City",
  },
  {
    key: "SENDER_STATE",
    label: "Sender State",
  },
  {
    key: "SENDER_PINCODE",
    label: "Sender Pincode",
  },
  {
    key: "SENDER_COUNTRY_ISO",
    label: "Sender Country ISO",
  },
  {
    key: "SENDER_MOBILE_ALT",
    label: "Sender Mobile Alt",
  },
  {
    key: "SENDER_EMAIL_ALT",
    label: "Sender Email Alt",
  },
  {
    key: "QUERY_PRODUCT_NAME",
    label: "Query Product Name",
  },
  {
    key: "QUERY_MESSAGE",
    label: "Query Message",
  },
  {
    key: "QUERY_MCAT_NAME",
    label: "Query MCAT Name",
  },
];

const aajjoIntegrationFields = [
  {
    key: "ContactPerson",
    label: "Contact Person",
  },
  {
    key: "Product",
    label: "Product",
  },
  {
    key: "EmailID",
    label: "Email ID",
  },
  {
    key: "PhoneNumber",
    label: "Phone Number",
  },
  {
    key: "City",
    label: "City",
  },
  {
    key: "StateName",
    label: "State Name",
  },
  {
    key: "LeadAddress",
    label: "Lead Address",
  },
  {
    key: "CountryName",
    label: "Country Name",
  },
  {
    key: "LeadDetails",
    label: "Lead Details",
  },
];

const ninetyNineAcresIntegrationFields = [
  {
    key: "Name",
    label: "Name",
  },
  {
    key: "Email",
    label: "Email",
  },
  {
    key: "Mobile",
    label: "Mobile",
  },
  {
    key: "MasterProjectID",
    label: "Master Project ID",
  },
  {
    key: "Project",
    label: "Project",
  },
];

const justdialIntegrationFields = [
  {
    key: "prefix",
    label: "Prefix",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "mobile",
    label: "Mobile",
  },
  {
    key: "phone",
    label: "Phone",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "category",
    label: "Category",
  },
  {
    key: "city",
    label: "City",
  },
  {
    key: "Area",
    label: "Area",
  },
  {
    key: "brancharea",
    label: "Branch Area",
  },
  {
    key: "dncmobile",
    label: "DNC Mobile",
  },
  {
    key: "dncphone",
    label: "DNC Phone",
  },
  {
    key: "company",
    label: "Company",
  },
  {
    key: "pincode",
    label: "Pincode",
  },
  {
    key: "time",
    label: "Time",
  },
  {
    key: "branchpin",
    label: "Branch Pin",
  },
  {
    key: "parentid",
    label: "Parent ID",
  },
];

const shikshaIntegrationFields = [
  {
    key: "First Name",
    label: "First Name",
  },
  {
    key: "Last Name",
    label: "Last Name",
  },
  {
    key: "Course Parent Institute",
    label: "Course Parent Institute",
  },
  {
    key: "Response to Course",
    label: "Response to Course",
  },
  {
    key: "City",
    label: "City",
  },
  {
    key: "Locality",
    label: "Locality",
  },
  {
    key: "Student State",
    label: "Student State",
  },
  {
    key: "Student City",
    label: "Student City",
  },
  {
    key: "Current Locality",
    label: "Current Locality",
  },
  {
    key: "Response Date",
    label: "Response Date",
  },
  {
    key: "Email",
    label: "Email",
  },
  {
    key: "Mobile",
    label: "Mobile",
  },
  {
    key: "Exams Taken",
    label: "Exams Taken",
  },
  {
    key: "Student Work Experience",
    label: "Student Work Experience",
  },
  {
    key: "Response to Course Program",
    label: "Response to Course Program",
  },
];

const housingIntegrationFields = [
  {
    key: "City",
    label: "City",
  },
  {
    key: "Locality",
    label: "Locality",
  },
  {
    key: "Lead Name",
    label: "Lead Name",
  },
  {
    key: "Lead Email",
    label: "Lead Email",
  },
  {
    key: "Project ID",
    label: "Project ID",
  },
  {
    key: "Project Name",
    label: "Project Name",
  },
  {
    key: "Service Type",
    label: "Service Type",
  },
  {
    key: "Property Type",
    label: "Property Type",
  },
  {
    key: "Lead Phone Number",
    label: "Lead Phone Number",
  },
];

const endTimeBufferList = [5, 10, 15, 30, 60];
const reportFilterRelativeDates = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "Last 7 Days", value: "Last 7 Days" },
  { label: "Last 14 Days", value: "Last 14 Days" },
  { label: "Last 30 Days", value: "Last 30 Days" },
  { label: "This Week", value: "This Week" },
  { label: "Last Week", value: "Last Week" },
  { label: "This Month", value: "This Month" },
  { label: "Last Month", value: "Last Month" },
  // { label: "Custom", value: "Custom" },
];

const attendanceFromDeviceList = ["Web", "Mobile", "Web or Mobile"];
const countryCodeList = ["+91", "+971"];

const taskStatusList = [
  "Completed",
  "Deferred",
  "Waiting For Input",
  "Open",
  "Closed",
];
const taskTypeList = [
  "Call",
  "Online Meeting",
  "Visit",
  "Product Demo",
  "Promotional Event",
  "SMS",
  "Email",
  "WhatsApp",
  "ToDo",
  "Personal",
  "Webinar",
  "Others",
];

const taskPriorityList = ["High", "Medium", "Low"];
const taskPageChipData = [
  { key: 0, label: "All" },
  { key: 1, label: "Today" },
];
const leadInteractionChannelList = ["SMS", "WhatsApp", "Emails", "Others"];

const leadFormQuestionTypeList = [
  {
    label: "Checkbox",
    value: "checkbox",
  },
  {
    label: "Checkbox with other",
    value: "checkbox_with_other",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Datetime",
    value: "datetime",
  },
  {
    label: "Dropdown",
    value: "dropdown",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Radio",
    value: "radio",
  },
  {
    label: "Radio with other",
    value: "radio_with_other",
  },
  {
    label: "String",
    value: "string",
  },
];

const quotationAdditionalColumns = [
  { column_name: "product_code", display_name: "HSN Code" },
];

const quotationTaxOptions = [
  {
    label: "No Tax",
    label_igst: "No Tax",
    label_cgst: "No Tax",
    value: -999.0,
  },
  {
    label: "0%",
    label_igst: "IGST0 [0%]",
    label_cgst: "GST0 [0%]",
    value: 0.0,
  },
  {
    label: "5%",
    label_igst: "IGST5 [5%]",
    label_cgst: "GST5 [5%]",
    value: 5.0,
  },
  {
    label: "12%",
    label_igst: "IGST12 [12%]",
    label_cgst: "GST12 [12%]",
    value: 12.0,
  },
  {
    label: "18%",
    label_igst: "IGST18 [18%]",
    label_cgst: "GST18 [18%]",
    value: 18.0,
  },
  {
    label: "28%",
    label_igst: "IGST28 [28%]",
    label_cgst: "GST28 [28%]",
    value: 28.0,
  },
];

const invoiceTaxOptions = [
  {
    label: "No Tax",
    label_igst: "IGST0 [0%]",
    label_cgst: "GST0 [0%]",
    value: -999.0,
  },
  {
    label: "0%",
    label_igst: "IGST0 [0%]",
    label_cgst: "GST0 [0%]",
    value: 0.0,
  },
  {
    label: "5%",
    label_igst: "IGST5 [5%]",
    label_cgst: "GST5 [5%]",
    value: 5.0,
  },
  {
    label: "12%",
    label_igst: "IGST12 [12%]",
    label_cgst: "GST12 [12%]",
    value: 12.0,
  },
  {
    label: "18%",
    label_igst: "IGST18 [18%]",
    label_cgst: "GST18 [18%]",
    value: 18.0,
  },
  {
    label: "28%",
    label_igst: "IGST28 [28%]",
    label_cgst: "GST28 [28%]",
    value: 28.0,
  },
];

//for create template sidepanel - company_country
const quotationCountriesList = ["India"];

const indianStatesList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Maharashtra",
  "Madhya Pradesh",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Telangana",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman & Nicobar",
  "Chandigarh",
  "Dadra & Nagar Haveli and Daman & Diu",
  "Delhi",
  "Jammu & Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

const birthdayPopupShowIntervals = [1, 2, 4, 8, 16, 32, 30];

const businessWhatsappProviders = ["Meta WABA"];
const businessWhatsappLanguages = [
  { value: "en", label: "English" },
  { value: "hi", label: "Hindi" },
  { value: "bn", label: "Bengali" },
  { value: "te", label: "Telugu" },
  { value: "mr", label: "Marathi" },
  { value: "ta", label: "Tamil" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "gu", label: "Gujarati" },
  { value: "pa", label: "Punjabi" },
];

const businessWhatsappTemplateCategories = ["MARKETING", "UTILITY"];

const businessWhatsappProviderData = [
  {
    name: "Meta WABA",
    provider_name: "Meta WABA",
    status: "Not Connected",
    disabled: false,
  },
  {
    name: "Carpus IT",
    provider_name: "Carpus",
    status: "Not Connected",
    disabled: true,
  },
  {
    name: "TextLocal",
    provider_name: "Textlocal",
    status: "Not Connected",
    disabled: true,
  },
  {
    name: "AiSensy",
    provider_name: "AiSensy",
    status: "Not Connected",
    disabled: true,
  },
];

const metaWabaHeaderOptions = ["None", "Text"];
const metaWabaButtonTypes = ["Call", "Visit"];

const quoteInvoiceListingFilters = [
  { label: "This Month", key: 0 },
  { label: "Last Month", key: 1 },
  { label: "All", key: 2 },
];
const dealsPageChipFilters = [
  { label: "This Month", value: "this_month", key: 0 },
  { label: "Last Month", value: "last_month", key: 1 },
  { label: "All", value: "all", key: 2 },
];
const quotationsPageFilters = [
  { label: "Quotations", key: 0 },
  { label: "Invoice", key: 1 },
];
const importsPageChipFilters = [
  { label: "leads", value: "leads", key: 0 },
  { label: "customers", value: "customers", key: 1 },
  { label: "products", value: "products", key: 2 },
];
const recordPaymentModes = [
  "Cash",
  "Cheque",
  "Bank transfer",
  "Credit/Debit card",
  "UPI",
  "Razorpay",
  "Other",
];

//used in create new campaign modal
const campaignSubTypes = [
  { option: "One-Time", disabled: false },
  { option: "Recurring", disabled: true },
];

const advancedSecurityOptions = [
  {
    title: "pin_to_location",
    desc: "Restrict user login to a particular latitude, longitude coordinate range",
    type: "sign_in_option",
  },
  {
    title: "ip_whitelisting",
    desc: "Whitelist an IP address",
    type: "sign_in_option",
  },
];

const defaultParamFields = [
  "lead_source",
  "lead_owner",
  "lead_status",
  "lead_stage",
  "team",
  "lead_creator",
];

const indiamartWebhookHelpURL =
  "https://help.indiamart.com/knowledge-base/integration-of-indiamarts-lead-manager-crm-push-api-with-third-party-crms-real-time-push-of-leads/";

const customerLeadsTabChipData = [
  { key: 0, label: "Open", value: "open" },
  { key: 1, label: "Won", value: "won" },
  { key: 2, label: "Lost", value: "lost" },
  { key: 3, label: "Closed", value: "closed" },
  { key: 4, label: "All", value: "all" },
];

const loginRestrictionOptions = [
  {
    label: "Allow Only Web - Single Login",
    value: "Allow Only Web - Single Login",
  },
  {
    label: "Allow Only Mobile - Single Login",
    value: "Allow Only Mobile - Single Login",
  },
  {
    label: "Allow One Web or One Mobile Login at a Time",
    value: "Allow One Web or One Mobile Login at a Time",
  },
];
const customerPageRequiredColumns = [
  "phone",
  "email",
  "city",
  "title",
  "owner",
  "created_at",
];

const productPageDefaultColumns = [
  "name",
  "product_type",
  "product_group",
  "category",
  "unit_price",
];

const OrganizationLeadsColumnHeaders = [
  {
    field: "lead_stage",
    headerName: "LEAD STAGE",
    flex: 1,
    width: 150,
    cellRenderer: (params) => {
      let value = dataRenderer(params.data.lead_stage);
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">{value}</span>
          </Tooltip>
        </>
      );
    },
  },
  {
    field: "lead_owner",
    headerName: "LEAD OWNER",
    flex: 1,
    width: 150,

    cellRenderer: (params) => {
      let value =
        params.data.lead_owner !== null ? params.data.lead_owner["name"] : "";
      return (
        <>
          <Tooltip title={value}>
            <span className="csutable-cell-trucate">
              {value !== "" ? value : "-"}
            </span>
          </Tooltip>
        </>
      );
    },
  },
];

const organizationDetailsColGroup = [
  {
    id: "0",
    organization_detail_tab_id: "0",
    editable: true,
    name: "Primary Information",
    sequence: 0,
  },
  {
    id: "1",
    organization_detail_tab_id: "0",
    editable: true,
    name: "Secondary Information",
    sequence: 1,
  },
  {
    id: "2",
    organization_detail_tab_id: "0",
    editable: false,
    name: "Other Information",
    sequence: 2,
  },
];

const organizationDetailsDetailTabs = [
  {
    id: 0,
    name: "Basic Details",
    sequence: 0,
  },
];

const organizationDetailTopWidgets = [
  { name: "Revenue", key: "revenue" },
  { name: "Contacts", key: "contacts_count" },
  { name: "Open Leads", key: "open_leads_count" },
];

const apiKeySettingWebhookDescriptions = {
  housing: [
    "Copy the Webhook URL below.",
    "Share the below Curl code with the housing.com team.",
  ],
  "99acres": [
    "Copy the Webhook URL below.",
    "Share the below Curl code with the 99acres team.",
  ],
  justdial: [
    "Copy the Webhook URL below.",
    "Share the below Curl code with the JustDial team.",
  ],
  shiksha: [
    "Copy the Webhook URL below.",
    "Share the below Curl code with the Shiksha team.",
  ],
};

const quoteCreateTypeSlugs = [
  { label: "Basic", value: "template_basic" },
  { label: "Area based", value: "template_area_based" },
];

const reportDownloadDateRanges = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "Last 7 days", value: "Last 7 Days" },
  { label: "Current Month", value: "Current Month" },
  { label: "Last Month", value: "Last Month" },
  { label: "Last 3 Months", value: "Last 3 Months" },
  { label: "Current Year", value: "Year" },
  { label: "Custom Date Range", value: "Custom Date Range" },
];

const productMediaList = [
  {
    type: "product_image",
    label: "Add Photo",
    icon: (
      <CameraAlt style={{ width: "60px", height: "60px", color: "#D3D3D3" }} />
    ),
    width: 2,
    font_size: "14px",
    allowed_file: "image/*",
  },
  {
    type: "product_video",
    label: "Add Video",
    icon: (
      <YouTube style={{ width: "24px", height: "24px", color: "#D3D3D3" }} />
    ),
    width: 1,
    font_size: "10px",
    allowed_file: "video/*",
  },
  {
    type: "product_brochure",
    label: "Add PDF",
    icon: (
      <PictureAsPdf
        style={{ width: "24px", height: "24px", color: "#D3D3D3" }}
      />
    ),
    width: 1,
    font_size: "10px",
    allowed_file: ".pdf",
  },
];

export {
  content,
  Auth,
  leadActivityColumns,
  callsColumns,
  businessContactColumns,
  notesContactColumns,
  LandingPageCardData,
  SubscriptionCardData,
  LandingPageCarouselData,
  FooterLinks,
  ImportHistoryColumns,
  TargetPageColumns,
  TargetDetailsColumns,
  leadFileColumns,
  MemberSettingColumns,
  ProjectSettingColumns,
  TeamSettingColumns,
  NewUSerSettingColumns,
  UserLoginHistoryColumns,
  AdminStatusColumns,
  AdminSourceColumns,
  AdminStageColumns,
  AdminStageHiddenColumns,
  AdminStatusToStageColumns,
  AdminViewColumns,
  AdminColumnsGroup,
  ReportListColumns,
  JobListColumns,
  SegmentListColumns,
  SupportSectionColumns,
  billingSectionColumns,
  CheckInColumns,
  whatsappPageColumnHeaders,
  adminWhatsappPageColumnHeaders,
  visitsTabColumnHeaders,
  daysList,
  monthsList,
  RawLeadsColumnHeaders,
  RawLeadsImportHistoryColumns,
  MoveRawLeadsResponseColumnHeaders,
  ruleList,
  createImportRuleList,
  updateImportRuleList,
  AutoDialerCampaignColumnHeaders,
  CampaignListColumnsHeaders,
  OzonetelDialerAgentListColumnHeaders,
  IpConfigColumnHeaders,
  RawLeadsImportHistoryTableColumns,
  RawLeadExportJobColumns,
  TableWidgetTestColumnHeader,
  ImportHistoryListingColumns,
  fieldBlockList,
  WebFormsColumnHeaders,
  ProjectAdminSmartViewColumnHeaders,
  ProjectAdminUnassignedTabViewColumnHeaders,
  productTypeList,
  dealsColumnHeaders,
  dealsPageColumnHeaders,
  quotesPageColumnHeaders,
  invoicesPageColumnHeaders,
  dealPriorityList,
  currencylist,
  ContactLeadsPageColumnHeaders,
  dealsTabProductsColumnHeaders,
  ProjectAdminViewGroupsColumnHeaders,
  CustomerLeadsColumnHeaders,
  filesTabColumnHeaders,
  predefinedDealsPageColumnHeaders,
  filesPageColumnHeaders,
  FolderFilesColumnHeaders,
  leadDealHistoryColumnHeaders,
  externalFormTypeList,
  filterOperatorMapList,
  operatorLabelMap,
  CustomerHistoryColumnHeaders,
  templatesConfig,
  formFieldTypeList,
  templateList,
  templateBgColors,
  optionFieldsList,
  ProjectAdminQualityColumnHeaders,
  qualityOperatorMapList,
  systemFieldList,
  leadQuoteListColumnHeaders,
  leadInvoiceListColumnHeaders,
  invoicePaymentColumns,
  adminQuationsPageColumnHeaders,
  leadPresetColorsWithLabels,
  leadContextMenuItems,
  submissionScopeList,
  nextFollowupRelativeDates,
  sortableFieldsListForLeads,
  customerActivityColumns,
  integrationsConfigList,
  indiamartIntegrationConfigTypes,
  indiamartWebhookDescs,
  indiamartIntegrationFields,
  aajjoIntegrationFields,
  housingIntegrationFields,
  ninetyNineAcresIntegrationFields,
  justdialIntegrationFields,
  shikshaIntegrationFields,
  adminProjectWorkdayColumnHeaders,
  daywiseWorkTimeColumnHeaders,
  endTimeBufferList,
  reportFilterRelativeDates,
  branchLocationsColumnHeaders,
  AttendanceSettingsPageColumns,
  attendanceFromDeviceList,
  AttendanceHistoryPageColumns,
  countryCodeList,
  tasksPageColumnHeaders,
  taskPriorityList,
  taskStatusList,
  taskTypeList,
  taskTypePageColumnHeaders,
  taskPageChipData,
  leadTaskTabColumnHeaders,
  leadInteractionChannelList,
  leadOtherInteractionTabColumnHeaders,
  leadFormQuestionTypeList,
  quotationAdditionalColumns,
  quotationTaxOptions,
  invoiceTaxOptions,
  indianStatesList,
  quotationCountriesList,
  birthdayPopupShowIntervals,
  reportFilterOperatorMapList,
  businessWhatsappProviders,
  businessWhatsappLanguages,
  businessWhatsappTemplateCategories,
  businessWhatsappProviderData,
  metaWabaHeaderOptions,
  metaWabaButtonTypes,
  quoteInvoiceListingFilters,
  recordPaymentModes,
  campaignSubTypes,
  advancedSecurityOptions,
  defaultParamFields,
  indiamartWebhookHelpURL,
  loginRestrictionOptions,
  customerLeadsTabChipData,
  contactOperatorMapList,
  customerPageRequiredColumns,
  OrganizationLeadsColumnHeaders,
  organizationDetailsColGroup,
  organizationDetailsDetailTabs,
  organizationDetailTopWidgets,
  apiKeySettingWebhookDescriptions,
  quoteCreateTypeSlugs,
  reportDownloadDateRanges,
  dealsPageChipFilters,
  quotationsPageFilters,
  productMediaList,
  productPageDefaultColumns,
  importsPageChipFilters,
  customerImportsColumnHeaders,
};
