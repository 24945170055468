import React from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Cancel, Close } from "@mui/icons-material";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  /* border-radius: 12px; */
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 40% opacity */
  z-index: 1;
`;

const ImageQuickViewComponent = ({ fileURL, handleRemove, handleClick }) => {
  return (
    <Container
      onClick={handleClick}
      sx={{
        "& .rpv-core__viewer.rpv-core__viewer--light": {
          position: "absolute",
        },
      }}
    >
      <Overlay />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleRemove();
        }}
        style={{
          color:"white",
          position: "absolute",
          top: "0px",
          right: "0px",
          padding: "2px",
          zIndex: 2, // Ensure the icon is above the overlay
        }}
      >
        <Close />
      </IconButton>

      <Image src={fileURL} style={{ zIndex: 0 }} />
    </Container>
  );
};

export default ImageQuickViewComponent;
