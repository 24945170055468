import {
  checkIfProjectHasWabaProvider,
  consoleLogger,
  hasAccessToWABAFeatures,
} from "../Functions";
import rootStore from "../stores/RootStore";
import notificationServiceInstance from "./notificationService";
import { makeObservable, observable } from "mobx";

class WabaChatNotificationService {
  webSocket = null;
  _state = {
    connected: false,
    connecting: false,
    establish_connection: false,
  };
  maxRetries = 0;
  curRetry = 0;
  retryDuration = 0;

  constructor() {
    makeObservable(this, {
      _state: observable,
    });
  }

  init = () => {
    this.curRetry = 0;
    this.maxRetries = 5;
    this.retryDuration = 5;
    //this.close();

    if (
      hasAccessToWABAFeatures() &&
      checkIfProjectHasWabaProvider(rootStore.authStore.currentProject.id)
    ) {
      consoleLogger("connecting waba websocket");
      this.connect();
    } else {
      return;
    }
  };

  getState() {
    return this._state;
  }

  connect = () => {
    if (this._state["connected"] || this._state["connecting"]) return;
    this._state["connecting"] = true;
    consoleLogger("WebSocket ::: connecting to web socket...");

    let userID = localStorage.getItem("uid");
    let token = localStorage.getItem("token");
    let device_id = localStorage.getItem("device_id");

    let url =
      process.env.REACT_APP_API_WS +
      `?X_USERID=${userID}&X_TOKEN=${token}&X_DEVICEID=${device_id}`;

    try {
      this.webSocket = new WebSocket(url);

      this.webSocket.addEventListener("open", (event) => {
        this._state["connecting"] = false;
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({ channel: "WabaChannel" }),
        };
        this.sendMessage(message);
      });

      this.webSocket.addEventListener("message", (event) => {
        this._state["connecting"] = false;
        this.onData(event.data);
      });
      this.webSocket.addEventListener("error", (event) => {
        consoleLogger("WebSocket ::: on error recieved -", event);
        this._state["connecting"] = false;
        // this.scheduleReconnect();
      });
      this.webSocket.addEventListener("close", (event) => {
        consoleLogger("WebSocket ::: on close recieved -", event);
        this._state["connecting"] = false;
        this.scheduleReconnect();
      });
    } catch (e) {
      consoleLogger(`WebSocket ::: connection failed: ${e}`);
      this._state["connecting"] = false;
      this.scheduleReconnect();
    }
  };

  onData(data) {
    if (this.curRetry !== 0) {
      this.curRetry = 0;
    }
    // console.log("WebSocket ::: message recieved -", data);
    const response = JSON.parse(data);
    if (response["type"] === "confirm_subscription") {
      this._state = {
        connected: true,
        connecting: false,
        establish_connection: false,
      };
      // testAPI();
    }
    if (response["type"] !== "ping") {
      notificationServiceInstance.sendMessageIn(data);
    }
  }

  scheduleReconnect() {
    consoleLogger(
      `WebSocket ::: scheduleReconnect ::: ${JSON.stringify(this._state)}`
    );

    if (
      !hasAccessToWABAFeatures() ||
      !checkIfProjectHasWabaProvider(rootStore.authStore.currentProject.id)
    ) {
      return;
    } else {
      this._state["connected"] = false;
      if (this.curRetry > 3) {
        this._state["establish_connection"] = true;
      }

      this.curRetry = this.curRetry + 1;

      setTimeout(() => {
        consoleLogger(
          `WebSocket ::: scheduleReconnect ::: retry ${this.curRetry}`
        );
        this.connect();
      }, this.retryDuration * 1000);
    }
  }

  close = (event) => {
    consoleLogger(`WebSocket ::: Closing WebSocket connection. ::: ${event}`);

    this.curRetry = 0;
    this._state = {
      connected: false,
      connecting: false,
      establish_connection: false,
    };

    if (this.webSocket !== null) {
      this.webSocket.close();
    }
  };

  _send(message) {
    this.webSocket.send(message);
  }

  sendMessage(msg) {
    consoleLogger("WebSocket ::: Sending WebSocket message:", msg);
    this._send(JSON.stringify(msg));
  }

  reset = () => {
    this.lastMessage = null;
    this.readyState = null;
    this.webSocket = null;
  };
}

const wabaChatNotificationService = new WabaChatNotificationService();
export default wabaChatNotificationService;
