import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 0.5px solid #195ed2;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageQuickViewWithoutActionComponent = ({ fileURL, handleFileClick }) => {
  return (
    <Container
      sx={{
        "& .rpv-core__viewer.rpv-core__viewer--light": {
          position: "absolute",
        },
      }}
      onClick={handleFileClick}
    >
      <Image src={fileURL} style={{ zIndex: 0 }} />
    </Container>
  );
};

export default ImageQuickViewWithoutActionComponent;
