import React from "react";
import { StyleSheet, View, Text, Font, Image } from "@react-pdf/renderer";
import email_icon from "../../../assets/campaign_icons/mail.png";
import web_icon from "../../../assets/campaign_icons/website.png";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    marginBottom: "0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
  },
  column: {
    flexDirection: "column",
    display: "flex",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    gap: "8px",
  },
  headerCell: {
    textAlign: "left",
    flex: 1,
    padding: "4px 8px",
  },
  textLg: {
    fontSize: 10,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  textSm: {
    fontSize: 6,
    fontWeight: "400",
    color: "black",
    fontFamily: "Poppins",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
  imageStyle: {
    width: "auto",
    height: "60px",
    objectFit: "contain",
  },
  icon: {
    width: 10, // Adjust the size of the icon
    height: 10,
    marginRight: 5, // Space between icon and text
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
  },
});
const FooterLayout = ({ data }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.textSm}>{data["company_name"]}</Text>

      <View style={styles.section}>
        <Image style={styles.icon} src={email_icon} />
        <Text style={styles.textSm}>{data["company_email"]}</Text>
      </View>
      <View style={styles.section}>
        <Image style={styles.icon} src={web_icon} />
        <Text style={styles.textSm}>{data["company_website"]}</Text>
      </View>
    </View>
  );
};

export default FooterLayout;
