import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Select,
  MenuItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import {
  consoleLogger,
  getLeadVariableValue,
  getLocalizedText,
} from "../../Functions";
import rootStore from "../../stores/RootStore";
import {
  sendAdminAccountWhatsAppBusinessApi,
  sendLeadWhatsAppBusinessChatApi,
  uploadLeadWhatsAppBusinessMediaApi,
} from "../../Api";
import { v4 as uuid } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { myTheme } from "../../themeUtils";
import WhatsappSendTemplateMessagePreview from "../whatsapp_business_chat_components/WhatsappSendTemplateMessagePreview";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "20px",
  width: "550px",
  height: "fit-content",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  overflow: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  height: "30vh",
  flexShrink: 0,
}));
const FieldsWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
  overflow: "auto",
  boxSizing: "border-box",
  padding: "8px",
}));
const TemplateLabel = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 10px Open Sans",
  color: "#4d4e4f",
  marginBottom: "8px",
}));
const TemplateInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
}));
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  width: "100%",
}));
const Label = styled(Typography)(({ theme }) => ({
  width: "25%",
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));
const Text = styled(Typography)(({ theme }) => ({
  margin: "0px",
  font: "normal normal 600 12px Open Sans",
  color: "#4d4e4f",
}));
const Text1 = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 14px Open Sans",
  color: "#4d4e4f",
  margin: 0,
  padding: "5px",
}));
const AlertMesage = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const Message = styled(Typography)(({ theme }) => ({
  margin: 0,
  font: "normal normal 600 10px Open Sans",
  marginBottom: "10px",
}));
const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px",
  flexDirection: "column",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function SendTemplateDialogComponent(props) {
  const [headerVariableField, setHeaderVariableField] = useState({});
  const [variableFields, setVariableFields] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaMissingWarning, setMediaMissingWarning] = useState(false);
  const [providerData, setProviderData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sendError, setSendError] = useState(false);

  const uploadWABAMedia = async (file) => {
    const formData = new FormData();
    formData.append("wp_id", providerData.id);
    formData.append("file", file);

    const response = await uploadLeadWhatsAppBusinessMediaApi({
      leadID: "",
      payload: formData,
    });

    if (!response.hasError()) {
      return response.data.media_id;
    } else {
      console.log(response.errorMessage);
      return "";
    }
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    //multi send logic by looping through lead ids and sending separate messages (using single media id if needed)
    let incoming_components = [];

    //handle media upload if it is there (disabled for now)
    // let mediaID = "";
    // let fileType = "";
    // if (selectedFile !== null) {
    //   //uploading medial once using the first lead id
    //   mediaID = await uploadWABAMedia(selectedFile[0]);
    //   fileType = selectedFile[0].type.split("/")[0]; // image or video
    //   incoming_components.push({
    //     type: "header",
    //     parameters: [{ type: fileType, [fileType]: { id: mediaID } }],
    //   });
    // }

    let bodyPayload = Object.values(details).map((text) => ({
      type: "text",
      text: text,
    }));

    const payload = {
      component_parameters: [
        ...incoming_components,
        {
          type: "body",
          parameters: bodyPayload,
        },
      ],
    };
    consoleLogger("payload:: ", payload);
    // send api
    const response = await sendAdminAccountWhatsAppBusinessApi({
      templateID: props.templateData.id,
      payload: payload,
    });

    if (!response.hasError()) {
      if (response.data.id === "") {
        setSendError(true);
      }
    } else {
      setSendError(true);
    }
    setIsSent(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const getComponentByType = (selectedTemplateData, type) => {
    const component = selectedTemplateData["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  const checkIfVariableHasComponentMapping = (varId, mapping) => {
    return mapping.hasOwnProperty(varId);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const providerResponse = [...rootStore.authStore.metaWabaProvidersList];
    const currentProjectID = rootStore.authStore.currentProject.id;
    let index = providerResponse.findIndex(
      (provider) => provider["project"]["id"] === currentProjectID
    );
    if (index !== -1) {
      setProviderData(providerResponse[index]);
    }
    const headerData = getComponentByType(props.templateData, "header");
    if (
      headerData !== null &&
      headerData.hasOwnProperty("format") &&
      headerData.format !== null
    ) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        setHeaderVariableField({
          name: headerData.format.toLowerCase(),
          id: -1,
        });
      }
    }
    let bodyData = [];

    if (props.templateData.components.length > 0) {
      bodyData = props.templateData["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let componentVariableMapping = {};
    if (props.templateData.hasOwnProperty("variables")) {
      componentVariableMapping = props.templateData["variables"];

      if (
        bodyData.hasOwnProperty("example") &&
        bodyData["example"].hasOwnProperty("body_text")
      ) {
        const varIds = bodyData["example"]["body_text"][0].map(
          (_, i) => `{{` + (i + 1) + `}}`
        );
        let varFields = [];
        for (let i = 0; i < varIds.length; i++) {
          if (
            !checkIfVariableHasComponentMapping(
              varIds[i],
              componentVariableMapping
            )
          ) {
            varFields.push({ name: varIds[i], id: i });
          }
        }
        setVariableFields(varFields);
      }
    } else {
      if (
        bodyData.hasOwnProperty("example") &&
        bodyData["example"].hasOwnProperty("body_text")
      ) {
        const varIds = bodyData["example"]["body_text"][0].map(
          (_, i) => `{{` + (i + 1) + `}}`
        );
        let varFields = [];
        for (let i = 0; i < varIds.length; i++) {
          varFields.push({ name: varIds[i], id: i });
        }
        setVariableFields(varFields);
      }
    }
  };

  const handlePhotoAdd = (event) => {
    setSelectedFile(event.target.files);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModelContainer>
        {!isSubmitted ? (
          <Container
            component={"form"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (Object.keys(headerVariableField).length !== 0) {
                if (selectedFile !== null) {
                  handleSubmit();
                  setMediaMissingWarning(false);
                } else {
                  setMediaMissingWarning(true);
                }
              } else {
                handleSubmit();
                setMediaMissingWarning(false);
              }
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar variant="dense" disableGutters sx={{ minHeight: "0px" }}>
                <Header>Send template</Header>
              </Toolbar>
              <Divider style={{ margin: "16px 0px" }} />
            </AppBar>
            <Wrapper>
              <Box sx={{ width: "100%", marginTop: "4px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PreviewWrapper>
                      <WhatsappSendTemplateMessagePreview
                        data={props.templateData["components"]}
                        variableDetails={details}
                        headerVariableField={headerVariableField}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        handlePhotoAdd={handlePhotoAdd}
                      />
                    </PreviewWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ marginBottom: "8px" }}>
                      {" "}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <legend
                            style={{
                              width: "auto",
                              marginBottom: "0px",
                              fontSize: "12px",
                            }}
                          >
                            Variables
                          </legend>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            overflow: "auto",
                            height: "fit-content",
                            maxHeight: "30vh",
                          }}
                        >
                          {variableFields.length > 0 ? (
                            variableFields.map((field) => (
                              <FieldsWrapper>
                                <InputWrapper key={field.id}>
                                  <Label>{field.name}</Label>
                                  <FormControl sx={{ width: "100%" }}>
                                    <OutlinedInput
                                      required
                                      name={`body_text_${field.id}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setDetails((prevDetails) => ({
                                          ...prevDetails,
                                          [field.id + 1]: value,
                                        }));
                                      }}
                                      value={details[field.id + 1] || ""}
                                      style={{
                                        height: "40px",
                                        width: "100%",
                                      }}
                                      inputProps={{
                                        "aria-label": "weight",
                                        style: { fontSize: "12px" },
                                      }}
                                    />
                                  </FormControl>
                                </InputWrapper>
                              </FieldsWrapper>
                            ))
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {getLocalizedText("no_variables_found")}
                            </Box>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Wrapper>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{ bottom: 0, width: "100%" }}
            >
              <Toolbar
                style={{
                  padding: "2px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  minHeight: "0px",
                }}
              >
                {mediaMissingWarning && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setMediaMissingWarning(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {getLocalizedText("please_add_media_before_sending")}
                  </ErrorAlert>
                )}

                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      type="button"
                      variant="contained"
                      color="inherit"
                      style={{
                        textTransform: "none",

                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      {getLocalizedText("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",

                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Container>
        ) : isSent ? (
          sendError ? (
            <Box>
              <MessageWrapper>
                <Message
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "red",
                  }}
                >
                  {getLocalizedText("some_error_occurred")}
                </Message>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  color="inherit"
                  style={{
                    width: "fit-content",

                    fontSize: "12px",
                    fontWeight: "bold",
                    margin: "auto",
                    textTransform: "none",
                  }}
                >
                  {getLocalizedText("close")}
                </Button>
              </MessageWrapper>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Message
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "green",
                }}
              >
                {getLocalizedText("sent_successfully")}
              </Message>
              <Button
                variant="outlined"
                onClick={() => {
                  props.setOpen(false);
                }}
                color="inherit"
                style={{
                  width: "fit-content",

                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "auto",
                  textTransform: "none",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <CircularProgress />
            {getLocalizedText("sending_message")}
          </Box>
        )}
      </ModelContainer>
    </Modal>
  );
}

export default SendTemplateDialogComponent;
