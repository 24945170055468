import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const QuotationAmountPickerDialog = ({
  open,
  setOpen,
  handleAmountSubmit,
  headerText,
  maxIndex,
  quotationDetails,
}) => {
  const [amount, setAmount] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAmountSubmit(parseInt(amount));
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (quotationDetails["amount"] !== null) {
      setAmount(quotationDetails["amount"]);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <ModelContainer>
        <Box component={"form"} onSubmit={handleSubmit}>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>{headerText}</Header>
                <SubHeader>Fill in the below details</SubHeader>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Container>
            <Box
              style={{
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Enter Amount*</Label>
                    <OutlinedInput
                      style={{ height: "30px" }}
                      inputProps={{
                        min: 0,
                        max: maxIndex,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      name="amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                      type={"number"}
                      placeholder={"Enter Value"}
                      required
                    />
                  </InputWrapper>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: "100%" }}
          >
            <Toolbar
              style={{
                padding: "16px",
                width: dialogWidth,
                position: "relative",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="inherit"
                    style={{
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default QuotationAmountPickerDialog;
