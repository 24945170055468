import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Select,
  MenuItem,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import WhatsappSendTemplateMessagePreview from "./WhatsappSendTemplateMessagePreview";
import {
  consoleLogger,
  getLeadVariableValue,
  getLocalizedText,
  getSystemVariableValue,
} from "../../Functions";
import rootStore from "../../stores/RootStore";
import {
  sendLeadWhatsAppBusinessChatApi,
  uploadLeadWhatsAppBusinessMediaApi,
} from "../../Api";
import { v4 as uuid } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { myTheme } from "../../themeUtils";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  border: "1px solid #c5d7f1",
  borderRadius: "8px",
  padding: "20px",
  width: "550px",
  height: "fit-content",
  maxHeight: "95vh",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  overflow: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  width: "100%",
  padding: "15px",
  position: "relative",
  backgroundColor: "#e5ddd5",
  boxSizing: "border-box",
  overflow: "auto",
  height: "30vh",
  flexShrink: 0,
}));
const FieldsWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  gap: "8px",
  overflow: "auto",
  boxSizing: "border-box",
  padding: "8px",
}));
const TemplateLabel = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 10px Open Sans",
  color: "#4d4e4f",
  marginBottom: "8px",
}));
const TemplateInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: "8px",
}));
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "100%",
  width: "100%",
}));
const Label = styled(Typography)(({ theme }) => ({
  width: "25%",
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));
const Text = styled(Typography)(({ theme }) => ({
  margin: "0px",
  font: "normal normal 600 12px Open Sans",
  color: "#4d4e4f",
}));
const Text1 = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 14px Open Sans",
  color: "#4d4e4f",
  margin: 0,
  padding: "5px",
}));
const AlertMesage = styled(Typography)(({ theme }) => ({
  font: "normal normal 400 16px Open Sans",
  color: "#4d4e4f",
}));
const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const Message = styled(Typography)(({ theme }) => ({
  margin: 0,
  font: "normal normal 600 10px Open Sans",
  marginBottom: "10px",
}));
const MessageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px",
  flexDirection: "column",
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "20px",
  },
}));

function MultiLeadTemplateSendComponent(props) {
  const classes = useStyles();
  const templateList = [...rootStore.authStore.metaWabaBusinessTemplateList];
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [headerVariableField, setHeaderVariableField] = useState({});
  const [variableDetails, setVariableDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [mediaMissingWarning, setMediaMissingWarning] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [providerData, setProviderData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const contactColumns = [...rootStore.contactStore.columnsList];

  const responseColumnHeader = [
    {
      field: "target",
      headerName: <Text>{"Lead ID"}</Text>,
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.target}>
              <span className="csutable-cell-trucate">
                {params.row.target !== null ? params.row.target : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: <Text>Status</Text>,
      sortable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.status}>
              <span className="csutable-cell-trucate">
                {params.row.status !== null ? params.row.status : "-"}
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleTemplateSelect = (template) => {
    const templateJSON = JSON.parse(JSON.stringify(template));
    setSelectedTemplate(templateJSON);
    consoleLogger("templ:: ", templateJSON);
    setupTemplate(templateJSON);
  };

  const uploadWABAMedia = async (file) => {
    const formData = new FormData();
    formData.append("wp_id", providerData.id);
    formData.append("file", file);

    const response = await uploadLeadWhatsAppBusinessMediaApi({
      leadID: props.selectedLeadsData[0].id,
      payload: formData,
    });

    if (!response.hasError()) {
      return response.data.media_id;
    } else {
      console.log(response.errorMessage);
      return "";
    }
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    let sendStatusData = [];
    //multi send logic by looping through lead ids and sending separate messages (using single media id if needed)
    let incoming_components = [];

    //handle media upload if it is there
    let mediaID = "";
    let fileType = "";
    if (selectedFile !== null) {
      //uploading medial once using the first lead id
      mediaID = await uploadWABAMedia(selectedFile[0]);
      fileType = selectedFile[0].type.split("/")[0]; // image or video
      incoming_components.push({
        type: "header",
        parameters: [{ type: fileType, [fileType]: { id: mediaID } }],
      });
    }

    //building body payload with template data for each lead (as they may have lead specific lead variables)
    for (const leadData of props.selectedLeadsData) {
      //prefilling lead variables first
      let prefilledVariableDetails = [...variableDetails];
      let variablesWithType = [];

      if (selectedTemplate.hasOwnProperty("variables")) {
        let componentVariableMapping = selectedTemplate["variables"];
        for (const key in componentVariableMapping) {
          const value = componentVariableMapping[key];

          const index = contactColumns.findIndex(
            (column) => column.id === value
          );
          if (index !== 1) {
            const mappingKey = key.replace(/[{}]/g, "");
            variablesWithType.push({
              variable_id: mappingKey,
              variable_name: contactColumns[index]["display_name"],
              variable_type: "lead_variable",
            });
          }
        }
        //prefill lead variable in payload
        const leadVariableValues = getLeadVariableValuesForMessage({
          variablesWithType: variablesWithType,
          leadData: leadData,
        });

        Object.keys(leadVariableValues).forEach((key) => {
          const index = prefilledVariableDetails.findIndex(
            (item) => item.id === parseInt(key)
          );
          if (index !== -1) {
            if (
              leadVariableValues[key] !== "" &&
              leadVariableValues[key] !== null
            ) {
              prefilledVariableDetails[index]["value"] =
                leadVariableValues[key];
            }
          }
        });
      }

      let bodyPayload = prefilledVariableDetails.map((detail) => ({
        type: "text",
        text: detail["value"],
      }));

      const payload = {
        wt_id: selectedTemplate.id,
        component_parameters: [
          ...incoming_components,
          {
            type: "body",
            parameters: bodyPayload,
          },
        ],
      };

      consoleLogger("payload:: ", payload);
      // send api called for each lead id
      const response = await sendLeadWhatsAppBusinessChatApi({
        leadID: leadData.id,
        payload: payload,
      });
      let leadSendStatus = {
        target: leadData.id,
        status: "failure",
      };

      if (!response.hasError()) {
        if (response.data.id === "") {
          leadSendStatus["status"] = "failure";
        } else {
          leadSendStatus["status"] = "success";
        }
      }
      sendStatusData.push(leadSendStatus);
    }
    setResponseData(sendStatusData);
    setIsSent(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  //used on handleSubmit to prefill contact column mapping from template
  const getLeadVariableValuesForMessage = ({ variablesWithType, leadData }) => {
    const columnsList = [...rootStore.userStore.AllColumnsList];

    let tempVariableValueMapping = {};
    variablesWithType.forEach((variableData, i) => {
      if (variableData["variable_type"] === "lead_variable") {
        let index = columnsList.findIndex(
          (column) => column["headerName"] === variableData["variable_name"]
        );
        if (index !== -1) {
          let variableValue = getLeadVariableValue({
            columnMeta: columnsList[index],
            leadData: leadData,
          });
          tempVariableValueMapping[variableData["variable_id"]] = variableValue;
        } else {
          tempVariableValueMapping[variableData["variable_id"]] = null;
        }
      } else {
        let variableValue = getSystemVariableValue({
          variable: variableData["variable_name"],
        });
        tempVariableValueMapping[variableData["variable_id"]] = variableValue;
      }
    });
    return tempVariableValueMapping;
  };

  const getComponentByType = (selectedTemplateData, type) => {
    const component = selectedTemplateData["components"].find(
      (x) => x.type.toLowerCase() === type
    );
    return component || null;
  };

  function extractPlaceholders(inputString) {
    const regex = /\{\{\d+\}\}/g;
    const matches = inputString.match(regex);
    return matches || [];
  }

  useEffect(() => {
    init();
  }, []);

  const setupTemplate = (selectedTemplateData) => {
    setHeaderVariableField({});
    setSelectedFile(null);

    //header var handling
    const headerData = getComponentByType(selectedTemplateData, "header");
    if (
      headerData !== null &&
      headerData.hasOwnProperty("format") &&
      headerData.format !== null
    ) {
      if (
        headerData.format.toLowerCase() === "image" ||
        headerData.format.toLowerCase() === "video"
      ) {
        setHeaderVariableField({
          name: headerData.format.toLowerCase(),
          id: -1,
        });
      }
    }
    let bodyData = [];
    if (selectedTemplateData.components.length > 0) {
      bodyData = selectedTemplateData["components"].find(
        (x) => x.type.toLowerCase() === "body"
      );
    }

    let componentVariableMapping = {};
    let variablesWithType = [];
    let allPlaceholders = extractPlaceholders(bodyData["text"]);
    let variableDetailsList = allPlaceholders.map((placeholder, i) => {
      return {
        name: placeholder,
        id: i + 1,
        is_visible: true,
        contact_column: false,
        value: "",
      };
    });

    if (selectedTemplateData.hasOwnProperty("variables")) {
      componentVariableMapping = selectedTemplateData["variables"];
      for (const key in componentVariableMapping) {
        const value = componentVariableMapping[key];

        const index = contactColumns.findIndex((column) => column.id === value);
        if (index !== 1) {
          const mappingKey = key.replace(/[{}]/g, "");
          variablesWithType.push({
            variable_id: mappingKey,
            variable_name: contactColumns[index]["display_name"],
            variable_type: "lead_variable",
          });
        }
      }

      //prefill lead variable in details

      variablesWithType.forEach((variable) => {
        const index = variableDetailsList.findIndex(
          (item) => item.id === parseInt(variable["variable_id"])
        );
        if (index !== -1) {
          variableDetailsList[index]["name"] = variable["variable_name"];
          variableDetailsList[index]["contact_column"] = true;
        }
      });
    }
    setVariableDetails(variableDetailsList);
  };

  const init = async () => {
    if (props.selectedLeadsData.length > 10) {
      return;
    }

    const providerResponse = [...rootStore.authStore.metaWabaProvidersList];
    const currentProjectID = rootStore.authStore.currentProject.id;
    let index = providerResponse.findIndex(
      (provider) => provider["project"]["id"] === currentProjectID
    );
    if (index !== -1) {
      setProviderData(providerResponse[index]);
    }

    //set templates - showing only approved templates
    let templates = [];

    for (let i = 0; i < templateList.length; i++) {
      if (
        templateList[i].project !== null &&
        templateList[i].project !== "" &&
        templateList[i].project.id === currentProjectID &&
        templateList[i]["status"] === "APPROVED"
      ) {
        templates.push(templateList[i]);
      }
    }
    setAvailableTemplates(templates);
    consoleLogger("templates:: ", templates);
  };

  const handlePhotoAdd = (event) => {
    setSelectedFile(event.target.files);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {props.selectedLeadsData.length <= 10 ? (
        <ModelContainer>
          {!isSubmitted ? (
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (Object.keys(headerVariableField).length !== 0) {
                  if (selectedFile !== null) {
                    handleSubmit();
                    setMediaMissingWarning(false);
                  } else {
                    setMediaMissingWarning(true);
                  }
                } else {
                  handleSubmit();
                  setMediaMissingWarning(false);
                }
              }}
            >
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                }}
              >
                <Toolbar
                  variant="dense"
                  disableGutters
                  sx={{ minHeight: "0px" }}
                >
                  <Header>Send template</Header>
                </Toolbar>
                <Divider style={{ margin: "16px 0px" }} />
              </AppBar>
              <Wrapper>
                <Box sx={{ width: "100%", marginTop: "4px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TemplateInputWrapper>
                        <TemplateLabel>Template</TemplateLabel>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="template"
                            displayEmpty
                            variant="outlined"
                            required
                            value={selectedTemplate?.id || ""}
                            onChange={(e) => {
                              const selectedId = e.target.value;
                              const selectedTemplateObj =
                                availableTemplates.find(
                                  (template) => template.id === selectedId
                                );
                              handleTemplateSelect(selectedTemplateObj);
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {availableTemplates.length > 0 ? (
                              availableTemplates.map((template) => (
                                <MenuItem key={template.id} value={template.id}>
                                  {template.template_title}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                {getLocalizedText("no_templates_found")}
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </TemplateInputWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <PreviewWrapper>
                        {selectedTemplate !== null ? (
                          <WhatsappSendTemplateMessagePreview
                            data={selectedTemplate["components"]}
                            variableDetails={variableDetails}
                            headerVariableField={headerVariableField}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            handlePhotoAdd={handlePhotoAdd}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              height: "250px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 0,
                            }}
                          >
                            <Text1>
                              {getLocalizedText("no_template_selected")}
                            </Text1>
                          </Box>
                        )}
                      </PreviewWrapper>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ marginBottom: "8px" }}>
                        {" "}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <legend
                              style={{
                                width: "auto",
                                marginBottom: "0px",
                                fontSize: "12px",
                              }}
                            >
                              Variables
                            </legend>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              overflow: "auto",
                              height: "fit-content",
                              maxHeight: "30vh",
                            }}
                          >
                            {variableDetails.length > 0 ? (
                              variableDetails.map(
                                (field) =>
                                  field.is_visible && (
                                    <FieldsWrapper>
                                      <InputWrapper key={field.id}>
                                        <Label>{field.name}</Label>
                                        <FormControl sx={{ width: "100%" }}>
                                          <OutlinedInput
                                            required
                                            name={`body_text_${field.id}`}
                                            onChange={(e) => {
                                              const { value } = e.target;
                                              let newDetails = [
                                                ...variableDetails,
                                              ];

                                              newDetails[field.id - 1][
                                                "value"
                                              ] = value;
                                              setVariableDetails(newDetails);
                                            }}
                                            disabled={
                                              variableDetails[field.id - 1][
                                                "contact_column"
                                              ]
                                            }
                                            value={
                                              variableDetails[field.id - 1][
                                                "value"
                                              ] || ""
                                            }
                                            style={{
                                              height: "40px",
                                              width: "100%",
                                            }}
                                            inputProps={{
                                              "aria-label": "weight",
                                              style: {
                                                fontSize: "12px",
                                              },
                                            }}
                                          />
                                        </FormControl>
                                      </InputWrapper>
                                    </FieldsWrapper>
                                  )
                              )
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {getLocalizedText("no_variables_found")}
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Wrapper>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "2px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    minHeight: "0px",
                  }}
                >
                  {mediaMissingWarning && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setMediaMissingWarning(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {getLocalizedText("please_add_media_before_sending")}
                    </ErrorAlert>
                  )}
                  {mediaMissingWarning && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setMediaMissingWarning(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {getLocalizedText("please_add_media_before_sending")}
                    </ErrorAlert>
                  )}

                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          handleClose();
                        }}
                        type="button"
                        variant="contained"
                        color="inherit"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Container>
          ) : isSent ? (
            responseData.length > 0 ? (
              <Box style={{ width: "100%" }}>
                <Column style={{ rowGap: "20px" }}>
                  <Header>{getLocalizedText("result")}</Header>
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "40vh",
                      overflowY: "auto",
                    }}
                  >
                    <DataGrid
                      getRowId={() => uuid() + Math.random()}
                      autoHeight={true}
                      rows={responseData}
                      columns={responseColumnHeader}
                      checkboxSelection={false}
                      style={{ cursor: "pointer" }}
                      hideFooter={true}
                      disableColumnMenu={true}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleClose();
                    }}
                    style={{
                      width: "fit-content",
                      ...myTheme.Button.btnLight,

                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </Column>
              </Box>
            ) : (
              <Box>
                <MessageWrapper>
                  <Message
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  >
                    {getLocalizedText("no_response_data_to_show")}
                  </Message>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      props.setOpen(false);
                    }}
                    color="inherit"
                    style={{
                      width: "fit-content",

                      fontSize: "12px",
                      fontWeight: "bold",
                      margin: "auto",
                      textTransform: "none",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </MessageWrapper>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <CircularProgress />
              {getLocalizedText("sending_messages")}
            </Box>
          )}
        </ModelContainer>
      ) : (
        <ModelContainer>
          <Box>
            <AlertMesage>
              {`Action Failed: You have selected more than the allowed limit of 10 ${getLocalizedText(
                "leads",
                "project"
              ).toLowerCase()}.`}
            </AlertMesage>
            <Box
              style={{
                padding: "0px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  width: "100px",

                  color: "white",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ModelContainer>
      )}
    </Modal>
  );
}

export default MultiLeadTemplateSendComponent;
