import React from "react";
import { StyleSheet, View, Font } from "@react-pdf/renderer";
// import Markdown from "markdown-to-jsx";
import Html from "react-pdf-html";
import { convertPxToPts } from "../../../Functions";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      fontWeight: 400,
      fontStyle: "normal", // Regular
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      fontWeight: 500,
      fontStyle: "normal", // Medium
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      fontWeight: 700,
      fontStyle: "normal", // Bold
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
      fontWeight: 300,
      fontStyle: "italic", // Italic
    },
  ],
});

const styles = StyleSheet.create({
  tableWrapper: {
    width: "100%",
  },
  tableRowWrapper: {
    width: "100%",
    display: "Flex",
    flexDirection: "row",
  },
});
const htmlStyleSheet = {
  // clear margins for all <p> tags
  strong: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  em: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: 300,
  },
  p: {
    fontFamily: "Poppins",
    margin: 0,
    fontSize: 9,
  },
  ul: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
  ol: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
};
const TableLayout = ({ tableData }) => {
  return (
    <View style={styles.tableWrapper} wrap={false}>
      {tableData.map((row, rowIndex) => (
        <View key={`row-${rowIndex}`} style={styles.tableRowWrapper}>
          {row.map((cell, cellIndex) => {
            const isBorderNone = cell.style.border === "none";

            const isFirstRow = rowIndex === 0;
            const isFirstCellInRow = cellIndex === 0;
            
            // Determine adjacent cells
            const topCell =
              rowIndex > 0 ? tableData[rowIndex - 1][cellIndex] : null;
            const leftCell = cellIndex > 0 ? row[cellIndex - 1] : null;
            const bottomCell =
              rowIndex < tableData.length - 1
                ? tableData[rowIndex + 1][cellIndex]
                : null;
            const rightCell =
              cellIndex < row.length - 1 ? row[cellIndex + 1] : null;

            // Set borders dynamically based on adjacent cells
            const borderTop =
              isBorderNone || (topCell && topCell.style.border === "none")
                ? "0px"
                : isFirstRow
                ? "1px solid black"
                : "none";
            const borderLeft =
              isBorderNone || (leftCell && leftCell.style.border === "none")
                ? "0px"
                : isFirstCellInRow
                ? "1px solid black"
                : "none";
            const borderBottom =
              isBorderNone || (bottomCell && bottomCell.style.border === "none")
                ? "0px"
                : "1px solid black";
            const borderRight =
              isBorderNone || (rightCell && rightCell.style.border === "none")
                ? "0px"
                : "1px solid black";

            return (
              <View
                key={cell.id}
                style={{
                  ...cell.style,
                  padding: "0px",
                  width: `${cell.style.width}%`,
                  backgroundColor: cell.style.backgroundColor,
                  boxSizing: "border-box",
                  borderTop,
                  borderLeft,
                  borderBottom,
                  borderRight,
                  paddingLeft: cell.content !== "" ? cell.style.padding : "0px",
                  paddingTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Html stylesheet={htmlStyleSheet}>
                  {cell.content !== ""
                    ? convertPxToPts(cell.content)
                    : "&nbsp;"}
                </Html>
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};
export default TableLayout;
