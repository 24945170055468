import {
  Box,
  Grid,
  OutlinedInput,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PreviewImageDialog from "../../../files_page_components/PreviewImageDialog";
import FilePickerCompoent from "../../../custom_file_picker_dialog/FilePickerCompoent";
import { A4_HEIGHT, A4_WIDTH } from "../../../../Config";

const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const RadioLabel = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 0px;
`;
const ImageComponentCard = ({ editableData, handleEditImage }) => {
  const [showImagePreview, setShowImagePreview] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleChange = (key, value) => {
    let tempObj = {
      image_url: editableData["content"]["image_url"],
      alignment: editableData["properties"]["alignment"],
      size:
        editableData["properties"]["size"] !== ""
          ? parseInt(editableData["properties"]["size"])
          : 0,
    };

    if (editableData["properties"].hasOwnProperty("aspect_ratio")) {
      tempObj["aspect_ratio"] = editableData["properties"]["aspect_ratio"];
    } else {
      tempObj["aspect_ratio"] = null;
    }

    if (
      editableData["properties"].hasOwnProperty("modWidth") &&
      editableData["properties"].hasOwnProperty("modHeight")
    ) {
      tempObj["modWidth"] = editableData["properties"]["modWidth"];
      tempObj["modHeight"] = editableData["properties"]["modHeight"];
    } else {
      tempObj["modWidth"] = null;
      tempObj["modHeight"] = null;
    }

    if (
      editableData["properties"].hasOwnProperty("orgWidth") &&
      editableData["properties"].hasOwnProperty("orgHeight")
    ) {
      tempObj["orgWidth"] = editableData["properties"]["orgWidth"];
      tempObj["orgHeight"] = editableData["properties"]["orgHeight"];
    } else {
      tempObj["orgWidth"] = null;
      tempObj["orgHeight"] = null;
    }

    // Check if the key is image_url, then calculate aspect ratio and dimensions
    if (key === "image_url" && value !== "") {
      getAspectRatio(value)
        .then(({ orgWidth, orgHeight, modWidth, modHeight }) => {
          tempObj[key] = value; // Update the image_url
          tempObj["aspect_ratio"] = orgWidth / orgHeight; // Update the aspect ratio
          tempObj["modWidth"] = modWidth; // Add scaled width
          tempObj["modHeight"] = modHeight; // Add scaled height
          tempObj["orgWidth"] = orgWidth;
          tempObj["orgHeight"] = orgHeight;
          tempObj["size"] = 100;
          handleEditImage(tempObj); // Call the function to handle the update
        })
        .catch((error) => {
          console.error("Error calculating aspect ratio:", error);
        });
    } else {
      // If the key is not image_url, just update the value
      if (key === "size") {
        tempObj[key] = parseInt(value);
      } else {
        tempObj[key] = value;
      }
      handleEditImage(tempObj);
    }
  };

  const getAspectRatio = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const imageWidth = img.width; // Original image width
        const imageHeight = img.height; // Original image height
        let ratio = imageHeight / imageWidth;
        let newWidth = A4_WIDTH;
        let newHeight = A4_WIDTH * ratio;

        if (newHeight > A4_HEIGHT) {
          ratio = imageWidth / imageHeight;
          newHeight = A4_HEIGHT;
          newWidth = A4_HEIGHT * ratio;
        }

        resolve({
          orgWidth: imageWidth,
          orgHeight: imageHeight,
          modWidth: Math.round(newWidth), // Scaled width
          modHeight: Math.round(newHeight), // Scaled height
        });
      };
      img.onerror = () => {
        reject("Error loading image");
      };
      img.src = imageUrl;
    });
  };

  return (
    <Wrapper component={"form"} onSubmit={handleSubmit}>
 
      <Box
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Select File</Label>

              <FilePickerCompoent
                handleFileUrl={(fileUrl) => {
                  handleChange("image_url", fileUrl);
                }}
                fileUrl={editableData["content"]["image_url"]}
                handleFileClick={() => {
                  setShowImagePreview(true);
                }}
                handleFileRemove={() => {
                  handleChange("image_url", "");
                }}
              />
            </InputWrapper>
          </Grid>

          <Grid item>
            <InputWrapper>
              <Label>Size</Label>
              <OutlinedInput
                disabled={editableData["content"]["image_url"] === ""}
                style={{ height: "30px", width: "200px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                name="size"
                onChange={(e) => {
                  handleChange("size", e.target.value);
                }}
                value={editableData["properties"]["size"]}
                type={"number"}
                placeholder={"Enter Value"}
                required
              />
            </InputWrapper>
          </Grid>
          <Grid item>
            <FormControl>
              <Label>Alignment</Label>
              <RadioGroup
                row
                value={editableData["properties"]["alignment"]}
                onChange={(e) => {
                  handleChange("alignment", e.target.value);
                }}
              >
                <FormControlLabel
                  disabled={editableData["content"]["image_url"] === ""}
                  value="left"
                  control={<Radio size="small" />}
                  label={<RadioLabel>Left</RadioLabel>}
                />
                <FormControlLabel
                  disabled={editableData["content"]["image_url"] === ""}
                  value="center"
                  control={<Radio size="small" />}
                  label={<RadioLabel>Center</RadioLabel>}
                />
                <FormControlLabel
                  disabled={editableData["content"]["image_url"] === ""}
                  value="right"
                  control={<Radio size="small" />}
                  label={<RadioLabel>Right</RadioLabel>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {showImagePreview && (
        <PreviewImageDialog
          open={showImagePreview}
          setOpen={setShowImagePreview}
          file={editableData["content"]["image_url"]}
          isCreate={false}
        />
      )}
    </Wrapper>
  );
};

export default ImageComponentCard;
