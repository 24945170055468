import React, { useEffect, useState } from "react";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { editContactApi, submitCallApi } from "../../Api";
import { myTheme } from "../../themeUtils";
import {
  canShowIndividualUpdateStage,
  consoleLogger,
  getAvailableStatusListByStage,
  getLocalizedText,
  isAfterCurrentTime,
  validateDisabledSubFeature,
} from "../../Functions";
import analytics from "../../Analytics";
import UpdateFieldsWidget from "../status_param_column_rendere/UpdateFieldsWidget";
import MuiAlert from "@mui/material/Alert";
import { Rectangle } from "@mui/icons-material";
import CustomLeadColorPickerDialog from "../color_picker_modal/CustomLeadColorPickerDialog";
import { AppBar, styled, Toolbar, Typography } from "@mui/material";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  ${`width: calc(50vw - 60px);`}
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;

  border: 1px solid #bfbfbf;
  font: normal normal 600 16px Open Sans;
  :disabled {
    color: #d3d3d3;
    font-weight: 300;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const SubmitMessage = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
  flex-direction: column;
  row-gap: 20px;
`;
const TextArea = styled("textarea")`
  width: 100%;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;

  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 600 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const BottomWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const NilColorBox = styled(Box)`
  position: relative;
  width: 25px;
  height: 20px;
  background-color: white;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 125%;
    height: 100%;
    border-top: 1px solid #bfbfbf;
    transform: rotate(37deg);
    transform-origin: top left;
  }
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const CallLogModal = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    notes: "",
    status: "",
    sub_status: "",
  });
  const [subStatusList, setSubStatusList] = useState([]);
  const [audioFile, setAudioFile] = useState();
  const columns = [...rootStore.userStore.AllColumnsList];
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateFieldList, setUpdateFieldList] = useState([]);
  const [updateFieldInputData, setUpdateFieldInputData] = useState({});
  const stageStatusList = [...rootStore.authStore.stageStatusList];
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [notesRequired, setNotesRequired] = useState(false);
  const [submitLogErrorMessage, setSubmitLogErrorMessage] = useState("");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState("");
  const [nextFollowupRelativeDate, setNextFollowupRelativeDate] = useState("");
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [selectedStage, setSelectedStage] = useState("");

  const handleClick = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setErrorMessage("");
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const isAllMandatoryFieldsFilled = () => {
    let filled_fields = { ...updateFieldInputData };
    for (let i = 0; i < updateFieldList.length; i++) {
      let item = updateFieldList[i];
      if (item["required"] === true) {
        if (filled_fields.hasOwnProperty(item["field"])) {
          if (
            filled_fields[item["field"]]["value"] === "" ||
            filled_fields[item["field"]]["value"] === null
          ) {
            setSubmitLogErrorMessage(
              `Please fill "${item["headerName"]}" field`
            );
            return false;
          }
        } else {
          setSubmitLogErrorMessage(`Please fill "${item["headerName"]}" field`);
          return false;
        }
      }
    }

    return true;
  };

  const isValidNextFollowupDatetime = () => {
    let tempDatetime = updateFieldInputData.hasOwnProperty("next_follow_up_on")
      ? updateFieldInputData["next_follow_up_on"]["value"]
      : null;
    if (tempDatetime !== null) {
      return isAfterCurrentTime(tempDatetime.toISOString());
    }
    return true;
  };

  const generateContactPayload = (data) => {
    let contactData = {};

    for (const key in data) {
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columns[index];
        if (columnData["contact_column"] !== null) {
          let fieldData = data[columnData["field"]];
          let value = fieldData["value"];
          if (value !== "" && value !== null) {
            if (
              fieldData["type"] === "datetime" ||
              fieldData["type"] === "date"
            ) {
              value = value.toISOString();
            } else if (fieldData["type"] === "integer") {
              value = parseInt(value);
            }
            contactData[columnData["contact_column"]] = value;
          }
        }
      }
    }
    return contactData;
  };

  const hasValidDateAndDateTime = (updateFieldInputData) => {
    for (const key in updateFieldInputData) {
      if (
        updateFieldInputData[key]["type"] === "datetime" ||
        updateFieldInputData[key]["type"] === "date"
      ) {
        let value = updateFieldInputData[key]["value"];
        if (value !== null) {
          try {
            if (!value.isValid()) {
              const index = columns.findIndex(
                (column) => column["field"] === key
              );

              if (index !== -1) {
                setSubmitLogErrorMessage(
                  `Invalid ${columns[index]["headerName"]}`
                );
                return false;
              }
            }
          } catch (error) {
            setSubmitLogErrorMessage("Some error occurred!\nError code:1020");
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    let formData = new FormData();

    /****************************Update Fields************************* */

    //error handling for invalid datetime field inputs
    if (hasValidDateAndDateTime(updateFieldInputData)) {
      let updateFieldsPayload =
        generateUpdateFieldsPayloadData(updateFieldInputData);
      if (color !== null && color !== "") {
        formData.append("color", color);
      }
      formData.append("update_fields", JSON.stringify(updateFieldsPayload));
      formData.append("updated_fields", JSON.stringify(updateFieldsPayload));
      if (updateFieldsPayload.hasOwnProperty("next_follow_up_on")) {
        formData.append(
          "next_followup_on",
          updateFieldsPayload["next_follow_up_on"]
        );
      }
      //***************************END*********************** */
      formData.append("notes", user.notes);
      user.hasOwnProperty("customer_notes") &&
        formData.append("customer_notes", user["customer_notes"]);

      formData.append("status", user.status);
      formData.append("sub_status", user.sub_status);
      if (canSendStageInPayload()) {
        formData.append("stage", selectedStage);
      }

      let dialerLogID = rootStore.phoneCallState.getDialerLogIdForLead(
        props.lid
      );
      if (dialerLogID !== "") {
        formData.append("dialer_log_id", dialerLogID);
      }

      audioFile !== undefined && formData.append("audio", audioFile);

      if (isAllMandatoryFieldsFilled()) {
        if (isValidNextFollowupDatetime()) {
          let contactPayload = generateContactPayload(updateFieldInputData);
          if (Object.keys(contactPayload).length > 0) {
            if (
              props.leadData["contact_id"] !== null &&
              props.leadData["contact_id"] !== ""
            ) {
              let response = await editContactApi({
                contactID: props.leadData["contact_id"],
                payload: contactPayload,
              });
              if (!response.hasError()) {
                await handleSubmitLogByDialer({
                  formData: formData,
                  dialerLogID: dialerLogID,
                });
              } else {
                setSubmitLogErrorMessage(response.errorMessage);
                setIsError(true);
              }
            }
          } else {
            await handleSubmitLogByDialer({
              formData: formData,
              dialerLogID: dialerLogID,
            });
          }
        } else {
          setSubmitLogErrorMessage("Invalid Next Followup On Time");
          setIsError(true);
        }
      } else {
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  const handleSubmitLogByDialer = async ({ formData, dialerLogID }) => {
    if (dialerLogID === "") {
      let callLogSubmitted = await sendCallLog({ formData: formData });
      if (callLogSubmitted) {
        setIsError(false);
        setIsFormSubmit(true);
      } else {
        setSubmitLogErrorMessage(getLocalizedText("some_error_occured"));
        setIsError(true);
      }
    } else {
      let dialer = rootStore.authStore.currentProject["external_dialer"];
      if (dialer === "teckinfo") {
        await teckInfoSubmitLog({
          formData: formData,
          dialerLogID: dialerLogID,
        });
      } else {
        await dailerSubmitLog({
          formData: formData,
          dialerLogID: dialerLogID,
        });
      }
    }
  };

  const teckInfoSubmitLog = async ({ formData, dialerLogID }) => {
    let callLogSubmitted = await sendCallLog({ formData: formData });
    if (callLogSubmitted) {
      rootStore.phoneCallState.postSubmitCallLog();
      let response = await setDisposition({
        dialerLogID: dialerLogID,
      });
      if (response.hasError()) {
        document.getElementById("call-log-btn").innerText = "Submit Details";
        if (response.data !== null && response.data.hasOwnProperty("details")) {
          let message =
            "Error submitting call log; " + response.data["details"];
          setErrorMessage(message);
        } else {
          let message =
            "teckInfoSubmitLog ::: Error submitting call log; Please contact the administrator.";
          setErrorMessage(message);
        }
      } else {
        setIsError(false);
        setIsFormSubmit(true);
      }
    } else {
      setSubmitLogErrorMessage(getLocalizedText("some_error_occured"));
      setIsError(true);
    }
  };
  const dailerSubmitLog = async ({ formData, dialerLogID }) => {
    let response = await setDisposition({
      dialerLogID: dialerLogID,
    });
    if (response.hasError()) {
      document.getElementById("call-log-btn").innerText = "Submit Details";
      if (response.data !== null && response.data.hasOwnProperty("details")) {
        let message = "Error submitting call log; " + response.data["details"];
        setErrorMessage(message);
      } else {
        let message =
          "dailerSubmitLog ::: Error submitting call log; Please contact the administrator.";
        setErrorMessage(message);
      }
    } else {
      console.log(
        "dailerSubmitLog::: post submit calllog ::: submit call log dialog"
      );
      rootStore.phoneCallState.postSubmitCallLog();
      let callLogSubmitted = await sendCallLog({ formData: formData });
      if (callLogSubmitted) {
        setIsError(false);
        setIsFormSubmit(true);
      } else {
        setSubmitLogErrorMessage(getLocalizedText("some_error_occured"));
        setIsError(true);
      }
    }
  };

  const setDisposition = async ({ dialerLogID }) => {
    let dispositionData = {
      dialer_log_id: dialerLogID,
      lead_status: user.status,
    };
    let response = await rootStore.phoneCallState.setCallDisposition(
      props.lid,
      dispositionData
    );
    console.log(
      `call log disposition ::: ${response.status} ::: ${JSON.stringify(
        response.data
      )}`
    );
    return response;
  };

  const sendCallLog = async ({ formData }) => {
    try {
      let response = await submitCallApi({ id: props.lid, data: formData });
      if (props.scope === "call_log_tab") {
        rootStore.authStore.addCallLog(response.data);
        props.setRefreshCallLogAfterAdd(true);
      }
      if (props.scope !== "app_bar") {
        props.setRefreshLeadDetails(true);
      }
      if (formData.has("next_followup_on")) {
        rootStore.hotLeadState.checkAndFetchHotLeadOnFollowupChange(
          props.lid,
          formData.get("next_followup_on"),
          props.leadData
        );
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleAudio = (e) => {
    setAudioFile(e.target.files[0]);
    consoleLogger(e.target.files[0]);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000064,
      "submit_call_log_form_load",
      "call_logs_tab",
      "",
      {}
    );
    if (
      props.leadData.hasOwnProperty("customer_notes") &&
      props.leadData["customer_notes"] !== null
    ) {
      setUser({ ...user, customer_notes: props.leadData["customer_notes"] });
    }

    if (props.leadData.hasOwnProperty("color")) {
      if (props.leadData["color"] !== null && props.leadData["color"] !== "") {
        setColor(props.leadData["color"]);
      }
    }
    let leadStage =
      props.leadData.hasOwnProperty("lead_stage") &&
      props.leadData["lead_stage"] !== null
        ? props.leadData["lead_stage"]
        : "";

    let tempStatusList = getAvailableStatusListByStage({
      currentStage: leadStage,
      stageList: stagesList,
      statusList: statusList,
    });
    setAvailableStatuses(tempStatusList);
    setSelectedStage(leadStage);
  }, []);

  const getUpdateFieldsInStageStatus = (statusName) => {
    let tempList = [];
    let index = stageStatusList.findIndex(
      (item) =>
        item["lead_status"]["status"] === statusName &&
        item["lead_stage"]["stage"] === selectedStage
    );
    if (index !== -1) {
      if (
        stageStatusList[index].hasOwnProperty("update_fields") &&
        stageStatusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = stageStatusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  const getUpdateFieldsInStatus = (statusName) => {
    let tempList = [];
    let index = statusList.findIndex(
      (status) => status["status"] === statusName
    );
    if (index !== -1) {
      if (
        statusList[index].hasOwnProperty("update_fields") &&
        statusList[index]["update_fields"] !== null
      ) {
        let updateFieldsData = statusList[index]["update_fields"];
        updateFieldsData.forEach((data) => {
          let index = columns.findIndex(
            (column) => column["id"] === data["column_meta"]["id"]
          );
          if (index !== -1) {
            let columnData = { ...columns[index] };
            columnData["required"] = data["required"];
            columnData["_overwrite"] = data["overwrite"];
            columnData["_append"] = data.hasOwnProperty("append")
              ? data["append"]
              : false;
            tempList.push(columnData);
          }
        });
      }
    }
    return tempList;
  };

  //**********************functions related to update_fields changes*************************** */
  const initUpdateFieldList = (statusName) => {
    let updateFieldsInStageSTatus = getUpdateFieldsInStageStatus(statusName);
    if (updateFieldsInStageSTatus.length > 0) {
      setUpdateFieldList(updateFieldsInStageSTatus);
    } else {
      let updateFieldsInStatus = getUpdateFieldsInStatus(statusName);
      setUpdateFieldList(updateFieldsInStatus);
    }
  };

  const generateUpdateFieldsPayloadData = (data) => {
    let normalList = {};
    let customList = {};
    for (const key in data) {
      let value = data[key]["value"];
      let index = columns.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        if (value !== "" && value !== null) {
          if (
            data[key]["type"] === "datetime" ||
            data[key]["type"] === "date"
          ) {
            value = value.toISOString();
          } else if (data[key]["type"] === "integer") {
            value = parseInt(value);
          }
          if (columns[index]["is_custom_param"]) {
            let newKey = key.replace("custom_params_", "");
            customList[newKey] = value;
          } else {
            normalList[key] = value;
          }
        }
      }
    }
    if (Object.keys(customList).length > 0) {
      normalList["custom_params"] = customList;
    }

    return normalList;
  };

  const canSendStageInPayload = () => {
    if (canShowIndividualUpdateStage() === true) {
      let existingLeadStage =
        props.leadData.hasOwnProperty("lead_stage") &&
        props.leadData["lead_stage"] !== null
          ? props.leadData["lead_stage"]
          : "";
      if (selectedStage.toLowerCase() !== existingLeadStage.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <Modal
        disableEnforceFocus
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isFormSubmit ? (
          <ModelContainer>
            <Container onSubmit={handleSubmit} component={"form"}>
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>{getLocalizedText("submit_call_log")}</Header>
                    <SubHeader>
                      {getLocalizedText("fill_in_the_below_details")}
                    </SubHeader>
                  </HeaderWrapper>
                </Toolbar>
              </AppBar>

              <Divider style={{ margin: "16px", marginTop: "0px" }} />

              <Box
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "400px",
                  padding: "0px 16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    {canShowIndividualUpdateStage() && (
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>{getLocalizedText("stage")}*</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <Select
                              classes={{
                                icon: classes.icon,
                              }}
                              name="stage"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              value={selectedStage}
                              onChange={(e) => {
                                setNextFollowupRelativeDate("");
                                setUpdateFieldList([]);
                                setUpdateFieldInputData({});
                                setUser({
                                  ...user,
                                  status: "",
                                  sub_status: "",
                                });
                                let index = stagesList.findIndex(
                                  (entry) => entry["stage"] === e.target.value
                                );
                                if (index !== -1) {
                                  let stageData = stagesList[index];
                                  let tempStatusList =
                                    getAvailableStatusListByStage({
                                      currentStage: stageData["stage"],
                                      stageList: stagesList,
                                      statusList: statusList,
                                    });
                                  setAvailableStatuses(tempStatusList);
                                  setSubStatusList([]);
                                  setSelectedStage(stageData["stage"]);
                                } else {
                                  setAvailableStatuses([]);
                                  setSubStatusList([]);
                                  setSelectedStage("");
                                }
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {stagesList.map((entry) => {
                                return (
                                  <MenuItem id={entry.id} value={entry.stage}>
                                    {entry.stage}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </InputWrapper>
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("status")}*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="status"
                            displayEmpty
                            disableUnderline
                            variant="outlined"
                            required
                            value={user.status}
                            onChange={(e) => {
                              setNextFollowupRelativeDate("");
                              setUpdateFieldList([]);
                              setUpdateFieldInputData({});
                              availableStatuses.map((item) => {
                                if (e.target.value === item.status) {
                                  let newList = item.sub_status;
                                  setSubStatusList(newList);
                                  setNotesRequired(item.notes_mandatory);
                                  initUpdateFieldList(e.target.value);

                                  setErrorMessage("");
                                  if (newList.length > 0) {
                                    if (newList.includes("None")) {
                                      setUser({
                                        ...user,
                                        status: e.target.value,
                                        sub_status: "None",
                                      });
                                    } else {
                                      setUser({
                                        ...user,
                                        status: e.target.value,
                                        sub_status: newList[0],
                                      });
                                    }
                                  }
                                }
                              });
                            }}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {availableStatuses.map((e) => {
                              if (e["is_not_set"] === false) {
                                return (
                                  <MenuItem id={e.id} value={e.status}>
                                    {e.status}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>

                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>{getLocalizedText("sub_status")}*</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          classes={{
                            root: classes.quantityRoot,
                          }}
                        >
                          <Select
                            classes={{
                              icon: classes.icon,
                            }}
                            name="sub_status"
                            displayEmpty
                            variant="outlined"
                            required
                            value={user.sub_status}
                            onChange={handleChange}
                            style={{
                              width: "100%",
                              height: "30px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              borderRadius: "0px",
                            }}
                          >
                            {subStatusList.length > 0 &&
                              subStatusList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e}>
                                    {e}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </InputWrapper>
                    </Grid>

                    <Grid item xs={12}>
                      <InputWrapper>
                        <Label>
                          {notesRequired
                            ? `${getLocalizedText("call_notes")}*`
                            : getLocalizedText("call_notes")}
                        </Label>
                        <TextArea
                          rows={5}
                          required={notesRequired}
                          type="text"
                          placeholder="Enter note here..."
                          name="notes"
                          onChange={handleChange}
                        />
                      </InputWrapper>
                    </Grid>

                    {validateDisabledSubFeature("customer_notes") && (
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>
                            {`${getLocalizedText("customer_notes")}*`}
                          </Label>
                          <TextArea
                            rows={5}
                            required
                            value={
                              user.hasOwnProperty("customer_notes")
                                ? user["customer_notes"]
                                : ""
                            }
                            type="text"
                            placeholder="Enter note here..."
                            name="customer_notes"
                            onChange={handleChange}
                          />
                        </InputWrapper>
                      </Grid>
                    )}

                    {validateDisabledSubFeature("call_audio_upload") && (
                      <Grid item xs={6}>
                        <InputWrapper>
                          <Label>{getLocalizedText("add_audio_file")}</Label>
                          <Input
                            accept=".mp3,audio/*"
                            type="file"
                            onChange={handleAudio}
                            style={{
                              width: "428px",
                              fontSize: "12px",
                              padding: "2px",
                              backgroundColor: "#EFEFF4",
                              border: "none",
                              borderRadius: "6px",
                            }}
                          />
                        </InputWrapper>
                      </Grid>
                    )}
                  </Grid>

                  {updateFieldList.length > 0 && (
                    <UpdateFieldsWidget
                      value={updateFieldInputData}
                      setValue={setUpdateFieldInputData}
                      fieldList={updateFieldList}
                      nextFollowupRelativeDate={nextFollowupRelativeDate}
                      setNextFollowupRelativeDate={setNextFollowupRelativeDate}
                      leadData={props.leadData}
                    />
                  )}
                </Grid>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MessageWrapper>
                    <Message style={{ color: "red" }}>{errorMessage}</Message>
                  </MessageWrapper>
                </Grid>
              </Grid>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: "100%",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  {isError && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsError(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {submitLogErrorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container>
                    <Grid item xs={12}>
                      <BottomWrapper>
                        {color === "" || color === "nil" ? (
                          <NilColorBox
                            onClick={() => {
                              setOpenColorPicker(true);
                            }}
                          />
                        ) : (
                          <Rectangle
                            style={{
                              color: color,
                              cursor: "pointer",
                              width: "30px",
                              height: "auto",
                            }}
                            onClick={() => {
                              setOpenColorPicker(true);
                            }}
                          />
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "16px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000065,
                                "cancel_button_tap",
                                "submit_call_log_form",
                                "cancel_button",
                                {}
                              );
                              handleClick();
                            }}
                            type="button"
                            variant="contained"
                            color="default"
                            style={{
                              backgroundColor: "#EFEFF4",
                              textTransform: "none",

                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {getLocalizedText("cancel")}
                          </Button>
                          <Button
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000066,
                                "submit_button_tap",
                                "submit_call_log_form",
                                "submit_button",
                                {}
                              );
                            }}
                            type="submit"
                            variant="contained"
                            id="call-log-btn"
                            style={{
                              textTransform: "none",
                              fontSize: "12px",
                              fontWeight: "bold",
                              ...myTheme.Button.btnBlue,
                            }}
                          >
                            {getLocalizedText("submit_details")}
                          </Button>
                        </Box>
                      </BottomWrapper>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Container>
          </ModelContainer>
        ) : (
          <ModelContainer
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <MessageWrapper style={{ marginBottom: "0px" }}>
              <SubmitMessage>Call log added successfully!</SubmitMessage>
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="default"
                style={{
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                {getLocalizedText("close")}
              </Button>
            </MessageWrapper>
          </ModelContainer>
        )}
      </Modal>
      {openColorPicker && (
        <CustomLeadColorPickerDialog
          value={color}
          open={openColorPicker}
          setOpen={setOpenColorPicker}
          handleApply={(value) => {
            setColor(value);
            setOpenColorPicker(false);
          }}
        />
      )}
    </>
  );
};

export default observer(CallLogModal);
