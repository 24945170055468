import { Grid } from "@material-ui/core";
import { Box, OutlinedInput, styled, Typography } from "@mui/material";
import React from "react";
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Input = styled("input")`
  width: 100%;
  height: 30px;
  outline: none;
  color: #4d4e4f;
  border: none;
  border-radius: 6px;
  padding: 7px;

  background-color: #efeff4;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ImportFileScreen = ({
  handleFileUpload,
  file,
  showFileErrorMessage,
  fileErrorMessage,
  fileName,
  handleFileName,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputWrapper>
          <Label style={{ fontSize: "12px" }}>Pick a File</Label>
          <Input
            type="file"
            onClick={(e) => (e.target.value = null)}
            accept={".csv"}
            onChange={(event) => handleFileUpload(event)}
          />
          {showFileErrorMessage && (
            <Label style={{ color: "red" }}>{fileErrorMessage}</Label>
          )}
          <Label style={{ marginTop: "4px" }}>
            Selected File: <strong>{file ? file.name : "-"}</strong>
          </Label>
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <Label
            style={{ fontSize: "12px", color: file ? "#4d4e4f" : "lightgray" }}
          >
            Name
          </Label>
          <OutlinedInput
            disabled={file === null}
            type="text"
            placeholder="Name"
            onChange={(event) => handleFileName(event.target.value)}
            name="file_name"
            value={fileName}
            style={{ height: "30px", overflow: "hidden" }}
            id="outlined-adornment-weight"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
              style: {
                padding: "0px 12px",
                fontSize: "12px",
                overflow: "hidden",
              },
            }}
          />
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default ImportFileScreen;
