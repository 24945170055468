import { AddBoxOutlined } from "@material-ui/icons";
import { Box, Chip, IconButton, OutlinedInput, styled } from "@mui/material";
import React, { useState } from "react";
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;
const ChipWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  padding: 4px;
`;
const MutliValueAddField = ({ inputHeight = "30px", value, handleChange }) => {
  const [fieldValue, setFieldValue] = useState("");
  return (
    <Wrapper>
      <InputWrapper>
        <OutlinedInput
          style={{
            height: inputHeight,
            width: "100%",
          }}
          inputProps={{
            min: 0,
            "aria-label": "weight",
            style: {
              fontSize: "12px",
            },
          }}
          value={fieldValue}
          type={"text"}
          placeholder={`Enter Value`}
          onChange={(event) => {
            setFieldValue(event.target.value);
          }}
        />
        <IconButton
          style={{ padding: "0px" }}
          color="primary"
          disabled={fieldValue === ""}
          onClick={() => {
            let newList = [...value];
            let index = newList.findIndex((entry) => entry === fieldValue);
            if (index === -1) {
              newList.push(fieldValue);
              handleChange(newList);
            }
            setFieldValue("");
          }}
        >
          <AddBoxOutlined style={{ width: inputHeight, height: inputHeight }} />
        </IconButton>
      </InputWrapper>
      {value.length > 0 && (
        <ChipWrapper>
          {value.map((entry) => {
            return (
              <Chip
                label={entry}
                onDelete={() => {
                  let newList = [...value];
                  newList = newList.filter((str) => str !== entry);
                  handleChange(newList);
                }}
              />
            );
          })}
        </ChipWrapper>
      )}
    </Wrapper>
  );
};

export default MutliValueAddField;
