import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import FilePickerCompoent from "../../../custom_file_picker_dialog/FilePickerCompoent";
import PreviewImageDialog from "../../../files_page_components/PreviewImageDialog";

const Wrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #eceef2;
  padding: 16px;
  border-radius: 4px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const HeaderPropertyCard = ({ companyDetails, handleCompanyDetails }) => {
  const [showImagePreview, setShowImagePreview] = useState(false);

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid container spacing={2} item>
          <Grid item xs={4}>
            <InputWrapper>
              <Label>Company Name</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                name="company_name"
                onChange={(e) => {
                  handleCompanyDetails("company_name", e.target.value);
                }}
                value={companyDetails["company_name"]}
                type={"text"}
                placeholder={"Enter Value"}
                required
              />
            </InputWrapper>
          </Grid>

          <Grid item xs={4}>
            <InputWrapper>
              <Label>Company Email</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                name="company_email"
                onChange={(e) => {
                  handleCompanyDetails("company_email", e.target.value);
                }}
                value={companyDetails["company_email"]}
                type={"text"}
                placeholder={"Enter Value"}
                required
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={4}>
            <InputWrapper>
              <Label>Company Website</Label>
              <OutlinedInput
                style={{ height: "30px" }}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                name="company_website"
                onChange={(e) => {
                  handleCompanyDetails("company_website", e.target.value);
                }}
                value={companyDetails["company_website"]}
                type={"text"}
                placeholder={"Enter Value"}
                required
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Company Address</Label>
              <OutlinedInput
                multiline
                rows={4}
                inputProps={{
                  min: 0,
                  "aria-label": "weight",
                  style: {
                    padding: "0px",
                    fontSize: "12px",
                  },
                }}
                name="company_address"
                onChange={(e) => {
                  handleCompanyDetails("company_address", e.target.value);
                }}
                value={companyDetails["company_address"]}
                type={"text"}
                placeholder={"Enter Value"}
                required
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Label>Company Logo</Label>

              <FilePickerCompoent
                handleFileUrl={(fileUrl) => {
                  handleCompanyDetails("company_logo", fileUrl);
                }}
                fileUrl={companyDetails["company_logo"]}
                handleFileClick={() => {
                  setShowImagePreview(true);
                }}
                handleFileRemove={() => {
                  handleCompanyDetails("company_logo", "");
                }}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <RadioGroup
                row
                value={
                  companyDetails["show_in_header"]
                    ? "show_in_header"
                    : "show_in_footer"
                }
                onChange={(e) => {
                  handleCompanyDetails(
                    "show_in_header",
                    e.target.value === "show_in_header"
                  );
                }}
              >
                <FormControlLabel
                  value="show_in_header"
                  control={<Radio size="small" />}
                  label={
                    <Label
                      style={{
                        marginBottom: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                    >
                      Show in Header
                    </Label>
                  }
                />
                <FormControlLabel
                  value="show_in_footer"
                  control={<Radio size="small" />}
                  label={
                    <Label
                      style={{
                        marginBottom: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                      }}
                    >
                      Show in Footer
                    </Label>
                  }
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          {showImagePreview && (
            <PreviewImageDialog
              open={showImagePreview}
              setOpen={setShowImagePreview}
              file={companyDetails["company_logo"]}
              isCreate={false}
            />
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default HeaderPropertyCard;
