import { Grid } from "@material-ui/core";
import { Box, OutlinedInput, styled, Typography } from "@mui/material";
import React, { useCallback } from "react";
import MultiMediaUploadComponent from "../components/MultiMediaUploadComponent";
import SearchableSelectField from "../../custom_field_components/SearchableSelectField";
import CustomTextField from "../../custom_field_components/CustomTextField";
import NumberField from "../../custom_field_components/NumberField";
import DateField from "../../custom_field_components/DateField";
import DateTimeField from "../../custom_field_components/DateTimeField";
import AddFileInputComponent from "../components/AddFileInputComponent";
import InputFileViewComponent from "../components/InputFileViewComponent";
import MutliValueAddField from "../../custom_field_components/MutliValueAddField";
import CustomDecimalField from "../../custom_field_components/CustomDecimalField";
import CustomStepperField from "../../custom_field_components/CustomStepperField";
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  margin-bottom: 16px;
`;
const BasicDetailsScreen = ({
  fieldsToRender,
  handleChange,
  listFieldInputMeta,
  fieldValues,
  getOptionListForSelectFieldToRender,
  mediaDetails,
  handleProductMediaUpload,
  handleRemoveProductMedia,
  handleViewProductMedia,
  selectedDocuments,
  selectedImages,
  handleDocumentUpload,
  handleImageUpload,
  handleDocumentPreview,
  handleImagePreview,
  handleRemoveDoc,
  handleRemoveImage,
  getFileUrl,
  allImageSlotsFilled,
  allDocSlotsFilled,
}) => {
  const RenderLabel = ({ item }) => {
    const isEditable = item["editable"];
    return (
      <Label style={{ color: isEditable ? "#4d4e4f" : "lightgray" }}>
        {item.display_name}
        {item.required && (
          <span style={{ color: isEditable ? "red" : "lightgray" }}>*</span>
        )}
      </Label>
    );
  };

  const RenderSelectedDocuments = useCallback(() => {
    return Object.entries(selectedDocuments).map(([key, doc]) =>
      doc ? (
        <InputFileViewComponent
          key={key}
          fileURL={getFileUrl(doc)}
          handleRemove={() => handleRemoveDoc(key)}
          handleView={() => handleDocumentPreview(doc)}
          scope={"pdf"}
        />
      ) : null
    );
  }, [selectedDocuments]);
  const RenderSelectedImages = useCallback(() => {
    return Object.entries(selectedImages).map(([key, image]) =>
      image ? (
        <InputFileViewComponent
          key={key}
          fileURL={getFileUrl(image)}
          handleRemove={() => handleRemoveImage(key)}
          handleView={() => handleImagePreview(image)}
          scope={"image"}
        />
      ) : null
    );
  }, [selectedImages]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MultiMediaUploadComponent
          handleMediaUpload={(file, type) =>
            handleProductMediaUpload(file, type)
          }
          mediaDetails={mediaDetails}
          handleRemove={(type) => handleRemoveProductMedia(type)}
          handleView={(type) => handleViewProductMedia(type)}
          getFileUrl={(file) => getFileUrl(file)}
        />
      </Grid>
      {fieldsToRender.map((item) => {
        if (
          item.hasOwnProperty("component_type") &&
          item["component_type"] !== null &&
          item["component_type"] === "stepper"
        ) {
          return (
            <Grid item xs={item["width"] === 1 ? 6 : 12}>
              <InputWrapper>
                <RenderLabel item={item} />
                <CustomStepperField
                  fieldName={item.column_name}
                  hintText={`Enter ${item.display_name}`}
                  isDiabled={item["editable"] === false}
                  isRequired={item.required}
                  value={
                    fieldValues.hasOwnProperty(item.column_name)
                      ? fieldValues[item.column_name]
                      : ""
                  }
                  handleChange={(event) => {
                    handleChange({
                      key: item["column_name"],
                      value: event.target.value,
                    });
                  }}
                  handleBlur={() => {}}
                  max={
                    item.hasOwnProperty("max_value") ? item["max_value"] : null
                  }
                  min={
                    item.hasOwnProperty("min_value") ? item["min_value"] : null
                  }
                  step={
                    item.hasOwnProperty("step_value")
                      ? item["step_value"]
                      : null
                  }
                />
              </InputWrapper>
            </Grid>
          );
        } else {
          if (item["is_array"] === true) {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <RenderLabel item={item} />
                  <MutliValueAddField
                    handleChange={(list) => {
                      handleChange({
                        key: item["column_name"],
                        value: list,
                      });
                    }}
                    value={
                      fieldValues.hasOwnProperty(item.column_name) &&
                      fieldValues[item.column_name] !== null &&
                      fieldValues[item.column_name] !== ""
                        ? fieldValues[item.column_name]
                        : []
                    }
                  />
                </InputWrapper>
              </Grid>
            );
          } else {
            if (item.input === "" || item.input === null) {
              if (item.is_multiline) {
                return (
                  <Grid item xs={12}>
                    <InputWrapper>
                      <RenderLabel item={item} />
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        required={item.required}
                        value={
                          fieldValues.hasOwnProperty(item.column_name)
                            ? fieldValues[item.column_name]
                            : ""
                        }
                        disabled={item["editable"] === false}
                        name={item.column_name}
                        type={"text"}
                        placeholder={`Enter ${item.display_name}`}
                        onChange={(event) => {
                          handleChange({
                            key: item["column_name"],
                            value: event.target.value,
                          });
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                );
              } else {
                if (item["data_type"] === "integer") {
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <RenderLabel item={item} />

                        <NumberField
                          value={
                            fieldValues.hasOwnProperty(item.column_name)
                              ? fieldValues[item.column_name]
                              : ""
                          }
                          name={item.column_name}
                          placeholder={
                            item.hint !== null && item.hint !== ""
                              ? item.hint
                              : `Enter ${item.display_name}`
                          }
                          required={item.required}
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              `Please enter a valid ${item.display_name}`
                            )
                          }
                          pattern={""}
                          disabled={item["editable"] === false}
                          handleChange={(e) => {
                            e.target.setCustomValidity("");

                            handleChange({
                              key: e.target.name,
                              value: e.target.value,
                            });
                          }}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                } else if (item["data_type"] === "decimal") {
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <RenderLabel item={item} />

                        <CustomDecimalField
                          fieldName={item.column_name}
                          hintText={`Enter ${item.display_name}`}
                          isDiabled={item["editable"] === false}
                          isRequired={item.required}
                          value={
                            fieldValues.hasOwnProperty(item.column_name)
                              ? fieldValues[item.column_name]
                              : ""
                          }
                          handleChange={(event) => {
                            handleChange({
                              key: item["column_name"],
                              value: event.target.value,
                            });
                          }}
                          handleBlur={() => {}}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                } else if (item["data_type"] === "date") {
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <RenderLabel item={item} />

                        <DateField
                          disabled={item["editable"] === false}
                          value={
                            fieldValues.hasOwnProperty(item.column_name) &&
                            fieldValues[item.column_name] !== ""
                              ? fieldValues[item.column_name]
                              : null
                          }
                          onChange={(event) => {
                            handleChange({
                              key: item["column_name"],
                              value: event,
                            });
                          }}
                          required={item.required}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                } else if (item["data_type"] === "datetime") {
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <RenderLabel item={item} />

                        <DateTimeField
                          disabled={item["editable"] === false}
                          value={
                            fieldValues.hasOwnProperty(item.column_name) &&
                            fieldValues[item.column_name] !== ""
                              ? fieldValues[item.column_name]
                              : null
                          }
                          onChange={(event) => {
                            handleChange({
                              key: item["column_name"],
                              value: event,
                            });
                          }}
                          required={item.required}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={item["width"] === 1 ? 6 : 12}>
                      <InputWrapper>
                        <RenderLabel item={item} />

                        <CustomTextField
                          fieldType={
                            item.data_type === "email" ? "email" : "text"
                          }
                          fieldName={item.column_name}
                          hintText={`Enter ${item.display_name}`}
                          isDiabled={item["editable"] === false}
                          isRequired={item.required}
                          value={
                            fieldValues.hasOwnProperty(item.column_name)
                              ? fieldValues[item.column_name]
                              : ""
                          }
                          handleChange={(event) => {
                            handleChange({
                              key: item["column_name"],
                              value: event.target.value,
                            });
                          }}
                          handleBlur={() => {}}
                        />
                      </InputWrapper>
                    </Grid>
                  );
                }
              }
            } else {
              return (
                <Grid item xs={item["width"] === 1 ? 6 : 12}>
                  <InputWrapper>
                    <RenderLabel item={item} />
                    <SearchableSelectField
                      columnMeta={item}
                      isDisabled={item["editable"] === false}
                      isRequired={item.required}
                      optionsList={
                        listFieldInputMeta.hasOwnProperty(item.column_name)
                          ? getOptionListForSelectFieldToRender(
                              listFieldInputMeta[item.column_name]
                            )
                          : []
                      }
                      value={
                        fieldValues.hasOwnProperty(item.column_name) &&
                        fieldValues[item.column_name] !== ""
                          ? fieldValues[item.column_name]
                          : null
                      }
                      handleChange={(event) => {
                        handleChange({
                          key: item["column_name"],
                          value: event,
                        });
                      }}
                    />
                  </InputWrapper>
                </Grid>
              );
            }
          }
        }
      })}
      <Grid item xs={12}>
        <InputWrapper>
          <SectionHeader>Images</SectionHeader>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {!allImageSlotsFilled() && (
              <AddFileInputComponent
                acceptedFiles={"image/jpeg,image/png"}
                handleChange={(event) => handleImageUpload(event)}
                scope={"image"}
              />
            )}
            <RenderSelectedImages />
          </Box>
        </InputWrapper>
      </Grid>
      <Grid item xs={12}>
        <InputWrapper>
          <SectionHeader>Documents</SectionHeader>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {!allDocSlotsFilled() && (
              <AddFileInputComponent
                acceptedFiles={"application/pdf,text/plain"}
                handleChange={(event) => handleDocumentUpload(event)}
                scope={"pdf"}
              />
            )}
            <RenderSelectedDocuments />
          </Box>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export default BasicDetailsScreen;
