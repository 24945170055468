import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  tabsClasses,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import RenderFooterComponents from "../quotation_builder_components/RenderFooterComponents.js";
import RenderContentSection from "../quotation_builder_components/RenderContenttSection.js";
import { createLeadQuotationApi, editLeadQuotationApi } from "../../Api.js";
import moment from "moment";
import QuotationPropertyCard from "../quotation_builder_components/card_widgets/QuotationPropertyCard.js";
import { embedVariableValuesInMessageString } from "../../Functions.js";
import CustomErrorMessageDialog from "../alert_dialogue/CustomErrorMessageDialog.js";
import QuotationAmountPickerDialog from "../quotation_builder_components/dialogs/QuotationAmountPickerDialog.js";

const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  width: 100%;
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
  padding: 16px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateQuoteWithContentDialog({
  open,
  setOpen,
  quotationTemplateData,
  setRefresh,
  leadID,
  scope,
  setScope,
  editableData,
  leadData,
}) {
  const [tabIdx, setTabIdx] = useState(0);
  const [widgetList, setWidgetList] = useState([]);
  const [footerWidgetList, setFooterWidgetList] = useState([]);
  const [pdfStyles, setPdfStyles] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [headerWidgetList, setHeaderWidgetList] = useState([]);
  const [quotationProperties, setQuotationProperties] = useState({
    quote_date: null,
    expiry_date: null,
    amount: "",
  });
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const [loading, setLoading] = useState(true);
  const [openAmountDialog, setOpenAmountDialog] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    company_logo: "",
    company_name: "",
    company_email: "",
    company_address: "",
    company_website: "",
    show_in_header: true,
  });
  const [templateVersion, setTemplateVersion] = useState(null);
  const init = async () => {
    let schemaData = {};
    if (scope === "edit" || scope === "revise") {
      schemaData = editableData["quote_schema"];
      let tempObj = {
        quote_date:
          editableData["quote_date"] !== null
            ? moment(editableData["quote_date"])
            : null,
        expiry_date:
          editableData["expiry_date"] !== null
            ? moment(editableData["expiry_date"])
            : null,
        amount: editableData["amount"],
      };
      setQuotationProperties(tempObj);
    } else {
      schemaData = quotationTemplateData["schema"];

      let tempObj = { ...quotationProperties };
      tempObj["quote_date"] = moment().startOf("day");
      setQuotationProperties(tempObj);
    }

    let templateVersion =
      schemaData.hasOwnProperty("template_version") &&
      schemaData["template_version"] !== null &&
      schemaData["template_version"] !== ""
        ? schemaData["template_version"]
        : 1;

    setTemplateVersion(templateVersion);

    let tempPdfStyles = { ...schemaData["styles"] };

    let tempHeaderWidgetList = schemaData["header"]["components"].map(
      (component) => {
        if (component["type"] === "text") {
          return {
            ...component,
            content: embedVariableValuesInMessageString({
              messageString: component["content"],
              leadData,
            }),
          };
        }
        return component;
      }
    );
    let tempFooterWidgetList = schemaData["footer"]["components"].map(
      (component) => {
        if (component["type"] === "text") {
          return {
            ...component,
            content: embedVariableValuesInMessageString({
              messageString: component["content"],
              leadData,
            }),
          };
        }
        return component;
      }
    );
    let tempComponents = schemaData["components"].map((component) => {
      if (component["type"] === "text") {
        return {
          ...component,
          content: embedVariableValuesInMessageString({
            messageString: component["content"],
            leadData,
          }),
        };
      } else if (component["type"] === "table") {
        return {
          ...component,
          content: component["content"].map((rowData) =>
            rowData.map((cell) =>
              cell["content"]
                ? {
                    ...cell,
                    content: embedVariableValuesInMessageString({
                      messageString: cell["content"],
                      leadData,
                    }),
                  }
                : cell
            )
          ),
        };
      }
      return component;
    });
    let tempCompanyDetails =
      schemaData.hasOwnProperty("company_details") &&
      schemaData["company_details"] !== null
        ? schemaData["company_details"]
        : {
            company_logo: "",
            company_name: "",
            company_email: "",
            company_address: "",
            company_website: "",
            show_in_header: true,
          };
    if (tempCompanyDetails.hasOwnProperty("show_in_header") === false) {
      tempCompanyDetails["show_in_header"] = true;
    }
    setCompanyDetails(tempCompanyDetails);
    setPdfStyles(tempPdfStyles);
    setHeaderWidgetList(tempHeaderWidgetList);
    setFooterWidgetList(tempFooterWidgetList);
    setWidgetList(tempComponents);

    setLoading(false);
  };
  React.useEffect(() => {
    init();
  }, []);

  const createQuote = async (payload) => {
    let response = await createLeadQuotationApi({
      payload: payload,
      leadID: leadID,
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowError(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const editQuote = async (payload) => {
    let response = await editLeadQuotationApi({
      payload: payload,
      leadID: leadID,
      quoteID: editableData["id"],
    });
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowError(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleSave = async (amount) => {
    setOpenAmountDialog(false);
    setIsFormSubmitting(true);
    let payload = {
      amount: amount !== "" ? parseInt(amount) : null,
      quote_date:
        quotationProperties["quote_date"] !== null
          ? quotationProperties["quote_date"]
          : null,
      expiry_date:
        quotationProperties["expiry_date"] !== null
          ? quotationProperties["expiry_date"]
          : null,
      quotation_template_id:
        scope === "edit" || scope === "revise"
          ? editableData["quotation_template_id"]
          : quotationTemplateData["id"],
    };
    if (scope === "edit" || scope === "revise") {
      payload["quote_schema"] = {
        components: widgetList,
        footer: {
          ...editableData["quote_schema"]["footer"],
          components: footerWidgetList,
        },
        header: {
          ...editableData["quote_schema"]["header"],
          components: headerWidgetList,
        },
        styles: pdfStyles,
        template_type: editableData["quote_schema"]["template_type"],
        template_version: templateVersion,
        company_details: companyDetails,
        version:2 //version 2, to prevent get template list api call in preview pdf page
      };
    } else {
      payload["quote_schema"] = {
        components: widgetList,
        footer: {
          ...quotationTemplateData["schema"]["footer"],
          components: footerWidgetList,
        },
        header: {
          ...quotationTemplateData["schema"]["header"],
          components: headerWidgetList,
        },
        styles: pdfStyles,
        template_type: quotationTemplateData["schema"]["template_type"],
        template_version: templateVersion,
        company_details: companyDetails,
        version:2 //version 2, to prevent get template list api call in preview pdf page
      };
    }
      if (scope === "edit" || scope === "revise") {
        await editQuote(payload);
      } else {
        await createQuote(payload);
      }
    
    setIsFormSubmitting(false);
  };

  const handleClose = () => {
    setScope("");
    setOpen(false);
  };

  const getSuccessMessageText = () => {
    if (scope === "edit") {
      return "Quote edited successfully!";
    } else if (scope === "revise") {
      return "Quote revised successfully!";
    } else {
      return "Quote created successfully!";
    }
  };

  const getHeaderText = () => {
    if (scope === "edit") {
      return "Edit Quote";
    } else if (scope === "revise") {
      return "Revise Quote";
    } else {
      return "Create Quote";
    }
  };
  const getAmountPickerTitleText = () => {
    if (scope === "edit" || scope === "revise") {
      return "Confirm Quotation Amount";
    }
    return "Quotation Amount";
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
    >
      {!loading ? (
        !isSubmitSuccess ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AppBar
              elevation={0}
              component={"nav"}
              position="sticky"
              color="inherit"
              sx={{ top: 0, bottom: "auto", width: "100%", right: 0 }}
            >
              <Toolbar style={{ padding: "16px", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Header>{getHeaderText()}</Header>
                </Box>
              </Toolbar>
            </AppBar>
            <Box
              component="main"
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "calc(100vh - 64px)",
                  width: "300px",
                  position: "sticky",
                  top: "64px",
                  left: "0px",
                }}
              >
                <Box>
                  <Tabs
                    orientation="vertical"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                      width: "100%",
                    }}
                    variant="scrollable"
                    value={tabIdx}
                    textColor="primary"
                    indicatorColor="primary"
                    scrollButtons={"auto"}
                    onChange={(event, newValue) => {
                      setTabIdx(newValue);
                    }}
                  >
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Quotation Details"}
                    />
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Content"}
                    />
                    <Tab
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#E7EEFA",
                          color: "#4079DA",
                          font: "normal normal 600 14px Open Sans",
                          textTransform: "capitalize",
                        },

                        backgroundColor: "white",
                        color: "#4D4E4F",
                        font: "normal normal 600 14px Open Sans",
                        textTransform: "capitalize",
                      }}
                      label={"Footer"}
                    />
                  </Tabs>
                  <Divider />
                </Box>
                <Box sx={{ height: "100%" }} />

                <Box>
                  <Divider />
                  <ButtonWrapper>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      color="inherit"
                      style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        setOpenAmountDialog(true);
                      }}
                      type="button"
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      Save
                    </Button>
                  </ButtonWrapper>
                </Box>
              </Box>

              {(() => {
                if (tabIdx === 0) {
                  return (
                    <QuotationPropertyCard
                      editableData={quotationProperties}
                      handleQuotationProperties={(data) => {
                        setQuotationProperties(data);
                      }}
                      scope={"quote"}
                      widgetList={headerWidgetList}
                      setWidgetList={setHeaderWidgetList}
                    />
                  );
                } else if (tabIdx === 1) {
                  return (
                    <RenderContentSection
                      widgetList={widgetList}
                      setWidgetList={setWidgetList}
                    />
                  );
                } else if (tabIdx === 2) {
                  return (
                    <RenderFooterComponents
                      widgetList={footerWidgetList}
                      setWidgetList={setFooterWidgetList}
                    />
                  );
                }
              })()}
            </Box>

            {showError && (
              <CustomErrorMessageDialog
                setOpen={setShowError}
                message={errorMessage}
                open={showError}
              />
            )}
            {openAmountDialog && (
              <QuotationAmountPickerDialog
                open={openAmountDialog}
                setOpen={setOpenAmountDialog}
                handleAmountSubmit={(amount) => {
                  handleSave(amount);
                }}
                headerText={getAmountPickerTitleText()}
                quotationDetails={quotationProperties}
              />
            )}
            <Backdrop
              open={isFormSubmitting}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress style={{ color: "white" }} />
            </Backdrop>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "Flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MessageWrapper>
              <Message>{getSuccessMessageText()}</Message>

              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                color="inherit"
                style={{
                  marginTop: "20px",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "100px",
                }}
              >
                Close
              </Button>
            </MessageWrapper>
          </Box>
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "Flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
}
