import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import { CurrencyRupeeRounded, Percent } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 320px;
  row-gap: 20px;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;

const AddProductModal = (props) => {
  const classes = useStyles();
  const [selectedProductData, setSelectedProductData] = useState({
    rate: "",
    quantity: "",
    tax: "",
    discount: "",
    desc: "",
  });
  const [customProductName, setCustomProductName] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleChange = (e) => {
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddProduct = () => {
    let newObj = {
      desc: selectedProductData["desc"],
    };
    let rate =
      selectedProductData["rate"] !== "" &&
      selectedProductData["rate"] !== null &&
      selectedProductData["rate"] !== undefined
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);
    let tax =
      selectedProductData["tax"] !== "" &&
      selectedProductData["tax"] !== null &&
      selectedProductData["tax"] !== undefined
        ? parseFloat(selectedProductData["tax"])
        : 0;

    let discount =
      selectedProductData["discount"] !== "" &&
      selectedProductData["discount"] !== null &&
      selectedProductData["discount"] !== undefined
        ? parseFloat(selectedProductData["discount"])
        : 0;
    let amount = rate * quantity;
    newObj["tax"] = tax;
    newObj["discount"] = discount;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["amount"] = amount;
    let taxedAmount = tax > 0 ? (newObj["amount"] * tax) / 100 : 0;
    newObj["amount"] = newObj["amount"] + taxedAmount;
    let discountedAmount = (newObj["amount"] * discount) / 100;
    newObj["amount"] = (newObj["amount"] - discountedAmount).toFixed(2);
    newObj["item"] = {
      name: customProductName,
      id: uuidv4(),
    };

    let newList = [...props.productAddedList, newObj];
    props.setProductAddedList(newList);
    props.updateCalculatedAmount(newList);
    props.setOpen(false);
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Header>Add a Item</Header>
          <SubHeader>Fill in the below details</SubHeader>
          <Divider style={{ margin: "10px 0px" }} />
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                handleAddProduct();
              }}
            >
              <InputContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Name*</Label>
                      <OutlinedInput
                        required
                        type="text"
                        placeholder="Name"
                        onChange={(e) => {
                          setCustomProductName(e.target.value);
                        }}
                        name="name"
                        value={customProductName}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Description</Label>
                      <OutlinedInput
                        multiline
                        rows={3}
                        type="text"
                        placeholder="Description"
                        onChange={handleChange}
                        name="desc"
                        value={selectedProductData["desc"]}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Quantity*</Label>

                      <OutlinedInput
                        required
                        type="number"
                        placeholder="Quantity"
                        onChange={handleChange}
                        name="quantity"
                        value={selectedProductData["quantity"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Rate</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Rate"
                        name="rate"
                        onChange={handleChange}
                        value={selectedProductData["rate"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <CurrencyRupeeRounded
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0,
                          step: "0.01",
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Tax*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          required
                          placeholder="Tax"
                          name="tax"
                          onChange={handleChange}
                          value={selectedProductData["tax"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                        >
                          {props.taxOptions.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.value}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      {/* old */}
                      {/* <OutlinedInput
                        type="number"
                        placeholder="Tax"
                        name="tax"
                        onChange={handleChange}
                        value={selectedProductData["tax"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <Percent
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      /> */}
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Discount</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Discount"
                        name="discount"
                        onChange={handleChange}
                        value={selectedProductData["discount"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <Percent
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0,
                          step: "0.01",
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <ButtonWrapper>
                <Button
                  onClick={handleClose}
                  type="button"
                  variant="contained"
                  color="inherit"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",

                    fontSize: "12px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Add
                </Button>
              </ButtonWrapper>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddProductModal;
