import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { dataRenderer } from "../../../Functions";
import { Box, Tooltip } from "@mui/material";

const ResultScreen = ({ data }) => {
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columnHeaders = [
    {
      field: "sr_no",
      headerName: "S.NO",
      width: 70,
      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "name",
      headerName: "Name",

      width: 130,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 130,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.status);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "message",
      headerName: "MESSAGE",
      width: 250,
      cellRenderer: (params) => {
        let value = dataRenderer(params.data.message);
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "400px",
        overflowY: "auto",
        overflowX: "auto",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <AgGridReact
          domLayout="autoHeight"
          gridOptions={gridOptions}
          rowData={data}
          defaultColDef={defaultColDef}
          columnDefs={columnHeaders}
          animateRows={true}
          suppressCellFocus
        />
      </div>
    </Box>
  );
};

export default ResultScreen;
