import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Select from "react-select";

const dialogWidth = 420;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const AddQuoteTemplateWidgetDialog = ({
  open,
  setOpen,
  handleAddTemplateWidget,
}) => {
  const [widgetType, setWidgetType] = useState(null);
  const [details, setDetails] = useState({
    number_of_rows: "",
    number_of_columns: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAddTemplateWidget(widgetType["value"], details);
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
    >
      <ModelContainer>
        <Box component={"form"} onSubmit={handleSubmit}>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>Add Content</Header>
                <SubHeader>Fill in the below details</SubHeader>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Container>
            <Box
              style={{
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>Select Content Type</Label>
                    <Select
                      menuPosition="fixed"
                      options={[
                        {
                          label: "Text Content",
                          value: "text",
                        },
                        {
                          label: "Image Content",
                          value: "image",
                        },
                        {
                          label: "Table Content",
                          value: "table",
                        },
                      ]}
                      value={widgetType}
                      onChange={(selectedOption) => {
                        setDetails({
                          number_of_columns: "",
                          number_of_rows: "",
                        });

                        setWidgetType(selectedOption);
                      }}
                      closeMenuOnSelect={true}
                      styles={customStyles}
                      isClearable
                    />
                  </InputWrapper>
                </Grid>
                {widgetType !== null && widgetType["value"] === "table" && (
                  <>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>Number of Rows</Label>
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          name="number_of_rows"
                          onChange={handleChange}
                          value={details["number_of_rows"]}
                          type={"number"}
                          placeholder={"Enter Value"}
                          required
                        />
                      </InputWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <InputWrapper>
                        <Label>Number of Columns</Label>
                        <OutlinedInput
                          style={{ height: "30px" }}
                          inputProps={{
                            min: 0,
                            max: 20, // 5% is min width we can give to each column - so max 20 columns we can add in 100% width
                            "aria-label": "weight",
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          name="number_of_columns"
                          onChange={handleChange}
                          value={details["number_of_columns"]}
                          type={"number"}
                          placeholder={"Enter Value"}
                          required
                        />
                      </InputWrapper>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: "100%" }}
          >
            <Toolbar
              style={{
                padding: "16px",
                width: dialogWidth,
                position: "relative",
              }}
            >
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="inherit"
                    style={{
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default AddQuoteTemplateWidgetDialog;
