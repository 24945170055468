import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal, makeStyles } from "@material-ui/core";
import { CurrencyRupeeRounded, Percent } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import MuiAlert from "@mui/material/Alert";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { quotationTaxOptions } from "../../../Db";
import { consoleLogger, getLocalizedText } from "../../../Functions";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchableSelectProduct from "../../searchable_select/SearchableSelectProduct";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px #ffffff",
  },
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  rowGap: "20px",
  overflowX: "hidden",
  overflowY: "auto",
  maxHeight: "calc(100vh - 300px)",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 10px Open Sans",
  color: "#4d4e4f",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  height: "fit-content",
  maxHeight: "95vh",
  width: "550px",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 6px #0000000d",
  borderRadius: "8px",
  padding: "30px",
  border: "1px solid #c5d7f1",
  boxShadow: "0px 3px 20px #185dd21f",
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
  margin: "0px",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  margin: "0px",
  marginTop: "2px",
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 14px Open Sans",
  color: "#a9a9a9",
}));
const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const AddAreaBasedProductModal = (props) => {
  const classes = useStyles();
  const [selectedProductData, setSelectedProductData] = useState({
    rate: "",
    quantity: "",
    tax: "",
    desc: "",
    total_unit: "",
    in_unit: "",
    height: "",
    width: "",
  });
  const [additionalDetailsList, setAdditionalDetailsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddAdditionalDetail = () => {
    const initialFields = { field: "", value: "" };

    const newList = [
      ...additionalDetailsList,
      { id: uuidv4(), ...initialFields },
    ];

    setAdditionalDetailsList(newList);
  };
  const handleDeleteAdditionalDetail = (id) => {
    setAdditionalDetailsList((prev) =>
      prev.filter((detail) => detail.id !== id)
    );
  };
  const handleAdditionalDetailChange = (id, field, value) => {
    setAdditionalDetailsList((prev) =>
      prev.map((detail) =>
        detail.id === id ? { ...detail, [field]: value } : detail
      )
    );
  };
  const getSelectedPruductDataByID = (id) => {
    let index = props.productList.findIndex((product) => product["id"] === id);
    if (index !== -1) {
      return props.productList[index];
    }
    return {};
  };
  const resetForm = () => {
    setSelectedProduct("");
    setSelectedProductData({
      tax: "",
      rate: "",
      quantity: "",
      unit: "",
      height: "",
      width: "",
    });
    setAdditionalDetailsList([]);
    setErrorMessage("");
    setShowAlert(false);
  };

  const prefillOnProductSelect = (selectedProductID) => {
    const productData = getSelectedPruductDataByID(selectedProductID);

    let newProductData = {
      tax:
        productData.hasOwnProperty("tax") && productData["tax"] !== null
          ? productData["tax"]
          : "",
      rate:
        productData.hasOwnProperty("unit_price") &&
        productData["unit_price"] !== null &&
        productData["unit_price"] !== ""
          ? productData["unit_price"]
          : "",
      quantity: "",
      unit:
        productData.hasOwnProperty("unit") &&
        productData["unit"] !== null &&
        productData["unit"] !== ""
          ? productData["unit"]
          : "",
    };

    const specifications = productData["specifications"];
    const keys = Object.keys(specifications).reduce((acc, key) => {
      acc[key.toLowerCase()] = key;
      return acc;
    }, {});

    if (keys["height"]) {
      newProductData["height"] = specifications[keys["height"]];
    }

    if (keys["width"]) {
      newProductData["width"] = specifications[keys["width"]];
    }

    let additionalDetailsList = [];

    Object.entries(productData.additional_details).forEach(([key, value]) => {
      additionalDetailsList.push({ field: key, value: value, id: uuidv4() });
    });
    setAdditionalDetailsList(additionalDetailsList);
    setSelectedProductData(newProductData);
    updateCalculatedFields(newProductData);
  };

  const getBaseAmount = ({ rate, totalUnit, quantity }) => {
    if (
      selectedProductData.width &&
      selectedProductData.height &&
      selectedProductData.width !== 0 &&
      selectedProductData.height !== 0 &&
      selectedProductData.width !== "" &&
      selectedProductData.height !== ""
    ) {
      return rate * totalUnit;
    }
    return rate * quantity;
  };

  const updateCalculatedFields = (selectedProductData) => {
    const { width, height, quantity } = selectedProductData;

    let inUnit = null;
    let totalUnit = null;

    if (width && height) {
      inUnit = width * height;
    }

    if (inUnit && quantity) {
      totalUnit = inUnit * quantity;
    }

    setSelectedProductData((prev) => ({
      ...prev,
      in_unit: inUnit,
      total_unit: totalUnit,
    }));
  };

  const handleAddProduct = () => {
    let newObj = {
      desc: selectedProductData["desc"],
    };
    let rate =
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);

    let productData = getSelectedPruductDataByID(selectedProduct);
    newObj["product_data"] = {
      id: productData["id"],
      name: productData["name"],
      unit_price: productData["unit_price"],
      unit_cost: productData["unit_cost"],
      tax: productData["tax"],
      max_discount: productData["max_discount"],
      product_code: productData["product_code"],
    };
    newObj["type"] = "product";
    newObj["height"] = selectedProductData["height"];
    newObj["width"] = selectedProductData["width"];
    newObj["in_unit"] =
      selectedProductData["in_unit"] === "" ||
      selectedProductData["in_unit"] === null
        ? null
        : parseFloat(selectedProductData["in_unit"]).toFixed(2);
    newObj["unit"] = selectedProductData["unit"];

    newObj["total_unit"] =
      selectedProductData["total_unit"] === "" ||
      selectedProductData["total_unit"] === null
        ? null
        : parseFloat(selectedProductData["total_unit"]).toFixed(2);
    let amount = getBaseAmount({
      rate: rate,
      totalUnit: newObj["total_unit"],
      quantity: quantity,
    });
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["amount"] = amount;
    let tax =
      selectedProductData["tax"] !== "" &&
      selectedProductData["tax"] !== null &&
      selectedProductData["tax"] !== undefined
        ? parseFloat(selectedProductData["tax"])
        : 0;

    newObj["tax"] = tax;
    let taxedAmount = tax > 0 ? (newObj["amount"] * tax) / 100 : 0;

    newObj["amount"] = parseFloat(newObj["amount"] + taxedAmount);
    newObj["additional_details"] = additionalDetailsList;
    newObj["item"] = {
      name: productData["name"],
      id: uuidv4(),
    };

    let newList = [...props.productAddedList, newObj];
    props.setProductAddedList(newList);
    props.updateCalculatedAmount(newList);
    props.setOpen(false);
  };

  const handleEditProduct = () => {
    let newObj = {
      ...props.editableProduct,
      desc: selectedProductData["desc"],
    };
    let rate =
      selectedProductData["rate"] !== "" &&
      selectedProductData["rate"] !== null &&
      selectedProductData["rate"] !== undefined
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);
    let tax =
      selectedProductData["tax"] !== "" &&
      selectedProductData["tax"] !== null &&
      selectedProductData["tax"] !== undefined
        ? parseFloat(selectedProductData["tax"])
        : 0;

    newObj["width"] = selectedProductData["width"];
    newObj["height"] = selectedProductData["height"];
    newObj["in_unit"] =
      selectedProductData["in_unit"] === "" ||
      selectedProductData["in_unit"] === null
        ? null
        : parseFloat(selectedProductData["in_unit"]).toFixed(2);
    newObj["unit"] = selectedProductData["unit"];
    newObj["total_unit"] =
      selectedProductData["total_unit"] === "" ||
      selectedProductData["total_unit"] === null
        ? null
        : parseFloat(selectedProductData["total_unit"]).toFixed(2);
    let amount = getBaseAmount({
      rate: rate,
      totalUnit: newObj["total_unit"],
      quantity: quantity,
    });
    newObj["tax"] = tax;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["amount"] = amount;

    let taxedAmount = tax > 0 ? (newObj["amount"] * tax) / 100 : 0;
    newObj["amount"] = parseFloat(newObj["amount"] + taxedAmount).toFixed(2);
    newObj["additional_details"] = additionalDetailsList;

    let newList = [...props.productAddedList];
    let index = props.productAddedList.findIndex(
      (productItem) => productItem["item"]["id"] === newObj["item"]["id"]
    );

    newList[index] = newObj;
    props.setProductAddedList(newList);
    props.updateCalculatedAmount(newList);

    //new tax value flag handling on place of supply change
    if (newList.every((x) => x.tax !== "")) {
      props.setItemTaxFlag(false);
      props.setShowItemTaxWarning(false);
    }
    props.handleClose();
  };

  const setupEditableData = () => {
    let productID =
      props.editableProduct.hasOwnProperty("product_data") &&
      props.editableProduct["product_data"] !== null
        ? props.editableProduct["product_data"]["id"]
        : "";
    setSelectedProduct(productID);

    setAdditionalDetailsList(
      props.editableProduct["additional_details"]
        ? props.editableProduct["additional_details"]
        : []
    );

    let tempSelectedProductData = {
      desc:
        props.editableProduct.hasOwnProperty("desc") &&
        props.editableProduct["desc"] !== null &&
        props.editableProduct["desc"] !== ""
          ? props.editableProduct["desc"]
          : "",
      width:
        props.editableProduct.hasOwnProperty("width") &&
        props.editableProduct["width"] !== null &&
        props.editableProduct["width"] !== ""
          ? props.editableProduct["width"]
          : "",
      height:
        props.editableProduct.hasOwnProperty("height") &&
        props.editableProduct["height"] !== null &&
        props.editableProduct["height"] !== ""
          ? props.editableProduct["height"]
          : "",
      rate: props.editableProduct.hasOwnProperty("rate")
        ? props.editableProduct["rate"]
        : "",
      quantity: props.editableProduct.hasOwnProperty("quantity")
        ? props.editableProduct["quantity"]
        : "",
      tax:
        props.editableProduct.hasOwnProperty("tax") &&
        props.editableProduct["tax"] !== null &&
        props.editableProduct["tax"] !== ""
          ? parseFloat(props.editableProduct["tax"])
          : "",
      unit:
        props.editableProduct.hasOwnProperty("unit") &&
        props.editableProduct["unit"] !== null &&
        props.editableProduct["unit"] !== ""
          ? props.editableProduct["unit"]
          : "",
    };
    setSelectedProductData(tempSelectedProductData);
    updateCalculatedFields(tempSelectedProductData);
  };
  useEffect(() => {
    if (props.isEdit) {
      setupEditableData();
    }
  }, []);

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <Container
              component={"form"}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (selectedProduct === "" || selectedProduct === null) {
                  setErrorMessage(getLocalizedText("please_select_a_product"));
                  setShowAlert(true);
                  return;
                }
                if (props.isEdit) {
                  handleEditProduct();
                } else {
                  handleAddProduct();
                }
              }}
            >
              <AppBar
                component={"nav"}
                elevation={0}
                position="sticky"
                color="inherit"
                sx={{
                  top: 0,
                  bottom: "auto",
                  width: "100%",
                  right: 0,
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "0px",
                  }}
                >
                  <HeaderWrapper>
                    <Header>
                      {" "}
                      {props.isEdit ? (
                        <Header>{getLocalizedText("edit_product")}</Header>
                      ) : (
                        <Header>{getLocalizedText("add_product")}</Header>
                      )}
                    </Header>
                    <SubHeader>Fill in the below details</SubHeader>
                  </HeaderWrapper>
                </Toolbar>
                <Divider style={{ margin: "10px 0px" }} />
              </AppBar>
              <InputContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SectionHeader>
                      {getLocalizedText("item_details")}
                    </SectionHeader>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>{`${getLocalizedText("select_product")}*`}</Label>
                      <SearchableSelectProduct
                        isEdit={props.isEdit}
                        product={props.editableProduct.product_data}
                        isProductAlreadyAdded={props.isProductAlreadyAdded}
                        setProductList={props.setProductList}
                        onChangeCallback={(value) => {
                          if (value === "") {
                            resetForm();
                            return;
                          }
                          let index = props.productList.findIndex(
                            (entry) => entry["id"] === value
                          );

                          if (index !== -1) {
                            setSelectedProduct(value);
                            prefillOnProductSelect(value);
                          } else {
                            setSelectedProduct("");
                          }
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Description</Label>
                      <OutlinedInput
                        multiline
                        rows={3}
                        type="text"
                        placeholder="Description"
                        onChange={handleChange}
                        name="desc"
                        value={selectedProductData["desc"]}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  {/* custom key value pairs */}
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <legend
                          style={{
                            width: "auto",
                            marginBottom: "0px",
                            fontSize: "12px",
                          }}
                        >
                          <SectionHeader>
                            {getLocalizedText("additional_details")}
                          </SectionHeader>
                        </legend>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            maxHeight: "10vh",
                            overflowY: "auto",
                          }}
                        >
                          {additionalDetailsList.map((row) => (
                            <Box
                              key={row.id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                marginBottom: "6px",
                              }}
                            >
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  maxLength: 2000,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Name"
                                value={row.field}
                                onChange={(e) => {
                                  handleAdditionalDetailChange(
                                    row.id,
                                    "field",
                                    e.target.value
                                  );
                                }}
                                size="small"
                                sx={{ flex: 1 }}
                              />
                              <OutlinedInput
                                required
                                style={{ height: "30px" }}
                                inputProps={{
                                  maxLength: 25,
                                  "aria-label": "weight",
                                  style: {
                                    padding: "0px 12px",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Value"
                                value={row.value}
                                onChange={(e) => {
                                  handleAdditionalDetailChange(
                                    row.id,
                                    "value",
                                    e.target.value
                                  );
                                }}
                                size="small"
                                sx={{ flex: 1 }}
                              />

                              <IconButton
                                onClick={() =>
                                  handleDeleteAdditionalDetail(row.id)
                                }
                                color="error"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}

                          <Button
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              handleAddAdditionalDetail();
                            }}
                          >
                            <AddIcon /> Add
                          </Button>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  {/* Area details */}
                  <Grid item xs={12}>
                    <SectionHeader>Area Details</SectionHeader>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Width</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Width"
                        onChange={(e) => {
                          const updatedProductData = {
                            ...selectedProductData,
                            width: e.target.value,
                          };

                          setSelectedProductData(updatedProductData);
                          updateCalculatedFields(updatedProductData);
                        }}
                        name="width"
                        value={selectedProductData["width"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0.1,
                          step: "0.1",
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Height</Label>
                      <OutlinedInput
                        type="number"
                        placeholder="Height"
                        name="height"
                        onChange={(e) => {
                          const updatedProductData = {
                            ...selectedProductData,
                            height: e.target.value,
                          };

                          setSelectedProductData(updatedProductData);
                          updateCalculatedFields(updatedProductData);
                        }}
                        value={selectedProductData["height"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0.1,
                          step: "0.1",
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Unit</Label>
                      <OutlinedInput
                        placeholder="Unit"
                        name="unit"
                        value={selectedProductData["unit"]}
                        onChange={handleChange}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>In Units</Label>
                      <OutlinedInput
                        readOnly
                        type="number"
                        placeholder="In Units"
                        name="in_unit"
                        value={selectedProductData["in_unit"] || ""}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Quantity*</Label>

                      <OutlinedInput
                        required
                        type="number"
                        placeholder="Quantity"
                        onChange={(e) => {
                          const updatedProductData = {
                            ...selectedProductData,
                            quantity: e.target.value,
                          };

                          setSelectedProductData(updatedProductData);
                          updateCalculatedFields(updatedProductData);
                        }}
                        name="quantity"
                        value={selectedProductData["quantity"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={6}>
                    <InputWrapper>
                      <Label>Total Units</Label>
                      <OutlinedInput
                        readOnly
                        type="number"
                        placeholder="Total Units"
                        name="total_unit"
                        value={selectedProductData["total_unit"] || ""}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 1,
                          "aria-label": "weight",
                          style: {
                            padding: "0px 12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Rate*</Label>
                      <OutlinedInput
                        required
                        type="number"
                        placeholder="Rate"
                        name="rate"
                        onChange={handleChange}
                        value={selectedProductData["rate"]}
                        style={{ height: "30px" }}
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">
                            <CurrencyRupeeRounded
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          min: 0,
                          step: "0.01",
                          "aria-label": "weight",
                          style: {
                            padding: "0px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Tax*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          required
                          placeholder="Tax"
                          name="tax"
                          onChange={handleChange}
                          value={selectedProductData["tax"]}
                          disableUnderline
                          variant="outlined"
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                        >
                          {quotationTaxOptions.map((item, i) => {
                            return (
                              <MenuItem
                                value={item.value}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{
                  top: "auto",
                  bottom: 0,
                  width: "100%",
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <Toolbar
                  style={{
                    padding: "0px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  {showAlert && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setShowAlert(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}{" "}
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "flex-end",
                          gap: "16px",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          onClick={props.handleClose}
                          type="button"
                          variant="contained"
                          color="inherit"
                          style={{
                            textTransform: "none",

                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            textTransform: "none",

                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          {props.isEdit
                            ? getLocalizedText("edit")
                            : getLocalizedText("add")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Container>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddAreaBasedProductModal;
