import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px 0;
`;

const Text = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #4d4e4f;
  margin: 0 10px;
  white-space: nowrap;
`;

const DottedLine = styled(Box)`
  flex-grow: 1;
  height: 2px;
  border-top: 2px dashed #eceef2;
`;

const PageBreakCard = () => {
  return (
    <Wrapper>
      <DottedLine />
      <Text>Page Break</Text>
      <DottedLine />
    </Wrapper>
  );
};

export default PageBreakCard;
