import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import "./import.css";
import rootStore from "../../stores/RootStore";
import { observer } from "mobx-react-lite";
import UploadModal from "../../components/upload_csv_modal/UploadModal";
import {
  Box,
  Chip,
  styled,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  checkFeaturePermission,
  getLocalizedText,
  validateDisabledSubFeature,
} from "../../Functions";
import CreateLeadImportModal from "../../components/upload_csv_modal/create_lead_import_modal/CreateLeadImportModal";
import analytics from "../../Analytics";
import LeadImportsDataTable from "../../components/imports_page_components/views/LeadImportsDataTable";
import { importsPageChipFilters } from "../../Db";
import CustomerImportsDataTable from "../../components/imports_page_components/views/CustomerImportsDataTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImportCustomersDialog from "../../components/import_customer_components/ImportCustomersDialog";
import ImportProductDialog from "../../components/import_product_components/ImportProductDialog";
import ProductImportsDataTable from "../../components/imports_page_components/views/ProductImportsDataTable";

const Container = styled(Box)`
  margin-top: 64px;
  background-color: #f9f9fc;
  ${`min-height: calc(100vh - 221px);`};
`;
const Wrapper = styled(Box)`
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const TopWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
  width: 100%;
  justify-content: space-between;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const ChipsContainer = styled(Box)`
  width: 100%;
  display: flex;
  column-gap: 8px;
`;
const ImportHistory = () => {
  const { authStore } = rootStore;
  const [openImport, setOpenImport] = useState(false);
  const [openCreateLeadImport, setOpenCreateLeadImport] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshCustomerImports, setRefreshCustomerImports] = useState(false);
  const [refreshProductImports, setRefreshProductImports] = useState(false);
  const [scope, setScope] = useState("leads");
  const [openImportCustomerDialog, setOpenImportCustomerDialog] =
    useState(false);
  const [openImportProductDialog, setOpenImportProductDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const Open = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChip = async (scope) => {
    setScope(scope);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4625000097,
      "imports_page_load",
      "imports_page",
      "",
      {}
    );
    authStore.updateLeadDetailStatus();
  }, []);

  const canShowSectionByChipScope = () => {
    return (
      checkFeaturePermission("imports_customer") === true &&
      checkFeaturePermission("imports_product") === true
    );
  };

  const canShowFeaturesMenu = () => {
    return (
      checkFeaturePermission("imports_customer") === true ||
      checkFeaturePermission("imports_product") === true ||
      validateDisabledSubFeature("update_leads_in_import")
    );
  };

  return (
    <>
      <Container>
        <Wrapper>
          <TopWrapper>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Header>Imports</Header>

              {canShowSectionByChipScope() && (
                <ChipsContainer>
                  {importsPageChipFilters.map((data, index) => {
                    return (
                      <Chip
                        style={{ fontWeight: "bold" }}
                        key={index}
                        color={scope === data.value ? "primary" : "default"}
                        label={getLocalizedText(data.label)}
                        onClick={() => {
                          handleChip(data.value);
                        }}
                      />
                    );
                  })}
                </ChipsContainer>
              )}
            </Box>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  analytics.triggerEvent(
                    4625000098,
                    "create_leads_button_tap",
                    "imports_page",
                    "create_leads_button",
                    {}
                  );
                  setOpenCreateLeadImport(true);
                }}
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  color: "white",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {getLocalizedText("create_leads", "project")}
              </Button>

              {canShowFeaturesMenu() && (
                <IconButton
                  style={{ padding: "0px" }}
                  aria-label="more"
                  id="long-button"
                  aria-controls={Open ? "long-menu" : undefined}
                  aria-expanded={Open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                style={{ display: "flex", flexDirection: "column" }}
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    width: "fit-content",
                  },
                }}
              >
                {checkFeaturePermission("imports_customer") === true && (
                  <MenuItem
                    onClick={() => {
                      setOpenImportCustomerDialog(true);
                      handleMenuClose();
                    }}
                  >
                    {getLocalizedText("import_customers")}
                  </MenuItem>
                )}
                {checkFeaturePermission("imports_product") === true && (
                  <MenuItem
                    onClick={() => {
                      setOpenImportProductDialog(true);
                      handleMenuClose();
                    }}
                  >
                    {getLocalizedText("import_products")}
                  </MenuItem>
                )}
                {validateDisabledSubFeature("update_leads_in_import") ===
                  true && (
                  <MenuItem
                    onClick={() => {
                      analytics.triggerEvent(
                        4625000099,
                        "update_leads_button_tap",
                        "imports_page",
                        "update_leads_button",
                        {}
                      );
                      setOpenImport(true);
                      handleMenuClose();
                    }}
                  >
                    {getLocalizedText("update_leads")}
                  </MenuItem>
                )}
              </Menu>
            </ButtonWrapper>
          </TopWrapper>
          <Bottom>
            {(() => {
              if (scope === "leads") {
                return (
                  <LeadImportsDataTable
                    refresh={refresh}
                    setRefresh={setRefresh}
                    scope={scope}
                  />
                );
              } else if (scope === "customers") {
                return (
                  <CustomerImportsDataTable
                    refresh={refreshCustomerImports}
                    setRefresh={setRefreshCustomerImports}
                    scope={scope}
                  />
                );
              } else if (scope === "products") {
                return (
                  <ProductImportsDataTable
                    refresh={refreshProductImports}
                    setRefresh={setRefreshProductImports}
                    scope={scope}
                  />
                );
              }
            })()}
          </Bottom>
        </Wrapper>
      </Container>
      <Footer />
      {openImport && (
        <UploadModal
          open={openImport}
          setOpen={setOpenImport}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}

      {openCreateLeadImport && (
        <CreateLeadImportModal
          open={openCreateLeadImport}
          setOpen={setOpenCreateLeadImport}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {openImportCustomerDialog && (
        <ImportCustomersDialog
          handleClose={() => {
            setOpenImportCustomerDialog(false);
          }}
          handleCloseResult={() => {
            setRefreshCustomerImports(true);
            setOpenImportCustomerDialog(false);
          }}
          open={openImportCustomerDialog}
        />
      )}

      {openImportProductDialog && (
        <ImportProductDialog
          handleClose={() => {
            setOpenImportProductDialog(false);
          }}
          open={openImportProductDialog}
          handleCloseResult={() => {
            setRefreshProductImports(true);
            setOpenImportProductDialog(false);
          }}
        />
      )}
    </>
  );
};

export default observer(ImportHistory);
