import React, { useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Cancel } from "@material-ui/icons";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import packageJson from "../../../../package.json";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black with 40% opacity */
  z-index: 1;
`;

const PdfThumbnail = styled(Box)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InputFileViewComponent = ({
  fileURL,
  handleView,
  scope,
  handleRemove,
  canRemove = true,
}) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const RenderThumbnail = useCallback(() => {
    if (scope === "image") {
      return <Image src={fileURL} style={{ zIndex: 0 }} />;
    } else {
      return (
        <PdfThumbnail>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={fileURL} plugins={[thumbnailPluginInstance]} />
            <Thumbnails />
          </Worker>
        </PdfThumbnail>
      );
    }
  }, [fileURL]);
  return (
    <Container
      onClick={handleView}
      sx={{
        "& .rpv-core__viewer.rpv-core__viewer--light": {
          position: "absolute",
        },
      }}
    >
      <Overlay />
      {canRemove && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleRemove();
          }}
          color="error"
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            padding: "2px",
            zIndex: 2, // Ensure the icon is above the overlay
          }}
        >
          <Cancel />
        </IconButton>
      )}
      <RenderThumbnail />
    </Container>
  );
};

export default InputFileViewComponent;
