import { Edit, MoreHoriz } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getAdminStatusesApi } from "../../Api";
import { AdminStatusColumns } from "../../Db";
import AddStatusModal from "../admin_setting_modals/AddStatusModal";
import analytics from "../../Analytics";
import ViewUpdateFieldsModal from "../admin_setting_modals/ViewUpdateFieldsModal";
import { Drawer, IconButton } from "@material-ui/core";
import ViewAdminStatusSidepanel from "../admin_setting_modals/view_sidepanels/ViewAdminStatusSidepanel";
import { Box, styled, Typography } from "@mui/material";
const Container = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 234px);`};

  position: relative;
  /* margin-top: 70px; */
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const StatusTab = (props) => {
  const [row, setRow] = useState([]);
  const [visibleRow, setVisibleRow] = useState([]);
  const [hiddenRow, setHiddenRow] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [viewUpdateFields, setViewUpdateFields] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDetailSidepanel, setOpenDetailSidepanel] = useState(false);
  const getStatusList = async () => {
    setRow([]);
    setVisibleRow([]);
    setHiddenRow([]);
    try {
      let response = await getAdminStatusesApi(props.id);
      let newData = [];
      if (response.data.length > 0) {
        newData = response.data.sort(function (a, b) {
          if (a.default > b.default) {
            return -1;
          }
          if (a.default < b.default) {
            return 1;
          }
          return 0;
        });
      }
      setRow(newData);

      //split row data based on "is_visible_to_add" key for visible/hidden tabs
      let visibleData = response.data.filter((x) => x.is_visible_to_add);
      setVisibleRow(visibleData);

      let hiddenData = response.data.filter((x) => !x.is_visible_to_add);
      setHiddenRow(hiddenData);
    } catch (error) {
      console.log(error);
    }
  };
  const init = async () => {
    await getStatusList();
    setLoading(false);
  };
  const refetch = async () => {
    await getStatusList();
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);
  useEffect(() => {
    init();
  }, []);
  const actionColumn = [
    {
      field: "update_fields",
      headerName: <Text>Fields to Update</Text>,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        if (params.row.update_fields.length > 0) {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEditableData(params.row);
                setViewUpdateFields(true);
              }}
            >
              <MoreHoriz color="primary" />
            </IconButton>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <>
            <Edit
              onClick={(e) => {
                analytics.triggerEvent(
                  4625000212,
                  "edit_button_tap",
                  "project_statuses_page",
                  "edit_button",
                  {}
                );
                e.stopPropagation();
                e.preventDefault();
                setEditableData(params.row);
                setIsEdit(true);
                props.setOpen(true);
              }}
              style={{ marginRight: "20px", width: "18px", height: "18px" }}
              color="primary"
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                getRowHeight={() => "auto"}
                autoHeight={true}
                //rows={row}
                rows={props.tabValue === "visible" ? visibleRow : hiddenRow}
                columns={AdminStatusColumns.concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                disableColumnMenu={true}
                loading={loading}
                onRowClick={(params) => {
                  setEditableData(params.row);
                  setOpenDetailSidepanel(true);
                }}
              />
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {props.open && (
        <AddStatusModal
          projectID={props.id}
          setOpen={props.setOpen}
          open={props.open}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          editableData={editableData}
          setRefresh={setRefresh}
          statusList={row}
        />
      )}
      {viewUpdateFields && (
        <ViewUpdateFieldsModal
          setOpen={setViewUpdateFields}
          open={viewUpdateFields}
          editableData={editableData}
        />
      )}
      {openDetailSidepanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openDetailSidepanel}
          onClose={() => {
            setOpenDetailSidepanel(false);
          }}
        >
          <ViewAdminStatusSidepanel
            details={editableData}
            handleEdit={() => {
              setIsEdit(true);
              props.setOpen(true);
              setOpenDetailSidepanel(false);
            }}
          />
        </Drawer>
      )}
    </>
  );
};

export default StatusTab;
