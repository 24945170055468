import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Modal } from "@material-ui/core";
import { Percent } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  AppBar,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { ExpandMore } from "@material-ui/icons";
import { getLocalizedText } from "../../../Functions";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin: 0px;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin: 0px;
  margin-top: 2px;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const AddCustomItemModal = ({
  open,
  dealValueCurrency,
  isEdit,
  editableProduct,
  postAddCallback,
  postEditCallback,
  handleClose,
}) => {
  const [selectedProductData, setSelectedProductData] = useState({
    desc: "",
    rate: "",
    quantity: "",
    discount: "",
    discount_in_price: "",
  });
  const [customProductName, setCustomProductName] = useState("");
  const [discountType, setDiscountType] = useState("price");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setErrorMessage("");
    setShowAlert(false);
    setSelectedProductData({
      ...selectedProductData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditProduct = () => {
    let newObj = { ...editableProduct };
    let rate =
      selectedProductData.hasOwnProperty("rate") &&
      selectedProductData["rate"] !== null &&
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);

    let discount =
      selectedProductData["discount"] !== ""
        ? parseFloat(selectedProductData["discount"])
        : 0;
    let discountInPrice =
      selectedProductData["discount_in_price"] !== ""
        ? parseFloat(selectedProductData["discount_in_price"])
        : 0;
    let amount = rate * quantity;

    if (discountType === "price") {
      if (selectedProductData["discount_in_price"] !== "") {
        let discountPricePercent =
          (parseFloat(selectedProductData["discount_in_price"]) / amount) * 100;
        discount = parseFloat(discountPricePercent.toFixed(2));
      }
    } else if (discountType === "percentage") {
      if (selectedProductData["discount"] !== "") {
        let discountableAmount =
          (amount * parseFloat(selectedProductData["discount"])) / 100;

        discountInPrice = discountableAmount;
      }
    }

    newObj["discount"] = discount;
    newObj["discount_in_price"] = discountInPrice;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["actual_amount"] = amount;
    let discountedAmount = parseFloat((amount - discountInPrice).toFixed(2));
    newObj["amount"] = discountedAmount;
    newObj["discount_type"] = discountType;

    newObj["product"]["desc"] = selectedProductData["desc"];
    newObj["product"]["name"] = customProductName;
    postEditCallback(newObj);
  };

  const handleAddProduct = () => {
    let newObj = {};
    let rate =
      selectedProductData.hasOwnProperty("rate") &&
      selectedProductData["rate"] !== null &&
      selectedProductData["rate"] !== ""
        ? parseFloat(selectedProductData["rate"])
        : 0;
    let quantity = parseInt(selectedProductData["quantity"]);

    let discount =
      selectedProductData["discount"] !== ""
        ? parseFloat(selectedProductData["discount"])
        : 0;
    let discountInPrice =
      selectedProductData["discount_in_price"] !== ""
        ? parseFloat(selectedProductData["discount_in_price"])
        : 0;
    let amount = rate * quantity;

    if (discountType === "price") {
      if (selectedProductData["discount_in_price"] !== "") {
        let discountPricePercent =
          (parseFloat(selectedProductData["discount_in_price"]) / amount) * 100;
        discount = parseFloat(discountPricePercent.toFixed(2));
      }
    } else if (discountType === "percentage") {
      if (selectedProductData["discount"] !== "") {
        let discountableAmount =
          (amount * parseFloat(selectedProductData["discount"])) / 100;

        discountInPrice = discountableAmount;
      }
    }

    newObj["discount"] = discount;
    newObj["discount_in_price"] = discountInPrice;
    newObj["rate"] = rate;
    newObj["quantity"] = quantity;
    newObj["actual_amount"] = amount;
    let discountedAmount = parseFloat((amount - discountInPrice).toFixed(2));
    newObj["amount"] = discountedAmount;

    newObj["discount_type"] = discountType;
    newObj["product"] = {
      desc: selectedProductData["desc"],
      type: "custom_item",
      name: customProductName,
      id: uuidv4(),
    };
    postAddCallback(newObj);
  };

  const hasValidDiscount = () => {
    if (selectedProductData["discount_in_price"] !== "") {
      let amount =
        parseFloat(selectedProductData["rate"]) *
        parseInt(selectedProductData["quantity"]);
      return parseInt(selectedProductData["discount_in_price"]) < amount;
    }
    return true;
  };

  const isDiscountFieldDisabled = () => {
    return (
      selectedProductData["quantity"] === "" ||
      selectedProductData["rate"] === ""
    );
  };

  const handleOffFocusRate = (value) => {
    if (value !== "") {
      if (selectedProductData["quantity"] !== "") {
        let amount =
          parseInt(selectedProductData["quantity"]) * parseInt(value);
        if (discountType === "price") {
          if (selectedProductData["discount_in_price"] !== "") {
            let discountPricePercent =
              (parseFloat(selectedProductData["discount_in_price"]) / amount) *
              100;
            setSelectedProductData({
              ...selectedProductData,
              discount: parseFloat(discountPricePercent.toFixed(2)),
            });
          }
        } else if (discountType === "percentage") {
          if (selectedProductData["discount"] !== "") {
            let discountableAmount =
              (amount * parseFloat(selectedProductData["discount"])) / 100;
            setSelectedProductData({
              ...selectedProductData,
              discount_in_price: discountableAmount,
            });
          }
        }
      }
    } else {
      if (
        selectedProductData["discount"] !== "" &&
        selectedProductData["discount_in_price"] !== ""
      ) {
        setSelectedProductData({
          ...selectedProductData,
          discount: "",
          discount_in_price: "",
        });
      }
    }
  };

  const handleOffFocusQty = (value) => {
    if (value !== "") {
      if (selectedProductData["rate"] !== "") {
        let amount = parseFloat(selectedProductData["rate"]) * parseInt(value);
        if (discountType === "price") {
          if (selectedProductData["discount_in_price"] !== "") {
            let discountPricePercent =
              (parseFloat(selectedProductData["discount_in_price"]) / amount) *
              100;
            setSelectedProductData({
              ...selectedProductData,
              discount: parseFloat(discountPricePercent.toFixed(2)),
            });
          }
        } else if (discountType === "percentage") {
          if (selectedProductData["discount"] !== "") {
            let discountableAmount =
              (amount * parseFloat(selectedProductData["discount"])) / 100;
            setSelectedProductData({
              ...selectedProductData,
              discount_in_price: discountableAmount,
            });
          }
        }
      }
    } else {
      if (
        selectedProductData["discount"] !== "" &&
        selectedProductData["discount_in_price"] !== ""
      ) {
        setSelectedProductData({
          ...selectedProductData,
          discount: "",
          discount_in_price: "",
        });
      }
    }
  };

  const handleOffFocusDiscountPrice = (value) => {
    if (value !== "") {
      let discountPrice = parseFloat(value);
      let amount =
        parseInt(selectedProductData["quantity"]) *
        parseFloat(selectedProductData["rate"]);
      if (discountPrice > amount) {
        setErrorMessage("Discount amount should be less than actual amount!");
        setShowAlert(true);
      } else {
        let discountPricePercent = (discountPrice / amount) * 100;
        setSelectedProductData({
          ...selectedProductData,
          discount: parseFloat(discountPricePercent.toFixed(2)),
        });
      }
    } else {
      if (selectedProductData["discount"] !== "") {
        setSelectedProductData({ ...selectedProductData, discount: "" });
      }
    }
  };

  const handleOffFocusDiscountPercent = (value) => {
    if (value !== "") {
      let discountPercent = parseFloat(value);
      let amount =
        parseInt(selectedProductData["quantity"]) *
        parseFloat(selectedProductData["rate"]);
      let discountedPrice = (amount * discountPercent) / 100;
      setSelectedProductData({
        ...selectedProductData,
        discount_in_price: parseFloat(discountedPrice.toFixed(2)),
      });
    } else {
      if (selectedProductData["discount_in_price"] !== "") {
        setSelectedProductData({
          ...selectedProductData,
          discount_in_price: "",
        });
      }
    }
  };
  const setupEditableData = () => {
    let productName = editableProduct.hasOwnProperty("product")
      ? editableProduct["product"]["name"]
      : "";
    setCustomProductName(productName);

    let tempSelectedProductData = {
      desc: editableProduct["product"].hasOwnProperty("desc")
        ? editableProduct["product"]["desc"]
        : "",
      rate: editableProduct.hasOwnProperty("rate")
        ? editableProduct["rate"]
        : "",
      quantity: editableProduct.hasOwnProperty("quantity")
        ? editableProduct["quantity"]
        : "",
      discount: editableProduct.hasOwnProperty("discount")
        ? editableProduct["discount"]
        : "",
      discount_in_price: editableProduct.hasOwnProperty("discount_in_price")
        ? editableProduct["discount_in_price"]
        : "",
    };

    setSelectedProductData(tempSelectedProductData);
    if (editableProduct.hasOwnProperty("discount_type")) {
      setDiscountType(editableProduct["discount_type"]);
    }
  };
  useEffect(() => {
    if (isEdit) {
      setupEditableData();
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Container
            component={"form"}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (hasValidDiscount()) {
                if (isEdit) {
                  handleEditProduct();
                } else {
                  handleAddProduct();
                }
              } else {
                setErrorMessage(
                  getLocalizedText(
                    "discount_amount_should_be_less_than_actual_amount"
                  )
                );
                setShowAlert(true);
              }
            }}
          >
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  {isEdit ? (
                    <Header>{getLocalizedText("edit_custom_item")}</Header>
                  ) : (
                    <Header>{getLocalizedText("add_custom_item")}</Header>
                  )}
                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>

            <Divider style={{ margin: "16px", marginTop: "0px" }} />
            <Box
              style={{
                height: "100%",
                height: "100%",
                padding: "0px 16px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("name")}*`}</Label>
                    <OutlinedInput
                      required
                      type="text"
                      placeholder="Name"
                      onChange={(e) => {
                        setCustomProductName(e.target.value);
                      }}
                      name="name"
                      value={customProductName}
                      style={{ height: "30px", overflow: "hidden" }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 1,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{getLocalizedText("description")}</Label>
                    <OutlinedInput
                      multiline
                      rows={3}
                      type="text"
                      placeholder="Description"
                      onChange={handleChange}
                      name="desc"
                      value={selectedProductData["desc"]}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 1,
                        "aria-label": "weight",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("quantity")}*`}</Label>

                    <OutlinedInput
                      required
                      type="number"
                      placeholder="Quantity"
                      onBlur={(e) => handleOffFocusQty(e.target.value)}
                      onChange={handleChange}
                      name="quantity"
                      value={selectedProductData["quantity"]}
                      style={{ height: "30px", overflow: "hidden" }}
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 1,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <Label>{`${getLocalizedText("rate")}*`}</Label>
                    <OutlinedInput
                      type="number"
                      placeholder="Rate"
                      name="rate"
                      onBlur={(e) => handleOffFocusRate(e.target.value)}
                      onChange={handleChange}
                      value={selectedProductData["rate"]}
                      style={{ height: "30px", overflow: "hidden" }}
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="start">
                          <Label style={{ fontSize: "12px", color: "gray" }}>
                            {dealValueCurrency}
                          </Label>
                        </InputAdornment>
                      }
                      required
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        min: 0,
                        step: "0.01",
                        "aria-label": "weight",
                        style: {
                          padding: "0px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Label style={{ fontSize: "14px" }}>
                        {getLocalizedText("discount")}
                      </Label>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <InputWrapper>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            gap: "16px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                            }}
                          >
                            <FormControlLabel
                              disabled={isDiscountFieldDisabled()}
                              value="price"
                              checked={discountType === "price"}
                              onChange={() => {
                                setDiscountType("price");
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("price")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Label
                                    style={{ fontSize: "12px", color: "gray" }}
                                  >
                                    {dealValueCurrency}
                                  </Label>
                                </InputAdornment>
                              }
                              disabled={
                                discountType === "percentage" ||
                                isDiscountFieldDisabled()
                              }
                              onBlur={(e) =>
                                handleOffFocusDiscountPrice(e.target.value)
                              }
                              onChange={handleChange}
                              name="discount_in_price"
                              value={selectedProductData["discount_in_price"]}
                              placeholder="Enter value"
                            />
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #cccccc",
                              padding: "12px",
                              paddingTop: "0px",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <FormControlLabel
                              disabled={isDiscountFieldDisabled()}
                              onChange={() => {
                                setDiscountType("percentage");
                              }}
                              checked={discountType === "percentage"}
                              value="percentage"
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>
                                  {getLocalizedText("percentage")}
                                </Label>
                              }
                            />
                            <OutlinedInput
                              disabled={
                                discountType === "price" ||
                                isDiscountFieldDisabled()
                              }
                              style={{
                                height: "30px",
                                width: "100%",
                                overflow: "hidden",
                              }}
                              inputProps={{
                                min: 0,
                                max: 100,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Percent
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      color: "gray",
                                    }}
                                  />
                                </InputAdornment>
                              }
                              onBlur={(e) =>
                                handleOffFocusDiscountPercent(e.target.value)
                              }
                              onChange={handleChange}
                              name="discount"
                              value={selectedProductData["discount"]}
                              placeholder="Enter value"
                            />
                          </Box>
                        </Box>
                      </InputWrapper>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Box>
            <AppBar
              position="static"
              color="inherit"
              elevation={0}
              sx={{
                top: "auto",
                bottom: 0,
                width: "100%",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                  width: "100%",
                  position: "relative",
                }}
              >
                {showAlert && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowAlert(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                        gap: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        type="button"
                        variant="contained"
                        color="inherit"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "none",

                          fontSize: "12px",
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        {isEdit
                          ? getLocalizedText("edit")
                          : getLocalizedText("add")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Container>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default AddCustomItemModal;
