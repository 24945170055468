import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import rootStore from "../../../stores/RootStore";
import LeadsAssignModal from "../../lead_assign_modal/LeadsAssignModal";
import { observer } from "mobx-react-lite";
import {
  changeLeadstatusApi,
  deleteMultipleLeadsApi,
  getFilteredLeadsApiV2,
  getLeadsApi,
  markUnmarkMultipleLeadAsFreshLeadApi,
  resetNextFollowupForLeadsApi,
} from "../../../Api";
import {
  FormattedColumnsFilters,
  leadListingIconsColumn,
} from "../../../ColumnUtils";
import { Assignment } from "@material-ui/icons";
import {
  checkFeatureAndSubFeaturePermission,
  checkFeaturePermission,
  currentDayNameGetter,
  getAvailableStatusListByStage,
  getLocalizedText,
  getLocalTimeFromIsoString,
  hasAccessToLeadsPaginationV2,
  hasAccessToWABAFeatures,
  isAdditionalStatusDetailsRequired,
  isLastContactedToday,
  isOpenCallLogContextMenuDisabled,
  isSetLeadColorContextMenuDisabled,
  validateDisabledSubFeature,
} from "../../../Functions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SuccessMessageDialog from "../../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../../alert_dialogue/ErrorMessageDialog";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import CustomConfirmationDialog from "../../alert_dialogue/CustomConfirmationDialog";
import MultiLeadExportModal from "../../multi_lead_export_modal/MultiLeadExportModal";
import MultiLeadUpdateStageModal from "../../update_stage_modal/MultiLeadUpdateStageModal";
import MultiLeadUpdateSourceModal from "../../update_source_modal/MultiLeadUpdateSourceModal";
import MultiLeadUpdateStatusModal from "../../update_status_modal/MultiLeadUpdateStatusModal";
import analytics from "../../../Analytics";
import DeleteLeadConfirmationDialog from "../../alert_dialogue/DeleteLeadConfirmationDialog";
import CustomMessageDialog from "../../alert_dialogue/CustomMessageDialog";
import PriorityIndividualLeadDialog from "../../priority_lead_dialog/PriorityIndividualLeadDialog";
import MoveProjectLeadModal from "../../move_leads_to_project/MoveProjectLeadModal";
import { toJS } from "mobx";
import DialogWithCommentField from "../../alert_dialogue/DialogWithCommentField";
import SetMultiLeadColorDialog from "../../update_lead_color_dialog/SetMultiLeadColorDialog";
import CallLogModal from "../../call_log_modal/CallLogModal";
import SetLeadColorDialog from "../../update_lead_color_dialog/SetLeadColorDialog";
import LeadContextMenu from "../../lead_context_menu/LeadContextMenu";
import TableSkeleton from "../../home_tabs/components/TableSkeleton";
import CustomAlertMessageDialog from "../../alert_dialogue/CustomAlertMessageDialog";
import moment from "moment";
import DropdownColumnWidget from "../../home_tabs/components/DropdownColumnWidget";
import UpdateStatusModal from "../../update_status_modal/UpdateStatusModal";
import ExportMultiLeadDialog from "../../export_multi_lead_dialog/ExportMultiLeadDialog";
import AddLeadRemarksDialog from "../../add_lead_remarks_dialog/AddLeadRemarksDialog";
import { AgGridReact } from "ag-grid-react";
import LeadsListingPaginationComponentV2 from "../../pagination/LeadsListingPaginationComponentV2";
import LeadListingPaginationComponentV1 from "../../pagination/LeadListingPaginationComponentV1";
import { leadListingRowBuffer } from "../../../Config";
import MultiLeadUpdateFieldModal from "../../update_field_modal/MultiLeadUpdateFieldModal";
import MultiLeadTemplateSendComponent from "../../whatsapp_business_chat_components/MultiLeadTemplateSendComponent";

const Header = styled(Typography)`
  font: normal normal 600 28px Open Sans;
  color: #4d4e4f;
`;
const Container = styled(Box)`
  min-height: 100vh;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const get_leads_url = process.env.REACT_APP_API_BASE_URL;
const FilterTable = (props) => {
  const { authStore, userStore } = rootStore;
  const [maxLeadLimit, setMaxLeadLimit] = useState(1000);
  let navigate = useNavigate();
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openAssignLead, setOpenAssignLead] = useState(false);
  const [columns, setColumns] = useState([]);
  let pid = authStore.projectId;
  let uid = localStorage.getItem("uid");
  let columnsList = [...userStore.AllColumnsList];
  let url = `${get_leads_url}projects/${pid}/leads/tab`;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openConfirmMarkLeadDialog, setOpenConfirmMarkLeadDialog] =
    useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openUpdateSourceModal, setOpenUpdateSourceModal] = useState(false);
  const [openUpdateStageModal, setOpenUpdateStageModal] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openMoveLead, setOpenMoveLead] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);
  const [openPriorityLeadDialog, setOpenPriorityLeadDialog] = useState(false);
  const [openCustomCommentDialog, setOpenCustomCommentDialog] = useState(false);
  const [openConfirmResetFollowupDialog, setOpenConfirmResetFollowupDialog] =
    useState(false);
  const [openSetLeadColorDialog, setOpenSetLeadColorDialog] = useState(false);
  //********************state variable related to lead context menu******** */
  const [leadContextAnchor, setLeadContextAnchor] = useState(null);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [
    openIndividualSetLeadColorDialog,
    setOpenIndividualSetLeadColorDialog,
  ] = useState(false);
  const [openCallLogDialog, setOpenCallLogDialog] = useState(false);
  //***************************END******************************* */
  const [showMaxLeadAlert, setShowMaxLeadAlert] = useState(false);
  const [showAssignLeadsMaxLeadAlert, setShowAssignLeadsMaxLeadAlert] =
    useState(false);
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);
  const [openUpdateFieldModal, setOpenUpdateFieldModal] = useState(false);
  const [openSendWhatsappTemplate, setOpenSendWhatsappTemplate] =
    useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");
  const [staleDataIDList, setStaleDataIDList] = useState([]);
  const [hoveredRowID, setHoveredRowID] = useState("");
  const [openExportLeadDialog, setOpenExportLeadDialog] = useState(false);
  const [openAddRemarksDialog, setOpenAddRemarksDialog] = useState(false);
  const [isAssignDisabled, setIsAssignDisabled] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageSize = async (e) => {
    rootStore.filterPageState.updateFilterPaginationInfo({
      key: "page_size",
      value: e.target.value,
    });
    rootStore.filterPageState.updateFilterPaginationInfo({
      key: "page",
      value: 0,
    });
    rootStore.filterPageState.reset();
    await fetchLeads();
  };
  const fetchLeads = async () => {
    setRow([]);
    setLoading(true);
    await rootStore.filterPageState.fetchLeads();
    setRow(rootStore.filterPageState.filterLeadList);
    setLoading(false);
    props.setFlag(false);
    setStaleDataIDList([]);
  };

  useEffect(() => {
    let list = authStore.currentProject;
    let today_day_name = "workday_" + currentDayNameGetter();
    if (list.hasOwnProperty(today_day_name)) {
      if (list[today_day_name]) {
        let start_time = getLocalTimeFromIsoString(list["work_start_time"]);
        let end_time = getLocalTimeFromIsoString(list["work_end_time"]);

        if (start_time !== null && end_time !== null) {
          let currentTime = moment().local();

          // Change the date of the given time to the current date, preserving the time
          start_time = moment(start_time).local().set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date(),
          });
          end_time = moment(end_time).local().set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date(),
          });

          if (currentTime.isBetween(start_time, end_time)) {
            setMaxLeadLimit(1000);
          } else {
            setMaxLeadLimit(5000);
          }
        }
      }
    }
  }, []);

  const fetchColumns = async () => {
    await rootStore.filterPageState.fetchColumns();

    let formattedColumn = FormattedColumnsFilters({
      columnMetaList: [...rootStore.filterPageState.selectColumns],
    });
    setColumns(formattedColumn);
  };

  const fetchLeadAndColumns = async () => {
    if (props.tabId !== null) {
      await fetchColumns();
      await fetchLeads();
    } else {
      setColumns([]);
      setRow([]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.isViewReset === false) {
      if (props.flag === true) {
        fetchLeadAndColumns();
      }
    } else {
      setRow([]);
    }
  }, [props.flag, props.tabId, props.refresh]);

  useEffect(() => {
    if (rootStore.filterPageState.leadSortingByColumnInfo["is_sorting"]) {
      if (rootStore.filterPageState.filterLeadList.length > 0) {
        fetchLeads();
      }
    }
  }, [rootStore.filterPageState.leadSortingByColumnInfo["is_sorting"]]); // this useeffect is to refetch the leads when we do column sorting based on is_sorting flag value

  useEffect(() => {
    if (refresh === true) {
      fetchLeads();
      rootStore.filterPageState.reset();
      setIsAssignDisabled(true);
      setRefresh(false);
    }
  }, [refresh]);
  const handlePage = async (e, page) => {
    rootStore.filterPageState.updateFilterPaginationInfo({
      key: "page",
      value: page - 1,
    });
    await fetchLeads();
  };

  const handleMarkLeadAsFreshLead = async () => {
    setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = {
      lead_ids: rootStore.filterPageState.getAllSelectedLeadIDList(),
      status: true,
    };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  const handleResetFollowupForLeads = async (comments) => {
    setOpenConfirmResetFollowupDialog(false);
    setShowBackDrop(true);
    let payload = {
      lead_ids: rootStore.filterPageState.getAllSelectedLeadIDList(),
      comments: comments,
    };
    let response = await resetNextFollowupForLeadsApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Next Follow-up reset successfully!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    let selectedLeadIDS = rootStore.filterPageState.getAllSelectedLeadIDList();

    for (let i = 0; i < selectedLeadIDS.length; i++) {
      let leadData =
        rootStore.filterPageState.leadDetailsForSelectedIDs[selectedLeadIDS[i]];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let selectedLeadIDS =
        rootStore.filterPageState.getAllSelectedLeadIDList();
      let filteredLeadIds = [];

      for (let i = 0; i < selectedLeadIDS.length; i++) {
        let leadData =
          rootStore.filterPageState.leadDetailsForSelectedIDs[
            selectedLeadIDS[i]
          ];
        if (leadData["is_customer"] !== null) {
          if (leadData["is_customer"] === false) {
            filteredLeadIds.push(selectedLeadIDS[i]);
          }
        } else {
          // including leads with null is_customer value
          filteredLeadIds.push(selectedLeadIDS[i]);
        }
      }

      let response = await deleteMultipleLeadsApi({
        idList: filteredLeadIds,
      });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        setRefresh(true);
      }
    }
  };

  const canShowMoveToProject = () => {
    if (checkFeaturePermission("move_to_project")) {
      if (toJS(authStore.projectList).length > 1) {
        return true;
      }
    }
    return false;
  };

  const sortIdsBasedOnListingOrder = (selectedLeadIDs) => {
    // Create a map to store the index of each object's ID in the list of objects
    const idIndexMap = new Map(row.map((obj, index) => [obj.id, index]));
    let ids = [...selectedLeadIDs];
    // Sort the list of IDs based on their index in the list of objects
    return ids.sort((a, b) => idIndexMap.get(a) - idIndexMap.get(b));
  };

  //****************************END********************************** */

  const getAllLeadIDs = async (offset) => {
    if (props.tabId !== "all") {
      let payload = {
        tab_id: props.tabId,
        query: props.filterPayload,
      };
      let response = await getLeadsApi({
        page: offset,
        page_size: props.size,
        payload: payload,
      });
      if (response.hasOwnProperty("items")) {
        let idList = response["items"].map((obj) => obj.id);
        return idList;
      } else {
        return [];
      }
    } else {
      let response = await getFilteredLeadsApiV2({
        page_size: props.size,
        page_no: offset,
        payload: { query: props.filterPayload },
      });
      if (response.hasOwnProperty("items")) {
        let idList = response["items"].map((obj) => obj.id);
        return idList;
      } else {
        return [];
      }
    }
  };

  const handleQuickUpdate = async ({ rowID, status }) => {
    let payload = { status: status, sub_status: "None" };
    let response = await changeLeadstatusApi({
      data: payload,
      id: rowID,
    });
    if (!response.hasError()) {
      reFreshLeadListingLocally({ leadData: response.data });
    }
  };

  const reFreshLeadListingLocally = ({ leadData }) => {
    let tempList = [...row];
    let index = tempList.findIndex((entry) => entry["id"] === leadData["id"]);
    if (index !== -1) {
      tempList[index] = leadData;
    }
    setRow(tempList);
    let tempIDList = [...staleDataIDList];
    if (!staleDataIDList.includes(leadData["id"])) {
      tempIDList.push(leadData["id"]);
    }
    setStaleDataIDList(tempIDList);
  };

  const StatusFieldRenderer = ({ rowData }) => {
    if (hoveredRowID === rowData.id) {
      const availableStatuses = getAvailableStatusListByStage({
        currentStage: rowData["lead_stage"],
        stageList: stagesList,
        statusList: statusList,
      });

      let index = availableStatuses.findIndex(
        (status) => status["status"] === rowData["lead_status"]
      );
      if (index === -1) {
        let statusIndexInAllStatus = statusList.findIndex(
          (status) => status["status"] === rowData["lead_status"]
        );
        if (statusIndexInAllStatus !== -1) {
          availableStatuses.unshift(statusList[statusIndexInAllStatus]);
        }
      }
      return (
        <DropdownColumnWidget
          scope={"status"}
          onChange={(value) => {
            let additionalDetailsRequired = isAdditionalStatusDetailsRequired({
              status: value,
              leadData: rowData,
            });

            if (additionalDetailsRequired === true) {
              setSelectedLeadStatus(value);
              setSelectedLeadData(rowData);
              setOpenUpdateStatusDialog(true);
            } else {
              handleQuickUpdate({ rowID: rowData.id, status: value });
            }
          }}
          options={availableStatuses}
          value={rowData["lead_status"]}
          labelKey={"status"}
          valueKey={"status"}
        />
      );
    } else {
      let value = rowData["lead_status"];
      let color_value = "";
      let index = statusList.findIndex((status) => status.status === value);
      if (index !== -1) {
        color_value = statusList[index]["color"];
      }
      return (
        <Tooltip title={value}>
          <span
            className="csutable-cell-trucate"
            style={{
              color: color_value !== null ? color_value : "",
            }}
          >
            {value}
          </span>
        </Tooltip>
      );
    }
  };

  const getColumnListForDatatable = () => {
    if (validateDisabledSubFeature("quick_lead_update_in_listing")) {
      let index = columns.findIndex(
        (column) => column["field"] === "lead_status"
      );
      if (index !== -1) {
        let tempObj = {
          ...columns[index],
          cellRenderer: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onMouseEnter={() => {
                  setHoveredRowID(params.data.id);
                }}
                onMouseLeave={() => {
                  setHoveredRowID("");
                }}
              >
                <StatusFieldRenderer rowData={params.data} />
              </Box>
            );
          },
        };
        columns[index] = tempObj;
      }
    }
    let len = columns.length;
    if (len > 0) {
      columns[len - 1]["minWidth"] = columns[len - 1]["width"];
      columns[len - 1]["flex"] = 1;
    }

    //checking if column width is persisted for a column
    columns.forEach((column) => {
      if (
        rootStore.filterPageState.columnsWidthMapping.hasOwnProperty(
          column["colId"]
        )
      ) {
        column["width"] =
          rootStore.filterPageState.columnsWidthMapping[column["colId"]];
      }
    });

    //********end******** */

    if (rootStore.filterPageState.selectedLeadCount > 0) {
      leadListingIconsColumn[0][
        "headerName"
      ] = `${rootStore.filterPageState.selectedLeadCount} Selected`;
    } else {
      leadListingIconsColumn[0]["headerName"] = "";
    }

    return leadListingIconsColumn.concat(columns);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
    preventDefaultOnContextMenu: validateDisabledSubFeature(
      "context_menu_in_lead_listing"
    ),
  };

  const handleCellContextMenu = (params) => {
    if (validateDisabledSubFeature("context_menu_in_lead_listing")) {
      params.event.preventDefault();
      setSelectedLeadData(params.data);
      setLeadContextAnchor({
        mouseX: params.event.clientX - 2,
        mouseY: params.event.clientY - 4,
      });
    }
  };
  const handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);

    let numberOfSelectedIds = selectedIds.length;
    let existingSelectedLeadCount =
      rootStore.filterPageState.getNumberOfSelectedLeads();
    let currentPageExistingLeads =
      rootStore.filterPageState.selectedLeadIDListMapping[props.page] || [];
    let tempIDs = sortIdsBasedOnListingOrder(selectedIds);
    let leadsList = {};
    for (let i = 0; i < tempIDs.length; i++) {
      let index = row.findIndex((lead) => lead["id"] === tempIDs[i]);
      leadsList[tempIDs[i]] = {
        id: row[index]["id"],
        lead_stage: row[index]["lead_stage"],
        lead_status: row[index]["lead_status"],
        is_customer: row[index]["is_customer"],
      };
    }

    rootStore.filterPageState.updateSelectedLeadIDListMapping({
      page: props.page,
      idList: tempIDs,
    });

    rootStore.filterPageState.updateLeadDetailsForSelectedIDs({
      leadsList: leadsList,
    });

    rootStore.filterPageState.updateSelectedLeadsDataList({
      page: props.page,
      list: selectedRows,
    });

    // old logic - for deselecting rows and showing  if it exceeds maxLimit

    // if (existingSelectedLeadCount === maxLeadLimit) {
    //   let idsToDeSelect = selectedIds.filter(
    //     (id) => !currentPageExistingLeads.includes(id)
    //   );
    //   event.api.forEachNode((node) => {
    //     if (idsToDeSelect.includes(node.data.id)) {
    //       node.setSelected(false);
    //     }
    //   });
    //   setShowMaxLeadAlert(true);
    // } else {
    //   let diff = numberOfSelectedIds - currentPageExistingLeads.length;
    //   let total = existingSelectedLeadCount + diff;

    //   if (total > maxLeadLimit) {
    //     let idsToDeSelect = selectedIds.filter(
    //       (id) => !currentPageExistingLeads.includes(id)
    //     );
    //     event.api.forEachNode((node) => {
    //       if (idsToDeSelect.includes(node.data.id)) {
    //         node.setSelected(false);
    //       }
    //     });
    //     setShowMaxLeadAlert(true);
    //   } else {
    //     rootStore.filterPageState.updateSelectedLeadIDListMapping({
    //       page: props.page,
    //       idList: tempIDs,
    //     });

    //     rootStore.filterPageState.updateLeadDetailsForSelectedIDs({
    //       leadsList: leadsList,
    //     });
    //   }
    // }

    let numberOfSelectedLeads =
      rootStore.filterPageState.getNumberOfSelectedLeads();
    if (numberOfSelectedLeads < 1) {
      setIsAssignDisabled(true);
    } else {
      setIsAssignDisabled(false);
    }
    rootStore.filterPageState.updateSelectedLeadCount(numberOfSelectedLeads);
  };

  const getRowStyle = (params) => {
    return {
      cursor: "pointer",
      backgroundColor: isLastContactedToday(params.data["last_contacted_on"])
        ? "#E6EDF7"
        : "unset",
      opacity: staleDataIDList.includes(params.data.id) ? "0.2" : "1",
    };
  };

  const onFirstDataRendered = (params) => {
    let currentPageSelectedLeadsIds =
      rootStore.filterPageState.selectedLeadIDListMapping[props.page] || [];
    const api = params.api;
    api.forEachNode((node) => {
      if (currentPageSelectedLeadsIds.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "icons") {
      params.event.stopPropagation();
      params.event.preventDefault();
    }
  };

  const onColumnResized = (params) => {
    if (params.finished) {
      // Get the resized column's ID and new width
      const colId = params.column.getColId();
      const newWidth = params.column.getActualWidth();

      rootStore.filterPageState.updateColumnsWidthMapping({
        columnID: colId,
        width: newWidth,
      });
    }
  };

  const memoizedColumns = useMemo(
    () => getColumnListForDatatable(),
    [columns, rootStore.filterPageState.selectedLeadCount, hoveredRowID]
  );

  return (
    <>
      <Container>
        <Row
          style={{
            marginBottom: "16px",
          }}
        >
          <Header>Search Results</Header>
          <Row style={{ width: "fit-content" }}>
            {checkFeaturePermission("assign_leads") && (
              <IconButton
                disabled={isAssignDisabled}
                onClick={() => {
                  analytics.triggerEvent(
                    4625000260,
                    "assign_lead_button_tap",
                    "filter_page",
                    "assign_lead_button",
                    {}
                  );
                  if (
                    rootStore.filterPageState.getNumberOfSelectedLeads() >
                    rootStore.userStore.leadsBulkAssignLimit
                  ) {
                    setShowAssignLeadsMaxLeadAlert(true);
                  } else {
                    setOpenAssignLead(true);
                  }
                }}
              >
                <Assignment style={{ cursor: "pointer" }} />
              </IconButton>
            )}

            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={Open ? "long-menu" : undefined}
              aria-expanded={Open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              style={{ display: "flex", flexDirection: "column" }}
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: "fit-content",
                },
              }}
            >
              {/* <MenuItem
                disabled={rootStore.filterPageState.getNumberOfSelectedLeads() < 1}
                onClick={() => {
                  setOpenExportDialog(true);
                  setAnchorEl(null);
                }}
              >
                Export
              </MenuItem> */}
              {checkFeaturePermission("lead_listing_delete") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    authStore.updateLastActiveTime();
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenDeleteLead(true);
                    }
                    handleClose();
                  }}
                >
                  {getLocalizedText("delete_leads", "project")}
                </MenuItem>
              )}
              {checkFeatureAndSubFeaturePermission("update_field") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    analytics.triggerEvent(
                      4624999487,
                      "update_field_button_tap",
                      "leads_page",
                      "update_field_button",
                      {}
                    );
                    authStore.updateLastActiveTime();
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenUpdateFieldModal(true);
                    }
                    handleClose();
                  }}
                >
                  Update Field
                </MenuItem>
              )}
              {checkFeatureAndSubFeaturePermission("update_source") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000275,
                      "update_source_button_tap",
                      "filter_page",
                      "update_source_button",
                      {}
                    );
                    authStore.updateLastActiveTime();
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenUpdateSourceModal(true);
                    }
                    handleClose();
                  }}
                >
                  Update Source
                </MenuItem>
              )}

              {checkFeatureAndSubFeaturePermission("update_stage") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000261,
                      "update_stage_button_tap",
                      "filter_page",
                      "update_stage_button",
                      {}
                    );
                    authStore.updateLastActiveTime();
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenUpdateStageModal(true);
                    }
                    handleClose();
                  }}
                >
                  Update Stage
                </MenuItem>
              )}

              {checkFeatureAndSubFeaturePermission("update_status") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000283,
                      "update_status_button_tap",
                      "filter_page",
                      "update_status_button",
                      {}
                    );
                    authStore.updateLastActiveTime();
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenUpdateStatusModal(true);
                    }
                    handleClose();
                  }}
                >
                  Update Status
                </MenuItem>
              )}
              {/* {checkFeaturePermission("fresh_lead") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    analytics.triggerEvent(
                      4625000262,
                      "mark_fresh_lead_button_tap",
                      "filter_page",
                      "mark_fresh_lead_button",
                      {}
                    );
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenConfirmMarkLeadDialog(true);
                    }
                    setAnchorEl(null);
                  }}
                >
                  Mark as Fresh Lead
                </MenuItem>
              )} */}
              {checkFeatureAndSubFeaturePermission("send_whatsapp_template") &&
                hasAccessToWABAFeatures() && (
                  <MenuItem
                    disabled={
                      rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                    }
                    onClick={() => {
                      authStore.updateLastActiveTime();
                      setOpenSendWhatsappTemplate(true);
                      handleClose();
                    }}
                  >
                    Send WhatsApp Template
                  </MenuItem>
                )}
              <MenuItem
                disabled={
                  rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                }
                onClick={() => {
                  if (
                    rootStore.filterPageState.getNumberOfSelectedLeads() >
                    maxLeadLimit
                  ) {
                    setShowMaxLeadAlert(true);
                  } else {
                    setOpenPriorityLeadDialog(true);
                  }
                  setAnchorEl(null);
                }}
              >
                {getLocalizedText("mark_as_priority_lead", "project")}
              </MenuItem>
              {canShowMoveToProject() && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenMoveLead(true);
                    }
                    setAnchorEl(null);
                  }}
                >
                  Move To Project
                </MenuItem>
              )}
              {checkFeaturePermission("reset_followup") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    if (
                      rootStore.filterPageState.getNumberOfSelectedLeads() >
                      maxLeadLimit
                    ) {
                      setShowMaxLeadAlert(true);
                    } else {
                      setOpenConfirmResetFollowupDialog(true);
                    }
                    setAnchorEl(null);
                  }}
                >
                  Reset Next Follow-up
                </MenuItem>
              )}
              <MenuItem
                disabled={
                  rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                }
                onClick={() => {
                  authStore.updateLastActiveTime();
                  if (
                    rootStore.filterPageState.getNumberOfSelectedLeads() >
                    maxLeadLimit
                  ) {
                    setShowMaxLeadAlert(true);
                  } else {
                    setOpenSetLeadColorDialog(true);
                  }
                  setAnchorEl(null);
                }}
              >
                {getLocalizedText("set_lead_color", "project")}
              </MenuItem>
              {checkFeaturePermission("export_leads_listing") && (
                <MenuItem
                  disabled={
                    rootStore.filterPageState.getNumberOfSelectedLeads() < 1
                  }
                  onClick={() => {
                    authStore.updateLastActiveTime();
                    setOpenExportLeadDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  {getLocalizedText("export_lead", "project")}
                </MenuItem>
              )}
            </Menu>
          </Row>
        </Row>
        <Wrapper>
          {!loading ? (
            <>
              <Box
                sx={{
                  "& .ag-root-wrapper-body": {
                    height: "calc(100vh - 127px)",
                  },
                  "& .ag-checkbox-input-wrapper input": {
                    cursor: "pointer",
                  },
                }}
              >
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={memoizedColumns}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={props.size}
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }

                      authStore.updateLastActiveTime();

                      navigate(`/users/${uid}/leads/${row.data.id}`, {
                        state: {
                          lead_status: null,
                          scope: "filter_page",
                        },
                      });
                    }}
                    onCellContextMenu={handleCellContextMenu}
                    rowSelection="multiple"
                    onSelectionChanged={handleSelectionChange}
                    onFirstDataRendered={onFirstDataRendered}
                    rowBuffer={leadListingRowBuffer}
                    suppressColumnVirtualisation={true}
                    onCellClicked={onCellClicked}
                    onColumnResized={onColumnResized}
                  />
                </div>
              </Box>
              <PaginationWrapper>
                {hasAccessToLeadsPaginationV2() ? (
                  <LeadsListingPaginationComponentV2
                    page_no={
                      rootStore.filterPageState.filterPaginataionInfo.page
                    }
                    page_size={
                      rootStore.filterPageState.filterPaginataionInfo.page_size
                    }
                    count={
                      rootStore.filterPageState.filterPaginataionInfo.count
                    }
                    paginationData={rootStore.filterPageState.getLeadPaginationCounterData()}
                    handlePageSize={handlePageSize}
                    handlePage={handlePage}
                    pageSizeOptionList={
                      rootStore.userStore.leadListingPageSizeOptions
                    }
                  />
                ) : (
                  <LeadListingPaginationComponentV1
                    pageNumber={props.page}
                    row={row}
                    pageSize={props.size}
                    count={
                      rootStore.filterPageState.filterPaginataionInfo["count"]
                    }
                    handlePageSize={handlePageSize}
                    handlePage={handlePage}
                    pageSizeOptionList={
                      rootStore.userStore.leadListingPageSizeOptions
                    }
                  />
                )}
              </PaginationWrapper>
            </>
          ) : (
            <TableSkeleton height={"calc(100vh - 98px)"} />
          )}

          {openAssignLead && (
            <LeadsAssignModal
              open={openAssignLead}
              setOpen={setOpenAssignLead}
              leads={rootStore.filterPageState.getAllSelectedLeadIDList()}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
              totalLeadCount={
                rootStore.filterPageState.filterPaginataionInfo["count"]
              }
              pageSize={props.size}
              getAllLeadIDsToAssign={async (offset) => {
                return await getAllLeadIDs(offset);
              }}
              enableAssignAllLead={true}
            />
          )}
        </Wrapper>
      </Container>
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
      {openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={openDeleteLead}
          setOpen={setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}
      {openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={getLocalizedText(
            "are_you_sure_you_want_to_mark_leads_as_fresh_lead",
            "project"
          )}
          open={openConfirmMarkLeadDialog}
          setOpen={setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}

      {openPriorityLeadDialog && (
        <PriorityIndividualLeadDialog
          open={openPriorityLeadDialog}
          setOpen={setOpenPriorityLeadDialog}
          selectedLeadIdsData={rootStore.filterPageState.getAllSelectedLeadIDList()}
          setRefresh={setRefresh}
        />
      )}
      {openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={openUpdateStageModal}
          setOpen={setOpenUpdateStageModal}
          selectedLeadIdsData={rootStore.filterPageState.getAllSelectedLeadIDList()}
          setRefresh={setRefresh}
          scope={"filter_page"}
        />
      )}
      {openUpdateSourceModal && (
        <MultiLeadUpdateSourceModal
          open={openUpdateSourceModal}
          setOpen={setOpenUpdateSourceModal}
          selectedLeadIdsData={rootStore.filterPageState.getAllSelectedLeadIDList()}
          setRefresh={setRefresh}
          scope={"filter_page"}
        />
      )}
      {openUpdateStatusModal && (
        <MultiLeadUpdateStatusModal
          open={openUpdateStatusModal}
          setOpen={setOpenUpdateStatusModal}
          selectedLeadIdsData={rootStore.filterPageState.getAllSelectedLeadIDList()}
          setRefresh={setRefresh}
          scope={"filter_page"}
        />
      )}
      {openUpdateFieldModal && (
        <MultiLeadUpdateFieldModal
          open={openUpdateFieldModal}
          setOpen={setOpenUpdateFieldModal}
          selectedLeadIdsData={rootStore.filterPageState.getAllSelectedLeadIDList()}
          setRefresh={setRefresh}
          scope={"filter_page"}
        />
      )}
      {openSendWhatsappTemplate && (
        <MultiLeadTemplateSendComponent
          open={openSendWhatsappTemplate}
          setOpen={setOpenSendWhatsappTemplate}
          selectedLeadsData={rootStore.filterPageState.getAllSelectedLeadDataList()}
        />
      )}
      {openMoveLead && (
        <MoveProjectLeadModal
          setOpen={setOpenMoveLead}
          open={openMoveLead}
          leads={rootStore.filterPageState.getAllSelectedLeadIDList()}
          pid={pid}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={getLocalizedText(
            "leads_are_delete_protected_unprotect_or_remove_to_proceed",
            "project"
          )}
        />
      )}
      {/* {openExportDialog && (
        <MultiLeadExportModal
          open={openExportDialog}
          setOpen={setOpenExportDialog}
          leads={leads}
          setRefresh={setRefresh}
        />
      )} */}
      {openConfirmResetFollowupDialog && (
        <CustomConfirmationDialog
          message={getLocalizedText(
            "are_you_sure_you_want_to_reset_next_followup_for_the_leads",
            "project"
          )}
          open={openConfirmResetFollowupDialog}
          setOpen={setOpenConfirmResetFollowupDialog}
          handleConfirm={() => {
            setOpenConfirmResetFollowupDialog(false);
            setOpenCustomCommentDialog(true);
          }}
        />
      )}
      {openCustomCommentDialog && (
        <DialogWithCommentField
          open={openCustomCommentDialog}
          setOpen={setOpenCustomCommentDialog}
          handleSubmitButton={(value) => {
            handleResetFollowupForLeads(value);
            setOpenCustomCommentDialog(false);
          }}
        />
      )}
      {openSetLeadColorDialog && (
        <SetMultiLeadColorDialog
          leadIDs={rootStore.filterPageState.getAllSelectedLeadIDList()}
          open={openSetLeadColorDialog}
          setOpen={setOpenSetLeadColorDialog}
          setRefresh={setRefresh}
        />
      )}
      {/* lead context menu related components */}
      {leadContextAnchor !== null && (
        <LeadContextMenu
          anchor={leadContextAnchor}
          setAnchor={setLeadContextAnchor}
          handleClose={() => {
            setLeadContextAnchor(null);
          }}
          handleMenuClick={(value) => {
            if (value === "set_lead_color") {
              setOpenIndividualSetLeadColorDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_call_log") {
              setOpenCallLogDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_remarks") {
              setOpenAddRemarksDialog(true);
              setLeadContextAnchor(null);
            }
          }}
          isOpenCallLogDisabled={() =>
            isOpenCallLogContextMenuDisabled({ leadData: selectedLeadData })
          }
          isSetLeadColorContextMenuDisabled={() =>
            isSetLeadColorContextMenuDisabled({ leadData: selectedLeadData })
          }
        />
      )}
      {openIndividualSetLeadColorDialog && (
        <SetLeadColorDialog
          open={openIndividualSetLeadColorDialog}
          setOpen={setOpenIndividualSetLeadColorDialog}
          setRefresh={setRefresh}
          leadID={selectedLeadData["id"]}
        />
      )}
      {openCallLogDialog && (
        <CallLogModal
          scope={"lead_listing"}
          open={openCallLogDialog}
          setOpen={setOpenCallLogDialog}
          lid={selectedLeadData["id"]}
          uid={localStorage.getItem("uid")}
          setRefreshCallLogAfterAdd={() => {}}
          setRefreshLeadDetails={setRefresh}
          leadData={selectedLeadData}
        />
      )}
      {showMaxLeadAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showMaxLeadAlert}
          handleClose={() => setShowMaxLeadAlert(false)}
          handleCloseOutside={() => setShowMaxLeadAlert(false)}
          message={`Action Failed: You have selected more than the allowed limit of ${maxLeadLimit} ${getLocalizedText(
            "leads",
            "project"
          ).toLowerCase()}.`}
        />
      )}
      {showAssignLeadsMaxLeadAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showAssignLeadsMaxLeadAlert}
          handleClose={() => setShowAssignLeadsMaxLeadAlert(false)}
          handleCloseOutside={() => setShowAssignLeadsMaxLeadAlert(false)}
          message={`Action Failed: You have selected more than the allowed limit of ${
            rootStore.userStore.leadsBulkAssignLimit
          } ${getLocalizedText("leads", "project").toLowerCase()}.`}
        />
      )}
      {openUpdateStatusDialog && (
        <UpdateStatusModal
          selectedLeadStatus={selectedLeadStatus}
          scope={"lead_listing_page"}
          id={selectedLeadData["id"]}
          open={openUpdateStatusDialog}
          setOpen={setOpenUpdateStatusDialog}
          data={selectedLeadData}
          setRefreshLeadDetails={() => {}}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}
      {openExportLeadDialog && (
        <ExportMultiLeadDialog
          leadIDs={rootStore.filterPageState.getAllSelectedLeadIDList()}
          open={openExportLeadDialog}
          setOpen={setOpenExportLeadDialog}
        />
      )}
      {openAddRemarksDialog && (
        <AddLeadRemarksDialog
          open={openAddRemarksDialog}
          setOpen={setOpenAddRemarksDialog}
          leadData={selectedLeadData}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}
      {/* end */}
    </>
  );
};

export default observer(FilterTable);
